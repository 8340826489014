import * as actionTypes from '../actionTypes';

const initialState = {
clientList : null,
clientDetail : null,
allClientList : null,
archieveClientList : null
};

const clientReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    switch (type) {

    case actionTypes.GetClientListRequested:
        object = { ...state, 
        //    clientList : null
         }
        break   
       
    case actionTypes.GetClientListSuccess:
        object = { ...state, clientList : payload }
        break

    case actionTypes.GetClientDetailRequested:
        object = { ...state, clientDetail: null }
        break

    case actionTypes.GetClientDetailSuccess:
        object = { ...state, clientDetail: payload }
        break

    case actionTypes.GetAllClientListSuccess:
        object = { ...state, allClientList: payload }
        break

    case actionTypes.GetClientArchiveListRequested:
        object = { ...state, 
            //archieveClientList : null 
        }
        break   
        
    case actionTypes.GetClientArchiveListSuccess:
        object = { ...state, archieveClientList : payload }
        break
        
    case actionTypes.LogOut: 
       object = { state : undefined };
        break

     default:
        object = state;
    }
    return object
};
export default clientReducer;
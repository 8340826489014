import axios from 'axios'
import { put, takeLatest, fork, call, delay } from 'redux-saga/effects';
import { WebConfig, AppConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loginAppSuccess,
    loadTodoRequest,
    loadTodoWarning,
    loadTodoError,
    loginDetail,
    getProfileRequested,
    getProfilesuccess,
    loadTodoSuccess,
    resettokenVerifyfailed,
    getInpersonateRequested,
    getInpersonateSuccess,
    getUserProfileRequested,
    getUserProfileSuccess,
    getQrCodeRequested,
    getQrCodeSuccess,
    getDeviceListRequested,
    getDeviceListSuccess,
    addDeviceSuccess,
    addDeviceRequested,
    addDeviceFailure
} from "store/actions";
import { JSON_to_URLEncoded } from 'utils/helpers';
import { ClientInstance } from 'utils/axios-instance';


const WEB_POINT_URL = WebConfig.serviceUrls.WebApi;
const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const COMPANIES_POINT_URL = WebConfig.ApiUrls.company.companies;
const ACCOUNTS_POINT_URL = WebConfig.ApiUrls.accounts;



export function* login(payload) {
    // alert(1)
    const data = payload;
    // yield put(loadTodoRequest());
    // const appIdtoken = yield* appLogin({ type: actionTypes.GetappAuth, payload: { ...AppConfig } })
    const response = yield call(() => {
        return axios
            .post(`${API_POINT_URL}${ACCOUNTS_POINT_URL}login/`, data.payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                return response;
            })
    });
    if (response) {
        if (response.data.status === 200) {
            yield put(loginDetail(response.data.response));
            if (response.data.response.two_factor_authentication) {
                localStorage.setItem('two_factor', true);
                localStorage.setItem('username', data.payload.username);
                localStorage.setItem('password', data.payload.password);
            }
            if (!response.data.response.two_factor_authentication) {
                localStorage.setItem('private_token', response.data.response.access)
                localStorage.setItem('refersh_token', response.data.response.refresh)

            }
        }
        else {
            yield put(loadTodoError(response.data.message.detail));

        }
    }
}


export function* azureLogin(payload) {
    const data = payload;
    const response = yield call(() => {
        return axios
            .post(`${API_POINT_URL}${ACCOUNTS_POINT_URL}login/`, data.payload, data.payload.azureToken && {
                headers: {
                    'Authorization': `Bearer ${data.payload.azureToken}`,
                    'Content-Type': 'application/json'
                },
            })
            .then(response => {
                return response;
            })
    });

    if (response) {
        if (response.data.status === 200) {
          //  console.log("response", response);
            yield put(loginDetail(response.data.response));
            if (data.payload.totp) {
                localStorage.setItem('two_factor_done', true);
            }
            if (response.data.response.two_factor_authentication) {
                localStorage.setItem('two_factor', true)
                localStorage.setItem('azureToken', data.payload.azureToken)
            }
            if (!response.data.response.two_factor_authentication) {
                if (localStorage.getItem("two_factor") ||
                    localStorage.getItem("azureToken")) {
                    localStorage.removeItem('two_factor');
                    localStorage.removeItem('azureToken');
                    localStorage.removeItem('username');
                    localStorage.removeItem('password');
                }
                localStorage.setItem('private_token', response.data.response.access)
                localStorage.setItem('refersh_token', response.data.response.refresh)
                // localStorage.setItem("inperosnate" , true)
                localStorage.setItem("inperosnateId", response.data.response.user_company_users && response.data.response.user_company_users.length > 0 ?
                    response.data.response.user_company_users[0].company : null);
                localStorage.setItem("impersonate_access_token", response.data.response.access)
                // yield getUserProfile({ type: actionTypes.GetUserProfile });
            }

        }
        else {
            yield put(loadTodoError(response.data.message.detail));
        }
    }
}




export function* forgotPassword(payload) {
    const data = payload;

    const response = yield call(() => {
        return axios
            .post(`${API_POINT_URL}${ACCOUNTS_POINT_URL}forgot-password`, data.payload, {
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then(response => {
                return response;
            })
    });

    if (response) {
        if (response.data.status === 200) {
            yield put(loadTodoSuccess("You will receive an email with instructions to reset your password"));
        }
        else {
            yield put(loadTodoError(response.data.message.detail));

        }
    }
}


export function* resetPassword(payload) {
    const data = payload;
    //  const appIdtoken = yield* appLogin({ type: actionTypes.GetappAuth, payload: { ...AppConfig } })
    const response = yield call(() => {
        return axios
            .post(`${API_POINT_URL}${ACCOUNTS_POINT_URL}reset-password/`, data.payload, {
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then(response => {
                return response;
            })
    });

    if (response) {
        if (response.data.status === 200) {
            yield put(loadTodoSuccess("Password has been changed successfully"));
        }
        else {
            yield put(loadTodoError(response.data.message.detail));

        }
    }

}


export function* getProfile(payload) {
    yield put(loaderShow());
    const data = payload;
    const { HideRequested } = payload
    if (!HideRequested) {
        yield put(getProfileRequested());
    }
    // yield delay(10000);
    const response = yield call(() => {
        return axios
            .get(`${API_POINT_URL}${ACCOUNTS_POINT_URL}profile/`)
            .then(response => {
                return response;
            })
    });
    if (response) {
        if (response.data.status === 200) {
            yield put(getProfilesuccess(response.data.response));
        }
        else {
            yield put(loadTodoError(response.data.message.detail));
        }
        // yield put(loaderhide());
    }
}


export function* getUserProfile() {
    yield put(getUserProfileRequested());
    yield put(loaderShow());
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${ACCOUNTS_POINT_URL}profile/`)
            .then(response => {
                return response;
            })
    });

    if (response) {
        if (response.data.status === 200) {
            if (!localStorage.getItem('inperosnateId')) {
                localStorage.setItem("inperosnateId", response.data.response.user_company_users && response.data.response.user_company_users.length > 0 ?
                    response.data.response.user_company_users[0].company : null);
            }
            localStorage.setItem("user_company_users", JSON.stringify(response.data.response.user_company_users))
            localStorage.setItem("inperosnate", true);
            yield put(getUserProfileSuccess(response.data.response));
        }
        else {
            yield put(loadTodoError(response.data.message.detail));
        }
        //yield put(loaderhide());
    }
}


export function* resettokenVerify(payload) {
    const data = payload;

    //  const appIdtoken = yield* appLogin({ type: actionTypes.GetappAuth, payload: { ...AppConfig } })

    const response = yield call(() => {
        return axios
            .post(`${API_POINT_URL}${ACCOUNTS_POINT_URL}forgot-password/validate-token/`, data.payload, {
                headers: {
                    //   'Authorization': `Bearer ${appIdtoken}`,
                    'Content-Type': 'application/json'
                },
            })
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 302) {
        yield put(resettokenVerifyfailed());
        yield put(loadTodoError(response.data.message.detail));

    }
}


export function* getInpersonate(payload) {
    const data = payload;

    yield put(getUserProfileRequested());
    yield put(getInpersonateRequested());

    const response = yield call(() => {
        return axios
            .get(`${API_POINT_URL}${COMPANIES_POINT_URL}${data.payload.id}/impersonate/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        localStorage.setItem("inperosnate", true)
        localStorage.setItem("inperosnateId", data.payload.id)
        localStorage.setItem("impersonate_access_token", response.data.response._impersonate_access_token)
        yield put(getInpersonateSuccess(response.data.response))
        yield getUserProfile({ type: actionTypes.GetUserProfile });

    }
    if (response && response.data.status === 302) {
        yield put(loadTodoWarning(response.data.message.detail));
    }
}



export function* updateProfile(payload) {
    const data = payload;

    const response = yield call(() => {
        return axios
            .patch(`${API_POINT_URL}${ACCOUNTS_POINT_URL}profile/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        yield delay(1000);
        yield getProfile({ type: actionTypes.GetProfile, payload: { HideRequested: true } });
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
}


export function* getQrCode(payload) {
    const data = payload;
    yield put(getQrCodeRequested());

    const response = yield call(() => {
        return axios
            .get(`${API_POINT_URL}${ACCOUNTS_POINT_URL}totp/provisioning_uri/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getQrCodeSuccess(response.data.response));
    }
}


export function* getDeviceList(payload) {
    yield put(getDeviceListRequested());
    const response = yield call(() => {
        return axios
            .get(`${API_POINT_URL}${ACCOUNTS_POINT_URL}totp/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getDeviceListSuccess(response.data.response));
    }
}

export function* addDevice(payload) {
    const data = payload;
    yield put(addDeviceRequested());
    const response = yield call(() => {
        return axios
            .post(`${API_POINT_URL}${ACCOUNTS_POINT_URL}totp/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        yield put(addDeviceSuccess());
        yield delay(1000);
        yield getDeviceList({ type: actionTypes.GetDeviceList });
    }
    if (response && response.data && response.data.status !== 200) {

        yield put(addDeviceFailure(response.data.message.detail));
        //yield put(loadTodoError(response.data.message.detail));
    }
}

export function* deleteDevice(payload) {
    const data = payload;
    const response = yield call(() => {
        return axios
            .delete(`${API_POINT_URL}${ACCOUNTS_POINT_URL}totp/${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        yield delay(1000);
        yield getDeviceList({ type: actionTypes.GetDeviceList });
    }
}

export function* fallBackCode(payload) {
    const data = payload;
    const response = yield call(() => {
        return axios
            .post(`${API_POINT_URL}${ACCOUNTS_POINT_URL}totp/fallback/`, data.payload, data.payload.azureToken && {
                headers: {
                    'Authorization': `Bearer ${data.payload.azureToken}`,
                    'Content-Type': 'application/json'
                },
            })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.message));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message));
    }
}

export function* changePassword(payload) {
    const data = payload;
    const response = yield call(() => {
        return axios
            .put(`${API_POINT_URL}${ACCOUNTS_POINT_URL}change-password/`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response) {
        if (response.data.status === 200) {
            yield put(loadTodoSuccess(response.data.message));
        }
        else {
            yield put(loadTodoError(response.data.message.detail));
        }
    }

}


function* watchlogin() {
    yield takeLatest(actionTypes.Login, login)
}

function* watchforgotPassword() {
    yield takeLatest(actionTypes.ForgotPassword, forgotPassword)
}

function* watchgetProfile() {
    yield takeLatest(actionTypes.GetProfile, getProfile)
}

function* watchresetPassword() {
    yield takeLatest(actionTypes.ResetPassword, resetPassword)
}

function* watchresettokeVerify() {
    yield takeLatest(actionTypes.ResetVerifyToken, resettokenVerify)
}

function* watchgetInpersonate() {
    yield takeLatest(actionTypes.InPersonate, getInpersonate)
}


function* watchazureLogin() {
    yield takeLatest(actionTypes.azureLogin, azureLogin)
}


function* watchgetUserProfile() {
    yield takeLatest(actionTypes.GetUserProfile, getUserProfile)
}

function* watchupdateProfile() {
    yield takeLatest(actionTypes.UpdateProfile, updateProfile)
}

function* watchgetQrCode() {
    yield takeLatest(actionTypes.GetQrCode, getQrCode)
}

function* watchgetDeviceList() {
    yield takeLatest(actionTypes.GetDeviceList, getDeviceList)
}

function* watchaddDevice() {
    yield takeLatest(actionTypes.AddDevice, addDevice)
}

function* watchdeleteDevice() {
    yield takeLatest(actionTypes.DeleteDevice, deleteDevice)
}

function* watchfallBackCode() {
    yield takeLatest(actionTypes.FallBackCode, fallBackCode)
}


function* watchchangePassword() {
    yield takeLatest(actionTypes.ChangePassword, changePassword)
}


export const AuthSaga = [
    //   fork(watchloginApp),
    fork(watchlogin),
    fork(watchgetProfile),
    fork(watchforgotPassword),
    fork(watchresetPassword),
    fork(watchresettokeVerify),
    fork(watchgetInpersonate),
    fork(watchazureLogin),
    fork(watchgetUserProfile),
    fork(watchupdateProfile),
    fork(watchgetQrCode),
    fork(watchgetDeviceList),
    fork(watchaddDevice),
    fork(watchdeleteDevice),
    fork(watchfallBackCode),
    fork(watchchangePassword)
]
import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig, } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getConfigurationSuccess,
    getPositionSuccess,
    getRecentSearchClient,
    getPostionDetailRequested,
    getPostionDetailSuccess,
    getPostionDropdownSuccess,
    getPositionEmployeSuccess,
    getPositionContactSuccess
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const POSTION_POINT_URL = WebConfig.ApiUrls.configurations.position;
const SUMMARY_POINT_URL = WebConfig.ApiUrls.configurations.summary;
const SUPERADMIN_POINT_URL = WebConfig.ApiUrls.superadmin;
const EMPLOYEES_POINT_URL = WebConfig.ApiUrls.company.employees;
const CLIENTS_POINT_URL = WebConfig.ApiUrls.company.clients;

export function* getConfiguration(payload) {
    const data = payload;

    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${SUMMARY_POINT_URL}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getConfigurationSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


export function* addPosition(payload) {
    const data = payload;

    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${POSTION_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}



export function* getPosition(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null } } = data;

    //  yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .get(search ?
                `${API_POINT_URL}${POSTION_POINT_URL}?page=${currentPage}&search=${search}` :
                status ? `${API_POINT_URL}${POSTION_POINT_URL}?page=${currentPage}&login_type=${status}` :
                    `${API_POINT_URL}${POSTION_POINT_URL}?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getPositionSuccess(response.data.response));
        if (search) {
            yield put(getRecentSearchClient({ page: 'positions-list' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}

export function* getPostionDetail(payload) {
    const data = payload;

    //   yield put(loadTodoRequest());
    yield put(getPostionDetailRequested());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${POSTION_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getPostionDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}

export function* deletePostion(payload) {
    const data = payload;

    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${POSTION_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000)

        if (!data.payload.postionDetailPage) {
            yield getPosition({ type: actionTypes.GetPosition, payload: { currentPage: data.payload.currentPage } });
        }

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}

export function* editPostion(payload) {
    const data = payload;

    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${POSTION_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


export function* getPostionDropdown(payload) {

    const data = payload;

    //  yield put(loadTodoRequest());
    

    let response;

    if (!localStorage.getItem('inperosnate')) {
        response = yield call(() => {
            return axios
                .get(`${API_POINT_URL}${SUPERADMIN_POINT_URL}positions/`)
                .then(response => {
                    return response;
                })
        });
    }
    else {
        response = yield call(() => {
            return ClientInstance
                .get(`${API_POINT_URL}${POSTION_POINT_URL}items/?fields=name,id,login_type&login_type=${data.payload.login_type}`)
                .then(response => {
                    return response;
                })
        });
    }


    if (response && response.data.status === 200) {
        yield put(getPostionDropdownSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


export function* getPositionEmploye(payload) {

    const data = payload;

    //  yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${EMPLOYEES_POINT_URL}?position=${data.payload.id}&page=${data.payload.currentPage}&is_active=1`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getPositionEmployeSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


export function* getPositionContact(payload) {

    const data = payload;

    //  yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}contacts/?position=${data.payload.id}&page=${data.payload.currentPage}&is_active=1`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getPositionContactSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


function* watchgetConfiguration() {
    yield takeLatest(actionTypes.GetConfiguration, getConfiguration)
}

function* watchaddPosition() {
    yield takeLatest(actionTypes.AddPosition, addPosition)
}

function* watchgetPosition() {
    yield takeLatest(actionTypes.GetPosition, getPosition)
}

function* watchgetPostionDetail() {
    yield takeLatest(actionTypes.GetPostionDetail, getPostionDetail)
}

function* watchdeletePostion() {
    yield takeLatest(actionTypes.DeletePostion, deletePostion)
}

function* watcheditPostion() {
    yield takeLatest(actionTypes.EditPostion, editPostion)
}

function* watchgetPostionDropdown() {
    yield takeLatest(actionTypes.GetPostionDropdown, getPostionDropdown)
}

function* watchgetPositionEmploye() {
    yield takeLatest(actionTypes.GetPositionEmploye, getPositionEmploye)
}

function* watchgetPositionContact() {
    yield takeLatest(actionTypes.GetPositionContact, getPositionContact)
}




export const ConfigurationSaga = [
    fork(watchgetConfiguration),
    fork(watchaddPosition),
    fork(watchgetPosition),
    fork(watchgetPostionDetail),
    fork(watchdeletePostion),
    fork(watcheditPostion),
    fork(watchgetPostionDropdown),
    fork(watchgetPositionEmploye),
    fork(watchgetPositionContact)
]
import * as actionTypes from '../actionTypes';

const initialState = {
    notificationList: null,
    notificationAllList : {
        page :null,
        page_size : null,
        total : null,
        unread: null,
        results : []
    },
    notificationDetail : null
};
const NotificationReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;

    switch (type) {

        case actionTypes.GetNotificationListSuccess:
            object = { ...state, notificationList: payload }
            break

        case actionTypes.GetNotificationListRequested:
            object = { ...state, notificationList: null }
            break
        
        case actionTypes.GetNotificationAllListRequested:
            object = { ...state, notificationAllList : {
                page :null,
                page_size : null,
                total : null,
                unread: null,
                results : []
            }, }
            break
        
        
        case actionTypes.GetNotificationAllListSuccess:
            const data = {
                page : payload.page,
                page_size : payload.page_size,
                total : payload.total,
                unread: payload.unread,
                results : [...state.notificationAllList.results , ...payload.results]
            }
            object = { ...state, notificationAllList : data }
            break

        case actionTypes.GetNotificationAllListSuccessReload:
            const dataReload = {
                page : payload.page,
                page_size : payload.page_size,
                total : payload.total,
                unread: payload.unread,
                results : [...payload.results]
            }
            object = { ...state, notificationAllList : dataReload }
            break


        case actionTypes.GetNotificationDetailRequested:
            object = { ...state, notificationDetail: null }
            break

        case actionTypes.GetNotificationDetailSuccess:
            object = { ...state, notificationDetail: payload }
            break

        case actionTypes.LogOut: 
            object = { state : undefined };
            break
            
        default:
            object = state;
    }
    return object
};
export default NotificationReducer;
import * as actionTypes from '../actionTypes';

export const getDashboardOrderList = (data) => {
    return {
        type: actionTypes.GetDashboardOrderList,
        payload: data
    }
}

export const getDashboardOrderListRequested = (data) => {
    return {
        type: actionTypes.GetDashboardOrderListRequested,
        payload: data
    }
}

export const getDashboardOrderListSuccess = (data) => {
    return {
        type: actionTypes.GetDashboardOrderListSuccess,
        payload: data
    }
}

export const addDashboardOrderList = (data) => {
    return {
        type: actionTypes.AddDashboardOrderList,
        payload: data
    }
}

export const getWidgetList = (data) => {
    return {
        type: actionTypes.GetWidgetList,
        payload: data
    }
}

export const getAverageWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetAverageWidgetListRequested,
        payload: data
    }
}

export const getAverageWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetAverageWidgetListSuccess,
        payload: data
    }
}

export const getTopClientWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetTopClientWidgetListRequested,
        payload: data
    }
}

export const getTopClientWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetTopClientWidgetListSuccess,
        payload: data
    }
}

export const getBottomClientWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetBottomClientWidgetListRequested,
        payload: data
    }
}

export const getBottomClientWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetBottomClientWidgetListSuccess,
        payload: data
    }
}

export const getTopServiceWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetTopServiceWidgetListRequested,
        payload: data
    }
}

export const getTopServiceWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetTopServiceWidgetListSuccess,
        payload: data
    }
}

export const getBottomServiceWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetBottomServiceWidgetListRequested,
        payload: data
    }
}

export const getBottomServiceWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetBottomServiceWidgetListSuccess,
        payload: data
    }
}

export const getWorkOrderSummary = (data) => {
    return {
        type: actionTypes.GetWorkOrderSummary,
        payload: data
    }
}

export const getWorkOrderSummaryRequested = (data) => {
    return {
        type: actionTypes.GetWorkOrderSummaryRequested,
        payload: data
    }
}

export const getWorkOrderSummarySuccess = (data) => {
    return {
        type: actionTypes.GetWorkOrderSummarySuccess,
        payload: data
    }
}


export const getWorkorderWidgetList = (data) => {
    return {
        type: actionTypes.GetWorkorderWidgetList,
        payload: data
    }
}


export const getOpenWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetOpenWidgetListRequested,
        payload: data
    }
}

export const getOpenWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetOpenWidgetListSuccess,
        payload: data
    }
}

export const getPerMonthWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetPerMonthWidgetListRequested,
        payload: data
    }
}


export const getPerMonthWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetPerMonthWidgetListSuccess,
        payload: data
    }
}

export const getByOriginWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetByOriginWidgetListRequested,
        payload: data
    }
}

export const getByOriginWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetByOriginWidgetListSuccess,
        payload: data
    }
}


export const getAvergaeCompetionWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetAvergaeCompetionWidgetListRequested,
        payload: data
    }
}

export const getAvergaeCompetionWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetAvergaeCompetionWidgetListSuccess,
        payload: data
    }
}


export const getPeriodicSummary = (data) => {
    return {
        type: actionTypes.GetPeriodicSummary,
        payload: data
    }
}

export const getPeriodicSummaryRequested = (data) => {
    return {
        type: actionTypes.GetPeriodicSummaryRequested,
        payload: data
    }
}

export const getPeriodicSummarySuccess = (data) => {
    return {
        type: actionTypes.GetPeriodicSummarySuccess,
        payload: data
    }
}


export const getPeriodicWidgetList = (data) => {
    return {
        type: actionTypes.GetPeriodicWidgetList,
        payload: data
    }
}


export const getTopClientPeriodicWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetTopClientPeriodicWidgetListRequested,
        payload: data
    }
}

export const getTopClientPeriodicWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetTopClientPeriodicWidgetListSuccess,
        payload: data
    }
}

export const getPerMonthPeriodicWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetPerMonthPeriodicWidgetListRequested,
        payload: data
    }
}

export const getPerMonthPeriodicWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetPerMonthPeriodicWidgetListSuccess,
        payload: data
    }
}

export const getOpenPeriodicWidgetListRequested = (data) => {
    return {
        type: actionTypes.GetOpenPeriodicWidgetListRequested,
        payload: data
    }
}

export const getOpenPeriodicWidgetListSuccess = (data) => {
    return {
        type: actionTypes.GetOpenPeriodicWidgetListSuccess,
        payload: data
    }
}

export const superDashboardSummary = (data) => {
    return {
        type: actionTypes.SuperDashboardSummary,
        payload: data
    }
}

export const superDashboardSummaryRequested = (data) => {
    return {
        type: actionTypes.SuperDashboardSummaryRequested,
        payload: data
    }
}

export const superDashboardSummarySuccess = (data) => {
    return {
        type: actionTypes.SuperDashboardSummarySuccess,
        payload: data
    }
}

export const getSuperDashboardOrderList = (data) => {
    return {
        type: actionTypes.GetSuperDashboardOrderList,
        payload: data
    }
}

export const getSuperDashboardOrderListRequested = (data) => {
    return {
        type: actionTypes.GetSuperDashboardOrderListRequested,
        payload: data
    }
}
export const getSuperDashboardOrderListSuccess = (data) => {
    return {
        type: actionTypes.GetSuperDashboardOrderListSuccess,
        payload: data
    }
}

export const addSuperDashboardOrderList = (data) => {
    return {
        type: actionTypes.AddSuperDashboardOrderList,
        payload: data
    }
}

export const getSuperWidgetList = (data) => {
    return {
        type: actionTypes.GetSuperWidgetList,
        payload: data
    }
}

export const topClientsInspectionRequested = (data) => {
    return {
        type: actionTypes.TopClientsInspectionRequested,
        payload: data
    }
}

export const topClientsInspectionSuccess = (data) => {
    return {
        type: actionTypes.TopClientsInspectionSuccess,
        payload: data
    }
}

export const bottomClientsInspectionRequested = (data) => {
    return {
        type: actionTypes.BottomClientsInspectionRequested,
        payload: data
    }
}

export const bottomClientsInspectionSuccess = (data) => {
    return {
        type: actionTypes.BottomClientsInspectionSuccess,
        payload: data
    }
}


export const topClientsWorkorderRequested = (data) => {
    return {
        type: actionTypes.TopClientsWorkorderRequested,
        payload: data
    }
}

export const topClientsWorkorderSuccess = (data) => {
    return {
        type: actionTypes.TopClientsWorkorderSuccess,
        payload: data
    }
}

export const bottomClientsWorkorderRequested = (data) => {
    return {
        type: actionTypes.BottomClientsWorkorderRequested,
        payload: data
    }
}

export const bottomClientsWorkorderSuccess = (data) => {
    return {
        type: actionTypes.BottomClientsWorkorderSuccess,
        payload: data
    }
}




export const topClientsPeriodicRequested = (data) => {
    return {
        type: actionTypes.TopClientsPeriodicRequested,
        payload: data
    }
}

export const topClientsPeriodicSuccess = (data) => {
    return {
        type: actionTypes.TopClientsPeriodicSuccess,
        payload: data
    }
}

export const bottomClientsPeriodicRequested = (data) => {
    return {
        type: actionTypes.BottomClientsPeriodicRequested,
        payload: data
    }
}

export const bottomClientsPeriodicSuccess = (data) => {
    return {
        type: actionTypes.BottomClientsPeriodicSuccess,
        payload: data
    }
}



export const topServiceLocationRequested = (data) => {
    return {
        type: actionTypes.TopServiceLocationRequested,
        payload: data
    }
}

export const topServiceLocationSuccess = (data) => {
    return {
        type: actionTypes.TopServiceLocationSuccess,
        payload: data
    }
}

export const bottomServiceLocationRequested = (data) => {
    return {
        type: actionTypes.BottomServiceLocationRequested,
        payload: data
    }
}

export const bottomServiceLocationSuccess = (data) => {
    return {
        type: actionTypes.BottomServiceLocationSuccess,
        payload: data
    }
}



export const inspectionPerMonthRequested = (data) => {
    return {
        type: actionTypes.InspectionPerMonthRequested,
        payload: data
    }
}

export const inspectionPerMonthSuccess = (data) => {
    return {
        type: actionTypes.InspectionPerMonthSuccess,
        payload: data
    }
}

export const workOrderPerMonthRequested = (data) => {
    return {
        type: actionTypes.WorkOrderPerMonthRequested,
        payload: data
    }
}

export const workOrderPerMonthSuccess = (data) => {
    return {
        type: actionTypes.WorkOrderPerMonthSuccess,
        payload: data
    }
}

export const genrateMasterTemplate = (data) => {
    return {
        type: actionTypes.GenrateMasterTemplate,
        payload: data
    }
}
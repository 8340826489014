import axios from "axios";
import { put, takeLatest, fork, call, delay } from 'redux-saga/effects';
import { WebConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getSublocationListSuccess,
    getSublocationDetailRequested,
    getSublocationDetailSuccess,
    getAllSublocationListRequested,
    getAllSublocationListSuccess,
    getAllSectionListRequested,
    getAllSectionListSuccess,
    getAllSublocationListDropdownRequested,
    getAllSublocationListDropdownSuccess,
    importFilesRequested,
    importFilesSuccess,
    importFilesFailure

} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const SUBLOCATION_POINT_URL = WebConfig.ApiUrls.company.subLocations;
const COMPANIES_POINT_URL = WebConfig.ApiUrls.company.companies;

export function* addSublocation(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${SUBLOCATION_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }


}


export function* editSublocation(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${SUBLOCATION_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }


}

export function* deleteSublocation(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${SUBLOCATION_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)
        if (!data.payload.sublocationDetailPage) {
            yield getSublocationList({
                type: actionTypes.GetSublocationList,
                payload: { currentPage: data.payload.currentPage, locationId: data.payload.locationId }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}

export function* deleteSublocationSection(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${SUBLOCATION_POINT_URL}${data.payload.sublocationId}/section/${data.payload.sectionId}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        yield delay(1000)
        yield getSublocationDetail({
            type: actionTypes.GetSublocationDetail,
            payload: { id: data.payload.sublocationId }
        });
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}



export function* getSublocationList(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null } } = data;

    //yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(search ?
                `${API_POINT_URL}${SUBLOCATION_POINT_URL}?service_location=${data.payload.locationId}&page=${currentPage}&search=${search}&fields=id,name,sections,group,is_active,templates` :
                status ? `${API_POINT_URL}${SUBLOCATION_POINT_URL}?service_location=${data.payload.locationId}&page=${currentPage}&is_active=${status}&fields=id,name,sections,group,is_active,templates` :
                    `${API_POINT_URL}${SUBLOCATION_POINT_URL}?service_location=${data.payload.locationId}&page=${currentPage}&fields=id,name,sections,group,is_active,templates`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getSublocationListSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* getSublocationDetail(payload) {
    const data = payload;

    // yield put(loadTodoRequest());
    yield put(getSublocationDetailRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${SUBLOCATION_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getSublocationDetailSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }




}

export function* updateSublocationStatus(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${SUBLOCATION_POINT_URL}${data.payload.id}/`, { is_active: data.payload.is_active })
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)
        if (!data.payload.sublocationDetailPage) {
            yield getSublocationList({
                type: actionTypes.GetSublocationList,
                payload: { currentPage: data.payload.currentPage, locationId: data.payload.locationId }
            });
        }
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }




}


export function* getAllSublocationList(payload) {
    const data = payload;
    let fields = null;
    let search = null;
    let groupNames = [];
    if (data.payload && data.payload.client) {
        groupNames = data.payload.client.map((item) => `&client=${[item]}`);
    }
    if (data.payload && data.payload.service_location) {
        groupNames = data.payload.service_location.map((item) => `&service_location=${[item]}`);
    }
    if (data.payload && data.payload.fields) {
        fields = data.payload.fields;
    }
    if (data.payload && data.payload.search) {
        search = data.payload.search;
    }
    //  yield put(getAllSublocationListRequested());
    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(fields && !search ? `${API_POINT_URL}${SUBLOCATION_POINT_URL}?no_page&fields=${fields}${groupNames.join("")}` :
                fields && search ? `${API_POINT_URL}${SUBLOCATION_POINT_URL}?no_page&fields=${fields}&search=${search}` :
                    `${API_POINT_URL}${SUBLOCATION_POINT_URL}?no_page&service_location=${data.payload.id}&fields=id,name`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getAllSublocationListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}



export function* getAllSublocationDropdownList(payload) {
    const data = payload;
    yield put(getAllSublocationListDropdownRequested());
    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${SUBLOCATION_POINT_URL}?no_page&fields=id,name`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getAllSublocationListDropdownSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}



export function* getAllSectionList(payload) {
    const data = payload;
    let fields = null;
    let search = null;
    let groupNames = [];
    if (data.payload && data.payload.client) {
        groupNames = data.payload.client.map((item) => `&client=${[item]}`);
    }
    if (data.payload && data.payload.service_location) {
        groupNames = data.payload.service_location.map((item) => `&service_location=${[item]}`);
    }
    if (data.payload && data.payload.sub_location) {
        groupNames = data.payload.sub_location.map((item) => `&sub_location=${[item]}`);
    }
    if (data.payload && data.payload.fields) {
        fields = data.payload.fields;
    }
    if (data.payload && data.payload.search) {
        search = data.payload.search;
    }
    //  yield put(getAllSectionListRequested());
    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(fields && !search ? `${API_POINT_URL}${COMPANIES_POINT_URL}sections/?no_page&fields=${fields}${groupNames.join("")}` :
                fields && search ? `${API_POINT_URL}${COMPANIES_POINT_URL}sections/?no_page&fields=${fields}&search=${search}` :
                    `${API_POINT_URL}${COMPANIES_POINT_URL}sections/?sub_location=${data.payload.id}&fields=id,name`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getAllSectionListSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
}


export function* importSublocationList(payload) {
    const data = payload;
    console.log("data" , data);

    yield put(importFilesRequested());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${SUBLOCATION_POINT_URL}import_xls/${data.payload.id}/`, data.payload.formData)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
       // console.log("response1" , response);
        yield put(importFilesSuccess(response.data.response.detail));
  
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
      //  console.log("response2" , response);
        yield put(importFilesFailure(response.data.message));
    }
    
}


function* watchaddSublocation() {
    yield takeLatest(actionTypes.AddSublocation, addSublocation)
}

function* watcheditSublocation() {
    yield takeLatest(actionTypes.EditSublocation, editSublocation)
}

function* watchgetSublocationList() {
    yield takeLatest(actionTypes.GetSublocationList, getSublocationList)
}

function* watchgetSublocationDetail() {
    yield takeLatest(actionTypes.GetSublocationDetail, getSublocationDetail)
}

function* watchdeleteSublocation() {
    yield takeLatest(actionTypes.DeleteSublocation, deleteSublocation)
}

function* watchdeleteSublocationSection() {
    yield takeLatest(actionTypes.DeleteSublocationSection, deleteSublocationSection)
}

function* watchupdateSublocationStatus() {
    yield takeLatest(actionTypes.UpdateSublocationStatus, updateSublocationStatus)
}

function* watchgetAllSublocationList() {
    yield takeLatest(actionTypes.GetAllSublocationList, getAllSublocationList)
}

function* watchgetAllSectionList() {
    yield takeLatest(actionTypes.GetAllSectionList, getAllSectionList)
}

function* watchgetAllSublocationDropdownList() {
    yield takeLatest(actionTypes.GetAllSublocationDropdownList, getAllSublocationDropdownList)
}

function* watchimportSublocationList() {
    yield takeLatest(actionTypes.ImportSublocationList, importSublocationList)
}


export const sublocationSaga = [
    fork(watchaddSublocation),
    fork(watcheditSublocation),
    fork(watchgetSublocationList),
    fork(watchgetSublocationDetail),
    fork(watchdeleteSublocation),
    fork(watchdeleteSublocationSection),
    fork(watchupdateSublocationStatus),
    fork(watchgetAllSublocationList),
    fork(watchgetAllSectionList),
    fork(watchgetAllSublocationDropdownList),
    fork(watchimportSublocationList)
]

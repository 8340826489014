import * as actionTypes from '../actionTypes';


export const addClient = (data) => {
    return {
        type: actionTypes.AddClient,
        payload: data
    }
}


export const getClientList = (data) => {
    return {
        type: actionTypes.GetClientList,
        payload: data
    }
}

export const getClientListRequested = (data) => {
    return {
        type: actionTypes.GetClientListRequested,
        payload: data
    }
}


export const getClientListSuccess = (data) => {
    return {
        type: actionTypes.GetClientListSuccess,
        payload: data
    }
}



export const updateClientStatus = (data) => {
    return {
        type: actionTypes.UpdateClientStatus,
        payload: data
    }
}

export const deleteClient = (data) => {
    return {
        type: actionTypes.DeleteClient,
        payload: data
    }
}


export const getClientDetail = (data) => {
    return {
        type: actionTypes.GetClientDetail,
        payload: data
    }
}

export const getClientDetailRequested = () => {
    return {
        type: actionTypes.GetClientDetailRequested,
    }
}

export const getClientDetailSuccess = (data) => {
    return {
        type: actionTypes.GetClientDetailSuccess,
        payload: data
    }
}


export const editClient = (data) => {
    return {
        type: actionTypes.EditClient,
        payload: data
    }
}


export const getClientAll = () => {
    return {
        type: actionTypes.GetAllClientList,
    }
}

export const getClientAllSuccess = (data) => { 
    return {
        type: actionTypes.GetAllClientListSuccess,
        payload: data
    }
}


export const getClientListArchieve = (data) => {
    return {
        type: actionTypes.GetClientArchiveList,
        payload: data
    }
}

export const getClientListArchieveRequested = (data) => {
    return {
        type: actionTypes.GetClientArchiveListRequested,
        payload: data
    }
}


export const getClientListArchieveSuccess = (data) => {
    return {
        type: actionTypes.GetClientArchiveListSuccess,
        payload: data
    }
}


export const updateClientArchieveStatus = (data) => {
    return {
        type: actionTypes.UpdateClientArchieveStatus,
        payload: data
    }
}

export const importClients = (data) => {
    return {
        type: actionTypes.ImportClients,
        payload: data
    }
}





import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig, AppConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getPrimaryAdminRequested,
    getPrimaryAdminSuccess,
    getPrimaryAdminDetail,
    getCompanyAdminSuccess,
    getCompanyAdminRequested,
    getCompanyAdminDetailRequested,
    getCompanyAdminDetailSuccess,
    getRecentSearch,
    getCompanyAdminArchieveRequested,
    getCompanyAdminArchieveSuccess

} from "store/actions";


const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const COMPANIESADMIN_POINT_URL = WebConfig.ApiUrls.company.companiesAdmin;
const COMPANIES_POINT_URL = WebConfig.ApiUrls.company.companies;

export function* getPrimaryAdmin(payload) {
    const data = payload;
    yield put(getPrimaryAdminRequested());
    
    const response = yield call(() => {
        return axios
            .get(`${API_POINT_URL}${COMPANIES_POINT_URL}${data.payload.id}/primary_admin/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 302) {
        yield put(getPrimaryAdminSuccess(false));
    }
    if (response && response.data.status === 200) {
        yield put(getPrimaryAdminSuccess(true));
        yield put(getPrimaryAdminDetail(response.data.response));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 302) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}

export function* addcompanyAdmin(payload) {
    const data = payload;
    
    const response = yield call(() => {
        return axios
            .post(`${API_POINT_URL}${COMPANIESADMIN_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}


export function* getCompanyAdmin(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null, ordering = null } } = data;
    yield put(getCompanyAdminRequested());
    
    const response = yield call(() => {
        return axios
            .get(search ?
                `${API_POINT_URL}${COMPANIESADMIN_POINT_URL}?page=${currentPage}&search=${search}` :
                status ? `${API_POINT_URL}${COMPANIESADMIN_POINT_URL}?page=${currentPage}&is_active=${status}` :
                    ordering ? `${API_POINT_URL}${COMPANIESADMIN_POINT_URL}?page=${currentPage}&ordering=${ordering}`
                        : `${API_POINT_URL}${COMPANIESADMIN_POINT_URL}?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getCompanyAdminSuccess(response.data.response));
        if (search) {
            yield put(getRecentSearch({ page: 'admins-list' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}

export function* getCompanyAdminDetail(payload) {
    const data = payload;
    yield put(getCompanyAdminDetailRequested());
    
    const response = yield call(() => {
        return axios
            .get(`${API_POINT_URL}${COMPANIESADMIN_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getCompanyAdminDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}


export function* editCompanyAdmin(payload) {
    const data = payload;
    
    const response = yield call(() => {
        return axios
            .put(`${API_POINT_URL}${COMPANIESADMIN_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}

export function* deleteCompanyAdmin(payload) {
    const data = payload;
    
    const response = yield call(() => {
        return axios
            .delete(`${API_POINT_URL}${COMPANIESADMIN_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000)
        if (!data.payload.adminPage) {
            yield getCompanyAdmin({ type: actionTypes.GetPrimaryAdmin, payload: { currentPage: 1 } });
        }

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    

}

export function* deleteAdminCompany(payload) {
    const data = payload;
    
    const response = yield call(() => {
        return axios
            .delete(`${API_POINT_URL}${COMPANIESADMIN_POINT_URL}${data.payload.id}/company/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000)
        yield getCompanyAdminDetail({ type: actionTypes.GetCompanyAdminDetail, payload: { id: data.payload.DetailId } });

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    

}


export function* getCompanyAdminArchieve(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null } } = data;
    yield put(getCompanyAdminArchieveRequested());
    
    const response = yield call(() => {
        return axios
            // .get(`${API_POINT_URL}companies/${data.payload.companyId}/clients/?page=${currentPage}`)
            .get(search ?
                `${API_POINT_URL}${COMPANIESADMIN_POINT_URL}archived/?page=${currentPage}&search=${search}`
                : `${API_POINT_URL}${COMPANIESADMIN_POINT_URL}archived/?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getCompanyAdminArchieveSuccess(response.data.response));
        if (search) {
            yield put(getRecentSearch({ page: 'admins-archived' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}

export function* UpdateCompanyAdminStatus(payload) {
    const data = payload;
    
    const response = yield call(() => {
        return axios
            .delete(`${API_POINT_URL}${COMPANIESADMIN_POINT_URL}${data.payload.id}/undelete/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000);

        if (!data.payload.AdminArchievePage) {
            yield getCompanyAdminArchieve({ type: actionTypes.GetCompanyAdminArchive, payload: { currentPage: data.payload.currentPage } });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}

export function* deleteAdminPostion(payload) {
    const data = payload;
    
    const response = yield call(() => {
        return axios
            .delete(`${API_POINT_URL}companies/employee-positions/${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000);
        yield getCompanyAdminDetail({ type: actionTypes.GetCompanyAdminDetail, payload: { id: data.payload.adminId } });
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}



function* watchdeleteAdminPostion() {
    yield takeLatest(actionTypes.DeleteAdminPostion, deleteAdminPostion)
}


function* watchgetPrimaryAdmin() {
    yield takeLatest(actionTypes.GetPrimaryAdmin, getPrimaryAdmin)
}


function* watchaddcompanyAdmin() {
    yield takeLatest(actionTypes.AddCompanyAdmin, addcompanyAdmin)
}

function* watchgetCompanyAdmin() {
    yield takeLatest(actionTypes.GetCompanyAdmin, getCompanyAdmin)
}

function* watchgetCompanyAdminDetail() {
    yield takeLatest(actionTypes.GetCompanyAdminDetail, getCompanyAdminDetail)
}

function* watcheditCompanyAdmin() {
    yield takeLatest(actionTypes.EditCompanyAdmin, editCompanyAdmin)
}

function* watchdeleteCompanyAdmin() {
    yield takeLatest(actionTypes.DeleteCompanyAdmin, deleteCompanyAdmin)
}

function* watchdeleteAdminCompany() {
    yield takeLatest(actionTypes.DeleteAdminCompany, deleteAdminCompany)
}

function* watchgetCompanyAdminArchieve() {
    yield takeLatest(actionTypes.GetCompanyAdminArchive, getCompanyAdminArchieve)
}

function* watchUpdateCompanyAdminStatus() {
    yield takeLatest(actionTypes.UpdateCompanyArchieveStatus, UpdateCompanyAdminStatus)
}



export const CompanyAdminSaga = [
    fork(watchgetPrimaryAdmin),
    fork(watchaddcompanyAdmin),
    fork(watchgetCompanyAdmin),
    fork(watchgetCompanyAdminDetail),
    fork(watcheditCompanyAdmin),
    fork(watchdeleteCompanyAdmin),
    fork(watchdeleteAdminCompany),
    fork(watchgetCompanyAdminArchieve),
    fork(watchUpdateCompanyAdminStatus),
    fork(watchdeleteAdminPostion)
]
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { TRANSLATIONS_EN } from './en/translation';
import { TRANSLATIONS_FR } from './fr/translation';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN
            },
            fr: {
                translation: TRANSLATIONS_FR
            }
        },
        fallbackLng: 'en',
        lng: localStorage.getItem("language") ? localStorage.getItem("language") : "en",
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
import * as actionTypes from '../actionTypes';

export const getPrimaryAdmin = (data) => {
    return {
        type: actionTypes.GetPrimaryAdmin,
        payload: data
    }
}

export const getPrimaryAdminRequested = () => {
    return {
        type: actionTypes.GetPrimaryAdminRequested,
    }
}

export const getPrimaryAdminSuccess = (data) => {
    return {
        type: actionTypes.GetPrimaryAdminSucess,
        payload: data
    }
}

export const getPrimaryAdminDetail = (data) => {
    return {
        type: actionTypes.GetPrimaryAdminDetail,
        payload: data
    }
}

export const addcompanyAdmin = (data) => {
    return {
        type: actionTypes.AddCompanyAdmin,
        payload: data
    }
}

export const getCompanyAdmin = (data) => {
    return {
        type: actionTypes.GetCompanyAdmin,
        payload: data
    }
}

export const getCompanyAdminSuccess = (data) => {
    return {
        type: actionTypes.GetCompanyAdminSuccess,
        payload: data
    }
}

export const getCompanyAdminRequested = (data) => {
    return {
        type: actionTypes.GetCompanyAdminRequested,
        payload: data
    }
}

export const getCompanyAdminDetail = (data) => {
    return {
        type: actionTypes.GetCompanyAdminDetail,
        payload: data
    }
}

export const getCompanyAdminDetailRequested = () => {
    return {
        type: actionTypes.GetCompanyAdminDetailRequested,
    }
}
export const getCompanyAdminDetailSuccess = (data) => {
    return {
        type: actionTypes.GetCompanyAdminDetailSuccess,
        payload: data
    }
}

export const editCompanyAdmin = (data) => {
    return {
        type: actionTypes.EditCompanyAdmin,
        payload: data
    }
}

export const deleteCompanyAdmin = (data) => {
    return {
        type: actionTypes.DeleteCompanyAdmin,
        payload: data
    }
}


export const deleteAdminCompany = (data) => {
    return {
        type: actionTypes.DeleteAdminCompany,
        payload: data
    }
}


export const getCompanyAdminArchieve = (data) => {
    return {
        type: actionTypes.GetCompanyAdminArchive,
        payload: data
    }
}

export const getCompanyAdminArchieveRequested = (data) => {
    return {
        type: actionTypes.GetCompanyAdminArchiveRequested,
        payload: data
    }
}


export const getCompanyAdminArchieveSuccess = (data) => {
    return {
        type: actionTypes.GetCompanyAdminArchiveSuccess,
        payload: data
    }
}


export const UpdateCompanyAdminStatus = (data) => {
    return {
        type: actionTypes.UpdateCompanyArchieveStatus,
        payload: data
    }
}

export const deleteAdminPostion = (data) => {
    return {
        type: actionTypes.DeleteAdminPostion,
        payload: data
    }
}



import * as actionTypes from '../actionTypes';

const initialState = {
    periodicList: null,
    periodicDetail: null,
    LastUpdatedHistory: null,
    messageList: []
};

const PeriodicReducer = (state, {
    type,
    payload
}) => {

    if (state === undefined) {
        state = initialState
    }

    let object;
    switch (type) {

        case actionTypes.GetWorkEmployeRequested:
            object = {
                ...state,
                periodicList: null
            }
            break

        case actionTypes.GetPeriodicListSuccess:
            object = {
                ...state,
                periodicList: payload
            }
            break

        case actionTypes.GetPeriodicDetailRequested:
            object = {
                ...state,
                periodicDetail: null
            }
            break

        case actionTypes.GetPeriodicDetailSuccess:
            object = {
                ...state,
                periodicDetail: payload
            }
            break

        case actionTypes.GetLastUpdatePeriodicHistorySuccess:
            object = {
                ...state,
                LastUpdatedHistory: payload
            }
            break

        case actionTypes.GetPeriodicMessageListRequested:
            object = {
                ...state,
                messageList: []
            }
            break
        case actionTypes.GetPeriodicMessageListSuccess:
            object = {
                ...state,
                messageList: payload
            }
            break
        case actionTypes.AddPeriodicorderMessageSuccess:
            object = {
                ...state,
                messageList: [...state.messageList, payload]
            }
            break

        case actionTypes.LogOut:
            object = { state: undefined };
            break

        default:
            object = state;
    }
    return object
};
export default PeriodicReducer;
import React, { Component, Fragment } from 'react';
import Loader from 'shared/components/Loader'

export const isWrapperAuth = (WrappedComponent , data) => {
    class HOC extends Component {
        render() {
            const { isLoading  } = this.props;
            return (
                <Fragment>
                    {isLoading   ? <Loader /> : null}
                    <WrappedComponent {...this.props} />
                </Fragment>
            );
        }
    }
    return HOC;

}

export default isWrapperAuth;

import * as actionTypes from '../actionTypes';

export const addSublocation = (data) => {
    return {
        type: actionTypes.AddSublocation,
        payload: data
    }
}

export const editSublocation = (data) => {
    return {
        type: actionTypes.EditSublocation,
        payload: data
    }
}

export const deleteSublocation = (data) => {
    return {
        type: actionTypes.DeleteSublocation,
        payload: data
    }
}

export const deleteSublocationSection = (data) => {
    return {
        type: actionTypes.DeleteSublocationSection,
        payload: data
    }
}

export const getSublocationList = (data) => {
    return {
        type: actionTypes.GetSublocationList,
        payload: data
    }
}

export const getSublocationListSuccess = (data) => {
    return {
        type: actionTypes.GetSublocationListSuccess,
        payload: data
    }
}

export const getSublocationDetail = (data) => {
    return {
        type: actionTypes.GetSublocationDetail,
        payload: data
    }
}

export const getSublocationDetailRequested = (data) => {
    return {
        type: actionTypes.GetSublocationDetailRequested,
        payload: data
    }
}

export const getSublocationDetailSuccess = (data) => {
    return {
        type: actionTypes.GetSublocationDetailSuccess,
        payload: data
    }
}

export const updateSublocationStatus = (data) => {
    return {
        type: actionTypes.UpdateSublocationStatus,
        payload: data
    }
}



export const getAllSublocationList = (data) => {
    return {
        type: actionTypes.GetAllSublocationList,
        payload: data
    }
}

export const getAllSublocationListRequested = (data) => {
    return {
        type: actionTypes.GetAllSublocationListRequested,
        payload: data
    }
}

export const getAllSublocationListSuccess = (data) => {
    return {
        type: actionTypes.GetAllSublocationListSuccess,
        payload: data
    }
}


export const getAllSectionList = (data) => {
    return {
        type: actionTypes.GetAllSectionList,
        payload: data
    }
}

export const getAllSectionListRequested = (data) => {
    return {
        type: actionTypes.GetAllSectionListRequested,
        payload: data
    }
}

export const getAllSectionListSuccess = (data) => {
    return {
        type: actionTypes.GetAllSectionListSuccess,
        payload: data
    }
}

export const getAllSublocationDropdownList = (data) => {
    return {
        type: actionTypes.GetAllSublocationDropdownList,
        payload: data
    }
}

export const getAllSublocationListDropdownRequested = (data) => {
    return {
        type: actionTypes.GetAllSublocationListDropdownRequested,
        payload: data
    }
}

export const getAllSublocationListDropdownSuccess = (data) => {
    return {
        type: actionTypes.GetAllSublocationListDropdownSuccess,
        payload: data
    }
}

export const importSublocationList = (data) => {
    return {
        type: actionTypes.ImportSublocationList,
        payload: data
    }
}


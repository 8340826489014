import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoError,
    getClientListSuccess,
    getClientListRequested,
    getRecentSearchClient,
    getClientDetailRequested,
    getClientDetailSuccess,
    getClientAllSuccess,
    getClientListArchieveRequested,
    getClientListArchieveSuccess,
    importFilesRequested,
    importFilesSuccess,
    importFilesFailure
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const CLIENTS_POINT_URL = WebConfig.ApiUrls.company.clients;

export function* addClient(payload) {
    const data = payload;
    
    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${CLIENTS_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}


export function* getClientList(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null, ordering = null,
        service_provider_division = null, page_size = null } } = data;
    yield put(getClientListRequested());
    
    let instance = localStorage.getItem('inperosnate') ? ClientInstance : axios
    let recentSearch = localStorage.getItem('inperosnate') ? true : false
    const response = yield call(() => {
        return instance
            // .get(`${API_POINT_URL}companies/${data.payload.companyId}/clients/?page=${currentPage}`)
            .get(search && !service_provider_division ?
                `${API_POINT_URL}${CLIENTS_POINT_URL}?page=${currentPage}&search=${search}&fields=id,name,service_provider_level_name,service_provider_division_name,is_active` :
                status ? `${API_POINT_URL}${CLIENTS_POINT_URL}?page=${currentPage}&is_active=${status}&fields=id,name,service_provider_level_name,service_provider_division_name,is_active` :
                    ordering ? `${API_POINT_URL}${CLIENTS_POINT_URL}?page=${currentPage}&ordering=${ordering}&fields=id,name,service_provider_level_name,service_provider_division_name,is_active` :
                        service_provider_division && !search ? `${API_POINT_URL}${CLIENTS_POINT_URL}?page=${currentPage}&service_provider_division=${service_provider_division}&page_size=${page_size}&fields=id,name,service_provider_level_name,service_provider_division_name,is_active` :
                            service_provider_division && search ? `${API_POINT_URL}${CLIENTS_POINT_URL}?page=${currentPage}&service_provider_division=${service_provider_division}&page_size=${page_size}&search=${search}&fields=id,name,service_provider_level_name,service_provider_division_name,is_active`
                                : `${API_POINT_URL}${CLIENTS_POINT_URL}?page=${currentPage}&fields=id,name,service_provider_level_name,service_provider_division_name,is_active`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getClientListSuccess(response.data.response));
        if (search && recentSearch) {
            yield put(getRecentSearchClient({ page: 'clients-list' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}

export function* updateClientStatus(payload) {
    const data = payload;
    
    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${CLIENTS_POINT_URL}${data.payload.id}/`, { is_active: data.payload.is_active })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000);
        if (!data.payload.clientPage) {
            yield getClientList({ type: actionTypes.GetClientList, payload: { currentPage: data.payload.currentPage } });
        }

        if (data.payload.spOrg) {

            yield getClientList({
                type: actionTypes.GetClientList,
                payload: {
                    currentPage: data.payload.currentPage, service_provider_division: data.payload.service_provider_division,
                    page_size: data.payload.page_size,
                }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    

}


export function* deleteClient(payload) {
    const data = payload;
    
    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${CLIENTS_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000)
        if (!data.payload.clientPage) {
            yield getClientList({ type: actionTypes.GetClientList, payload: { currentPage: data.payload.currentPage } });
        }
        if (data.payload.spOrg) {

            yield getClientList({
                type: actionTypes.GetClientList,
                payload: {
                    currentPage: data.payload.currentPage, service_provider_division: data.payload.service_provider_division,
                    page_size: data.payload.page_size,
                }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}



export function* getClientDetail(payload) {
    const data = payload;
    yield put(getClientDetailRequested());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getClientDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}


export function* editClient(payload) {
    const data = payload;
    const payloadData = { ...data.payload };
    delete payloadData["id"];
    
    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${CLIENTS_POINT_URL}${data.payload.id}/`, payloadData)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    

}


export function* getClientAll() {
  
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}?no_page&fields=id,name`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getClientAllSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    //
}


export function* getClientListArchieve(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null } } = data;
    yield put(getClientListArchieveRequested());
    
    const response = yield call(() => {
        return ClientInstance
            // .get(`${API_POINT_URL}companies/${data.payload.companyId}/clients/?page=${currentPage}`)
            .get(search ?
                `${API_POINT_URL}${CLIENTS_POINT_URL}archived/?page=${currentPage}&search=${search}`
                : `${API_POINT_URL}${CLIENTS_POINT_URL}archived/?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getClientListArchieveSuccess(response.data.response));
        if (search) {
            yield put(getRecentSearchClient({ page: 'clients-archived' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}

export function* updateClientArchieveStatus(payload) {
    const data = payload;
  
    
    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${CLIENTS_POINT_URL}${data.payload.id}/undelete/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000);

        if (!data.payload.clientArchievePage) {
            yield getClientListArchieve({ type: actionTypes.GetClientArchiveList, payload: { currentPage: data.payload.currentPage } });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}

export function* importClients(payload) {
    const data = payload;

    yield put(importFilesRequested());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${CLIENTS_POINT_URL}import_xls/`, data.payload.formData)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
       // console.log("response1" , response);
        yield put(importFilesSuccess(response.data.response.detail));
  
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
      //  console.log("response2" , response);
        yield put(importFilesFailure(response.data.message));
    }
    
}


function* watchaddClient() {
    yield takeLatest(actionTypes.AddClient, addClient)
}

function* watchgetClientList() {
    yield takeLatest(actionTypes.GetClientList, getClientList)
}

function* watchupdateClientStatus() {
    yield takeLatest(actionTypes.UpdateClientStatus, updateClientStatus)
}

function* watchgetClientDetail() {
    yield takeLatest(actionTypes.GetClientDetail, getClientDetail)
}

function* watchdeleteClient() {
    yield takeLatest(actionTypes.DeleteClient, deleteClient)
}

function* watcheditClient() {
    yield takeLatest(actionTypes.EditClient, editClient)
}

function* watchgetClientAll() {
    yield takeLatest(actionTypes.GetAllClientList, getClientAll)
}


function* watchgetClientListArchieve() {
    yield takeLatest(actionTypes.GetClientArchiveList, getClientListArchieve)
}

function* watchupdateClientArchieveStatus() {
    yield takeLatest(actionTypes.UpdateClientArchieveStatus, updateClientArchieveStatus)
}

function* watchimportClients() {
    yield takeLatest(actionTypes.ImportClients, importClients)
}




export const ClientSaga = [
    fork(watchaddClient),
    fork(watchgetClientList),
    fork(watchupdateClientStatus),
    fork(watchdeleteClient),
    fork(watchgetClientDetail),
    fork(watcheditClient),
    fork(watchgetClientAll),
    fork(watchgetClientListArchieve),
    fork(watchupdateClientArchieveStatus),
    fork(watchimportClients)
]
import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig, } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getLevelPostionSuccess,
    getDivisionLevelSuccess,
    getDivisionReportRequested,
    getDivisionReportSuccess,
    getAllLevelSuccess,
    getAllDivisionRequested,
    getAllDivisionSuccess,
    getDivisionSearchRequested,
    getDivisionSearchSuccess,
    getLevelDetailSuccess,
    getLevelDetailRequested,
    deleteLevelRequested,
    deleteLevelSuccess,
    deleteLevelError,
    getLevelRequested,
    getLevelSuccess,
    getDivisonRequested,
    getDivisonSuccess,
    getClientLevelSuccess,
    getClientDivisionRequested,
    getClientDivisionSuccess

} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const CLIENTS_POINT_URL = WebConfig.ApiUrls.company.clients;

export function* getLevelPostion(payload) {
    const data = payload;
    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}levels/positions/?client_id=${data.payload.clientId}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        
        yield put(getLevelPostionSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


export function* addLevel(payload) {
    const data = payload;
    //  yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${CLIENTS_POINT_URL}levels/`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000);
        yield getAllLevel({ type: actionTypes.GetAllLevel, payload: { clientId: data.payload.client } });
        yield getAllDivision({ type: actionTypes.GetAllDivision, payload: { clientId: data.payload.client } });

    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }

    



}


export function* getDivisionLevel(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}levels/flat_list/?fields=name,id,client&client_id=${data.payload.clientId}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield put(getDivisionLevelSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


export function* getDivisionReport(payload) {
    const data = payload;
    yield put(getDivisionReportRequested());
    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}levels/${data.payload.levelId}/flat_divisions_list/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield put(getDivisionReportSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}


export function* addDivision(payload) {
    const data = payload;
    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${CLIENTS_POINT_URL}divisions/`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000);
        yield getAllDivision({ type: actionTypes.GetAllDivision, payload: { clientId: data.payload.client } });

    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }

    



}


export function* getAllLevel(payload) {
    const data = payload;
    //  yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}levels/?fields=name,id,order,client&client_id=${data.payload.clientId}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield put(getAllLevelSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


export function* getAllDivision(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());
    // yield put(getAllDivisionRequested());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}divisions/dump/?client_id=${data.payload.clientId}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield put(getAllDivisionSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}



export function* getDivisionSearch(payload) {
    const data = payload;

    // yield put(loadTodoRequest());
    yield put(getDivisionSearchRequested());

    yield delay(500);

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}divisions/search/?client_id=${data.payload.clientId}&search=${data.payload.name}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getDivisionSearchSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}

export function* getLevelDetail(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null } } = data;
    // yield put(loadTodoRequest());
    
    yield put(getLevelDetailRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(search ?
                `${API_POINT_URL}${CLIENTS_POINT_URL}divisions/?client_id=${data.payload.clientId}&level_id=${data.payload.levelId}&page=${currentPage}&page_size=5&search=${search}` :
                `${API_POINT_URL}${CLIENTS_POINT_URL}divisions/?client_id=${data.payload.clientId}&level_id=${data.payload.levelId}&page=${currentPage}&page_size=5`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        
        yield put(getLevelDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


export function* editLevel(payload) {
    const data = payload;
    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${CLIENTS_POINT_URL}levels/${data.payload.levelId}/`, { name: data.payload.name })
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000);
        yield getAllLevel({ type: actionTypes.GetAllLevel, payload: { clientId: data.payload.client } });
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}

export function* deleteLevel(payload) {
    const data = payload;
    yield put(deleteLevelRequested());
    //yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${CLIENTS_POINT_URL}levels/${data.payload.levelId}/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        yield put(deleteLevelSuccess());
        
        yield delay(2000)
        yield getAllLevel({ type: actionTypes.GetAllLevel, payload: { clientId: data.payload.client } });
        yield getAllDivision({ type: actionTypes.GetAllDivision, payload: { clientId: data.payload.client } });
    }

    if (response && response.data.status === 111) {
        yield put(deleteLevelError(response.data.message.detail));
    }

    if (response && response.data && response.data.status !== 200 && response.data.status !== 111) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}

export function* deleteDivision(payload) {
    const data = payload;
    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${CLIENTS_POINT_URL}divisions/${data.payload.divisionId}/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000);
        yield getAllDivision({ type: actionTypes.GetAllDivision, payload: { clientId: data.payload.client } });
    }

    if (response && response.data && response.data.status !== 200 && response.data.status !== 111) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}


export function* getLevel(payload) {
    const data = payload;
    // yield put(loadTodoRequest());
    yield put(getLevelRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}levels/${data.payload.levelId}/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getLevelSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* getDivison(payload) {
    const data = payload;
    // yield put(loadTodoRequest());
    yield put(getDivisonRequested());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}divisions/${data.payload.divisionId}/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getDivisonSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}


export function* updateDivisionSingle(payload) {
    const data = payload;
    //  yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${CLIENTS_POINT_URL}divisions/${data.payload.divisionId}/`, { name: data.payload.name })
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(2000);
        yield getAllDivision({ type: actionTypes.GetAllDivision, payload: { clientId: data.payload.client } });
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }

    
}

export function* updateDivision(payload) {
    const data = payload;

    //yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${CLIENTS_POINT_URL}divisions/${data.payload.divisionId}/`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(2000);
        yield getAllDivision({ type: actionTypes.GetAllDivision, payload: { clientId: data.payload.client } });
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }

    
}


export function* getClientLevel(payload) {
    const data = payload;
    const { loaderHide = null } = data;

    yield put(getClientDivisionRequested());
    //  yield put(loadTodoRequest());
    if (!loaderHide) {
        
    }

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}levels/items/?fields=name,id,client,divisions_count&client_id=${data.payload.clientId}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getClientLevelSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}

export function* getClientDivision(payload) {
    const data = payload;
    const { loaderHide = null } = data;

    yield put(getClientDivisionRequested());
    //  yield put(loadTodoRequest());
    if (!loaderHide) {
        
    }
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}divisions/items/?client_id=${data.payload.clientId}&level_id=${data.payload.levelId}&fields=name,id`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getClientDivisionSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    

}




function* watchgetLevelPostion() {
    yield takeLatest(actionTypes.GetLevelPostion, getLevelPostion)
}

function* watchaddLevel() {
    yield takeLatest(actionTypes.AddLevel, addLevel)
}


function* watchgetDivisionLevel() {
    yield takeLatest(actionTypes.GetDivisionLevelPostion, getDivisionLevel)
}

function* watchgetDivisionReport() {
    yield takeLatest(actionTypes.GetDivisionReport, getDivisionReport)
}

function* watchaddDivision() {
    yield takeLatest(actionTypes.AddDivision, addDivision)
}


function* watchgetAllLevel() {
    yield takeLatest(actionTypes.GetAllLevel, getAllLevel)
}


function* watchgetAllDivision() {
    yield takeLatest(actionTypes.GetAllDivision, getAllDivision)
}

function* watchgetDivisionSearch() {
    yield takeLatest(actionTypes.GetDivisionSearch, getDivisionSearch)
}


function* watchgetLevelDetail() {
    yield takeLatest(actionTypes.GetLevelDetail, getLevelDetail)
}

function* watcheditLevel() {
    yield takeLatest(actionTypes.EditLevel, editLevel)
}

function* watchdeleteLevel() {
    yield takeLatest(actionTypes.DeleteLevel, deleteLevel)
}

function* watchdeleteDivision() {
    yield takeLatest(actionTypes.DeleteDivision, deleteDivision)
}

function* watchgetLevel() {
    yield takeLatest(actionTypes.GetLevel, getLevel)
}

function* watchgetDivison() {
    yield takeLatest(actionTypes.GetDivison, getDivison)
}


function* watchupdateDivisionSingle() {
    yield takeLatest(actionTypes.UpdateDivisionSingle, updateDivisionSingle)
}

function* watchgupdateDivision() {
    yield takeLatest(actionTypes.UpdateDivision, updateDivision)
}

function* watchgetClientLevel() {
    yield takeLatest(actionTypes.GetClientLevel, getClientLevel)
}


function* watchgetClientDivision() {
    yield takeLatest(actionTypes.GetClientDivision, getClientDivision)
}



export const LevelDivisonSaga = [
    fork(watchgetLevelPostion),
    fork(watchaddLevel),
    fork(watchgetDivisionLevel),
    fork(watchgetDivisionReport),
    fork(watchaddDivision),
    fork(watchgetAllLevel),
    fork(watchgetAllDivision),
    fork(watchgetDivisionSearch),
    fork(watchgetLevelDetail),
    fork(watcheditLevel),
    fork(watchdeleteLevel),
    fork(watchdeleteDivision),
    fork(watchgetLevel),
    fork(watchgetDivison),
    fork(watchupdateDivisionSingle),
    fork(watchgupdateDivision),
    fork(watchgetClientLevel),
    fork(watchgetClientDivision)
]
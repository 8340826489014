import React, { Fragment } from "react";
import { Scrollbars } from 'react-custom-scrollbars';
import NavList from './components';
const AdminNav = (props) => {
    // console.log("admin-nav" , props);
    return (
        <Fragment>
            {/* {props.expandState || props.dashLayout ? <Scrollbars
                renderTrackHorizontal={props =>
                    <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
                autoHide autoHeight autoHeightMax={'90vh'}>
                <NavList expandState={props.expandState} dashLayout={props.dashLayout} userProfile={props.userProfile} /> </Scrollbars>
                : <NavList expandState={props.expandState} dashLayout={props.dashLayout} userProfile={props.userProfile} />} */}
            <Scrollbars
                renderTrackHorizontal={props =>
                    <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
                autoHide autoHeight autoHeightMax={'90vh'}>
                <NavList expandState={props.expandState} dashLayout={props.dashLayout} userProfile={props.userProfile} /> </Scrollbars>
        </Fragment>
    );
};

export default AdminNav;

import axios from "axios";
import { masterInstacne, refershInstance, ClientInstance } from 'utils/axios-instance';
import { createBrowserHistory } from 'history';
import * as actionTypes from '../store/actionTypes';
import { WebConfig } from '../config';

const history = createBrowserHistory({
    forceRefresh: true
});
let axiosLoadCount = 0;
let clientLoadCount = 0;

masterInstacne.interceptors.request.use((config) => {
    // const userInfo = JSON.parse(localStorage.getItem("_tokenExpireDate"));
    // const expDuration = (new Date(userInfo).getTime());
    // if (expDuration < (new Date().getTime() - 1000)) {
    //     refershInstance
    //         .post(`${WEB_POINT_URL}/o/token/`, JSON_to_URLEncoded(
    //             {
    //                 client_id: process.env.CLIENT_ID,
    //                 client_secret: process.env.CLIENT_SECRET,
    //                 grant_type: process.env.GRANT_TYPE_REFRESH_TOKEN,
    //                 refresh_token: `${localStorage.getItem('refersh_token')}`
    //             }

    //         ), {
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded',
    //             },
    //         })
    //         .then(response => {
    //             localStorage.setItem('public_token', response.data.access_token)
    //             localStorage.setItem('refersh_token', response.data.refresh_token)
    //             const expDate = new Date(new Date().getTime() + +response.data.expires_in * 1000);
    //             localStorage.setItem('_tokenExpireDate', JSON.stringify(expDate));
    //             window.location.reload();
    //         }).catch(() => {
    //             localStorage.clear();
    //             history.push("/");
    //         });
    // }
    return config;
},
    error => {
        Promise.reject(error)
    });

masterInstacne.interceptors.response.use((response) => {
    if (response.data.status === 401) {
        localStorage.clear();
        history.push("/");
        window.location.reload();
    }
    return response;
}, (error) => {

    return Promise.reject(error);
});

const clientinterceptor = (store) => {
    ClientInstance.interceptors.request.use((config) => {

        clientLoadCount++;

        store.dispatch({ type: actionTypes.LoadTodoRequested });
        store.dispatch({ type: actionTypes.LoaderShow });
        // if (config.url !== `${API_POINT_URL}events/inspections/widgets/?widget=average_score`) {

        // }
        config.headers = {
            ...config.headers,
            'Authorization': localStorage.getItem("impersonate_access_token") ?
                `Bearer ${localStorage.getItem("impersonate_access_token")}`
                : `Bearer ${localStorage.getItem("private_token")}`,
            'Company': localStorage.getItem("inperosnateId"),
            'Accept-Language': localStorage.getItem("language") ? localStorage.getItem("language") : "en"
        }
        // console.log("config" , config) localStorage.getItem("inperosnateId");
        // console.log("API_POINT_URL" , API_POINT_URL);
        return config;
    });

    ClientInstance.interceptors.response.use((response) => {
        const API_POINT_URL = WebConfig.serviceUrls.RestApi;
        clientLoadCount--;
        //  console.log("clientLoadCount" , clientLoadCount);

        if (clientLoadCount === 0 &&
            response.config.url !== `${API_POINT_URL}master/azure/sas/`) {
            setTimeout(() => {
                store.dispatch({ type: actionTypes.LoaderHide });
            }, 500)
        }

        if (response.data.status === 401) {
            localStorage.clear();
            window.location.reload();
            history.push("/");
        }
        return response;
    }, (error) => {
        // console.log("error" , error.response);
        //clientLoadCount = 0;
        clientLoadCount--;
        if (!error.response) {
            localStorage.clear();
            window.location.reload();
            history.push("/");
        }

        if (error.response && error.response.status === 500) {
            store.dispatch({ type: actionTypes.LoaderHide });
            setTimeout(() => {
                history.push("/500", { Errordata: error.response.data, PrevLocation: history.location });
            }, 1000)
            return;
        }

        if (error.response && error.response.status !== 404) {
            store.dispatch({ type: actionTypes.LoadTodoError, payload: error.response.statusText });
            store.dispatch({ type: actionTypes.LoaderHide });
            return;
        }
        if (error.response && error.response.status === 404) {
            store.dispatch({ type: actionTypes.LoadTodoError, payload: error.response.statusText });
            store.dispatch({ type: actionTypes.LoaderHide });
            return;
        }
        store.dispatch({ type: actionTypes.LoadTodoError, payload: `${error}` });
        store.dispatch({ type: actionTypes.LoaderHide });
        // return Promise.reject(error);
    });
}


const axiosinterceptor = (store) => {
    axios.interceptors.request.use((config) => {
        axiosLoadCount++;
        store.dispatch({ type: actionTypes.LoadTodoRequested });
        store.dispatch({ type: actionTypes.LoaderShow });
        if (localStorage.getItem("private_token")) {
            config.headers = {
                ...config.headers,
                'Authorization': `Bearer ${localStorage.getItem("private_token")}`,
                'Accept-Language': localStorage.getItem("language") ? localStorage.getItem("language") : "en",
            }
            if (localStorage.getItem("CompanyId")) {
                config.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("private_token")}`,
                    'Accept-Language': localStorage.getItem("language") ? localStorage.getItem("language") : "en",
                    'Company': localStorage.getItem("CompanyId"),
                }
            }
        }
        return config;
    });

    axios.interceptors.response.use((response) => {
        axiosLoadCount--;
        if (axiosLoadCount === 0) {
            setTimeout(() => {
                store.dispatch({ type: actionTypes.LoaderHide });
            }, 500)
        }
        if (response.data.status === 401) {
            if(localStorage.getItem("private_token"))
            {
                localStorage.clear();
                window.location.reload();
                history.push("/");
                return
            }
            setTimeout(() => {
                localStorage.clear();
                window.location.reload();
                history.push("/");
            }, 4000);
        }
        return response;
    }, (error) => {
        axiosLoadCount--;
        if (!error.response) {
            localStorage.clear();
            window.location.reload();
            history.push("/");
        }

        if (error.response && error.response.status === 500) {
            store.dispatch({ type: actionTypes.LoaderHide });
            setTimeout(() => {
                history.push("/500", { Errordata: error.response.data, PrevLocation: history.location });
            }, 1000)
            return;
        }
        if (error.response && error.response.status !== 404) {
            store.dispatch({ type: actionTypes.LoadTodoError, payload: error.response.statusText });
            store.dispatch({ type: actionTypes.LoaderHide });
            return;
        }
        if (error.response && error.response.status === 404) {
            store.dispatch({ type: actionTypes.LoadTodoError, payload: error.response.statusText });
            store.dispatch({ type: actionTypes.LoaderHide });
            return;
        }
        store.dispatch({ type: actionTypes.LoadTodoError, payload: `${error}` });
        store.dispatch({ type: actionTypes.LoaderHide });
        // return Promise.reject(error);
    });
}

export default {
    axiosinterceptor, clientinterceptor
};


import * as actionTypes from '../actionTypes';

const initialState = {
    savedReportList: null,
    reportDetail : null,
    genratedFile : null
};

const ReportReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    
    let object;

    switch (type) {

        case actionTypes.GetSavedReportSuccess:
            object = { ...state , savedReportList:payload };
            break

        case actionTypes.GetReportDetailRequested:
            object = { ...state , reportDetail:null };
            break

        case actionTypes.GetReportDetailSuccess:
            object = { ...state , reportDetail:payload };
            break

        case actionTypes.GenrateReportRequested:
            object = { ...state , genratedFile:null };
            break

        case actionTypes.GenrateReportSuccess:
            object = { ...state , genratedFile:payload };
            break

        case actionTypes.LogOut:
            object = { state: undefined };
            break

        default:
            object = state;
    }
    return object
};
export default ReportReducer;
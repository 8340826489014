import * as actionTypes from '../actionTypes';

const initialState = {
    serviceList: null,
    serviceDetail : null,
    templateDropdownList: null,
    serviceDropdownList : null,
    areaTemplateList: null,
    areaList : null,
    areaDetail : null,
    serviceAreaList : null,
    areaLocationList : null,
    isAreaNameExist : null,
    areaAllList : null
};
const areaServiceReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    
    switch (type) {

        case actionTypes.GetServiceListSuccess:
            object = { ...state, serviceList: payload }
            break

        case actionTypes.GetAreaServiceDetailRequested:
            object = { ...state, serviceDetail: null }
            break
        
        case actionTypes.GetAreaServiceDetailSuccess:
            object = { ...state, serviceDetail: payload }
            break
        
        case actionTypes.GetAreaTemplateItemsRequested:
            object = { ...state, templateDropdownList: null }
            break

        case actionTypes.GetAreaTemplateItemsSuccess:
            object = { ...state, templateDropdownList: payload }
            break


        case actionTypes.GetAreaTemplateServicesRequested:
            object = { ...state, serviceDropdownList: null }
            break

        case actionTypes.GetAreaTemplateServicesSuccess:
            object = { ...state, serviceDropdownList: payload }
            break

        
        case actionTypes.GetCloneTemplateRequested:
            object = { ...state, areaTemplateList: null }
            break

        case actionTypes.GetCloneTemplateSuccess:
            object = { ...state, areaTemplateList: payload }
            break

        case actionTypes.GetAreaListSuccess:
            object = { ...state, areaList: payload }
            break


        case actionTypes.GetAreaDetailRequested:
            object = { ...state, areaDetail: null }
            break

        case actionTypes.GetAreaDetailSuccess:
            object = { ...state, areaDetail: payload }
            break


        case actionTypes.GetServicesAreaAssociatedRequested:
            object = { ...state, serviceAreaList: null }
            break

        case actionTypes.GetServicesAreaAssociatedSuccess:
            object = { ...state, serviceAreaList: payload }
            break


        case actionTypes.GetLocationAreaAssociatedRequested:
            object = { ...state, areaLocationList: null }
            break

        case actionTypes.GetLocationAreaAssociatedSuccess:
            object = { ...state, areaLocationList: payload }
            break


        case actionTypes.AddAreaCheckRequested:
            object = { ...state, isAreaNameExist: false }
            break

        case actionTypes.AddAreaCheckLoad:
            object = { ...state, isAreaNameExist: null }
            break

        case actionTypes.AddAreaCheckSuccess:
            object = { ...state, isAreaNameExist: true }
            break

        case actionTypes.GetAreaAllListRequested:
            object = { ...state, areaAllList: null }
            break
        
        case actionTypes.GetAreaAllListSuccess:
            object = { ...state, areaAllList: payload }
            break

        case actionTypes.LogOut: 
            object = { state : undefined };
            break

        default:
            object = state;
    }
    return object
};
export default areaServiceReducer;
import * as actionTypes from '../actionTypes';

const initialState = {
    countriesList: null,
    regionList: null,
    citiesList: null,
    companyList : null,
    companyDetail : null,
    allcompanyList : null
};
const companyReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    switch (type) {

        case actionTypes.GetCountriesSuccess:
            object = { ...state, countriesList: payload }
            break

        case actionTypes.GetRegionRequested:
            object = { ...state, regionList: null, citiesList: null }
            break

        case actionTypes.GetRegionSuccess:
            object = { ...state, regionList: payload }
            break


        case actionTypes.GetCitiesRequested:
            object = { ...state, citiesList: null }
            break

        case actionTypes.GetCitiesSuccess:
            object = { ...state, citiesList: payload }
            break

        case actionTypes.GetCompanyListRequested:
            object = { ...state, companyList: null }
            break

        case actionTypes.GetCompanyListSuccess:
            object = { ...state, companyList: payload }
            break

        case actionTypes.GetCompanyDetailRequested:
            object = { ...state, companyDetail: null }
            break

        case actionTypes.GetCompanyDetailSuccess:
            object = { ...state, companyDetail: payload }
            break

        case actionTypes.GetCompanyAllListSuccess:
            object = { ...state, allcompanyList: payload }
            break
            
        case actionTypes.LogOut: 
           object = { state : undefined };
            break

        default:
            object = state;
    }
    return object
};
export default companyReducer;
import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig, } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getRecentSearchClient,
    geEmployeListRequested,
    geEmployeListSuccess,
    getEmployeDetailRequested,
    getEmployeDetailSuccess,
    getEmployeListArchieveRequested,
    getEmployeListArchieveSuccess,
    getEmployeDropdownListRequested,
    getEmployeDropdownListSuccess,
    getAllInspectorListRequested,
    getAllInspectorListSuccess
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const EMPLOYEES_POINT_URL = WebConfig.ApiUrls.company.employees;
const COMPANY_POINT_URL =  WebConfig.ApiUrls.companyUsers;

export function* addEmploye(payload) {
    const data = payload;

    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${EMPLOYEES_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }

    



}


export function* getEmployeList(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null,
        status = null, ordering = null, service_provider_division = null, page_size = null } } = data;

    yield put(geEmployeListRequested());
    // if (search) {
    //     yield delay(400);
    // }
    // yield put(loadTodoRequest());
    
    let instance = localStorage.getItem('inperosnate') ? ClientInstance : axios;
    let recentSearch = localStorage.getItem('inperosnate') ? true : false

    const response = yield call(() => {
        return instance
            // .get(`${API_POINT_URL}companies/${data.payload.companyId}/clients/?page=${currentPage}`)
            .get(search && !service_provider_division ?
                `${API_POINT_URL}${EMPLOYEES_POINT_URL}?page=${currentPage}&search=${search}&fields=id,name,position_assignment,user_company_users,employee_type,is_active` :
                status ? `${API_POINT_URL}${EMPLOYEES_POINT_URL}?page=${currentPage}&is_active=${status}&fields=id,name,position_assignment,user_company_users,employee_type,is_active` :
                    ordering ? `${API_POINT_URL}${EMPLOYEES_POINT_URL}?page=${currentPage}&ordering=${ordering}&fields=id,name,position_assignment,user_company_users,employee_type,is_active` :
                        service_provider_division && !search ? `${API_POINT_URL}${EMPLOYEES_POINT_URL}?page=${currentPage}&service_provider_division=${service_provider_division}&page_size=${page_size}&fields=id,name,position_assignment,user_company_users,employee_type,is_active` :
                            service_provider_division && search ? `${API_POINT_URL}${EMPLOYEES_POINT_URL}?page=${currentPage}&service_provider_division=${service_provider_division}&page_size=${page_size}&search=${search}&fields=id,name,position_assignment,user_company_users,employee_type,is_active`
                                : `${API_POINT_URL}${EMPLOYEES_POINT_URL}?page=${currentPage}&fields=id,name,position_assignment,user_company_users,employee_type,is_active`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {

        yield put(geEmployeListSuccess(response.data.response));
        if (search && recentSearch) {
            yield put(getRecentSearchClient({ page: 'employees-list' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

    



}

export function* updateEmployeStatus(payload) {
    const data = payload;

    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${EMPLOYEES_POINT_URL}${data.payload.id}/`, { is_active: data.payload.is_active })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000);
        if (!data.payload.employePage) {
            yield getEmployeList({ type: actionTypes.GetEmployeList, payload: { currentPage: data.payload.currentPage } });
        }

        if (data.payload.spOrg) {
            yield getEmployeList({
                type: actionTypes.GetEmployeList, payload: {
                    currentPage: data.payload.currentPage,
                    service_provider_division: data.payload.service_provider_division,
                    page_size: data.payload.page_size,
                }
            });
        }

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


export function* deleteEmploye(payload) {
    const data = payload;

    //   yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${EMPLOYEES_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });


    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000)

        if (!data.payload.employePage) {
            yield getEmployeList({ type: actionTypes.GetEmployeList, payload: { currentPage: data.payload.currentPage } });
        }

        if (data.payload.spOrg) {
            yield getEmployeList({
                type: actionTypes.GetEmployeList, payload: {
                    currentPage: data.payload.currentPage,
                    service_provider_division: data.payload.service_provider_division,
                    page_size: data.payload.page_size,
                }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}



export function* getEmployeDetail(payload) {
    const data = payload;
    yield put(getEmployeDetailRequested());
    //yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${EMPLOYEES_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getEmployeDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    

}


export function* editEmploye(payload) {
    const data = payload;
    //  yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${EMPLOYEES_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


export function* getEmployeListArchieve(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null } } = data;
    yield put(getEmployeListArchieveRequested());
    // if (search) {
    //     yield delay(400);
    // }
    //  yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            // .get(`${API_POINT_URL}companies/${data.payload.companyId}/clients/?page=${currentPage}`)
            .get(search ?
                `${API_POINT_URL}${EMPLOYEES_POINT_URL}archived/?page=${currentPage}&search=${search}`
                : `${API_POINT_URL}${EMPLOYEES_POINT_URL}archived/?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getEmployeListArchieveSuccess(response.data.response));
        if (search) {
            yield put(getRecentSearchClient({ page: 'employees-archived' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

    
}

export function* updateEmployeArchieveStatus(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());
    
    let response;

    if (data.payload.group) {
        response = yield call(() => {
            return ClientInstance
                .delete(`${API_POINT_URL}${EMPLOYEES_POINT_URL}${data.payload.id}/undelete/`, {
                    data: {
                        group: data.payload.group
                    }
                })
                .then(response => {
                    return response;
                })
        });
    }
    else {
        response = yield call(() => {
            return ClientInstance
                .delete(`${API_POINT_URL}${EMPLOYEES_POINT_URL}${data.payload.id}/undelete/`)
                .then(response => {
                    return response;
                })
        });
    }

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000);

        if (!data.payload.clientArchievePage) {
            yield getEmployeListArchieve({ type: actionTypes.GetEmployeArchiveList, payload: { currentPage: data.payload.currentPage } });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}

export function* deleteEmployePostion(payload) {
    const data = payload;
    // yield put(loadTodoRequest());
    
    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}companies/employee-positions/${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });


    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        
        yield delay(1000);
        yield getEmployeDetail({ type: actionTypes.GetEmployeDetail, payload: { id: data.payload.employeId } });
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    


}


export function* getEmployeDropdownList(payload) {
    const data = payload;
    yield put(getEmployeDropdownListRequested());
    
    let codenames = null;
    let levels = null;
    if(data.payload && data.payload.codenames)
    {
        codenames = data.payload.codenames.map((item) => `&codename=${[item]}`);
    }
    if(data.payload && data.payload.levels)
    {
        levels = data.payload.levels.map((item) => `&level=${[item]}`);
    }

    const response = yield call(() => {
        return ClientInstance
            .get(codenames && !levels ? `${API_POINT_URL}${COMPANY_POINT_URL}?type=sp${codenames.join("")}` :
             codenames && levels ? `${API_POINT_URL}${COMPANY_POINT_URL}?type=sp${codenames.join("")}${levels.join("")}` :
                `${API_POINT_URL}${COMPANY_POINT_URL}?type=sp`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getEmployeDropdownListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    

}


export function* getAllInspectorList(payload) {
    const data = payload;
    yield put(getAllInspectorListRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${COMPANY_POINT_URL}?codename=${data.payload.codename}&include_request_user`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getAllInspectorListSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}


function* watchaddEmploye() {
    yield takeLatest(actionTypes.AddEmploye, addEmploye)
}

function* watchgetEmployeList() {
    yield takeLatest(actionTypes.GetEmployeList, getEmployeList)
}

function* watchupdateEmployeStatus() {
    yield takeLatest(actionTypes.UpdateEmployeStatus, updateEmployeStatus)
}

function* watchdeleteEmploye() {
    yield takeLatest(actionTypes.DeleteEmploye, deleteEmploye)
}


function* watchgetEmployeDetail() {
    yield takeLatest(actionTypes.GetEmployeDetail, getEmployeDetail)
}

function* watcheditEmploye() {
    yield takeLatest(actionTypes.EditEmploye, editEmploye)
}


function* watchgetEmployeListArchieve() {
    yield takeLatest(actionTypes.GetEmployeArchiveList, getEmployeListArchieve)
}

function* watchupdateEmployeArchieveStatus() {
    yield takeLatest(actionTypes.UpdateEmployeArchieveStatus, updateEmployeArchieveStatus)
}

function* watchdeleteEmployePostion() {
    yield takeLatest(actionTypes.DeleteEmployePostion, deleteEmployePostion)
}

function* watchgetEmployeDropdownList() {
    yield takeLatest(actionTypes.GetEmployeDropdownList, getEmployeDropdownList)
}

function* watchgetAllInspectorList() {
    yield takeLatest(actionTypes.GetAllInspectorList, getAllInspectorList)
}


export const EmployeSaga = [
    fork(watchaddEmploye),
    fork(watchgetEmployeList),
    fork(watchupdateEmployeStatus),
    fork(watchdeleteEmploye),
    fork(watchgetEmployeDetail),
    fork(watcheditEmploye),
    fork(watchgetEmployeListArchieve),
    fork(watchupdateEmployeArchieveStatus),
    fork(watchdeleteEmployePostion),
    fork(watchgetEmployeDropdownList),
    fork(watchgetAllInspectorList)
]
import * as actionTypes from '../actionTypes';

const initialState = {
    splevelPostionList: null,
    spdivisionlevelList : null,
    spdivisionReportList : null,
    allSpLevelList : null,
    allSpDumpList : null,
    searchSpDivsionList: null,
    levelSpDetail : null,
    deleteSpLevelError: false,
    deleteLevelSpErrorMessage : null,
    divisionSpDetail : null,
    SplevelDropdownList : null,
    SpDivisionDropdownList : null
};
const SpOrgReducer = (state, { type , payload}) => {

    if (state === undefined) {
        state = initialState
    }
    
    let object;
    switch (type) {

        case actionTypes.GetSpLevelPostionSuccess:
            object = { ...state, splevelPostionList: payload }
            break

        case actionTypes.GetSpDivisionLevelPostionSuccess:
            object = { ...state, spdivisionlevelList: payload }
            break

        case actionTypes.GetSpDivisionReportRequested:
            object = { ...state, spdivisionReportList: null }
            break

        case actionTypes.GetSpDivisionReportSuccess:
            object = { ...state, spdivisionReportList: payload }
            break

        case actionTypes.GetAllSpLevelSuccess:
            object = { ...state, allSpLevelList: payload }
            break

        case actionTypes.GetAllSpLevelRequested:
            object = { ...state, allSpLevelList: null }
            break
    

        case actionTypes.GetAllSpDivisionRequested:
            object = { ...state, allSpDumpList: null }
            break

        case actionTypes.GetAllSpDivisionSuccess:
            object = { ...state, allSpDumpList: payload }
            break

        case actionTypes.GetAllSpAdminDivisionRequested:
            object = { ...state, allSpDumpList: null }
            break

        case actionTypes.GetAllSpAdminDivisionSuccess:
            object = { ...state, allSpDumpList: payload }
            break
        

        case actionTypes.GetSpDivisionSearchRequested:
            object = { ...state, searchSpDivsionList: null }
            break

        case actionTypes.GetSpDivisionSearchSuccess:
            object = { ...state, searchSpDivsionList: payload }
            break


        case actionTypes.GetSpLevelDetailRequested:
            object = { ...state, levelSpDetail: null }
            break

        case actionTypes.GetSpLevelDetailSuccess:
            object = { ...state, levelSpDetail: payload }
            break

        case actionTypes.DeleteSpLevelError:
            object = { ...state, deleteSpLevelError: true , deleteLevelSpErrorMessage : payload}
            break

        case actionTypes.DeleteSpLevelSuccess:
            object = { ...state, deleteSpLevelError: false , deleteLevelSpErrorMessage : null }
            break

        case actionTypes.DeleteSpLevelRequested:
            object = { ...state, deleteSpLevelError: false , deleteLevelSpErrorMessage : null }
            break

        case actionTypes.GetSpDivisonRequested:
            object = { ...state, divisionSpDetail: null }
            break

        case actionTypes.GetSpDivisonSuccess:
            object = { ...state, divisionSpDetail: payload }
            break


        case actionTypes.GetSPLevellistSuccess:
            object = { ...state, SplevelDropdownList: payload }
            break

        case actionTypes.GetSPDivsionRequested:
            object = { ...state, SpDivisionDropdownList: null }
            break

        case actionTypes.GetSPDivsionSuccess:
            object = { ...state, SpDivisionDropdownList: payload }
            break

        case actionTypes.LogOut: 
            object = { state : undefined };
            break
            
        default:
            object = state;
    }
    return object
};
export default SpOrgReducer;
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { UncontrolledTooltip } from 'reactstrap';

class DashboardNav extends Component {

  render() {
    // console.log("dashboard-nav", this.props);
    const { t } = this.props;
    return (
      <div className="dashboard-nav">
        <ul>
          <li>
            <NavLink to="/admin/super-dashboard" exact>
              <span className="icon" id="dashboard">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  width="18px" height="18px" viewBox="0 0 18 18" >
                  <path fill="#8F9DB1" d="M5.727,15.545H3.273c-0.452,0-0.818-0.365-0.818-0.818c0-0.451,0.366-0.817,0.818-0.817h2.454
    c0.452,0,0.818,0.366,0.818,0.817C6.545,15.18,6.179,15.545,5.727,15.545z M6.545,12.273h-4.09c-0.905,0-1.637,0.731-1.637,1.636
    v1.636c0,0.905,0.732,1.637,1.637,1.637h4.09c0.905,0,1.637-0.731,1.637-1.637V13.91C8.182,13.006,7.45,12.273,6.545,12.273
    L6.545,12.273z M14.727,4.091h-2.453c-0.453,0-0.818-0.367-0.818-0.818c0-0.452,0.365-0.818,0.818-0.818h2.453
    c0.453,0,0.818,0.366,0.818,0.818C15.545,3.724,15.18,4.091,14.727,4.091z M15.545,0.818h-4.09c-0.905,0-1.637,0.732-1.637,1.637
    V4.09c0,0.904,0.731,1.636,1.637,1.636h4.09c0.905,0,1.637-0.732,1.637-1.636V2.455C17.182,1.55,16.45,0.818,15.545,0.818z
     M15.545,14.729c0,0.449-0.365,0.816-0.818,0.816h-2.453c-0.453,0-0.818-0.365-0.818-0.818V9.818C11.455,9.367,11.82,9,12.273,9
    h2.453c0.453,0,0.818,0.367,0.818,0.818V14.729z M15.545,7.364h-4.09C10.55,7.364,9.818,8.096,9.818,9v6.545
    c0,0.905,0.731,1.637,1.637,1.637h4.09c0.905,0,1.637-0.731,1.637-1.637V9C17.182,8.096,16.45,7.364,15.545,7.364z M6.545,8.182
    C6.545,8.633,6.179,9,5.727,9H3.273C2.821,9,2.455,8.633,2.455,8.182v-4.91c0-0.45,0.366-0.817,0.818-0.817h2.454
    c0.452,0,0.818,0.366,0.818,0.818V8.182z M6.545,0.818h-4.09c-0.905,0-1.637,0.732-1.637,1.637V9c0,0.904,0.732,1.636,1.637,1.636
    h4.09c0.905,0,1.637-0.731,1.637-1.636V2.455C8.182,1.55,7.45,0.818,6.545,0.818z"/>
                </svg>
                {!this.props.expandState && !this.props.dashLayout &&
                  <UncontrolledTooltip placement="right" target="dashboard" container="dashboard" innerClassName="c-tooltip" arrowClassName="c-tooltip-arrow">
                    {t('NAV.dashboard')}
                  </UncontrolledTooltip>
                }
              </span>
              {(this.props.expandState || this.props.dashLayout) && t('NAV.dashboard')}

            </NavLink>
          </li>
          <li>
            <NavLink exact
              to="/admin/companies"
              activeClassName="active"
              className={this.props.history.location.pathname === "/admin/add-company" ? 'active'
                : this.props.history.location.state && this.props.history.location.state.edit ? 'active' :
                  this.props.history.location.state && this.props.history.location.state.companydetail ? 'active' : ''}
            >
              <span className="icon" id="company">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  width="18px" height="18px" viewBox="0 0 18 18" >
                  <path fill="#8F9DB1" d="M8.182,7.364h1.636V5.727H8.182V7.364z M8.182,10.636h1.636V9H8.182V10.636z M9.818,15.546v-3.272H8.182
    v3.271H5.727V4.91h6.546v10.636H9.818z M15.545,13.909v1.636H13.91V3.273H9.818V0.818H8.183v2.455H4.09v12.272H2.454V13.91H0.818
    v3.271h16.364v-3.272H15.545L15.545,13.909z"/>
                </svg>
                {!this.props.expandState && !this.props.dashLayout &&
                  <UncontrolledTooltip placement="right" target="company" container="company" innerClassName="c-tooltip" arrowClassName="c-tooltip-arrow">
                    {t('NAV.companies')}
                  </UncontrolledTooltip>
                }
              </span>
              {(this.props.expandState || this.props.dashLayout) && t('NAV.companies')}

            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/companies-admin" className={
              this.props.history.location.pathname === "/admin/add-company-admin" ? 'active' :
                this.props.history.location.state && this.props.history.location.state.editAdmin ? 'active' :
                  this.props.history.location.state && this.props.history.location.state.companyAdmindetail ? 'active' : ''}>
              <span className="icon" id="companyAdmin">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  width="18px" height="15px" viewBox="0 0 18 15" >
                  <path fill="#8F9DB1" d="M13.462,14.843h-1.488v-1.487c0-1.229-1-2.23-2.229-2.23h-5.95c-1.231,0-2.232,1.001-2.232,2.23v1.486H0.074
    v-1.486c0-2.051,1.669-3.719,3.72-3.719h5.95c2.05,0,3.718,1.668,3.718,3.719V14.843z M10.487,4.43c0-2.051-1.668-3.719-3.719-3.719
    S3.049,2.379,3.049,4.43c0,2.05,1.668,3.718,3.719,3.718S10.487,6.48,10.487,4.43z M8.999,4.43c0,1.23-1,2.23-2.231,2.23
    s-2.231-1-2.231-2.23c0-1.23,1-2.231,2.231-2.231S8.999,3.2,8.999,4.43z M17.926,13.355c0-1.694-1.147-3.174-2.789-3.6l-0.373,1.441
    c0.985,0.255,1.674,1.143,1.674,2.158v1.487h1.488V13.355z M14.956,4.434c0-1.699-1.15-3.181-2.797-3.603L11.79,2.272
    c0.988,0.253,1.678,1.142,1.678,2.162s-0.689,1.908-1.678,2.161l0.369,1.442C13.806,7.616,14.956,6.134,14.956,4.434z"/>
                </svg>
                {!this.props.expandState && !this.props.dashLayout &&
                  <UncontrolledTooltip placement="right" target="companyAdmin" container="companyAdmin" popperClassName="c-big-tooltip"
                    innerClassName="c-tooltip" arrowClassName="c-tooltip-arrow">
                    {t('NAV.companyAdmin')}
                  </UncontrolledTooltip>
                }
              </span>
              {(this.props.expandState || this.props.dashLayout) && t('NAV.companyAdmin')}

            </NavLink>
          </li>
          <li>
            <NavLink exact to="/admin/archives" activeClassName="active">
              <span className="icon" id="Archives">

                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15">
                  <g fill="none">
                    <g fill="#8F9DB1">
                      <g>
                        <g>
                          <g>
                            <g>
                              <path d="M25.75 11c.414 0 .75.336.75.75v3.636c0 .415-.336.75-.75.75h-.705v8.705c0 .38-.282.693-.648.743l-.102.007h-13.09c-.38 0-.694-.282-.744-.648l-.006-.102-.001-8.705H9.75c-.38 0-.693-.282-.743-.648L9 15.386V11.75c0-.414.336-.75.75-.75zm-2.205 5.136H11.954v7.954h11.591v-7.954zm-4.34 1.41c.414 0 .75.335.75.75 0 .38-.283.693-.649.743l-.101.006h-2.91c-.414 0-.75-.335-.75-.75 0-.38.283-.693.649-.743l.101-.007h2.91zM25 12.5H10.5v2.136H25V12.5z" transform="translate(-31 -517) translate(22 92) translate(0 414)" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                {!this.props.expandState && !this.props.dashLayout &&
                  <UncontrolledTooltip placement="right" target="Archives" container="Archives"
                    innerClassName="c-tooltip" arrowClassName="c-tooltip-arrow">
                    {t('NAV.Archives')}
                  </UncontrolledTooltip>
                }
              </span>
              {(this.props.expandState || this.props.dashLayout) && t('NAV.Archives')}

            </NavLink>
          </li>

          <li>
            <NavLink exact to="/admin/sp-orgnization" activeClassName="active">
              <span className="icon" id="spOrg">

                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                  <g fill="none">
                    <g fill="#8F9DB1">
                      <g>
                        <g>
                          <path d="M16 13h4V9h-4v4zm0 14h4v-4h-4v4zm8 0h4v-4h-4v4zM8 27h4v-4H8v4zm3-6H9v-4h8v-2h2v2h8v4h-2v-2h-6v2h-2v-2h-6v2z" transform="translate(-30 -285) translate(22 276)" />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                {!this.props.expandState && !this.props.dashLayout &&
                  <UncontrolledTooltip placement="right" target="spOrg" container="spOrg"
                    innerClassName="c-tooltip" arrowClassName="c-tooltip-arrow">
                    {t('NAV.SpOrganization')}
                  </UncontrolledTooltip>
                }
              </span>
              {(this.props.expandState || this.props.dashLayout) && t('NAV.SpOrganization')}

            </NavLink>
          </li>
        </ul>
      </div>
    );
  }


};

export default (withTranslation()(withRouter(DashboardNav)));

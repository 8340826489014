import * as actionTypes from '../actionTypes';

export const getCountries = () => {
    return {
        type: actionTypes.GetCountries,
    }
}

export const getCountriesSucess = (data) => {
    return {
        type: actionTypes.GetCountriesSuccess,
        payload: data
    }
}

export const getRegion = (data) => {
    return {
        type: actionTypes.GetRegion,
        payload: data
    }
}

export const getRegionRequested = () => {
    return {
        type: actionTypes.GetRegionRequested
    }
}

export const getRegionSuccess = (data) => {
    return {
        type: actionTypes.GetRegionSuccess,
        payload: data
    }
}

export const getCites = (data) => {
    return {
        type: actionTypes.GetCities,
        payload: data
    }
}

export const getCitiesRequested = () => {
    return {
        type: actionTypes.GetCitiesRequested
    }
}

export const getCitesSuccess = (data) => {
    return {
        type: actionTypes.GetCitiesSuccess,
        payload: data
    }
}

export const addCompany = (data) => {
    return {
        type: actionTypes.CreateCompany,
        payload: data
    }
}

export const getcompanyList = (data) => {
    return {
        type: actionTypes.GetCompanyList,
        payload : data
    }
}

export const getcompanyListSuccess = (data) => {
    return {
        type: actionTypes.GetCompanyListSuccess,
        payload: data
    }
}

export const getcompanyListRequested = (data) => {
    return {
        type: actionTypes.GetCompanyListRequested,
        payload: data
    }
}

export const updateStatus = (data) => {
    return {
        type: actionTypes.UpdateStatus,
        payload: data
    }
}

export const getCompanyDetail = (data) => {
    return {
        type: actionTypes.GetCompanyDetail,
        payload: data
    }
}


export const getCompanyDetailRequested = (data) => {
    return {
        type: actionTypes.GetCompanyDetailRequested,
        payload: data
    }
}

export const getCompanyDetailSuccess = (data) => {
    return {
        type: actionTypes.GetCompanyDetailSuccess,
        payload: data
    }
}

export const editCompany = (data) => {
    return {
        type: actionTypes.EditCompany,
        payload: data
    }
}


export const getcompanyall = (data) => {
    return {
        type: actionTypes.GetCompanyAllList,
        payload : data
    }
}

export const getcompanyallSuccess = (data) => {
    return {
        type: actionTypes.GetCompanyAllListSuccess,
        payload: data
    }
}




import * as actionTypes from '../actionTypes';

const initialState = {
    employeList: null,
    employeDetail: null,
    archieveEmployeList: null,
    employeDropdownList: null,
    allInspectorList : null
};

const EmployeReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    switch (type) {

        case actionTypes.GetEmployeListRequested:
            object = {
                ...state,
                //  employeList : null
            }
            break

        case actionTypes.GetEmployeListSuccess:
            object = { ...state, employeList: payload }
            break

        case actionTypes.GetEmployeDetailRequested:
            object = { ...state, employeDetail: null }
            break

        case actionTypes.GetEmployeDetailSuccess:
            object = { ...state, employeDetail: payload }
            break

        case actionTypes.GetEmployeArchiveListRequested:
            object = {
                ...state,
                //archieveEmployeList : null 
            }
            break

        case actionTypes.GetEmployeArchiveListSuccess:
            object = { ...state, archieveEmployeList: payload }
            break

        case actionTypes.GetEmployeDropdownListRequested:
            object = { ...state, employeDropdownList: null }
            break

        case actionTypes.GetEmployeDropdownListSuccess:
            object = { ...state, employeDropdownList: payload }
            break

        case actionTypes.GetAllInspectorListRequested:
            object = { ...state, allInspectorList: null }
            break
        
        case actionTypes.GetAllInspectorListSuccess:
            object = { ...state, allInspectorList: payload }
            break

        case actionTypes.LogOut:
            object = { state: undefined };
            break

        default:
            object = state;
    }
    return object
};
export default EmployeReducer;
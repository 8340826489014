import * as actionTypes from '../actionTypes';

const initialState = {
    primaryAdmin: null,
    primaryAdminDetail: null,
    companyAdminList: null,
    companyAdminDetail: null,
    archieveAdminList : null
};
const companyAdminReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    switch (type) {

        case actionTypes.GetPrimaryAdminSucess:
            object = { ...state, primaryAdmin: payload }
            break

        case actionTypes.GetPrimaryAdminRequested:
            object = { ...state, primaryAdmin: null }
            break

        case actionTypes.GetPrimaryAdminDetail:
            object = { ...state, primaryAdminDetail: payload }
            break

        case actionTypes.GetCompanyAdminRequested:
            object = { ...state, 
               // companyAdminList: null 
            }
            break

        case actionTypes.GetCompanyAdminSuccess:
            object = { ...state, companyAdminList: payload }
            break

        case actionTypes.GetCompanyAdminDetailRequested:
            object = { ...state, 
                companyAdminDetail: null 
            }
            break

        case actionTypes.GetCompanyAdminDetailSuccess:
            object = { ...state, companyAdminDetail: payload }
            break

        case actionTypes.GetCompanyAdminArchiveRequested:
            object = { ...state,
                // archieveAdminList : null
            }
            break   
            
        case actionTypes.GetCompanyAdminArchiveSuccess:
            object = { ...state, archieveAdminList : payload }
            break
            
        case actionTypes.LogOut: 
          object = { state : undefined };
            break
    
        default:
            object = state;
    }
    return object
};
export default companyAdminReducer;
import * as actionTypes from '../actionTypes';


export const getLevelPostion = (data) => {
    return {
        type: actionTypes.GetLevelPostion,
        payload: data
    }
}

export const getLevelPostionSuccess = (data) => {
    return {
        type: actionTypes.GetLevelPostionSuccess,
        payload: data
    }
}

export const addLevel = (data) => {
    return {
        type: actionTypes.AddLevel,
        payload: data
    }
}


export const getDivisionLevel = (data) => {
    return {
        type: actionTypes.GetDivisionLevelPostion,
        payload: data
    }
}

export const getDivisionLevelSuccess = (data) => {
    return {
        type: actionTypes.GetDivisionLevelPostionSuccess,
        payload: data
    }
}

export const getDivisionReportRequested = (data) => {
    return {
        type: actionTypes.GetDivisionReportRequested,
        payload: data
    }
}


export const getDivisionReport = (data) => {
    return {
        type: actionTypes.GetDivisionReport,
        payload: data
    }
}

export const getDivisionReportSuccess = (data) => {
    return {
        type: actionTypes.GetDivisionReportSuccess,
        payload: data
    }
}

export const addDivision = (data) => {
    return {
        type: actionTypes.AddDivision,
        payload: data
    }
}


export const getAllLevel = (data) => {
    return {
        type: actionTypes.GetAllLevel,
        payload: data
    }
}


export const getAllLevelSuccess = (data) => {
    return {
        type: actionTypes.GetAllLevelSuccess,
        payload: data
    }
}


export const getAllDivision = (data) => {
    return {
        type: actionTypes.GetAllDivision,
        payload: data
    }
}


export const getAllDivisionRequested = (data) => {
    return {
        type: actionTypes.GetAllDivisionRequested,
        payload: data
    }
}


export const getAllDivisionSuccess = (data) => {
    return {
        type: actionTypes.GetAllDivisionSuccess,
        payload: data
    }
}


export const getDivisionSearch = (data) => {
    return {
        type: actionTypes.GetDivisionSearch,
        payload: data
    }
}


export const getDivisionSearchRequested = (data) => {
    return {
        type: actionTypes.GetDivisionSearchRequested,
        payload: data
    }
}


export const getDivisionSearchSuccess = (data) => {
    return {
        type: actionTypes.GetDivisionSearchSuccess,
        payload: data
    }
}

export const getLevelDetailRequested = (data) => {
    return {
        type: actionTypes.GetLevelDetailRequested,
        payload: data
    }
}


export const getLevelDetail = (data) => {
    return {
        type: actionTypes.GetLevelDetail,
        payload: data
    }
}


export const getLevelDetailSuccess = (data) => {
    return {
        type: actionTypes.GetLevelDetailSuccess,
        payload: data
    }
}


export const editLevel = (data) => {
    return {
        type: actionTypes.EditLevel,
        payload: data
    }
}

export const deleteLevelRequested = (data) => {
    return {
        type: actionTypes.DeleteLevelRequested,
        payload: data
    }
}


export const deleteLevelSuccess = (data) => {
    return {
        type: actionTypes.DeleteLevelSuccess,
        payload: data
    }
}


export const deleteLevel = (data) => {
    return {
        type: actionTypes.DeleteLevel,
        payload: data
    }
}


export const deleteLevelError = (data) => {
    return {
        type: actionTypes.DeleteLevelError,
        payload: data
    }
}

export const deleteDivision = (data) => {
    return {
        type: actionTypes.DeleteDivision,
        payload: data
    }
}


export const getLevel = (data) => {
    return {
        type: actionTypes.GetLevel,
        payload: data
    }
}


export const getLevelRequested = (data) => {
    return {
        type: actionTypes.GetLevelRequested,
        payload: data
    }
}

export const getLevelSuccess = (data) => {
    return {
        type: actionTypes.GetLevelSuccess,
        payload: data
    }
}

export const getDivison = (data) => {
    return {
        type: actionTypes.GetDivison,
        payload: data
    }
}


export const getDivisonRequested = (data) => {
    return {
        type: actionTypes.GetDivisonRequested,
        payload: data
    }
}


export const getDivisonSuccess = (data) => {
    return {
        type: actionTypes.GetDivisonSuccess,
        payload: data
    }
}

export const updateDivisionSingle = (data) => {
    return {
        type: actionTypes.UpdateDivisionSingle,
        payload: data
    }
}

export const updateDivision = (data) => {
    return {
        type: actionTypes.UpdateDivision,
        payload: data
    }
}


export const getClientLevel = (data) => {
    return {
        type: actionTypes.GetClientLevel,
        payload: data
    }
}

export const getClientLevelSuccess = (data) => {
    return {
        type: actionTypes.GetClientLevelSuccess,
        payload: data
    }
}

export const getClientDivisionRequested = (data) => {
    return {
        type: actionTypes.GetClientDivisionRequested,
        payload: data
    }
}


export const getClientDivision = (data) => {
    return {
        type: actionTypes.GetClientDivision,
        payload: data
    }
}

export const getClientDivisionSuccess = (data) => {
    return {
        type: actionTypes.GetClientDivisionSuccess,
        payload: data
    }
}
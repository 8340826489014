// export const WebConfig = {
//     serviceUrls: {
//         WebApi: `http://192.168.0.241:8000`,
//     // RestApi: `http://192.168.0.241:8000/api/v1/`, 
//        RestApi :`https://soaq-gdi-qc.agilecollab.com/api/v1/`,
//      // RestApi : `https://soaqgdi-api-stg.agilecollab.com/api/v1/`,
//         SocketApi : "ws://192.168.0.241:8051/",
//         ContainerName : "gdiinspect",
//         StorageName : "gdistorage001"
//     },
//     ApiUrls : {
//         configurations :  {
//             position : `configurations/positions/`,
//             services : `configurations/services/`,
//             summary :  `configurations/summary/`,
//             areas : `configurations/areas/`,
//             areaTemplate : `configurations/area-templates/`,
//             ratingTemplates : `configurations/rating-templates/`,
//             groups : `configurations/groups/`,
//             forms : `configurations/forms/`
//         },
//         notifcations : `notifications/`,
//         events : `events/inspections/`,
//         ReportALLInspectionPoint : `events/all-inspections/`,
//         ReportALLServicePoint : `companies/all-service-locations/`,
//         eventsWorkOrder : `events/work-orders/`,
//         eventsPeriodic : `events/periodic/`,
//         eventsQuestionnaires :'events/questionnaires/',
//         superadmin : `superadmin/`,
//         companyOrgChart : {
//             levels : `companies/levels/`,
//             divisions : `companies/divisions/`
//         },
//         companyUsers : `companies/users/`,
//         accounts : `accounts/`,
//         search : `search/recent/`,
//         company : {
//             companies :`companies/`,
//             employees : `companies/employees/`,
//             employeesPostion : `companies/employee-positions/`,
//             companiesAdmin : `companies/admins/`,
//             clients  : `companies/clients/`,
//             clientsServiceLocation : `companies/clients/service-locations/`,
//             subLocations : `companies/sub-locations/`,
//             clientsContacts : `companies/clients/contacts/`
//         },
//         report : {
//             reports : 'reports/'
//         },
//         dashboard : 'dashboard/',
//         supderDashboard : 'dashboard/superadmin/',
//         permissions : `permissions/`
//     }
// }

export const WebConfig = {
    serviceUrls: {
        WebApi: process.env.REACT_APP_REST_API_URL,
        RestApi: process.env.REACT_APP_WEB_API_URL,
        SocketApi: process.env.REACT_APP_WEB_SOCKET_URL,
        AzureUrl : process.env.REACT_APP_WEB_AZURE_URL,
        ClientIdUrl : process.env.REACT_APP_AZURE_CLIENT_ID,
        AuthorityUrl : process.env.REACT_APP_AZURE_AUTHORITY_URL,
        ContainerName : process.env.REACT_APP_CONTAINER_NAME,
        StorageName :  process.env.REACT_APP_STORAGE_NAME
    },
    ApiUrls : {
        configurations :  {
            position : `configurations/positions/`,
            services : `configurations/services/`,
            summary :  `configurations/summary/`,
            areas : `configurations/areas/`,
            areaTemplate : `configurations/area-templates/`,
            ratingTemplates : `configurations/rating-templates/`,
            groups : `configurations/groups/`,
            forms : `configurations/forms/`
        },
        notifcations : `notifications/`,
        events : `events/inspections/`,
        ReportALLInspectionPoint : `events/all-inspections/`,
        ReportALLServicePoint : `companies/all-service-locations/`,
        eventsWorkOrder : `events/work-orders/`,
        eventsPeriodic : `events/periodic/`,
        eventsQuestionnaires :'events/questionnaires/',
        superadmin : `superadmin/`,
        companyOrgChart : {
            levels : `companies/levels/`,
            divisions : `companies/divisions/`
        },
        companyUsers : `companies/users/`,
        accounts : `accounts/`,
        search : `search/recent/`,
        company : {
            companies :`companies/`,
            employees : `companies/employees/`,
            employeesPostion : `companies/employee-positions/`,
            companiesAdmin : `companies/admins/`,
            clients  : `companies/clients/`,
            clientsServiceLocation : `companies/clients/service-locations/`,
            subLocations : `companies/sub-locations/`,
            clientsContacts : `companies/clients/contacts/`
        },
        report : {
            reports : 'reports/'
        },
        dashboard : 'dashboard/',
        supderDashboard : 'dashboard/superadmin/',
        permissions : `permissions/`
    }
}

export const UserRole = {
    SuperAdmin: 2,
    CompanyAdmin: 3,
    ContactAdmin : 4
}


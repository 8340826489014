import * as actionTypes from '../actionTypes';

export const getPermissionList = (data) => {
    return {
        type: actionTypes.GetPermissionList,
        payload: data
    }
}

export const getPermissionListSuccess = (data) => {
    return {
        type: actionTypes.GetPermissionListSuccess,
        payload: data
    }
}

export const getPermissionListRequested = (data) => {
    return {
        type: actionTypes.GetPermissionListRequested,
        payload: data
    }
}

export const deleteGetPermission = (data) => {
    return {
        type: actionTypes.DeleteGetPermission,
        payload: data
    }
}

export const getPermissionModuleList = (data) => {
    return {
        type: actionTypes.GetPermissionModuleList,
        payload: data
    }
}

export const getPermissionModuleListSuccess = (data) => {
    return {
        type: actionTypes.GetPermissionModuleListSuccess,
        payload: data
    }
}

export const getPermissionModuleListRequested = (data) => {
    return {
        type: actionTypes.GetPermissionModuleListRequested,
        payload: data
    }
}

export const addPermissionTemplate = (data) => {
    return {
        type: actionTypes.AddPermissionTemplate,
        payload: data
    }
}

export const upDatePermissionTemplate = (data) => {
    return {
        type: actionTypes.UpDatePermissionTemplate,
        payload: data
    }
}

export const getPermissionDetail = (data) => {
    return {
        type: actionTypes.GetPermissionDetail,
        payload: data
    }
}

export const getPermissionDetailRequested = (data) => {
    return {
        type: actionTypes.GetPermissionDetailRequested,
        payload: data
    }
}

export const getPermissionDetailSuccess = (data) => {
    return {
        type: actionTypes.GetPermissionDetailSuccess,
        payload: data
    }
}


export const getPermissionDropdownList = (data) => {
    return {
        type: actionTypes.GetPermissionDropdownList,
        payload: data
    }
}

export const getPermissionDropdownListSuccess = (data) => {
    return {
        type: actionTypes.GetPermissionDropdownListSuccess,
        payload: data
    }
}

export const getPermissionDropdownListRequested = (data) => {
    return {
        type: actionTypes.GetPermissionDropdownListRequested,
        payload: data
    }
}






export const getPermissionPrimaryDetail = (data) => {
    return {
        type: actionTypes.GetPermissionPrimaryDetail,
        payload: data
    }
}

export const getPermissionPrimaryDetailRequested = (data) => {
    return {
        type: actionTypes.GetPermissionPrimaryDetailRequested,
        payload: data
    }
}

export const getPermissionPrimaryDetailSuccess = (data) => {
    return {
        type: actionTypes.GetPermissionPrimaryDetailSuccess,
        payload: data
    }
}





export const getPermissionSecondaryDetail = (data) => {
    return {
        type: actionTypes.GetPermissionSecondaryDetail,
        payload: data
    }
}

export const getPermissionSecondaryDetailRequested = (data) => {
    return {
        type: actionTypes.GetPermissionSecondaryDetailRequested,
        payload: data
    }
}

export const getPermissionSecondaryDetailSuccess = (data) => {
    return {
        type: actionTypes.GetPermissionSecondaryDetailSuccess,
        payload: data
    }
}

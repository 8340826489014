import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig, } from 'config';
import * as actionTypes from '../actionTypes';
import {
	loaderShow,
	loaderhide,
	loadTodoSuccess,
	loadTodoRequest,
	loadTodoError,
	getSpLevelPostionSuccess,
	getSpDivisionLevelSuccess,
	getSpDivisionReportRequested,
	getSpDivisionReportSuccess,
	getAllSpLevelSuccess,
	getAllSpDivisionSuccess,
	getSpDivisionSearchRequested,
	getSpDivisionSearchSuccess,
	getSpLevelDetailRequested,
	getSpLevelDetailSuccess,
	deleteSpLevelRequested,
	deleteSpLevelSuccess,
	deleteSpLevelError,
	getSpDivisonRequested,
	getSpDivisonSuccess,
	getSPLevellistSuccess,
	getSPDivsionRequested,
	getSPDivsionSuccess,
	getAllSpAdminDivisionSuccess

} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const SUPERADMIN_POINT_URL = WebConfig.ApiUrls.superadmin;
const ORG_LEVEL_POINT_URL = WebConfig.ApiUrls.companyOrgChart.levels;
const ORG_DIVISION_POINT_URL = WebConfig.ApiUrls.companyOrgChart.divisions;
const COMPANIES_POINT_URL = WebConfig.ApiUrls.company.companies;

export function* getSpLevelPostion(payload) {
	const data = payload;

	//yield put(loadTodoRequest());

	const response = yield call(() => {
		return ClientInstance
			.get(`${API_POINT_URL}${ORG_LEVEL_POINT_URL}positions/`)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {

		yield put(getSpLevelPostionSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}



}

export function* addSpLevel(payload) {
	const data = payload;

	//yield put(loadTodoRequest());

	const response = yield call(() => {
		return ClientInstance
			.post(`${API_POINT_URL}${ORG_LEVEL_POINT_URL}`, data.payload)
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));

		yield delay(1000);
		yield getAllSpLevel({ type: actionTypes.GetAllSpLevel });
		yield getAllSpDivision({ type: actionTypes.GetAllSpDivision });

	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}





}


export function* getSpDivisionLevel(payload) {
	const data = payload;

	//yield put(loadTodoRequest());

	const response = yield call(() => {
		return ClientInstance
			.get(`${API_POINT_URL}${ORG_LEVEL_POINT_URL}flat_list/?fields=name,id,client}`)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));

		yield put(getSpDivisionLevelSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}



}


export function* getSpDivisionReport(payload) {
	const data = payload;

	yield put(getSpDivisionReportRequested());
	//	yield put(loadTodoRequest());

	const response = yield call(() => {
		return ClientInstance
			.get(`${API_POINT_URL}${ORG_LEVEL_POINT_URL}${data.payload.levelId}/flat_divisions_list/`)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));

		yield put(getSpDivisionReportSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}



}


export function* addSpDivision(payload) {
	const data = payload;

	//	yield put(loadTodoRequest());

	const response = yield call(() => {
		return ClientInstance
			.post(`${API_POINT_URL}${ORG_DIVISION_POINT_URL}`, data.payload)
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));

		yield delay(1000);
		yield getAllSpDivision({ type: actionTypes.GetAllSpDivision });

	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}





}


export function* getAllSpLevel(payload) {
	const data = payload;

	//	yield put(loadTodoRequest());

	const response = yield call(() => {

		if (localStorage.getItem('inperosnate')) {
			return ClientInstance
				.get(`${API_POINT_URL}${ORG_LEVEL_POINT_URL}?fields=name,id,order`)
				.then(response => {
					return response;
				})
		}
		else {
			return axios
				.get(`${API_POINT_URL}${ORG_LEVEL_POINT_URL}?fields=name,id,order`)
				.then(response => {
					return response;
				})
		}
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));

		yield put(getAllSpLevelSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}



}


export function* getAllSpDivision(payload) {
	const data = payload;

	//	yield put(loadTodoRequest());
	// yield put(getAllDivisionRequested());

	const response = yield call(() => {
		return ClientInstance
			.get(`${API_POINT_URL}${ORG_DIVISION_POINT_URL}dump/`)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));

		yield put(getAllSpDivisionSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}



}

export function* getAllSpAdminDivision(payload) {
	const data = payload;

	//yield put(loadTodoRequest());
	// yield put(getAllDivisionRequested());

	const response = yield call(() => {
		return axios
			.get(`${API_POINT_URL}${COMPANIES_POINT_URL}${data.payload.id}/service_provider_organization_tree/`)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));

		yield put(getAllSpAdminDivisionSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}



}





export function* getSpDivisionSearch(payload) {
	const data = payload;

	//	yield put(loadTodoRequest());
	yield put(getSpDivisionSearchRequested());

	yield delay(500);
	let instance = localStorage.getItem('inperosnate') ? ClientInstance : axios

	const response = yield call(() => {
		return instance.get(`${API_POINT_URL}${ORG_DIVISION_POINT_URL}search/?search=${data.payload.name}`)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(getSpDivisionSearchSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}


}

export function* getSpLevelDetail(payload) {
	const data = payload;
	const { payload: { currentPage = null, search = null } } = data;


	//yield put(loadTodoRequest());

	yield put(getSpLevelDetailRequested());
	let instance = localStorage.getItem('inperosnate') ? ClientInstance : axios
	const response = yield call(() => {
		return instance
			.get(search ?
				`${API_POINT_URL}${ORG_DIVISION_POINT_URL}?level_id=${data.payload.levelId}&page=${currentPage}&page_size=5&search=${search}` :
				`${API_POINT_URL}${ORG_DIVISION_POINT_URL}?level_id=${data.payload.levelId}&page=${currentPage}&page_size=5`)
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {

		yield put(getSpLevelDetailSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}



}


export function* editSpLevel(payload) {
	const data = payload;

	//yield put(loadTodoRequest());

	const response = yield call(() => {
		return ClientInstance
			.patch(`${API_POINT_URL}${ORG_LEVEL_POINT_URL}${data.payload.levelId}/`, { name: data.payload.name })
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));

		yield delay(1000);
		yield getAllSpLevel({ type: actionTypes.GetAllSpLevel });
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}





}

export function* deleteSpLevel(payload) {
	const data = payload;

	yield put(deleteSpLevelRequested());
	//	yield put(loadTodoRequest());

	const response = yield call(() => {
		return ClientInstance
			.delete(`${API_POINT_URL}${ORG_LEVEL_POINT_URL}${data.payload.levelId}/`)
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));
		yield put(deleteSpLevelSuccess());

		yield delay(2000)
		yield getAllSpLevel({ type: actionTypes.GetAllSpLevel });
		yield getAllSpDivision({ type: actionTypes.GetAllSpDivision });
	}

	if (response && response.data.status === 111) {
		yield put(deleteSpLevelError(response.data.message.detail));
	}

	if (response && response.data && response.data.status !== 200 && response.data.status !== 111) {
		yield put(loadTodoError(response.data.message.detail));
	}



}


export function* deleteSpDivision(payload) {
	const data = payload;

	//	yield put(loadTodoRequest());

	const response = yield call(() => {
		return ClientInstance
			.delete(`${API_POINT_URL}${ORG_DIVISION_POINT_URL}${data.payload.divisionId}/`)
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));

		yield delay(1000);
		yield getAllSpDivision({ type: actionTypes.GetAllSpDivision });
	}

	if (response && response.data && response.data.status !== 200 && response.data.status !== 111) {
		yield put(loadTodoError(response.data.message.detail));
	}



}


export function* getSpDivison(payload) {
	const data = payload;


	//yield put(loadTodoRequest());

	yield put(getSpDivisonRequested());
	let instance = localStorage.getItem('inperosnate') ? ClientInstance : axios
	const response = yield call(() => {
		return instance
			.get(`${API_POINT_URL}${ORG_DIVISION_POINT_URL}${data.payload.divisionId}/`)
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(getSpDivisonSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}




}


export function* updateSpDivisionSingle(payload) {
	const data = payload;

	//yield put(loadTodoRequest());


	const response = yield call(() => {
		return ClientInstance
			.patch(`${API_POINT_URL}${ORG_DIVISION_POINT_URL}${data.payload.divisionId}/`, { name: data.payload.name })
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));

		yield delay(2000);
		yield getAllSpDivision({ type: actionTypes.GetAllSpDivision });
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}





}

export function* updateSpDivision(payload) {
	const data = payload;

	//yield put(loadTodoRequest());

	const response = yield call(() => {
		return ClientInstance
			.put(`${API_POINT_URL}${ORG_DIVISION_POINT_URL}${data.payload.divisionId}/`, data.payload)
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));

		yield delay(2000);
		yield getAllSpDivision({ type: actionTypes.GetAllSpDivision });
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}





}


export function* getSPLevellist(payload) {
	const data = payload;

	//yield put(loadTodoRequest());
	let response;
	if (!localStorage.getItem('inperosnate')) {
		response = yield call(() => {
			return axios
				.get(`${API_POINT_URL}${SUPERADMIN_POINT_URL}levels/`)
				.then(response => {
					return response;
				})
		});
	}
	else {
		response = yield call(() => {
			return ClientInstance
				.get(`${API_POINT_URL}${ORG_LEVEL_POINT_URL}items/?fields=name,id,divisions_count`)
				.then(response => {
					return response;
				})
		});
	}

	if (response && response.data.status === 200) {
		yield put(getSPLevellistSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}

}

export function* getSPDivsion(payload) {
	const data = payload;
	const { payload: { levelId = null } } = data;



	yield put(getSPDivsionRequested());
	//	yield put(loadTodoRequest());


	let response;

	if (!localStorage.getItem('inperosnate')) {
		response = yield call(() => {
			return axios
				.get(`${API_POINT_URL}${SUPERADMIN_POINT_URL}divisions/`
				)
				.then(response => {
					return response;
				})
		});
	}
	else {
		response = yield call(() => {
			return ClientInstance
				.get(levelId ? `${API_POINT_URL}${ORG_DIVISION_POINT_URL}items/?level_id=${data.payload.levelId}&fields=name,id` :
					`${API_POINT_URL}${ORG_DIVISION_POINT_URL}items/`
				)
				.then(response => {
					return response;
				})
		});
	}


	if (response && response.data.status === 200) {
		yield put(getSPDivsionSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}



}




function* watchgetSpLevelPostion() {
	yield takeLatest(actionTypes.GetSpLevelPostion, getSpLevelPostion)
}

function* watchaddSpLevel() {
	yield takeLatest(actionTypes.AddSpLevel, addSpLevel)
}

function* watchgetSpDivisionLevel() {
	yield takeLatest(actionTypes.GetSpDivisionLevelPostion, getSpDivisionLevel)
}

function* watchgetSpDivisionReport() {
	yield takeLatest(actionTypes.GetSpDivisionReport, getSpDivisionReport)
}

function* watchaddSpDivision() {
	yield takeLatest(actionTypes.AddSpDivision, addSpDivision)
}

function* watchgetAllSpLevel() {
	yield takeLatest(actionTypes.GetAllSpLevel, getAllSpLevel)
}

function* watchgetAllSpDivision() {
	yield takeLatest(actionTypes.GetAllSpDivision, getAllSpDivision)
}

function* watchgetSpDivisionSearch() {
	yield takeLatest(actionTypes.GetSpDivisionSearch, getSpDivisionSearch)
}

function* watchgetSpLevelDetail() {
	yield takeLatest(actionTypes.GetSpLevelDetail, getSpLevelDetail)
}

function* watcheditSpLevel() {
	yield takeLatest(actionTypes.EditSpLevel, editSpLevel)
}

function* watchdeleteSpLevel() {
	yield takeLatest(actionTypes.DeleteSpLevel, deleteSpLevel)
}

function* watchdeleteSpDivision() {
	yield takeLatest(actionTypes.DeleteSpDivision, deleteSpDivision)
}

function* watchgetSpDivison() {
	yield takeLatest(actionTypes.GetSpDivison, getSpDivison)
}

function* watchupdateSpDivisionSingle() {
	yield takeLatest(actionTypes.UpdateSpDivisionSingle, updateSpDivisionSingle)
}

function* watchupdateSpDivision() {
	yield takeLatest(actionTypes.UpdateSpDivision, updateSpDivision)
}


function* watchgetSPLevellist() {
	yield takeLatest(actionTypes.GetSPLevellist, getSPLevellist)
}

function* watchgetSPDivsion() {
	yield takeLatest(actionTypes.GetSPDivsion, getSPDivsion)
}

function* watchgetAllSpAdminDivision() {
	yield takeLatest(actionTypes.GetAllSpAdminDivision, getAllSpAdminDivision)
}





export const SpOrgSaga = [
	fork(watchgetSpLevelPostion),
	fork(watchaddSpLevel),
	fork(watchgetSpDivisionLevel),
	fork(watchgetSpDivisionReport),
	fork(watchaddSpDivision),
	fork(watchgetAllSpLevel),
	fork(watchgetAllSpDivision),
	fork(watchgetSpDivisionSearch),
	fork(watchgetSpLevelDetail),
	fork(watcheditSpLevel),
	fork(watchdeleteSpLevel),
	fork(watchgetSpDivison),
	fork(watchupdateSpDivisionSingle),
	fork(watchupdateSpDivision),
	fork(watchdeleteSpDivision),
	fork(watchgetSPLevellist),
	fork(watchgetSPDivsion),
	fork(watchgetAllSpAdminDivision)
]
import React from 'react';

const AuthBanner = () => 
{
    return (
        <div className="auth-banner-block">
            <div className="logo">
            <img src={require('assets/images/gdi-logo.svg').default} className="img-fluid" />
            </div>
        </div>
    )
}

export default AuthBanner;

import * as actionTypes from '../actionTypes';


export const addAreaService = (data) => {
    return {
        type: actionTypes.AddService,
        payload: data
    }
}


export const getServiceList = (data) => {
    return {
        type: actionTypes.GetServiceList,
        payload: data
    }
}

export const importServiceList = (data) => {
    return {
        type: actionTypes.ImportServiceList,
        payload: data
    }
}

export const getServiceListSuccess = (data) => {
    return {
        type: actionTypes.GetServiceListSuccess,
        payload: data
    }
}

export const getAreaServiceDetail = (data) => {
    return {
        type: actionTypes.GetAreaServiceDetail,
        payload: data
    }
}


export const getAreaServiceDetailRequested = () => {
    return {
        type: actionTypes.GetAreaServiceDetailRequested,
    }
}

export const getAreaServiceDetailSuccess = (data) => {
    return {
        type: actionTypes.GetAreaServiceDetailSuccess,
        payload: data
    }
}

export const editAreaService = (data) => {
    return {
        type: actionTypes.EditAreaService,
        payload: data
    }
}

export const deleteAreaService = (data) => {
    return {
        type: actionTypes.DeleteAreaService,
        payload: data
    }
}

export const getAreaTemplateItems = (data) => {
    return {
        type: actionTypes.GetAreaTemplateItems,
        payload: data
    }
}

export const getAreaTemplateItemsRequested = (data) => {
    return {
        type: actionTypes.GetAreaTemplateItemsRequested,
        payload: data
    }
}

export const getAreaTemplateItemsSuccess = (data) => {
    return {
        type: actionTypes.GetAreaTemplateItemsSuccess,
        payload: data
    }
}

export const getAreaTemplateServices = (data) => {
    return {
        type: actionTypes.GetAreaTemplateServices,
        payload: data
    }
}

export const getAreaTemplateServicesRequested = (data) => {
    return {
        type: actionTypes.GetAreaTemplateServicesRequested,
        payload: data
    }
}

export const getAreaTemplateServicesSuccess = (data) => {
    return {
        type: actionTypes.GetAreaTemplateServicesSuccess,
        payload: data
    }
}



export const getCloneTemplate = (data) => {
    return {
        type: actionTypes.GetCloneTemplate,
        payload: data
    }
}

export const getCloneTemplateRequested = (data) => {
    return {
        type: actionTypes.GetCloneTemplateRequested,
        payload: data
    }
}

export const getCloneTemplateSuccess = (data) => {
    return {
        type: actionTypes.GetCloneTemplateSuccess,
        payload: data
    }
}

export const addArea = (data) => {
    return {
        type: actionTypes.AddArea,
        payload: data
    }
}


export const getAreaList = (data) => {
    return {
        type: actionTypes.GetAreaList,
        payload: data
    }
}

export const getAreaListSuccess = (data) => {
    return {
        type: actionTypes.GetAreaListSuccess,
        payload: data
    }
}



export const getAreaDetail = (data) => {
    return {
        type: actionTypes.GetAreaDetail,
        payload: data
    }
}


export const getAreaDetailRequested = () => {
    return {
        type: actionTypes.GetAreaDetailRequested,
    }
}

export const getAreaDetailSuccess = (data) => {
    return {
        type: actionTypes.GetAreaDetailSuccess,
        payload: data
    }
}


export const editArea = (data) => {
    return {
        type: actionTypes.EditArea,
        payload: data
    }
}

export const deleteAreaTemplate = (data) => {
    return {
        type: actionTypes.DeleteAreaTemplate,
        payload: data
    }
}

export const deleteArea = (data) => {
    return {
        type: actionTypes.DeleteArea,
        payload: data
    }
}

export const removeAreaService = (data) => {
    return {
        type: actionTypes.RemoveAreaService,
        payload: data
    }
}


export const editAreaDetail = (data) => {
    return {
        type: actionTypes.EditAreaDetail,
        payload: data
    }
}

export const addAreaDetail = (data) => {
    return {
        type: actionTypes.AddAreaDetail,
        payload: data
    }
}



export const getServicesAreaAssociated = (data) => {
    return {
        type: actionTypes.GetServicesAreaAssociated,
        payload: data
    }
}


export const getServicesAreaAssociatedRequested = () => {
    return {
        type: actionTypes.GetServicesAreaAssociatedRequested,
    }
}

export const getServicesAreaAssociatedSuccess = (data) => {
    return {
        type: actionTypes.GetServicesAreaAssociatedSuccess,
        payload: data
    }
}

export const removeAreaAssocaitedService = (data) => {
    return {
        type: actionTypes.RemoveAreaAssocaitedService,
        payload: data
    }
}


export const getLocationAreaAssociated = (data) => {
    return {
        type: actionTypes.GetLocationAreaAssociated,
        payload: data
    }
}


export const getLocationAreaAssociatedRequested = () => {
    return {
        type: actionTypes.GetLocationAreaAssociatedRequested,
    }
}

export const getLocationAreaAssociatedSuccess = (data) => {
    return {
        type: actionTypes.GetLocationAreaAssociatedSuccess,
        payload: data
    }
}

export const removeLocationAssocaited = (data) => {
    return {
        type: actionTypes.RemoveLocationAssocaited,
        payload: data
    }
}


export const addAreaCheck = (data) => {
    return {
        type: actionTypes.AddAreaCheck,
        payload: data
    }
}

export const AddAreaCheckLoad = (data) => {
    return {
        type: actionTypes.AddAreaCheckLoad,
        payload: data
    }
}


export const addAreaCheckRequested = (data) => {
    return {
        type: actionTypes.AddAreaCheckRequested,
        payload: data
    }
}

export const addAreaCheckSuccess = (data) => {
    return {
        type: actionTypes.AddAreaCheckSuccess,
        payload: data
    }
}

export const getAreaAllList = (data) => {
    return {
        type: actionTypes.GetAreaAllList,
        payload: data
    }
}

export const getAreaAllListRequested = (data) => {
    return {
        type: actionTypes.GetAreaAllListRequested,
        payload: data
    }
}

export const getAreaAllListSuccess = (data) => {
    return {
        type: actionTypes.GetAreaAllListSuccess,
        payload: data
    }
}

export const importAreaList = (data) => {
    return {
        type: actionTypes.ImportAreaList,
        payload: data
    }
}
import React from 'react';

const Toast = (props) => {
    const { Success, Error, Warn } = props;
    return (
        <div className={`toast-container ${Success ? 'toast-success' : ''} ${Warn ? 'toast-warn' : ''}`}>
            <span className="ico" onClick={props.Close}>
                {Error && <img
                    src={require("assets/images/error-close.svg").default}
                    className="img-fluid"
                />}

                {Success && <img
                    src={require("assets/images/toast-success.svg").default}
                    className="img-fluid"
                />}

                {Warn && <img
                    src={require("assets/images/toast-warn.svg").default}
                    className="img-fluid"
                />}

            </span>
            <p>{props.message}</p>
        </div>
    )
}

export default Toast;
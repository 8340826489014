import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
/*components*/
import Login from 'pages/Auth/Login';
import ResetPassword from 'pages/Auth/ResetPassword';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import DashboardLayout from 'pages/Dashboard/containers';
import Notfound from 'shared/components/NotFound';
import TwoFactorAuth from 'pages/Auth/TwoFactor';
import ServerError from 'shared/components/ServerError';
import Notfound403 from 'shared/components/NotFound-403';

const Routes = () => (
    
    <BrowserRouter>
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/token" component={TwoFactorAuth} />
            <Route path='/admin' component={DashboardLayout} />
            <Redirect exact from="/" to="login" />
            <Route component={Notfound} path='/404' />
            <Route component={Notfound403} path='/403' />
            <Route component={ServerError} path='/500' />
            <Redirect from='*' to='/404' />
           
        </Switch>
    </BrowserRouter>
)

export default Routes;
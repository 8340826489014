import axios from 'axios'
import { put, takeLatest, fork, call, delay, takeEvery } from 'redux-saga/effects';
import { WebConfig, AppConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoRequest,
    loadTodoWarning,
    loadTodoError,
    loadTodoSuccess,
    getDashboardOrderListRequested,
    getDashboardOrderListSuccess,
    getAverageWidgetListRequested,
    getAverageWidgetListSuccess,
    getTopClientWidgetListRequested,
    getTopClientWidgetListSuccess,
    getBottomClientWidgetListRequested,
    getBottomClientWidgetListSuccess,
    getTopServiceWidgetListRequested,
    getTopServiceWidgetListSuccess,
    getBottomServiceWidgetListRequested,
    getBottomServiceWidgetListSuccess,
    getWorkOrderSummaryRequested,
    getWorkOrderSummarySuccess,
    getOpenWidgetListRequested,
    getOpenWidgetListSuccess,
    getPerMonthWidgetListRequested,
    getPerMonthWidgetListSuccess,
    getByOriginWidgetListRequested,
    getByOriginWidgetListSuccess,
    getAvergaeCompetionWidgetListRequested,
    getAvergaeCompetionWidgetListSuccess,
    getPeriodicSummaryRequested,
    getPeriodicSummarySuccess,
    getTopClientPeriodicWidgetListRequested,
    getTopClientPeriodicWidgetListSuccess,
    getPerMonthPeriodicWidgetListRequested,
    getPerMonthPeriodicWidgetListSuccess,
    getOpenPeriodicWidgetListRequested,
    getOpenPeriodicWidgetListSuccess,
    superDashboardSummaryRequested,
    superDashboardSummarySuccess,
    getSuperDashboardOrderListRequested,
    getSuperDashboardOrderListSuccess,
    topClientsInspectionRequested,
    topClientsInspectionSuccess,
    bottomClientsInspectionRequested,
    bottomClientsInspectionSuccess,
    topClientsWorkorderRequested,
    topClientsWorkorderSuccess,
    bottomClientsWorkorderRequested,
    bottomClientsWorkorderSuccess,
    topClientsPeriodicRequested,
    topClientsPeriodicSuccess,
    bottomClientsPeriodicRequested,
    bottomClientsPeriodicSuccess,
    topServiceLocationRequested,
    topServiceLocationSuccess,
    bottomServiceLocationRequested,
    bottomServiceLocationSuccess,

    inspectionPerMonthRequested,
    inspectionPerMonthSuccess,
    workOrderPerMonthRequested,
    workOrderPerMonthSuccess
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const BASE_POINT_URL = WebConfig.serviceUrls.WebApi;
const DASHBOARD_POINT_URL = WebConfig.ApiUrls.dashboard;
const EVENTS_POINT_URL = WebConfig.ApiUrls.events;
const WORK_ORDER_POINT_URL = WebConfig.ApiUrls.eventsWorkOrder;
const PERODIC_POINT_URL = WebConfig.ApiUrls.eventsPeriodic;
const SUPER_DASHBOARD_URL = WebConfig.ApiUrls.supderDashboard;

export function* getDashboardOrderList(payload) {
    const data = payload;
    let response;
    yield put(getDashboardOrderListRequested());
    if (data.payload.view) {
        response = yield call(() => {
            return ClientInstance
                .get(`${API_POINT_URL}${DASHBOARD_POINT_URL}?view=${data.payload.view}`)
                .then(response => {
                    return response;
                })
        });
    }
    if (!data.payload.view) {
        response = yield call(() => {
            return ClientInstance
                .get(`${API_POINT_URL}${DASHBOARD_POINT_URL}`)
                .then(response => {
                    return response;
                })
        });
    }
    if (response && response.data.status === 200) {
        yield put(getDashboardOrderListSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
}

export function* addDashboardOrderList(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${DASHBOARD_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        yield delay(1000);
        yield getDashboardOrderList({
            type: actionTypes.GetDashboardOrderList,
            payload: { view: data.payload.dashboardType === 'all' ? null : data.payload.view }
        });
    }

    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }
}

export function* getWidgetList(payload) {
    const data = payload;
    let response = null;
    let client_Names = null;
    let Template_Names = null;
    let serviceLocations_Names = null;
    let inspectors_Names = null;
    let completed_on_after = null;
    let completed_on_before = null;
    let completed_dates = null;


    if (data.payload && data.payload.clients) {
        client_Names = data.payload.clients.map((item) => `&client=${[item]}`);
    }

    if (data.payload && data.payload.areaTemplates) {
        Template_Names = data.payload.areaTemplates.map((item) => `&area_template=${[item]}`);
    }

    if (data.payload && data.payload.serviceLocations) {
        serviceLocations_Names = data.payload.serviceLocations.map((item) => `&service_location=${[item]}`);
    }

    if (data.payload && data.payload.inspectors) {
        inspectors_Names = data.payload.inspectors.map((item) => `&claimed_by=${[item]}`);
    }

    if (data.payload && data.payload.completed_on_before && data.payload.completed_on_after) {
        completed_on_after = data.payload.completed_on_after.map((item) => `&completed_on_after=${[item]}`);
        completed_on_before = data.payload.completed_on_before.map((item) => `completed_on_before=${[item]}`);
        completed_dates = [...completed_on_after, ...completed_on_before];
        //console.log("completed_dates" , completed_dates);
    }


    if (data.payload && data.payload.widget && data.payload.widget === 'average_score') {
        yield put(getAverageWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || Template_Names || serviceLocations_Names || inspectors_Names ||
                    completed_dates ? `${API_POINT_URL}${EVENTS_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${Template_Names ? Template_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${EVENTS_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getAverageWidgetListSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'top_clients') {
        yield put(getTopClientWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || Template_Names || serviceLocations_Names || inspectors_Names ||
                    completed_dates ? `${API_POINT_URL}${EVENTS_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${Template_Names ? Template_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${EVENTS_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getTopClientWidgetListSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'top_service_locations') {
        yield put(getTopServiceWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || Template_Names || serviceLocations_Names || inspectors_Names ||
                    completed_dates ? `${API_POINT_URL}${EVENTS_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${Template_Names ? Template_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${EVENTS_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getTopServiceWidgetListSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'bottom_service_locations') {
        yield put(getBottomServiceWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || Template_Names || serviceLocations_Names || inspectors_Names ||
                    completed_dates ? `${API_POINT_URL}${EVENTS_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${Template_Names ? Template_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${EVENTS_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getBottomServiceWidgetListSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'bottom_clients') {
        yield put(getBottomClientWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || Template_Names || serviceLocations_Names || inspectors_Names ||
                    completed_dates ? `${API_POINT_URL}${EVENTS_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${Template_Names ? Template_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${EVENTS_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getBottomClientWidgetListSuccess(response.data.response));
        }
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
}

export function* getWorkOrderSummary(payload) {
    const data = payload;
    //console.log("data" , data);
    let client_Names = null;
    let serviceLocations_Names = null;
    let inspectors_Names = null;
    let completed_on_after = null;
    let completed_on_before = null;
    let completed_dates = null;


    if (data.payload && data.payload.clients) {
        client_Names = data.payload.clients.map((item) => `&client=${[item]}`);
    }


    if (data.payload && data.payload.serviceLocations) {
        serviceLocations_Names = data.payload.serviceLocations.map((item) => `&service_location=${[item]}`);
    }

    if (data.payload && data.payload.inspectors) {
        inspectors_Names = data.payload.inspectors.map((item) => `&created_by=${[item]}`);
    }

    if (data.payload && data.payload.completed_on_before && data.payload.completed_on_after) {
        completed_on_after = data.payload.completed_on_after.map((item) => `&created_or_completed_at_after=${[item]}`);
        completed_on_before = data.payload.completed_on_before.map((item) => `created_or_completed_at_before=${[item]}`);
        completed_dates = [...completed_on_after, ...completed_on_before];
        //console.log("completed_dates" , completed_dates);
    }

    yield put(getWorkOrderSummaryRequested());
    const response = yield call(() => {
        return ClientInstance
            .get(client_Names || serviceLocations_Names || inspectors_Names ||
                completed_dates
                ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}summary/?${client_Names ? client_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}` :
                `${API_POINT_URL}${WORK_ORDER_POINT_URL}summary/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getWorkOrderSummarySuccess(response.data.response));

    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }
}


export function* getWorkorderWidgetList(payload) {
    const data = payload;
    let response = null;
    let client_Names = null;
    let serviceLocations_Names = null;
    let inspectors_Names = null;
    let completed_on_after = null;
    let completed_on_before = null;
    let completed_dates = null;


    if (data.payload && data.payload.clients) {
        client_Names = data.payload.clients.map((item) => `&client=${[item]}`);
    }


    if (data.payload && data.payload.serviceLocations) {
        serviceLocations_Names = data.payload.serviceLocations.map((item) => `&service_location=${[item]}`);
    }

    if (data.payload && data.payload.inspectors) {
        inspectors_Names = data.payload.inspectors.map((item) => `&created_by=${[item]}`);
    }

    if (data.payload && data.payload.completed_on_before && data.payload.completed_on_after) {
        completed_on_after = data.payload.completed_on_after.map((item) => `&created_or_completed_at_after=${[item]}`);
        completed_on_before = data.payload.completed_on_before.map((item) => `created_or_completed_at_before=${[item]}`);
        completed_dates = [...completed_on_after, ...completed_on_before];
        //console.log("completed_dates" , completed_dates);
    }


    if (data.payload && data.payload.widget && data.payload.widget === 'open_vs_completed') {
        yield put(getOpenWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || serviceLocations_Names || inspectors_Names ||
                    completed_dates ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${WORK_ORDER_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getOpenWidgetListSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'per_month') {
        yield put(getPerMonthWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || serviceLocations_Names || inspectors_Names ||
                    completed_dates ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${WORK_ORDER_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getPerMonthWidgetListSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'by_origin') {
        yield put(getByOriginWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || serviceLocations_Names || inspectors_Names ||
                    completed_dates ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${WORK_ORDER_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getByOriginWidgetListSuccess(response.data.response));
        }
    }


    if (data.payload && data.payload.widget && data.payload.widget === 'average_completion') {
        yield put(getAvergaeCompetionWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || serviceLocations_Names || inspectors_Names ||
                    completed_dates ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${WORK_ORDER_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getAvergaeCompetionWidgetListSuccess(response.data.response));
        }
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
}


export function* getPeriodicSummary(payload) {
    const data = payload;
    // console.log("data" , data);
    let client_Names = null;
    let serviceLocations_Names = null;
    let inspectors_Names = null;
    let completed_on_after = null;
    let completed_on_before = null;
    let completed_dates = null;
    let completedBy_Names = null;


    if (data.payload && data.payload.clients) {
        client_Names = data.payload.clients.map((item) => `&client=${[item]}`);
    }


    if (data.payload && data.payload.serviceLocations) {
        serviceLocations_Names = data.payload.serviceLocations.map((item) => `&service_location=${[item]}`);
    }

    if (data.payload && data.payload.inspectors) {
        inspectors_Names = data.payload.inspectors.map((item) => `&created_by=${[item]}`);
    }


    if (data.payload && data.payload.completedBy) {
        completedBy_Names = data.payload.completedBy.map((item) => `&completed_by=${[item]}`);
    }

    if (data.payload && data.payload.completed_on_before && data.payload.completed_on_after) {
        completed_on_after = data.payload.completed_on_after.map((item) => `&start_date_or_completed_at_after=${[item]}`);
        completed_on_before = data.payload.completed_on_before.map((item) => `start_date_or_completed_at_before=${[item]}`);
        completed_dates = [...completed_on_after, ...completed_on_before];
        //console.log("completed_dates" , completed_dates);
    }

    yield put(getPeriodicSummaryRequested());
    const response = yield call(() => {
        return ClientInstance
            .get(client_Names || serviceLocations_Names || inspectors_Names ||
                completed_dates || completedBy_Names
                ? `${API_POINT_URL}${PERODIC_POINT_URL}summary/?${client_Names ? client_Names.join("") : ''}${completedBy_Names ? completedBy_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}` :
                `${API_POINT_URL}${PERODIC_POINT_URL}summary/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getPeriodicSummarySuccess(response.data.response));

    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }
}


export function* getPeriodicWidgetList(payload) {
    const data = payload;
    let response = null;
    let client_Names = null;
    let serviceLocations_Names = null;
    let inspectors_Names = null;
    let completed_on_after = null;
    let completed_on_before = null;
    let completed_dates = null;
    let completedBy_Names = null;

    if (data.payload && data.payload.clients) {
        client_Names = data.payload.clients.map((item) => `&client=${[item]}`);
    }


    if (data.payload && data.payload.serviceLocations) {
        serviceLocations_Names = data.payload.serviceLocations.map((item) => `&service_location=${[item]}`);
    }

    if (data.payload && data.payload.inspectors) {
        inspectors_Names = data.payload.inspectors.map((item) => `&created_by=${[item]}`);
    }


    if (data.payload && data.payload.completedBy) {
        completedBy_Names = data.payload.completedBy.map((item) => `&completed_by=${[item]}`);
    }

    if (data.payload && data.payload.completed_on_before && data.payload.completed_on_after) {
        completed_on_after = data.payload.completed_on_after.map((item) => `&start_date_or_completed_at_after=${[item]}`);
        completed_on_before = data.payload.completed_on_before.map((item) => `start_date_or_completed_at_before=${[item]}`);
        completed_dates = [...completed_on_after, ...completed_on_before];
        //console.log("completed_dates" , completed_dates);
    }


    if (data.payload && data.payload.widget && data.payload.widget === 'open_vs_completed') {
        yield put(getOpenPeriodicWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || serviceLocations_Names || inspectors_Names || completedBy_Names ||
                    completed_dates ? `${API_POINT_URL}${PERODIC_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}${completedBy_Names ? completedBy_Names.join("") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${PERODIC_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getOpenPeriodicWidgetListSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'top_clients') {
        yield put(getTopClientPeriodicWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || serviceLocations_Names || inspectors_Names || completedBy_Names ||
                    completed_dates ? `${API_POINT_URL}${PERODIC_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}${completedBy_Names ? completedBy_Names.join("") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${PERODIC_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getTopClientPeriodicWidgetListSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'per_month') {
        yield put(getPerMonthPeriodicWidgetListRequested());

        response = yield call(() => {
            return ClientInstance
                .get(client_Names || serviceLocations_Names || inspectors_Names || completedBy_Names ||
                    completed_dates ? `${API_POINT_URL}${PERODIC_POINT_URL}widgets/?${client_Names ? client_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}${completedBy_Names ? completedBy_Names.join("") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${PERODIC_POINT_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(getPerMonthPeriodicWidgetListSuccess(response.data.response));
        }
    }


    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
}

export function* superDashboardSummary(payload) {
    const data = payload;
    //console.log("data" , data);
    let client_Names = null;
    // let completed_on_after = null;
    // let completed_on_before = null;
    // let completed_dates = null;

    // if (data.payload && data.payload.completed_on_before && data.payload.completed_on_after) {
    //     completed_on_after = data.payload.completed_on_after.map((item) => `&created_or_completed_at_after=${[item]}`);
    //     completed_on_before = data.payload.completed_on_before.map((item) => `created_or_completed_at_before=${[item]}`);
    //     completed_dates = [...completed_on_after, ...completed_on_before];
    // }

    if (data.payload && data.payload.clients) {
        client_Names = data.payload.clients.map((item) => `&client=${[item]}`);
    }

    yield put(superDashboardSummaryRequested());
    const response = yield call(() => {
        return axios
            .get(client_Names
                ? `${API_POINT_URL}${SUPER_DASHBOARD_URL}summary/?${client_Names ? client_Names.join("") : ''}` :
                `${API_POINT_URL}${SUPER_DASHBOARD_URL}summary/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(superDashboardSummarySuccess(response.data.response));

    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }
}


export function* getSuperDashboardOrderList(payload) {
    const data = payload;
    let response;
    yield put(getSuperDashboardOrderListRequested());
    response = yield call(() => {
        return axios
            .get(`${API_POINT_URL}${SUPER_DASHBOARD_URL}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getSuperDashboardOrderListSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
}

export function* addSuperDashboardOrderList(payload) {
    const data = payload;

    const response = yield call(() => {
        return axios
            .post(`${API_POINT_URL}${SUPER_DASHBOARD_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        yield delay(1000);
        yield getSuperDashboardOrderList({
            type: actionTypes.GetSuperDashboardOrderList
        });
    }

    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }
}


export function* getSuperWidgetList(payload) {

    const data = payload;
    let response = null;
    let company_Names = null;
    let completed_on_after = null;
    let completed_on_before = null;
    let completed_after_at = null;
    let completed_before_at = null;
    let completed_dates = null;
    let completed_dates_at = null;
    
    let open_dates_after = null;
    let open_dates_before = null;
    let open_dates = null;

    if (data.payload && data.payload.companies) {
        company_Names = data.payload.companies.map((item) => `&company=${[item]}`);
    }

    if (data.payload && data.payload.completed_on_before && data.payload.completed_on_after) {
        completed_on_after = data.payload.completed_on_after.map((item) => `&completed_on_after=${[item]}`);
        completed_on_before = data.payload.completed_on_before.map((item) => `completed_on_before=${[item]}`);
        completed_dates = [...completed_on_after, ...completed_on_before];

        completed_after_at = data.payload.completed_on_after.map((item) => `&completed_at_after=${[item]}`);
        completed_before_at = data.payload.completed_on_before.map((item) => `completed_at_before=${[item]}`);
        completed_dates_at = [...completed_after_at, ...completed_before_at];

        open_dates_after = data.payload.completed_on_after.map((item) => `&created_or_completed_at_after=${[item]}`);
        open_dates_before = data.payload.completed_on_before.map((item) => `created_or_completed_at_before=${[item]}`);
        open_dates = [...open_dates_after, ...open_dates_before];

        //console.log("completed_dates" , completed_dates);
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'inspection_top_clients') {
        yield put(topClientsInspectionRequested());
        response = yield call(() => {
            return axios
                .get(company_Names ||
                    completed_dates ? `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?${company_Names ? company_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });
        if (response && response.data.status === 200) {
            yield put(topClientsInspectionSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'inspection_bottom_clients') {
        yield put(bottomClientsInspectionRequested());
        response = yield call(() => {
            return axios
                .get(company_Names ||
                    completed_dates ? `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?${company_Names ? company_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(bottomClientsInspectionSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'work_order_top_clients') {
        yield put(topClientsWorkorderRequested());
        response = yield call(() => {
            return axios
                .get(company_Names ||
                    completed_dates_at ? `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?${company_Names ? company_Names.join("") : ''}${completed_dates_at ? completed_dates_at.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(topClientsWorkorderSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'work_order_bottom_clients') {
        yield put(bottomClientsWorkorderRequested());
        response = yield call(() => {
            return axios
                .get(company_Names ||
                    completed_dates_at ? `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?${company_Names ? company_Names.join("") : ''}${completed_dates_at ? completed_dates_at.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(bottomClientsWorkorderSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'periodic_top_clients') {
        yield put(topClientsPeriodicRequested());
        response = yield call(() => {
            return axios
                .get(company_Names ||
                    completed_dates_at ? `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?${company_Names ? company_Names.join("") : ''}${completed_dates_at ? completed_dates_at.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(topClientsPeriodicSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'periodic_bottom_clients') {
        yield put(bottomClientsPeriodicRequested());
        response = yield call(() => {
            return axios
                .get(company_Names ||
                    completed_dates_at ? `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?${company_Names ? company_Names.join("") : ''}${completed_dates_at ? completed_dates_at.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(bottomClientsPeriodicSuccess(response.data.response));
        }
    }


    if (data.payload && data.payload.widget && data.payload.widget === 'inspection_per_month') {
        yield put(inspectionPerMonthRequested());
        response = yield call(() => {
            return axios
                .get(company_Names ||
                    open_dates ? `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?${company_Names ? company_Names.join("") : ''}${open_dates ? open_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(inspectionPerMonthSuccess(response.data.response));
        }
    }


    if (data.payload && data.payload.widget && data.payload.widget === 'work_order_per_month') {
        yield put(workOrderPerMonthRequested());
        response = yield call(() => {
            return axios
                .get(company_Names ||
                    open_dates ? `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?${company_Names ? company_Names.join("") : ''}${open_dates ? open_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(workOrderPerMonthSuccess(response.data.response));
        }
    }


    if (data.payload && data.payload.widget && data.payload.widget === 'top_service_locations') {
        yield put(topServiceLocationRequested());
        response = yield call(() => {
            return axios
                .get(company_Names ||
                    completed_dates ? `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?${company_Names ? company_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(topServiceLocationSuccess(response.data.response));
        }
    }

    if (data.payload && data.payload.widget && data.payload.widget === 'bottom_service_locations') {
        yield put(bottomServiceLocationRequested());
        response = yield call(() => {
            return axios
                .get(company_Names ||
                    completed_dates ? `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?${company_Names ? company_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}&widget=${data.payload.widget}` :
                    `${API_POINT_URL}${SUPER_DASHBOARD_URL}widgets/?widget=${data.payload.widget}`)
                .then(response => {
                    return response;
                })
        });

        if (response && response.data.status === 200) {
            yield put(bottomServiceLocationSuccess(response.data.response));
        }
    }


    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
}

export function* genrateMasterTemplate(payload) {
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${DASHBOARD_POINT_URL}download_master_template/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        //console.log(response);
        const link = document.createElement('a');
        link.href = `${response.data.response.file}`;
        // link.setAttribute(
        //     'download',
        //     `FileName.xlsx`,
        // );
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


function* watchgetDashboardOrderList() {
    yield takeLatest(actionTypes.GetDashboardOrderList, getDashboardOrderList)
}

function* watchaddDashboardOrderList() {
    yield takeLatest(actionTypes.AddDashboardOrderList, addDashboardOrderList)
}

function* watchgetWidgetList() {
    yield takeEvery(actionTypes.GetWidgetList, getWidgetList)
}

function* watchgetWorkOrderSummary() {
    yield takeLatest(actionTypes.GetWorkOrderSummary, getWorkOrderSummary)
}


function* watchgetWorkorderWidgetList() {
    yield takeEvery(actionTypes.GetWorkorderWidgetList, getWorkorderWidgetList)
}

function* watchgetPeriodicSummary() {
    yield takeLatest(actionTypes.GetPeriodicSummary, getPeriodicSummary)
}


function* watchgetPeriodicWidgetList() {
    yield takeEvery(actionTypes.GetPeriodicWidgetList, getPeriodicWidgetList)
}


function* watchsuperDashboardSummary() {
    yield takeLatest(actionTypes.SuperDashboardSummary, superDashboardSummary)
}


function* watchgetSuperDashboardOrderList() {
    yield takeLatest(actionTypes.GetSuperDashboardOrderList, getSuperDashboardOrderList)
}

function* watchaddSuperDashboardOrderList() {
    yield takeLatest(actionTypes.AddSuperDashboardOrderList, addSuperDashboardOrderList)
}

function* watchgetSuperWidgetList() {
    yield takeEvery(actionTypes.GetSuperWidgetList, getSuperWidgetList)
}

function* watchgenrateMasterTemplate() {
    yield takeEvery(actionTypes.GenrateMasterTemplate, genrateMasterTemplate)
}


export const DashboardSaga = [
    fork(watchgetDashboardOrderList),
    fork(watchaddDashboardOrderList),
    fork(watchgetWidgetList),
    fork(watchgetWorkOrderSummary),
    fork(watchgetWorkorderWidgetList),
    fork(watchgetPeriodicSummary),
    fork(watchgetPeriodicWidgetList),
    fork(watchsuperDashboardSummary),
    fork(watchgetSuperDashboardOrderList),
    fork(watchaddSuperDashboardOrderList),
    fork(watchgetSuperWidgetList),
    fork(watchgenrateMasterTemplate)
]


import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helper from 'utils/helpers'
import { connect } from "react-redux";

/*actions*/
import { forgotPassword } from "store/actions";
/*components*/
import AuthBanner from "../shared/AuthBanner";
import Input from "shared/components/Input";
import Footer from 'shared/components/Footer';
import isWrapperAuth from 'hoc';
import Toast from 'shared/components/Toast';


class ForgotPassword extends Component {

    state = {
        errors: {},
        email: '',
        isToast: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.errorMessage !== this.props.errorMessage && !this.state.isToast) {
            this.setState({ isToast: true }, () => {
                window.scrollTo(0, 0);
                if (this.state.isToast) {
                    setTimeout(() => {
                        this.setState({ isToast: false });
                    }, 3000);
                }
            });
        }
    }

    isValid = () => {
        const {
            email,
        } = this.state;

        const errors = {};
        let isValid = true;

        if (!email) {
            errors.email = "Please enter your email.";
            isValid = false;
        }
        if (email && !Helper.validateEmail(email)) {
            errors.email = "Please enter your email in a valid format.";
            isValid = false;
        }

        this.setState({ errors });
        return isValid;
    };

    InputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleForgot = (event) => {
        event.preventDefault();
        const isValid = this.isValid();
        const { email } = this.state;
        if (isValid) {
            const payload = {
                email
            }
            this.props.forgotPassword({ ...payload });
        }
    }

    toastHide = () => {
        this.setState({ isToast: false });
    }

    render() {
        const { errors, isToast } = this.state;
        const { errorMessage, isSuccess, isWarning, isError } = this.props;
        return (
            <div className="auth-section">
                <div className="container-fluid">
                    <div className="row no-gutters main-row">
                        <div className="col-md-6 col-lg-7">
                            <AuthBanner />
                        </div>
                        <div className="col-md-6 col-lg-5">
                            {errorMessage && isToast &&
                                <Toast
                                    message={errorMessage}
                                    Success={isSuccess}
                                    Warn={isWarning}
                                    Error={isError}
                                    Close={this.toastHide}
                                />}
                            <div className="auth-form">
                                <h1 className="title">Forgot Your Password?</h1>
                                <h2>Enter your email address and we’ll send you an email
                                    <br /> with a link to reset your password</h2>
                                <div className="field-group">
                                <form onSubmit={this.handleForgot}>
                                    <Input
                                        label="Email Address"
                                        type="email"
                                        placeholder="Enter your email address"
                                        icon="email-icon"
                                        name="email"
                                        handleChange={this.InputChange}
                                        error={errors.email}
                                    />

                                    <div className="button-block">
                                        <button title="Submit"
                                            type="submit"
                                            className="button button-rounded button-height-large button-block">Submit</button>
                                    </div>

                                    <div className="rember-link">
                                        <span>Remember Password? <Link to="/login">Back to Login</Link> </span>
                                    </div>
                                    </form>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
    errors: { errorMessage, isSuccess, isWarning, isError },
    loading: { isLoading }
}) =>

    ({ isLoading, errorMessage, isSuccess, isWarning, isError });

export default connect(mapStateToProps, { forgotPassword })(isWrapperAuth(ForgotPassword));


import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helper from 'utils/helpers'
import { connect } from "react-redux";

/*actions*/
import { azureLogin , fallBackCode } from "store/actions";
/*components*/
import AuthBanner from "../shared/AuthBanner";
import Footer from 'shared/components/Footer';
import isWrapperAuth from 'hoc';
import Toast from 'shared/components/Toast';
import OtpInput from 'react-otp-input';

class TwoFactorAuth extends Component {

    state = {
        errors: {},
        totp: "",
        isToast: false
    }
    componentDidMount() {
        if (!localStorage.getItem('two_factor')) {
            this.props.history.push('/login');
        }
        this.handleroute();
    }

    isValid = () => {
        const { totp } = this.state;
        const errorsData = {};
        let isValid = true;
        if (!totp) {
            errorsData.totp = "Please enter the verfication code.";
            isValid = false;
        }
        this.setState({ errors: errorsData });
        return isValid;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.errorMessage !== this.props.errorMessage && !this.state.isToast) {
            this.setState({ isToast: true }, () => {
                window.scrollTo(0, 0);
                if (this.state.isToast) {
                    setTimeout(() => {
                        this.setState({ isToast: false });
                    }, 4000);
                }
            });
        }
        this.handleroute();
    }

    handleroute = () => {
        if (localStorage.getItem('two_factor_done')) {
            this.props.history.push('/admin');
        }
    }


    toastHide = () => {
        this.setState({ isToast: false });
    }

    otpHandler = (otp) => {
        this.setState({
            totp: otp
        })
    }

    goBack = () => {
        localStorage.removeItem('two_factor');
        localStorage.removeItem('azureToken');
        localStorage.removeItem('two_factor_done');
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        localStorage.removeItem('LogAzure');
        this.props.history.push('/login');
    }

    verifyHandler = () => {
        const isValid = this.isValid();
        if (isValid) {
            if(localStorage.getItem('azureToken'))
            {
                this.props.azureLogin({
                    azureToken: localStorage.getItem('azureToken'),
                    totp: this.state.totp,
                })
            }
            else 
            {
                this.props.azureLogin({
                    totp: this.state.totp,
                    username : localStorage.getItem('username'),
                    password : localStorage.getItem('password')
                }) 
            }
        }
    }
    fallbackHandler = () => {
        if(localStorage.getItem('azureToken'))
            {
                this.props.fallBackCode({
                    azureToken: localStorage.getItem('azureToken'),
                })
            }
            else 
            {
                this.props.fallBackCode({
                    username : localStorage.getItem('username'),
                    password : localStorage.getItem('password')
                }) 
            }
    }

    render() {
        const { errors, isToast } = this.state;
        const { errorMessage, isSuccess, isWarning, isError } = this.props;
        return (
            <div className="auth-section">
                <div className="container-fluid">
                    <div className="row no-gutters main-row">
                        <div className="col-md-6 col-lg-7">
                            <AuthBanner />
                        </div>
                        <div className="col-md-6 col-lg-5">
                            {errorMessage && isToast &&
                                <Toast
                                    message={errorMessage}
                                    Success={isSuccess}
                                    Warn={isWarning}
                                    Error={isError}
                                    Close={this.toastHide}
                                />}
                            <div className="auth-form">
                                <div className="back-btn">
                                    <span className="_link" onClick={this.goBack}>
                                        <img
                                            src={require("assets/images/arrow-back.svg").default}
                                            className="img-fluid"
                                        />
                                        <span>Back to Login</span>
                                    </span>
                                </div>
                                <h1 className="title">Two Factor Authentication</h1>
                                <h2>Two-factor authentication is enabled for your account. Please enter the code from your authentication app.</h2>
                                <div className="field-group">
                                    <div className="c-field c-field-medium">
                                        <label>Verification Code</label>
                                        <OtpInput
                                            value={this.state.totp}
                                            isInputNum={true}
                                            //  isInputSecure={true}
                                            onChange={this.otpHandler}
                                            inputStyle={'otp-input'}
                                            containerStyle={'otp-field'}
                                            numInputs={6}
                                            separator={false}
                                        />
                                        {this.state.errors && this.state.errors.totp &&
                                            <span className="error-msg">{this.state.errors.totp}</span>
                                        }
                                    </div>
                                    <div className="button-block">
                                        <button title="Log In"
                                            className="button button-height-large button-rounded button-block"
                                            type="button" onClick={this.verifyHandler}>Log In</button>
                                        <div className="options-link">
                                            <p>Didn’t receive the code? &nbsp;
                                                  <span className="color-blue pointer" onClick={this.fallbackHandler}> Receive Code via Email</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
    errors: { errorMessage, isSuccess, isWarning, isError },
    loading: { isLoading }
}) =>

    ({ isLoading, errorMessage, isSuccess, isWarning, isError });

export default connect(mapStateToProps, { azureLogin , fallBackCode })(isWrapperAuth(TwoFactorAuth));


import axios from "axios";
import { put, takeLatest, fork, call, delay } from 'redux-saga/effects';
import { WebConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoError,
    getSavedReportSuccess,
    getReportDetailRequested,
    getReportDetailSuccess,
    genrateReportRequested,
    genrateReportSuccess
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const REPORTS_POINT_URL = WebConfig.ApiUrls.report.reports;

export function* addReport(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${REPORTS_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* getSavedReport(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null,
        create_date_after = null, create_date_before = null } } = data;

       //ClientInstance.defaults.headers.common = {};
   // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = yield call(() => {
        return ClientInstance
            .get(search ? `${API_POINT_URL}${REPORTS_POINT_URL}?page=${currentPage}&search=${search}` :
                status ? `${API_POINT_URL}${REPORTS_POINT_URL}?page=${currentPage}&type=${status}` :
                    create_date_after && create_date_before ? `${API_POINT_URL}${REPORTS_POINT_URL}?page=${currentPage}&create_date_after=${create_date_after}&create_date_before=${create_date_before}` :
                        `${API_POINT_URL}${REPORTS_POINT_URL}?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getSavedReportSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* getReportDetail(payload) {
    const data = payload;

    yield put(getReportDetailRequested());
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${REPORTS_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getReportDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* upDateReoprt(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${REPORTS_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {

        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* genrateReport(payload) {
    const data = payload;

    yield put(genrateReportRequested());
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${REPORTS_POINT_URL}${data.payload.id}/generate/` , {
                headers: {'X-Timezone': timezone}
               })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(genrateReportSuccess(response.data.response));

        const link = document.createElement('a');
        link.href = response.data.response.url;
        link.setAttribute(
            'download',
            `FileName.pdf`,
        );
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* deleteSavedReport(payload) {
    const data = payload;
    
    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${REPORTS_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        yield delay(1000)
        yield getSavedReport({ type: actionTypes.GetSavedReport,
             payload: { currentPage: data.payload.currentPage } });
      
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    
}


function* watchaddReport() {
    yield takeLatest(actionTypes.AddReport, addReport)
}

function* watchgetSavedReport() {
    yield takeLatest(actionTypes.GetSavedReport, getSavedReport)
}

function* watchgetReportDetail() {
    yield takeLatest(actionTypes.GetReportDetail, getReportDetail)
}

function* watchupDateReoprt() {
    yield takeLatest(actionTypes.UpDateReoprt, upDateReoprt)
}

function* watchgenrateReport() {
    yield takeLatest(actionTypes.GenrateReport, genrateReport)
}


function* watchdeleteSavedReport() {
    yield takeLatest(actionTypes.DeleteSavedReport, deleteSavedReport)
}


export const Reportsaga = [
    fork(watchaddReport),
    fork(watchgetSavedReport),
    fork(watchgetReportDetail),
    fork(watchupDateReoprt),
    fork(watchgenrateReport),
    fork(watchdeleteSavedReport)
]
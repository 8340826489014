import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig, } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoError,
    getRecentSearchClient,
    getCustomQuestionRequested,
    getCustomQuestionSuccess,
    getCustomQuestionDetailRequested,
    getCustomQuestionDetailSuccess,
    getCustomServiceLocationsRequested,
    getCustomServiceLocationsSuccess
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const QUESTION_POINT_URL = WebConfig.ApiUrls.configurations.forms;


export function* addCustomQuestion(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${QUESTION_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {

        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* updateCustomQuestion(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${QUESTION_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {

        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* getCustomQuestion(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, service_locations = null } } = data;

    // yield put(getCustomQuestionRequested());
    const response = yield call(() => {
        return ClientInstance
            .get(search ? `${API_POINT_URL}${QUESTION_POINT_URL}?page=${currentPage}&search=${search}` :
                service_locations ? `${API_POINT_URL}${QUESTION_POINT_URL}?no_page&service_locations=${service_locations}`
                    : `${API_POINT_URL}${QUESTION_POINT_URL}?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getCustomQuestionSuccess(response.data.response));
        if (search) {
            yield put(getRecentSearchClient({ page: 'forms-list' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* getCustomQuestionDetail(payload) {
    const data = payload;

    yield put(getCustomQuestionDetailRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${QUESTION_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getCustomQuestionDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* deleteCustomQuestion(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${QUESTION_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));


        if (!data.payload.questionDetailPage) {
            yield delay(1000)
            yield getCustomQuestion({
                type: actionTypes.GetCustomQuestion,
                payload: { currentPage: data.payload.currentPage }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* deleteCustomQuestionField(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${QUESTION_POINT_URL}${data.payload.id}/fields/${data.payload.fieldId}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));


        if (!data.payload.questionDetailPage) {
            yield delay(1000)
            yield getCustomQuestionDetail({
                type: actionTypes.GetCustomQuestionDetail,
                payload: { id: data.payload.id }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* deleteCustomQuestionOption(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${QUESTION_POINT_URL}${data.payload.id}/fields/${data.payload.fieldId}/options/${data.payload.optionId}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));


        if (!data.payload.questionDetailPage) {
            yield delay(1000)
            yield getCustomQuestionDetail({
                type: actionTypes.GetCustomQuestionDetail,
                payload: { id: data.payload.id }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* addCustomServiceLocation(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${QUESTION_POINT_URL}${data.payload.id}/`, {
                service_locations: data.payload.service_locations
            })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        if (!data.payload.questionDetailPage) {
            yield delay(1000)
            yield getCustomQuestion({
                type: actionTypes.GetCustomQuestion,
                payload: { currentPage: data.payload.currentPage }
            });
        }
        if (data.payload.questionDetailPage) {
            yield delay(1000)
            yield getCustomServiceLocations({
                type: actionTypes.GetCustomServiceLocations,
                payload: { formId: data.payload.id }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* getCustomServiceLocations(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null } } = data;

    yield put(getCustomServiceLocationsRequested());
    const response = yield call(() => {
        return ClientInstance
            .get(search ? `${API_POINT_URL}${QUESTION_POINT_URL}service-locations/?form=${data.payload.formId}&search=${search}`
                : `${API_POINT_URL}${QUESTION_POINT_URL}service-locations/?form=${data.payload.formId}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getCustomServiceLocationsSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* deleteCustomServiceLocation(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${QUESTION_POINT_URL}${data.payload.formId}/service-location/${data.payload.serive_location_id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)
        yield getCustomServiceLocations({
            type: actionTypes.GetCustomServiceLocations,
            payload: { formId: data.payload.formId }
        });
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}




function* watchaddCustomQuestion() {
    yield takeLatest(actionTypes.AddCustomQuestion, addCustomQuestion)
}
function* watchgetCustomQuestion() {
    yield takeLatest(actionTypes.GetCustomQuestion, getCustomQuestion)
}
function* watchgetCustomQuestionDetail() {
    yield takeLatest(actionTypes.GetCustomQuestionDetail, getCustomQuestionDetail)
}
function* watchupdateCustomQuestion() {
    yield takeLatest(actionTypes.UpDateCustomQuestion, updateCustomQuestion)
}

function* watchdeleteCustomQuestion() {
    yield takeLatest(actionTypes.DeleteCustomQuestion, deleteCustomQuestion)
}

function* watchdeleteCustomQuestionField() {
    yield takeLatest(actionTypes.DeleteCustomQuestionField, deleteCustomQuestionField)
}

function* watchdeleteCustomQuestionOption() {
    yield takeLatest(actionTypes.DeleteCustomQuestionOption, deleteCustomQuestionOption)
}

function* watchaddCustomServiceLocation() {
    yield takeLatest(actionTypes.AddCustomServiceLocation, addCustomServiceLocation)
}

function* watchgetCustomServiceLocations() {
    yield takeLatest(actionTypes.GetCustomServiceLocations, getCustomServiceLocations)
}

function* watchdeleteCustomServiceLocation() {
    yield takeLatest(actionTypes.DeleteCustomServiceLocation, deleteCustomServiceLocation)
}

export const QuestionSaga = [
    fork(watchaddCustomQuestion),
    fork(watchgetCustomQuestion),
    fork(watchgetCustomQuestionDetail),
    fork(watchupdateCustomQuestion),
    fork(watchdeleteCustomQuestion),
    fork(watchdeleteCustomQuestionField),
    fork(watchdeleteCustomQuestionOption),
    fork(watchaddCustomServiceLocation),
    fork(watchgetCustomServiceLocations),
    fork(watchdeleteCustomServiceLocation)
]
import * as actionTypes from '../actionTypes';

const initialState = {
    appTokenDetail: null,
    loginInfo: null,
    profile: null,
    verifyToken: true,
    inperosnate: false,
    inperosnateDetail: null,
    inperosnateLogout: null,
    userProfile: null,
    qrData: null,
    deviceList : null,
    isAddDevice : null,
    errorMessageDevice: null,
};
const authReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    switch (type) {

        case actionTypes.GetappAuthSuccess:
            object = { ...state, appTokenDetail: payload }
            break

        case actionTypes.LoginDetail:
            object = { ...state, loginInfo: payload }
            break

        case actionTypes.GetProfileRequested:
            object = { ...state, profile: null }
            break

        case actionTypes.GetProfileSuccess:
            object = { ...state, profile: payload }
            break

        case actionTypes.ResetVerifyTokenFailed:
            object = { ...state, verifyToken: false }
            break

        case actionTypes.InPersonateRequested:
            object = {
                ...state, inperosnate: false, inperosnateLogout: false,
                inperosnateDetail: null
            }
            break

        case actionTypes.InPersonateSuccess:
            object = {
                ...state,
                inperosnate: true,
                inperosnateDetail: payload
            }
            break

        case actionTypes.InPersonateLogout:
            object = {
                ...state,
                inperosnate: false,
                inperosnateDetail: null, inperosnateLogout: true
            }
            break

        case actionTypes.GetUserProfileRequested:
            object = { ...state, userProfile: null }
            break

        case actionTypes.GetUserProfileSuccess:
            object = { ...state, userProfile: payload }
            break


        case actionTypes.GetQrCodeRequested:
            object = { ...state, qrData: null }
            break

        case actionTypes.GetQrCodeSuccess:
            object = { ...state, qrData: payload }
            break

        
        case actionTypes.GetDeviceListRequested:
            object = { ...state, deviceList: null }
            break

        case actionTypes.GetDeviceListSuccess:
            object = { ...state, deviceList: payload }
            break
        
        case actionTypes.AddDeviceRequested:
            object = { ...state, isAddDevice: null , errorMessageDevice : null}
            break

        case actionTypes.AddDeviceSuccess:
            object = { ...state, isAddDevice: true  , errorMessageDevice : null}
            break

        case actionTypes.AddDeviceFailure:
            object = { ...state, errorMessageDevice: payload }
            break

        case actionTypes.LogOut:
            object = { state: undefined };
            break

        default:
            object = state;
    }
    return object
};
export default authReducer;
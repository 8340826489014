import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import './translations';
import configureStore from 'store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import  './utils/http-interceptor';
import { PublicClientApplication , EventType } from '@azure/msal-browser';
import { msalConfig } from "./config/authConfig";
import Interceptor  from './utils/http-interceptor';

const history = createBrowserHistory();
const store = configureStore(history);

Interceptor.axiosinterceptor(store);
Interceptor.clientinterceptor(store);

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});


ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
        <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

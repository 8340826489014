import axios from "axios";
import { put, takeLatest, fork, call, delay } from 'redux-saga/effects';
import { WebConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getNotificationListHeaderSuccess,
    getNotificationAllListSuccess,
    getNotificationAllListSuccessReload,
    getNotificationDetailRequested,
    getNotificationDetailSuccess
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const NOTIFICATION_POINT_URL = WebConfig.ApiUrls.notifcations;

export function* getNotificationListHeader(payload) {
    const data = payload;
    const { payload: { page_size = null } } = data;

    //   yield put(loadTodoRequest());
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${NOTIFICATION_POINT_URL}?page_size=${page_size}`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getNotificationListHeaderSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}


export function* getNotificationAllList(payload) {
    const data = payload;
    const { payload: { page_size = null, currentPage = null, reload = null } } = data;

    //   yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${NOTIFICATION_POINT_URL}?page=${currentPage}&page_size=${page_size}`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        if (reload) {
            yield put(getNotificationAllListSuccessReload(response.data.response));
        }
        else {
            yield put(getNotificationAllListSuccess(response.data.response));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}


export function* notificationReadAll(payload) {
    const data = payload;
    const { payload: { page_size = null, currentPage = null } } = data;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${NOTIFICATION_POINT_URL}mark_all_read/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000);
        yield getNotificationAllList({
            type: actionTypes.GetNotificationAllList,
            payload: {
                currentPage, page_size, reload: true
            }
        });
        yield getNotificationListHeader({
            type: actionTypes.GetNotificationList,
            payload: {
                page_size: 5
            }
        });
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}




export function* getNotificationDetail(payload) {
    const data = payload;

    yield put(getNotificationDetailRequested());
    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${NOTIFICATION_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getNotificationDetailSuccess(response.data.response));

        yield delay(1000);
        yield getNotificationAllList({
            type: actionTypes.GetNotificationAllList,
            payload: {
                currentPage: 1, page_size: 10, reload: true
            }
        });
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}


export function* getNotificationRead(payload) {
    const data = payload;

    const { payload: { page_size = null, currentPage = null } } = data;

    //   yield put(loadTodoRequest());
    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${NOTIFICATION_POINT_URL}${data.payload.id}/`, {
                read: data.payload.read
            })
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        if (currentPage) {

            yield getNotificationAllList({
                type: actionTypes.GetNotificationAllList,
                payload: {
                    currentPage, page_size, reload: true
                }
            });
        }

        yield getNotificationListHeader({
            type: actionTypes.GetNotificationList,
            payload: {
                page_size: 5
            }
        });

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}




function* watchgetNotificationListHeader() {
    yield takeLatest(actionTypes.GetNotificationList, getNotificationListHeader)
}


function* watchgetNotificationAllList() {
    yield takeLatest(actionTypes.GetNotificationAllList, getNotificationAllList)
}

function* watchnotificationReadAll() {
    yield takeLatest(actionTypes.NotificationReadAll, notificationReadAll)
}


function* watchgetNotificationDetail() {
    yield takeLatest(actionTypes.GetNotificationDetail, getNotificationDetail)
}

function* watchgetNotificationRead() {
    yield takeLatest(actionTypes.GetNotificationRead, getNotificationRead)
}


export const notificationSaga = [
    fork(watchgetNotificationListHeader),
    fork(watchgetNotificationAllList),
    fork(watchnotificationReadAll),
    fork(watchgetNotificationDetail),
    fork(watchgetNotificationRead)
]

import axios from "axios";
import { put, takeLatest, fork, call, delay } from 'redux-saga/effects';
import { WebConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoError,
    getTimezoneSuccess,
    getRecentSearchClient,
    getServiceLocationRequested,
    getServiceLocationSuccess,
    getServiceDetailRequested,
    getServiceDetailSuccess,
    getServiceAllSuccess,
    getServiceContactRequested,
    getServiceContactSuccess,
    getServiceListArchieveRequested,
    getServiceListArchieveSuccess,
    getAllServiceListRequested,
    getAllServiceListReportRequested,
    getAllServiceListReportSuccess,
    getAllServiceDropdownRequested,
    getAllServiceDropdownSuccess,
    importFilesRequested,
    importFilesSuccess,
    importFilesFailure
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const CLIENTS_SERVICE_POINT_URL = WebConfig.ApiUrls.company.clientsServiceLocation;
const CLIENTS_POINT_URL = WebConfig.ApiUrls.company.clients;
const EVENTS_SERVICE_POINT_URL = WebConfig.ApiUrls.ReportALLServicePoint;

export function* getTimezone() {

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}master/timezones/?fields=id,name`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getTimezoneSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* addService(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* getServiceLocation(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null, ordering = null,
        client_division = null, page_size = null, service_provider_division = null } } = data;
    yield put(getServiceLocationRequested());

    let instance = localStorage.getItem('inperosnate') ? ClientInstance : axios
    let recentSearch = localStorage.getItem('inperosnate') ? true : false
    const response = yield call(() => {
        return instance
            .get(search && !client_division && !service_provider_division ?
                `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?page=${currentPage}&search=${search}&fields=id,name,client_name,is_active,service_provider_division_name` :
                status ? `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?page=${currentPage}&is_active=${status}&fields=id,name,client_name,is_active,service_provider_division_name` :
                    ordering ? `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?page=${currentPage}&ordering=${ordering}&fields=id,name,client_name,is_active,service_provider_division_name` :
                        client_division && !search ? `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?page=${currentPage}&client_division=${client_division}&page_size=${page_size}&fields=id,name,client_name,is_active,service_provider_division_name` :
                            client_division && search ? `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?page=${currentPage}&client_division=${client_division}&page_size=${page_size}&search=${search}&fields=id,name,client_name,is_active,service_provider_division_name` :
                                service_provider_division && !search ? `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?page=${currentPage}&service_provider_division=${service_provider_division}&page_size=${page_size}&fields=id,name,client_name,is_active,service_provider_division_name` :
                                    service_provider_division && search ? `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?page=${currentPage}&service_provider_division=${service_provider_division}&page_size=${page_size}&search=${search}&fields=id,name,client_name,is_active,service_provider_division_name`
                                        : `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?page=${currentPage}&fields=id,name,client_name,is_active,service_provider_division_name`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getServiceLocationSuccess(response.data.response));
        if (search && recentSearch) {
            yield put(getRecentSearchClient({ page: 'service-locations-list' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* getServiceclientLocation(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null, ordering = null,
        clientId = null } } = data;
    yield put(getServiceLocationRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(search ?
                `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?client_id=${clientId}&page=${currentPage}&search=${search}&fields=id,name,client_name,is_active,service_provider_division_name` :
                status ? `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?client_id=${clientId}&page=${currentPage}&is_active=${status}&fields=id,name,client_name,is_active,service_provider_division_name` :
                    ordering ? `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?client_id=${clientId}&page=${currentPage}&ordering=${ordering}&fields=id,name,client_name,is_active,service_provider_division_name`
                        : `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?client_id=${clientId}&page=${currentPage}&fields=id,name,client_name,is_active,service_provider_division_name`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getServiceLocationSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* updateServiceStatus(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}${data.payload.id}/`, { is_active: data.payload.is_active })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000);

        if (!data.payload.servicePage) {
            yield getServiceLocation({ type: actionTypes.GetServiceLocation, payload: { currentPage: data.payload.currentPage } });
        }
        if (data.payload.clientorgContact) {
            yield getServiceLocation({
                type: actionTypes.GetServiceLocation,
                payload: { currentPage: data.payload.currentPage, client_division: data.payload.client_division, page_size: data.payload.page_size }
            });
        }
        if (data.payload.spOrg) {
            yield getServiceLocation({
                type: actionTypes.GetServiceLocation,
                payload: {
                    currentPage: data.payload.currentPage,
                    service_provider_division: data.payload.service_provider_division, page_size: data.payload.page_size
                }
            });
        }

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* updateClientServiceStatus(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}${data.payload.id}/`, { is_active: data.payload.is_active })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000);
        if (!data.payload.servicePage) {
            yield getServiceclientLocation({
                type: actionTypes.GetClientServiceLocation,
                payload: { currentPage: data.payload.currentPage, clientId: data.payload.clientId }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}




export function* deleteService(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)
        if (!data.payload.servicePage) {
            yield getServiceLocation({ type: actionTypes.GetServiceLocation, payload: { currentPage: data.payload.currentPage } });
        }
        if (data.payload.clientorgContact) {
            yield getServiceLocation({
                type: actionTypes.GetServiceLocation,
                payload: { currentPage: data.payload.currentPage, client_division: data.payload.client_division, page_size: data.payload.page_size }
            });
        }
        if (data.payload.spOrg) {
            yield getServiceLocation({
                type: actionTypes.GetServiceLocation,
                payload: { currentPage: data.payload.currentPage, service_provider_division: data.payload.service_provider_division, page_size: data.payload.page_size }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* deleteClientService(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)

        if (!data.payload.servicePage) {
            yield getServiceclientLocation({
                type: actionTypes.GetClientServiceLocation,
                payload: { currentPage: data.payload.currentPage, clientId: data.payload.clientId }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* getServiceDetail(payload) {
    const data = payload;
    yield put(getServiceDetailRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getServiceDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* editService(payload) {
    const data = payload;
    const payloadData = { ...data.payload };
    delete payloadData["id"];

    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}${data.payload.id}/`, payloadData)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}



export function* getServiceAll(payload) {
    const data = payload;
    yield put(getAllServiceListRequested());

    let fields = null;
    if (data.payload && data.payload.fields) {
        fields = data.payload.fields;
    }

    const response = yield call(() => {
        return ClientInstance
            .get(data.payload && !fields ?
                `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?no_page&fields=id,name,service_provider_division&client_id=${data.payload.id}` :
                fields ? `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?no_page&fields=${fields}` :
                    `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}?no_page&fields=id,name,service_provider_division`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getServiceAllSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* getAllServiceReportList(payload) {
    const data = payload;
    // yield put(getAllServiceListReportRequested());

    let fields = null;
    let search = null;
    let groupNames = [];
    if (data.payload && data.payload.employee) {
        groupNames = data.payload.employee.map((item) => `&employee=${[item]}`);
    }
    if (data.payload && data.payload.client_id) {
        groupNames = data.payload.client_id.map((item) => `&client=${[item]}`);
    }
    if (data.payload && data.payload.service_provider_division) {
        groupNames = data.payload.service_provider_division.map((item) => `&service_provider_division=${[item]}`);
    }
    if (data.payload && data.payload.fields) {
        fields = data.payload.fields;
    }
    if (data.payload && data.payload.search) {
        search = data.payload.search;
    }
    const response = yield call(() => {
        return ClientInstance
            .get(fields && !search ? `${API_POINT_URL}${EVENTS_SERVICE_POINT_URL}?no_page&fields=${fields}${groupNames.join("")}` :
                fields && search ? `${API_POINT_URL}${EVENTS_SERVICE_POINT_URL}?no_page&fields=${fields}&search=${search}` :
                    `${API_POINT_URL}${EVENTS_SERVICE_POINT_URL}?no_page&fields=id,name`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getAllServiceListReportSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* getServiceContact(payload) {
    const data = payload;
    const { payload: { currentPage = null, } } = data;
    yield put(getServiceContactRequested());
    //
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_POINT_URL}contacts/?service_location=${data.payload.id}&page=${currentPage}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getServiceContactSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    // 
}


export function* getServiceListArchieve(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null } } = data;
    yield put(getServiceListArchieveRequested());

    const response = yield call(() => {
        return ClientInstance
            // .get(`${API_POINT_URL}companies/${data.payload.companyId}/clients/?page=${currentPage}`)
            .get(search ?
                `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}archived/?page=${currentPage}&search=${search}`
                : `${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}archived/?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getServiceListArchieveSuccess(response.data.response));
        if (search) {
            yield put(getRecentSearchClient({ page: 'service-locations-archived' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* updateServiceArchieveStatus(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}${data.payload.id}/undelete/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000);

        if (!data.payload.locationArchievePage) {
            yield getServiceListArchieve({ type: actionTypes.GetServiceArchiveList, payload: { currentPage: data.payload.currentPage } });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* getAllServiceDropdown(payload) {
    const data = payload;
    yield put(getAllServiceDropdownRequested());

    let fields = null;
    if (data.payload && data.payload.fields) {
        fields = data.payload.fields;
    }

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${EVENTS_SERVICE_POINT_URL}?fields=id,name`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getAllServiceDropdownSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* importServiceModalList(payload) {
    const data = payload;

    yield put(importFilesRequested());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${CLIENTS_SERVICE_POINT_URL}import_xls/`, data.payload.formData)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
       // console.log("response1" , response);
        yield put(importFilesSuccess(response.data.response.detail));
  
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
      //  console.log("response2" , response);
        yield put(importFilesFailure(response.data.message));
    }
    
}


function* watchgetTimezone() {
    yield takeLatest(actionTypes.GetTimezone, getTimezone)
}

function* watchaddService() {
    yield takeLatest(actionTypes.AddServiceLocation, addService)
}

function* watchgetServiceLocation() {
    yield takeLatest(actionTypes.GetServiceLocation, getServiceLocation)
}

function* watchupdateServiceStatus() {
    yield takeLatest(actionTypes.UpdateServiceStatus, updateServiceStatus)
}

function* watchdeleteService() {
    yield takeLatest(actionTypes.DeleteService, deleteService)
}

function* watchgetServiceDetail() {
    yield takeLatest(actionTypes.GetServiceDetail, getServiceDetail)
}

function* watcheditService() {
    yield takeLatest(actionTypes.EditService, editService)
}

function* watchgetServiceAll() {
    yield takeLatest(actionTypes.GetAllServiceList, getServiceAll)
}

function* watchgetServiceclientLocation() {
    yield takeLatest(actionTypes.GetClientServiceLocation, getServiceclientLocation)
}

function* watchupdateClientServiceStatus() {
    yield takeLatest(actionTypes.UpdateClientServiceStatus, updateClientServiceStatus)
}

function* watchdeleteClientService() {
    yield takeLatest(actionTypes.DeleteClientService, deleteClientService)
}

function* watchgetServiceContact() {
    yield takeLatest(actionTypes.GetServiceContacts, getServiceContact)
}

function* watchgetServiceListArchieve() {
    yield takeLatest(actionTypes.GetServiceArchiveList, getServiceListArchieve)
}

function* watchupdateServiceArchieveStatus() {
    yield takeLatest(actionTypes.UpdateServiceArchieveStatus, updateServiceArchieveStatus)
}

function* watchgetAllServiceReportList() {
    yield takeLatest(actionTypes.GetAllServiceReportList, getAllServiceReportList)
}


function* watchgetAllServiceDropdown() {
    yield takeLatest(actionTypes.GetAllServiceDropdown, getAllServiceDropdown)
}


function* watchImportServiceModalList() {
    yield takeLatest(actionTypes.ImportServiceModalList, importServiceModalList)
}





export const serviceSaga = [
    fork(watchgetTimezone),
    fork(watchaddService),
    fork(watchgetServiceLocation),
    fork(watchupdateServiceStatus),
    fork(watchdeleteService),
    fork(watchgetServiceDetail),
    fork(watcheditService),
    fork(watchgetServiceAll),
    fork(watchgetServiceclientLocation),
    fork(watchupdateClientServiceStatus),
    fork(watchdeleteClientService),
    fork(watchgetServiceContact),
    fork(watchgetServiceListArchieve),
    fork(watchupdateServiceArchieveStatus),
    fork(watchgetAllServiceReportList),
    fork(watchgetAllServiceDropdown),
    fork(watchImportServiceModalList)
]

export const TRANSLATIONS_FR = {
    NAV: {
        dashboard: "Tableau de bord",
        companies: "Entreprises",
        companyAdmin: "Administrateurs de l'entreprise",
        Clients: "Clientes",
        Service: "Emplacements de service",
        Contacts: "Contacts",
        Inspections: "Contrôles",
        Work: "Demande de service",
        Reports: "Rapports",
        Periodics: "Périodique",
        Employees: "Des employés",
        Admin: "Administratrice",
        Archives : "Les archives",
        SpOrganization : "Organisation SP",
        Configuration : "Configuration",
        Profile : "Mon profil",
        Switch : "Changer de compte",
        SignOut : "Se déconnecter",
        HiText : "Salut",
        SwitchModal : {
            text : "Êtes-vous sûr de vouloir passer à"
        }
    },
    CompanyList: {
        title: 'Entreprises',
        addButton: 'Ajouter une entreprise',
        searchPlaceholder: 'Rechercher des entreprises',
        statusFilter: {
            status: 'Statut',
            all: "Toute",
            active: 'Active',
            Inactive: 'Inactive'
        },
        DeactivatePopup : {
            title : 'Désactiver le Compagnie',
            text1 : "Êtes-vous sûr de vouloir désactiver le compagnie",
        },
        ActivatePopup : {
            title : 'Activer le Compagnie',
            text1 : 'Êtes-vous sûr de vouloir activer le compagnie',
        },
        table: {
            heading1: "NOM DE LA COMPAGNIE",
            heading2: "STATUT",
            heading3: "ACTIONS"
        }
    },
    AddCompany: {
        title: 'Ajouter une entreprise',
        editTitle: 'Modifier la société',
        genralTitle: 'INFORMATIONS GÉNÉRALES',
        addressTtitle: "INFORMATIONS D'ADRESSE",
        Errors : {
            e1 : "Veuillez saisir le nom de l'entreprise.",
            e2 : "Veuillez entrer le nom de société valide.",
            e3 : "Veuillez entrer le nom de l'entreprise min 3 ou max 30 caractères.",
            e4 : "Veuillez entrer le code postal.",
            e5 : "Veuillez saisir un code postal valide.",
            e6 : "Veuillez entrer l'adresse.",
            e7 : "Veuillez entrer l'adresse valide.",
            e8 : "Veuillez entrer l'adresse max 100 caractères.",
            e9 : "Veuillez entrer la ville.",
            e10 : "Veuillez entrer un nom de ville valide.",
            e11 : "Veuillez entrer la ville max 100 caractères.",
            e12 : "Veuillez sélectionner le pays.",
            e13 : "Veuillez sélectionner l'état."
        },
        fields: {
            'CompanyName': 'Nom de la compagnie*',
            'Address1': 'Adresse 1*',
            'Address2': 'Adresse 2',
            'City': 'Ville*',
            'Postal': 'Code postal / Zip*',
            'Province': 'Province / État*',
            'Country': 'De campagne*'
        },
        placeholder: {
            'CompanyName': 'Par exemple. Steve Kent',
            'Address1': 'Entrez votre adresse',
            'Address2': 'Entrez votre adresse',
            'City': 'Par exemple. New York',
            'Postal': 'Par exemple. M1B M9W ou 11017',
        }
    },
    companyDetail: {
        title: 'Détails de la société',
        labels: {
            'CompanyName': 'Nom de la compagnie',
            'Address': 'Adresse',
        }
    },
    companyAdminDetail: {
        title: "Détails de l'administrateur de l'entreprise",
        genralTitle: 'INFORMATIONS GÉNÉRALES',
        tooltip : "est l'administrateur principal de l'entreprise pour cette entreprise",
        labels: {
            'label1': 'NOM ADMIN',
            'label2': 'E-MAIL',
            'label3': 'COMPAGNIE',
            'label4': 'NUMÉRO DE TÉLÉPHONE',
            'label5': 'LANGUE PRÉFÉRÉE',
            'label6': 'REMARQUES',
            'label7': 'ADRESSE' ,
            'label8': "Type d'administrateur"
        },
        tabs : {
            tab1 : "Informations générales",
            tab2 : "Entreprises assignées et autorisations"
        }
    },
    AddCompanyAdmin: {
        title: 'Ajouter un administrateur de société',
        companyTitle : "COMPAGNIE",
        editTitle: "Modifier l'administrateur de l'entreprise",
        genralTitle: 'INFORMATIONS GÉNÉRALES',
        addressTitle: "Informations d'adresse",
        AddCompanyButton : "Ajouter une autre entreprise",
        addressSubTitle: "L'adresse de l'administrateur de l'entreprise est-elle la même que celle de l'entreprise? (Facultatif)",
        PrimayPopup: {
            title: "L'administrateur principal de l'entreprise existe déjà!",
            text: "Cette action supprimera l'accès administrateur principal de",
            text3: 'pour la compagnie',
            text2: 'Souhaitez-vous continuer?'
        },
        Errors : {
            e1  : "Veuillez saisir le nom de l'administrateur.",
            e2 : "Veuillez saisir un nom d'administrateur valide.",
            e3 : "Veuillez entrer le nom de l'administrateur min 3 ou max 30 caractères.",
            e4 : "Veuillez saisir votre e-mail.",
            e5 : "Veuillez saisir votre email dans un format valide.",
            e6 : "S'il vous plaît entrer un numéro de téléphone valide.",
            e7 : "Veuillez saisir des notes valides.",
            e8 : "Veuillez sélectionner l'entreprise.",
            e9 : "Veuillez sélectionner le niveau.",
            e10 : "Veuillez sélectionner la division.",
            e11 : "Veuillez sélectionner le poste.",
            e12 : "Cette société est déjà ajoutée.",
            e13 : "Veuillez sélectionner l'entreprise.",
            e14 : "Cette division est déjà ajoutée."
        },
        fields: {
            'AdminName': "Nom de l'administrateur de l'entreprise *",
            'Email': 'Adresse e-mail*',
            'Company': 'Entreprise*',
            'City': 'Ville*',
            'Phone': 'Pas de téléphone',
            "primaryadmin": "Rendre l’administrateur principal de l’entreprise",
            "primaryadminToolTip": "L'administrateur principal de l'entreprise a la possibilité de créer d'autres administrateurs d'entreprise de la même entreprise",
            "Language": "langue préférée",
            "Notes": "Remarques",
            "Yes": 'Oui',
            "No": 'non',
            "optionalText": "Ajouter une nouvelle adresse (facultatif)"
        },
        placeholder: {
            'AdminName': 'Par exemple. Steve Kent',
            'Email': "Par exemple. gdi@email.tsum",
            'Phone': 'Par exemple. +1 333 444 5555',
            "Notes": "Saisissez des notes ici.",
            "Company" : "Sélectionnez une entreprise"
        },
        DeletePopup : {
            title : 'Supprimer le poste',
            text2 : 'Êtes-vous sûr de vouloir supprimer la position.'
        },
        steps: {
            step1: "Détails Généraux",
            step2: "Affecter des entreprises"
        },
    },
    CompanyAdmin: {
        title: "Administrateurs de l'entreprise",
        addButton: 'Ajouter un administrateur de société',
        searchPlaceholder: 'Rechercher un administrateur de société',
        DeletePopup: {
            title: "Supprimer l'administrateur de l'entreprise",
            text1: "Si vous supprimez cet administrateur d'entreprise, il ne pourra plus se connecter et gérer l'entreprise",
            text2: "Êtes-vous sûr de vouloir supprimer l'administrateur"
        },
        DeletePopupCompany : {
            title : 'Supprimer la société',
            text1 : 'La suppression de cet utilisateur laissera',
            text2 : "sans administrateur principal de l'entreprise. Veuillez associer un nouvel administrateur principal avant de procéder à la suppression.",
            text3 : "Êtes-vous sûr de qui veut supprimer / supprimer cette entreprise"
        },
        table: {
            heading1: "NOM DE L'ADMINISTRATEUR DE LA SOCIÉTÉ",
            heading2: "COMPAGNIE",
            heading3: "ADRESSE EMAIL",
            heading4: "DE CAMPAGNE",
            heading5: "PAS DE TÉLÉPHONE.",
            heading6: "ACTIONS"
        },
        tooltip : {
            tooltip1 : "Cet utilisateur est un administrateur d'entreprise principal d'une entreprise"
        }
    },

    NotFoundComponent: {
        title: 'Page non trouvée',
        text: "Désolé, nous n'avons pas trouvé la page que vous recherchez"
    },
    NotFound403Component: {
        title: 'Accès refusé',
        text: "You don’t have permission to access on this server."
    },
    ServerErrorComponent: {
        title: 'Quelque chose ne va pas',
        text: 'Nous rencontrons quelques problèmes en ce moment. Veuillez réessayer plus tard.'
    },

    clientList: {
        title: "Clientes",
        searchPlaceholder: "Rechercher des clients",
        addButton: "Ajouter un client",
        importButton : "Importer",
        DeactivatePopup : {
            title : 'Désactiver le client',
            text1 : 'Êtes-vous sûr de vouloir désactiver le client',
        },
        ActivatePopup : {
            title : 'Activer le client',
            text1 : 'Êtes-vous sûr de vouloir activer le client',
        },
        DeletePopup : {
            title : 'Supprimer le client',
            text2 : 'Êtes-vous sûr de vouloir supprimer le client'
        },
        table: {
            heading1: "Nom du client",
            heading2: "Niveau SP",
            heading2: "Division SP",
            heading4: "Statut",
            heading5: "Actions"
        }
    },
    AddClient: {
        title: 'Ajouter un client',
        editTitle: "Modifier le client",
        genralTitle: 'INFORMATIONS GÉNÉRALES',
        addressTtitle: "INFORMATIONS D'ADRESSE",
        tooltip : "Cette adresse sera ajoutée comme emplacement de service et incluse dans la liste d'emplacements de service",
        Errors: {
            e1 : "Veuillez entrer le nom du client.",
            e2 : "Veuillez saisir un nom de client valide.",
            e3 : "Veuillez entrer le nom du client max 50 caractères.",
            e4 : "Veuillez entrer le code postal.",
            e5 : "Veuillez saisir un code postal valide.",
            e6 : "Veuillez entrer l'adresse.",
            e7 : "Veuillez entrer l'adresse valide.",
            e8 : "Veuillez entrer l'adresse max 100 caractères.",
            e9 : "Veuillez entrer la ville.",
            e10 : "Veuillez sélectionner le niveau sp.",
            e11 : "Veuillez sélectionner la division sp.",
            e12 : "Veuillez entrer un nom de ville valide.",
            e13 : "Veuillez entrer la ville max 100 caractères.",
            e14 : "Veuillez sélectionner le pays.",
            e15 : "Veuillez sélectionner l'état.",
            e16 : "Veuillez saisir des notes valides.",
            e17 : "Veuillez entrer un nom de lieu de service.",
            e18 : "Veuillez saisir un nom de lieu de service valide.",
            e19 : "Veuillez entrer le nom de l'emplacement du service max 100 caractères.",
            e20 : "Veuillez sélectionner le fuseau horaire."
        },
        fields: {
            'clientName': 'Nom du client*',
            'spName' : "Niveau SP *",
            'divisonName' : "Division SP *",
            'checbox1' : "Afficher les contacts de la descendance dans le dossier client",
            'checbox2' : "Afficher les contacts en amont dans les enregistrements d'emplacement de service",
            'Address1': 'Adresse 1*',
            'Address2': 'Adresse 2*',
            'City': 'Ville*',
            'Postal': 'Code postal / Zip*',
            'Province': 'Province / État*',
            'Country': 'De campagne*',
            "Notes" : "Remarques",
            "Service" : "Nom de l'emplacement du service*",
            "addressService" : "Cette adresse est un emplacement de service",
            "timezone" : "Fuseau horaire*"
        },
        placeholder : {
            'clientName': 'Par exemple. Steve Kent',
            "Notes" : "Saisissez des notes ici.",
            'spName' : "Sélectionnez le niveau de SP",
            'divisonName' : "Sélectionnez la division SP",
            "service" : "Par exemple. Place Scotia",
            'Address1': 'Entrez votre adresse',
            'Address2': 'Entrez votre adresse',
            'City': 'Par exemple. New York',
            'Postal': 'Par exemple. M1B M9W ou 11017',
            "timezone" : "Sélectionnez le fuseau horaire"
        }
    },
    clientDetail : {
        tabs : {
            tab1 : "Informations générales",
            tab2 : "Emplacements de service",
            tab3 : "Contacts",
            tab4 : "Organigramme",
            tab5 : "Rapports"
        },
        labels : {
            label1 : "Niveau Sp",
            label2 : "Division SP",
            label3 : "Statut",
            label4 : "CONSULTEZ LES CONTACTS EN LIGNE EN BAS DANS LE DOSSIER DU CLIENT",
            label5 : "AFFICHER LES CONTACTS UPLINE DANS LES ENREGISTREMENTS DES EMPLACEMENTS DE SERVICE",
            label6 : "Adresse",
            label7 : "Remarques",
        }
    },

    AddServiceLocation: {
        title: 'Ajouter un emplacement de service',
        editTitle: "Modifier l'emplacement du service",
        genralTitle: 'INFORMATIONS GÉNÉRALES',
        addressTtitle: "INFORMATIONS D'ADRESSE",
        Errors : {
            e1 : "Veuillez entrer le nom de l'emplacement du service.",
            e2 : "Veuillez saisir un nom de lieu de service valide.",
            e3 : "Veuillez entrer le nom de l'emplacement du service min 1 ou max 50 caractères.",
            e4 : "Veuillez entrer le code postal.",
            e5 : "Veuillez entrer un code postal valide.",
            e6 : "Veuillez entrer l'adresse.",
            e7 : "Veuillez entrer l'adresse valide.",
            e8 : "Veuillez entrer l'adresse max 100 caractères.",
            e9 : "Veuillez entrer la ville.",
            e10 : "Veuillez entrer un nom de ville valide.",
            e11 : "Veuillez entrer la ville max 100 caractères.",
            e12 : "Veuillez sélectionner le pays.",
            e13 : "Veuillez sélectionner le client.",
            e14 : "Veuillez sélectionner le fuseau horaire.",
            e15 : "Veuillez sélectionner l'état.",
            e16 : "Veuillez saisir des notes valides.",
            e17 : "Veuillez sélectionner le niveau sp.",
            e18 : "Veuillez sélectionner la division sp.",
            e19 : "Veuillez sélectionner le niveau de client.",
            e20 : "Veuillez sélectionner la division client."
        },
        fields: {
            'serviceName': "Nom de l'emplacement du service *",
            'client' : 'Cliente*',
            'ServiceArea' : "Zone de service*",
            'divisonName' : "Division SP *",
            "Notes" : "Remarques",
            "timezone" : "Fuseau horaire*",
            "clientLevel" : "Niveau client*",
            "clientDivision" : "Division client*",
        },
        placeholder : {
            'serviceName': 'Par exemple. Place Scotia',
            'client' : 'Sélectionnez un client',
            "Notes" : "Saisissez des notes ici.",
            'ServiceArea' : "Sélectionnez la zone ",
            'divisonName' : "Sélectionnez la division SP",
            "timezone" : "Sélectionnez le fuseau horaire",
            "clientLevel" : "Choisir le niveau",
            "clientDivision" : "Sélectionnez une division",
        }
    },

    serviceList : {
        title: 'Emplacements de service',
        addButton: 'Ajouter un emplacement de service',
        importButton : "Importer",
        searchPlaceholder: 'Rechercher des emplacements de service',
        DeactivatePopup : {
            title : "Désactiver l'emplacement du service",
            text1 : "Voulez-vous vraiment désactiver l'emplacement du service",
        },
        ActivatePopup : {
            title : "Activer l'emplacement du service",
            text1 : "Êtes-vous sûr de vouloir activer l'emplacement du service",
        },
        DeletePopup : {
            title : "Supprimer l'emplacement du service",
            text2 : "Voulez-vous vraiment supprimer l'emplacement du service"
        },
        table : {
            heading1 : "NOM DU LIEU DE SERVICE",
            heading2 : "NOM DU CLIENT",
            heading3 : "Statut",
            heading4 : "Division SP",
            heading5 : "Actions",
            heading6 : "SOUS-EMPLACEMENTS",
            heading7 : "SECTIONS"
        },
        employePlacholder : "Sélectionnez les employés",
        clientPlaceholder : "Sélectionnez des clients",
        divisionPlaceholder : "Sélectionnez la section"
    },

    serviceDetail : {
        tabs : {
            tab1 : "Informations générales",
            tab2 : "Sous-emplacements",
            tab3 : "Inspections",
            tab4 : "Bons de travail",
            tab5 : "Périodiques",
            tab6 : "Horaire",
            tab7 : "Rapports"
        },

        labels : {
            label1 : "Adresse",
            label2 : "CLIENTE",
            label3 : "Niveau SP",
            label4 : "Statut",
            label5 : "FUSEAU HORAIRE",
            label6 : "Remarques",
            label7 : "Division SP",
            label8 : "Niveau client",
            label9 : "Division client"
        },
        QuestioBlock : {
            title : "Modèles de questions personnalisées"
        }
    },
    AddContact: {
        title: 'Ajouter le contact',
        editTitle : 'Modifier le contact',
        genralTitle: 'INFORMATIONS GÉNÉRALES',
        Errors : {
            e1 : "Veuillez entrer le nom du contact.",
            e2 : "Veuillez saisir un nom de contact valide.",
            e3 : "Veuillez entrer le nom du contact min 1 ou max 50 caractères.",
            e4 : "Veuillez saisir votre e-mail.",
            e5 : "Veuillez saisir votre email dans un format valide.",
            e6 : "s'il vous plaît entrer un numéro de téléphone valide.",
            e7 : "Veuillez sélectionner le client.",
            e8 : "Veuillez sélectionner le lieu de service.",
            e9 : "Veuillez saisir des notes valides.",
            e10 : "Veuillez sélectionner le niveau.",
            e11 : "Veuillez sélectionner la division.",
            e12 : "Veuillez sélectionner le poste.",
            e13 : "Veuillez sélectionner le modèle d'autorisation de contact."
        },
        fields: {
            'contactName': 'Nom du contact*',
            'email': 'Adresse e-mail*',
            'phone': 'Numéro de téléphone',
            'checkbox1': "Activer l'accès à la connexion",
            'checkbox2': 'Activer les notifications',
            'client': 'Cliente*',
            'service': 'Emplacement du service*',
            'notes' : 'Remarques',
            Templates : "Modèle d'autorisation de contact*"
        },
        placeholder : {
            'contactName': 'Par exemple. Adam Smith',
            'email': 'Par exemple. adamsmith@email.tsum',
            'phone': 'Par exemple. +1 ззз ччь 5555',
            'client': 'Sélectionnez un client',
            'service': "Sélectionnez l'emplacement du service",
            'notes' : "Entrez les notes ici",
            Templates : "Sélectionnez le modèle d'autorisation de contact"
        },
        NotificationSettings : {
            label1 : "Notifications par email",
            text1 : "Recevoir une notification par e-mail",
            label2 : "Notifications dans l'application",
            text2 : "Recevoir une notification dans l'application",
           
        }
    },
    contactList : {
        title: 'Contacts',
        importButton : "Importer",
        addButton: 'Ajouter le contact',
        searchPlaceholder: 'Rechercher un contact',
        DeactivatePopup : {
            title : "Désactiver le contact",
            text1 : "Êtes-vous sûr de vouloir désactiver le contact",
        },
        ActivatePopup : {
            title : "Activer le contact",
            text1 : "Êtes-vous sûr de vouloir activer le contact",
        },
        DeletePopup : {
            title : 'Supprimer le contact',
            text2 : "Êtes-vous sûr de vouloir supprimer le contact"
        },
        table : {
            heading1 : "Nom du contact",
            heading2 : "Numéro de téléphone",
            heading3 : "Cliente",
            heading4 : "EMPLACEMENT DU SERVICE",
            heading5 : "Statut",
            heading6 : "Actions",
            heading7 : "Email du contact",
            heading8 : "Positionner",
            heading9: "DIVISION"
        }
    },

    contactDetail : {
        tabs : {
            tab1 : "Informations générales",
            tab2 : "Autorisations",
            tab3 : "Paramètres de notification"
        },

        labels : {
            label1 : "Cliente",
            label2 : "NOM DU LIEU DE SERVICE",
            label3 : "Adresse e-mail",
            label4 : "Téléphone",
            label5 : "Statut",
            label6 : "Remarques",
            label7 : "Adresse"
        },
        NotificationSettings : {
            title : "Paramètres de notification",
            label1 : "E-mail",
            text1 : "Recevoir des notifications par e-mail",
            label2 : "Dans l'APP",
            text2 : "Recevez des notifications dans l'application"
        }
    },

    Archives : {
        title : "Les archives",
        tabs : {
            tab1 : "Clientes",
            tab2 : "Emplacements de service",
            tab3 : "Contacts",
            tab4 : "Des employés",
            tab5 : "Administrateur de l'entreprise"
        },
        contactTooltip1 : "Emplacement du service :",
        contactTooltip2 : "associé à ce contact est désactivé. Veuillez d'abord réactiver l'emplacement du service.",
        serviceTooltip1 : "Cliente :",
        serviceTooltip2 : "associé à cet emplacement de service est désactivé. Veuillez d'abord réactiver le client.",
        templatePopup : {
            title : "Attribuer un modèle d'autorisation",
            text1 : "Modèle d'autorisation n'est plus disponible.",
            fields : {
                label1 : "Choisissez un modèle d'autorisation*"
            }
        }
    },

    
    AddEmploye: {
        title: "Ajouter un employé",
        editTitle : "Modifier l'employé",
        genralTitle: "INFORMATIONS GÉNÉRALES",
        Errors : {
            e1 : "Veuillez entrer le nom de l'employé.",
            e2 : "Veuillez saisir un nom d'employé valide.",
            e3 :  "Veuillez entrer le nom du contact min 1 ou max 50 caractères.",
            e4 : "Veuillez saisir votre e-mail.",
            e5 : "Veuillez saisir votre email dans un format valide.",
            e6 : "S'il vous plaît entrer un numéro de téléphone valide.",
            e7 : "Veuillez saisir des notes valides.",
            e8 : "Veuillez sélectionner le niveau.",
            e9 : "Veuillez sélectionner la division.",
            e10 : "Veuillez sélectionner le poste.",
            e11 : "Veuillez sélectionner le modèle d'autorisation des employés.",
            e12 : "Cette division est déjà ajoutée."
        },
        fields: {
            'EmployeeName': "Nom de l'employé*",
            'email': 'Adresse e-mail *',
            'phone': 'Numéro de téléphone',
            'secondaryAdmin': 'Cet employé est-il administrateur de la société secondaire?',
            'permissions': 'Autorisations',
            "Yes" : 'Oui',
            "No" :'Non',
            'checkbox1': "Activer l'accès à la connexion",
            'checkbox2': "Activer les notifications",
            'checkbox3': 'Activer les inspections',
            'checkbox4': "Activer les actions sur l'ordre de travail",
            'language' : "Langue préférée",
            'notes' : 'Remarques',
            Templates : "Modèle d'autorisation d'employé*"
        },
        placeholder : {
            'EmployeeName': 'E.g. Adam Smith',
            'email': 'E.g. adamsmith@email.com',
            'phone': 'E.g. +1 333 444 5555',
            'notes' : 'Enter notes here',
            Templates : "Sélectionnez le modèle d'autorisation d'employé"
        },
        postions: {
         
            alertPopup : {
                title : "Alerte",
                text : "Vous avez déjà attribué un poste à cet employé. Voulez-vous vraiment ajouter d'autres postes?"
            }
        },
        steps: {
            step1: "Détails Généraux",
            step2: "Détails du modèle d'autorisation"
        },
    },
 
    commonPostion : {
        title : "Attribution de poste",
        subtitle : "L'attribution des détails du niveau de poste à votre administrateur reflétera la position hiérarchique dans l'organigramme.",
        fields : {
            level : "Niveau*",
            division: "Division*",
            position : "Positionner*"
        },
        placeholder : {
            level : "Choisir le niveau",
            division : "Sélectionnez une division",
            position : "Sélectionnez la position"
        },

        postions: {
            title : "Attribution de poste",
            table : {
                heading1 : "Niveau",
                heading2 : "Division",
                heading3 : "Positionner"
            }
        }

},

    employeList : {
        title: "Des employés",
        importButton : "Importer",
        addButton: "Ajouter un employé",
        searchPlaceholder: "Rechercher un employé",
        DeactivatePopup : {
            title : "Désactiver l'employé",
            text1 : "Êtes-vous sûr de vouloir désactiver l'employé",
        },
        ActivatePopup : {
            title : "Activer le employé",
            text1 : "Êtes-vous sûr de vouloir activer l'employé",
        },
        DeletePopup : {
            title : "Effacer le employé",
            text2 : "Êtes-vous sûr de vouloir supprimer l'employé"
        },
        table : {
            heading1 : "Nom de l'employé",
            heading2 : "Niveau",
            heading3 : "DIVISION SP",
            heading4 : "POSITIONNER",
            heading5 : "Statut",
            heading6 : "Actions",
            heading7 : "Adresse e-mail",
            heading8 : "Positionner"
        }
    },

    
    EmployeDetail : {
        tabs : {
            tab1 : "Informations générales",
            tab2 : "Autorisations",
            tab3 : "Paramètres de notification"
        },

        labels : {
            label1 : "Adresse e-mail",
            label2 : "Téléphoner",
            label3 : "ADRESSE",
            label4 : "Type d'employé",
            label5 : "PREFERRED LANGUAGE",
            label6 : "Statut",
            label7 : "Remarques"
        },

      
    },

    clientOrg : {
        title:"Ajouter un niveau",
        editTitle : "Modifier le niveau",
        addLevelButton : "Ajouter un niveau",
        addDivisionButton : "Ajouter une division",
        ExpandAll : "Développer tout",
        CollapseAll  : "Tout réduire",
        fields : {
            name : "Nom du niveau*",
            position: "Positionner*",
            helpText : "Remarque: la position de ce niveau ne peut pas être modifiée car des divisions lui sont associées"
        },
        placeholder : {
            name : "Par exemple. Wall Mart",
            position : "Sélectionnez la position"
        },
        AddLevel : {
            Errors : {
                e1 : "Veuillez entrer le nom du niveau.",
                e2 : "Veuillez saisir un nom de niveau valide.",
                e3 : "Veuillez entrer le nom du niveau max 50 caractères.",
                e4 : "Veuillez sélectionner le poste."
            }  
        },
        addDvision: {
            title : "Ajouter une division",
            editTitle : "Modifier la division",
            Errors : {
                e1 : "Veuillez entrer le nom de la division.",
                e2 : "Veuillez entrer le nom de division valide.",
                e3 : "Veuillez entrer le nom de la division max 50 caractères.",
                e4 : "Veuillez sélectionner le niveau.",
                e5 : "Veuillez sélectionner la division mère.",
            },
            fields: {
                name : "Nom de la division*",
                level : "Niveau*",
                reports : "Rapports à*"
            },
            placeholder : {
                name : "Par exemple. Wall Mart",
                position : "Sélectionnez la position",
                divison : "Sélectionnez une division"
            },
        },
        levelsDetail: {
            title : "Les divisions",
            searchPlaceholder : "Division de la recherche",
            actions : {
                edit : "Modifier le niveau",
                delete : "Supprimer le niveau",
                editDivison :"Modifier la division",
                deleteDivison :"Supprimer la division"
            },
            table : {
                heading1 : "Contact Name",
                heading2 : "Actions",
            },
            DeletePopup : {
                title:"Supprimer le niveau",
                text:"Êtes-vous sûr de vouloir supprimer ce niveau?"
            },
            DeleteDivison: {
                title:"Supprimer la division",
                text:"Suppression",
                text1:"transférera tous ses postes, clients et emplacements de service à la division mère.",
                text2:"Voulez-vous vraiment supprimer cette division?"
            }
        },
        divisionDetail : {
            tabs : {
                client : "Contacts clients",
                service : "Emplacements de service"
            },
            actions : {
                editDivison :"Modifier la division",
                deleteDivison :"Supprimer la division",
                editContact :"Modifier le contact",
                deleteContact :"Effacer le contact",
                editService :"Modifier l'emplacement du service",
                deleteService :"Supprimer le service",
                deactivateService : "Désactiver l'emplacement de service",
                activateContact : "Activer le contact",
                activateService : "Activer l'emplacement du service",
                deactivateContact : "Supprimer l'emplacement du service",
            },
        }
    },
    spOrg : {
        title:"Organisation du fournisseur de services",
        addLevelButton : "Nouveau niveau",
        addDivisionButton : "Nouvelle division",
        ExpandAll : "Développer tout",
        CollapseAll  : "Tout réduire",
        AddLevel : {
            leveltitle : "Ajouter un niveau",
            leveleditTitle : "Modifier le niveau",
            fields : {
                name : "Nom du niveau*",
                position: "Positionner*",
                helpText : "Note: Position of this level cannot be changed as there are divisions associated with it"
            },
            placeholder : {
                name : "Par exemple. Wall Mart",
                position : "Sélectionnez la position"
            },
        },
        divisionDetail : {
            tabs : {
                employe : "Des employés",
                client : "Clientes",
                service : "Emplacements de service"
            },
            actions : {
                editEmployee : "Modifier l'employé",
                deleteEmployee : "Supprimer l'employé",
                activateEmployee : "Activer l'employé",
                deactivateEmployee : "Désactiver l'employé",
                editService :"Modifier le service",
                deactivateService : "Désactiver le service",
                activateService : "Activer le service",
                deleteService :"Supprimer le service",
                editClient : "Modifier le client",
                deleteClient : "Supprimer le client",
                activateClient : "Activer le client",
                deactivateClient : "Désactiver le client"
            },
        }
    },
    configuration : {
        title : "Configuration",
        fields : {
            Positions : "Postes",
            Area : "Configuration de la zone",
            Permissions : "Autorisations",
            Questions : "Questions personnalisées"
        },
        areaConfiguration: {
            title : "Configuration de la zone",
            fields : {
                Templates : "Modèles de zone",
                Services : "Prestations de service",
                Rating : "Modèle d'évaluation",
                Sublocation : "Modèles d'affectation de service de sous-location",
                inspection : "Types de modèles d'inspection",
                Mobile : "Modèles d'enquêtes mobiles",
                Summary : "Résumé des couleurs « En un coup d'œil »",
                Report : "Rédacteur de rapport"

            }
        }
    },
    postions : {
        title : "Postes",
        addButton : "Ajouter une position",
        searchPlaceholder : "Position de recherche",
        filter : {
            client : "Cliente",
            service : "Fournisseur de services",
            status : "Type de connexion"
        },
        DeletePopup : {
            title: "Supprimer la position",
            text2 : "Êtes-vous sûr de vouloir supprimer la position"
        },
        addPostion : {
            title : "Ajouter une position",
            edittitle : "Modifier la position",
            genralTitle : "Informations générales",
            Errors : {
                e1 : "Veuillez saisir le nom du poste.",
                e2 : "Veuillez saisir un nom de poste valide.",
                e3 : "Veuillez entrer le nom du poste max 50 caractères.",
                e4 : "Veuillez sélectionner le type de connexion.",
                e5 : "Veuillez entrer une description valide."
            },
            fields : {
                name : "Nom du poste*",
                type: "Type de connexion*",
                notes : "La description",
                checkbox : "Directement responsable de l'emplacement du service"
            },
            placeholder : {
                name : "Par exemple. Chef de secteur",
                type : "Sélectionnez le type de connexion",
                notes : "Entrez la description ici"
            }
        }
    },
    postionsList: {
        table : {
            heading1 : "Nom du poste",
            heading2 : "Type de connexion",
            heading3 : "Actions"
        }
    },
    postionDetail : {
        tabs : {
            tab1 : "Informations générales"
        },
        labels: {
            label1 : "Type de connexion",
            label2 : "Directement responsable de l'emplacement du service",
            label3 : "La description"
        },
        positionEmployees : {
            title: "Des employés"
        },
        positionContacts : {
            title: "Contacts"
        }
    },
    Services: {
        title : "Prestations de service",
        searchPlaceholder : "Rechercher des services",
        addButton : "Ajouter un service",
        AddServices : {
            title : "Ajouter un service",
            edittitle : "Modifier le service",
            genralTitle: "Informations générales",
            Errors : {
                e1 : "Veuillez saisir le nom du service.",
                e2 : "Veuillez saisir un nom de service valide.",
                e3 : "Veuillez entrer le nom du service max 50 caractères.",
                e4 : "Veuillez entrer le poids.",
                e5 : "Veuillez saisir uniquement des chiffres.",
                e6 : "Veuillez entrer une description valide."
            },
            fields : {
                Name : "Nom du service*",
                Weight : "Poids de service*",
                Description : "La description"
            },
            placeholder: {
                Name : "Par exemple. Cloisons en verre",
                Weight : "Par exemple. dix",
                Description : "Entrez la description ici"
            }
        },
        table: {
            heading1 : "Prestations de service",
            heading2 : "POIDS DE SERVICE",
            heading3 : "Actions"
        },
        DeletePopup : {
            title: "Supprimer le service",
            text2 : 'Êtes-vous sûr de vouloir supprimer le service'
        },
        ServicesDetail: {
            title : "Détails du service",
            labels: {
                label1 : "Un service",
                label2 : "Poids de service",
                label3 : "La description"
            },
            Areas : {
                title : "Modèle de zone associée",
                searchPlaceholder : "Modèle de zone de recherche",
                table : {
                    heading1: "Nom du modèle",
                    heading2: "Surface",
                    heading3: "Actions"
                }
            }
        }
    },

    AreaTemplate : {
        title : "Modèles de zone",
        addButton : "Ajouter un modèle de zone",
        searchPlaceholder : "Modèle de zone de recherche",
        table :{
            heading1: "Nom du modèle",
            heading2 : "Surface",
            heading3 : "Actions"
        },
        AddArea : {
            title : "Ajouter un modèle de zone",
            editTitle : "Modifier le modèle de zone",
            genralTitle: "Informations générales",
            toolTip: "Sélectionnez n'importe quel modèle existant pour importer tous ses services et zones pour le nouveau modèle de zone",
            addButton : "Ajouter une zone",
            steps : {
                step1 : "Détails du modèle",
                step2 : "Détails de la zone et du service"
            },
            Errors: {
                e1 : "Veuillez entrer le nom de la zone.",
                e2 : "Veuillez entrer le nom de zone valide.",
                e3 : "Veuillez entrer le nom de la zone max 50 caractères.",
                e4 : "Veuillez entrer une description valide."
            },
            fields : {
                Name : "Nom du modèle*",
                Clone : "Modèle de clone",
                Description: "La description"
            },
            placeholder : {
                Name : "Par exemple. Les archives",
                Clone : "Sélectionner un modèle",
                Description : "Entrez la description ici"
            },
            table: {
                heading1 : "Prestations de service",
                heading2 : "POIDS DE SERVICE",
                heading3 : "Actions"
            },
            addPopup : {
                title : "Ajouter une zone",
                editTitle : "Zone d'édition",
                Errors : {
                    e1  : "Veuillez entrer le nom de la zone.",
                    e2  : "Veuillez sélectionner le modèle d'évaluation.",
                    e3 : "Veuillez entrer le nom de zone valide.",
                    e4 : "Veuillez entrer le nom de la zone max 50 caractères.",
                    e5 : "Veuillez sélectionner les services."
                },
                fields : {
                    Name : "Nom de la zone*",
                    Services: "Prestations de service*",
                    Rating : "Modèle d'évaluation*"
                },
                placeholder : {
                    Name : "Par exemple. Les archives",
                    Services : "Sélectionnez les services",
                    Rating : "Sélectionner un modèle"
                },
            },
            DeleteAreaPopup : {
                title: "Supprimer la zone",
                text2 : 'Êtes-vous sûr de vouloir supprimer la zone'
            },
            DeleteServicePopup : {
                title: "Supprimer le service",
                text2 : 'Êtes-vous sûr de vouloir supprimer le service'
            },
        },
        alertPopup : {
            title : "Alerte",
            text1 : "C'est le dernier service attribué à",
            text2 : "et ne peut donc pas être supprimé."
        },
        DeleteAreaPopup : {
            title : "Supprimer le modèle de zone",
            text1 : "La suppression supprimera ce modèle de zone de la liste.",
            text2 : "Êtes-vous sûr de vouloir supprimer ce modèle de zone",
           
        }
    },

    AreaTemplateDetail: {
        tabs: {
            tab1: "Informations générales",
            tab2: "Emplacements de service associés"
        },
        labels : {
            label1 : "La description"
        },
        areas : {
            title : "Zones"
        }
    },
    Sublocation : {
        addButton : "Ajouter un sous-emplacement",
        searchPlaceholder : "Recherche de sous-emplacement",
        serviePlaceholder : "Sélectionnez l'emplacement du service",
        clientPlaceholder : "Sélectionnez des clients",
        addSubLocation: {
            title : "Ajouter un sous-emplacement",
            edittitle : "Modifier le sous-emplacement",
            genralTitle : "Informations générales",
            Errors : {
                e1 : "Veuillez entrer le nom.",
                e2 : "Veuillez saisir un nom valide.",
                e3 : "Veuillez entrer le nom max 50 caractères."
            },
            fields : {
                Name : "Nom du sous-emplacement*",
                RadioLabel : "Ce sous-emplacement a-t-il une section ?",
                Yes : "Oui",
                No : "Non",
                Template : "Modèle de zone",
                SectionName : "Nom de la section*"
            },
            placeholder : {
                Name : "Par exemple. 1er étage",
                Template : "Modèle de zone de recherche",
                SectionName : "Par exemple. couloir"
            }
        },
        table : {
            heading1 : "Sous-emplacement",
            heading2 : "Section",
            heading3 : "MODÈLE DE ZONE",
            heading4 : "SURFACE",
            heading5 : "Statut",
            heading6 : "Actions",
            heading7 : "EMPLACEMENT DES SERVICES",
            heading8 : "CLIENTE"
        },
        DeactivatePopup: {
            title: 'Désactiver le sous-emplacement',
            text1: 'Êtes-vous sûr de vouloir désactiver le sous-emplacement',
        },
        ActivatePopup: {
            title: 'Activer la sous-location',
            text1: 'Êtes-vous sûr de vouloir activer le sous-emplacement',
        },
        DeletelocationPopup: {
            title: "Supprimer le sous-emplacement",
            text2: "Êtes-vous sûr de vouloir supprimer ce service de la sous-location"
        },
        DeleteSectionPopup: {
            title: "Supprimer la section",
            text2: "Êtes-vous sûr de vouloir supprimer cette section"
        }
    },
    RatingTemplate: {
        title : "Modèle d'évaluation",
        searchPlaceholder : "Modèle d'évaluation de la recherche",
        addButton : "Ajouter un modèle d'évaluation",
        SPDefault: "SP par défaut",
        table : {
            heading1 : "NOM DE L'ÉVALUATION",
            heading2 : "ÉTIQUETER",
            heading3 : "POUR CENT",
            heading4 : "DÉFICIENCE"
        },
        AddRatingTemplate : {
            title : "Ajouter un modèle d'évaluation",
            editTitle : "Modifier le modèle d'évaluation",
            genralTitle : "Informations générales",
            ratingTitle : "Évaluations",
            ratingSubTitle : "Créer et gérer des évaluations personnalisées à des fins d'inspection.",
            Errors: {
                e1 : "Veuillez saisir le nom du modèle.",
                e2 : "Veuillez saisir un nom de modèle valide.",
                e3 : "Veuillez entrer le nom du modèle de 50 caractères maximum.",
                e4 : "Veuillez entrer une description valide.",
                e5 : "Veuillez entrer le nom.",
                e6 : "Veuillez saisir le libellé.",
                e7 : "Veuillez saisir uniquement des caractères alphanumériques.",
                e8 : "Veuillez entrer le nom de l'étiquette max 5 caractères.",
                e9 : "Veuillez entrer le pourcentage.",
                e10 : "Veuillez n'entrer que des chiffres.",
                e11 : "Veuillez entrer 0 à 100 seulement.",
                e12 : "Veuillez sélectionner la couleur."
            },
            fields : {
                Name : "Nom du modèle d'évaluation*",
                Description : "La description",
                Provider : "Valeur par défaut pour le fournisseur de services",
                placeholder : {
                    Name : "Par exemple. 1-5 Note",
                    Description : "Entrez la description ici"
                },
                Rating : {
                    label1 : "Nom",
                    label2 : "Étiquette de notation",
                    label3 : "Pour cent",
                    label4 : "Couleur",
                    label5 : "Déficiente",
                    placeholder: {
                        label1 : "Par exemple. Impeccable et bien rangé",
                        label2 : "Par exemple. 1",
                        label3 : "Par exemple. 100"
                    }
                }
            },
            defaultFields : {
                label1 : "N'est pas applicable"
            },
            PrimayPopup: {
                title: 'Le fournisseur de services par défaut existe déjà !',
                text: "En sélectionnant ceci, vous remplacez l'existant",
                text2: "modèle d'évaluation.",
                text3: "Êtes-vous sur de vouloir continuer?"
            },
        },
        DeactivatePopup: {
            title: "Désactiver le modèle d'évaluation",
            text1: "Êtes-vous sûr de vouloir désactiver ce modèle d'évaluation",
        },
        ActivatePopup: {
            title: "Activer le modèle d'évaluation",
            text1: "Êtes-vous sûr de vouloir activer ce modèle d'évaluation",
        },
        DeletePopup: {
            title: "Supprimer l'évaluation",
            text2: "Êtes-vous sûr de vouloir supprimer l'évaluation."
        },
        ReplacementPopup : {
            title : "Désactiver le modèle d'évaluation",
            subTitle : "est utilisé par plusieurs modèles de zone.",
            note : "Pour désactiver ce modèle d'évaluation, sélectionnez son remplacement ci-dessous:",
            Errors : {
                e1 : "Veuillez sélectionner le modèle d'évaluation."
            },
            fields : {
                ratingTemplate : "Modèle d'évaluation*",
                placeholder : {
                    ratingTemplate : "Sélectionner un modèle"
                }
            },
            text : "Êtes-vous sûr de vouloir désactiver",
            replaceButton : "Oui, remplacer et désactiver"
        }
    },
    Inspections : {
        AddInspections: {
            title : "Ajouter une inspection",
            editTitle : "Modifier l'inspection",
            Errors : {
                e1 : "Veuillez sélectionner le client.",
                e2 : "Veuillez sélectionner l'emplacement du service.",
                e3 : "Veuillez sélectionner le type d'inspection.",
                e4 : "Veuillez sélectionner la fréquence.",
                e5 : "Veuillez entrer une description valide.",
                e6 : "Veuillez sélectionner la date.",
                e7 : "Veuillez sélectionner la semaine.",
                e8 : "Veuillez sélectionner le mois de début.",
                e9 : "Veuillez sélectionner l'année de départ.",
                e10 : "Veuillez sélectionner les dates du mois.",
                e11 : "Veuillez sélectionner des semaines."
            },
            fields : {
                Client : "Cliente*",
                Service : "Emplacement des services*",
                InspectionType : "Type d'inspection*",
                InspectionDescription : "Description de l'inspection",
                StartTime : "Heure de début",
                EndTime : "Heure de fin",
                Frequency : "La fréquence*",
                DueOn :"Échéance le*",
                Reminders : "Rappels",
                Event : "Jours avant l'événement",
                Days : "Journées",
                Hours : "Les heures",
                Minutes : "Minutes",
                StartDate : "Date de début*",
                EndDate : "Prend fin",
                OnDays : "Un jour",
                Repeat : "Répéter chaque",
                EndDateOpt : [
                    {
                        label1: "Au",
                        label2 : "Jamais"
                    }
                ],
                AllWeekdays : "Tous les jours de la semaine",
                AllWeekends : "Tous les week-ends",
                StartingMonth : "Mois de début",
                StartingYear : "Année de début",
                OnDate : "À la date",
                SelectWeek : "Sélectionnez la semaine",
                SelectDate : "Sélectionner une date",
                WeekDays : [
                    {
                    label1 : "Soleil",
                    label2 : "lun",
                    label3 : "Mar",
                    label4 : "mer",
                    label5 : "L'automne",
                    label6 : "ven"
                    }
                ]
            },
            placeholder : {
                Client : "Sélectionner un client",
                Service : "Sélectionnez l'emplacement du service",
                InspectionType : "Sélectionnez l'inspection",
                InspectionDescription : "Ajoutez une description ici",
                Frequency : "Sélectionnez la fréquence",
                DueOn : "Sélectionner une date",
                Days : "Sélectionnez les jours",
                Hours : "Sélectionnez les heures",
                Minutes : "Sélectionnez Minutes",
                Date : "Sélectionner une date",
                Repeat : "Sélectionnez la semaine",
                Select : "Sélectionner",
                RepeatMonth : "Sélectionnez un mois",
                SelectWeek : "Sélectionnez la semaine",
            }
        },
        title: "Contrôles",
        addButton: "Ajouter une inspection",
        importButton: "Importer",
        searchPlaceholder: "Inspection de recherche",
        tabs: {
            tab1: "En attente",
            tab2: "Enregistrée",
            tab3: "Complété",
            tab4 : "Enregistrée",
            tab5 : "Sans surveillance"
        },
        statusFilter: {
            status: 'Statut : Tous',
            all: "Toute",
            Claimed: 'Revendiqué',
            Unclaimed: 'Non réclamés',
        },
        typeFilter: {
            status: "Type d'inspection",
            genral: "Inspection générale",
            health: 'Inspection de la santé et de la sécurité',
        },
        table: {
            heading1: "ID D'INSPECTION",
            heading2: "EMPLACEMENT DES SERVICES",
            heading3: "REVENDIQUÉE PAR",
            heading4: "ÉCHÉANCE ON",
            heading5: "CRÉÉ PAR",
            heading6: "TYPE D'INSPECTION",
            heading7: "Actions",
            heading8 : "Enregistrée",
            heading9 : "Taper"
            
        },
        DeletePopup: {
            title: "Delete Inspection",
            text2: "Êtes-vous sûr de vouloir supprimer l'inspection."
        },
        DeleteInpection : {
            fields : {
                text1 : "Sélectionnez les événements de",
                text2 : "vous voulez supprimer.",
                opt1 : "Supprimer cet événement uniquement",
                opt2 : "Supprimer cet événement et tout ce qui suit"
            }
        },
        InspectionsPool : {
            tabs: {
                tab1: "Bassin d'inspection",
                tab2: "Revendiqué",
                tab3: "Enregistrée",
                tab4: "Terminé"
            },
        },
        ClaimPopup : {
            title : "Inspection des réclamations",
            text1 : "Réclamer l'inspection ",
            text2 : "sera planifier l'inspection sur votre calendrier pour",
            text3 : "Êtes-vous sûr de vouloir réclamer cette inspection?"
        },
        UnClaimPopup : {
            title : "Inspection non réclamée",
            text1 : "Inspection sans réclamation",
            text2 : "le renverra au pool d'inspection",
            text3 : "Êtes-vous sûr de vouloir annuler cette inspection?"
        },
        ChangePopup : {
            title : "Modifier la date d'inspection",
            DueOn: "Échéance le*",
        },
        Completed : {
            From : "Partir de la date",
            To : "À ce jour",
            table: {
                heading1: "EMPLACEMENT DES SERVICES",
                heading2: "REVENDIQUÉE PAR",
                heading3: "ÉCHÉANCE ON",
                heading4: "TERMINÉ LE",
                heading5: "CRÉÉ PAR",
                heading6: "TYPE D'INSPECTION"
            },
        },
        Unattended : {
            table: {
                heading1: "EMPLACEMENT DES SERVICES",
                heading2: "REVENDIQUÉE PAR",
                heading3: "ÉCHÉANCE ON",
                heading4: "A expiré le",
                heading5: "CRÉÉ PAR",
                heading6: "TYPE D'INSPECTION",
                heading7: "Enregistrée",
                heading8 : "Actions"
            },
        },
         UnattendedDetail : {
            labels: {
                label1 : "ÉCHÉANCE ON",
                label2 : "A expiré le",
                label3 : "Type d'inspection",
                label4 : "CRÉÉ PAR",
                label5 : "REVENDIQUÉE PAR",
                label6 : "CLIENTE",
                label7 : "EMPLACEMENT DES SERVICES",
                label8 : "ADRESSE",
                label9 : "DÉTAILS SUPPLÉMENTAIRES",
            }
        },
        CompletedDetail : {
            tabs: {
                tab1: "Détails de l'inspection",
                tab2: "Détails du score",
                tab3: "Détails de la signature",
                tab4 : "Modèles de questions personnalisées"
            },
            labels: {
                label1 : "ÉCHÉANCE ON",
                label2 : "Terminé le",
                label3 : "TOTAUX D'INSPECTION",
                label4 : "NOTES D'INSPECTION",
                label5 : "Type d'inspection",
                label6 : "CRÉÉ PAR",
                label7 : "INSPECTÉ PAR ",
                label8 : "CLIENTE",
                label9 : "EMPLACEMENT DES SERVICES",
                label10 : "ADRESSE",
                label11 : "DÉTAILS SUPPLÉMENTAIRES",
                label12  : "ZONES INSPECTÉES"
            },
            Services : {
                table : {
                    heading1 : "Prestations de service",
                    heading2 : "Évalué",
                    heading3 : "Évaluation",
                    heading4 : "But",
                    heading5 : "But%",
                    heading6 : "Note d'inspection",
                    heading7 : "Images téléchargées"
                }
            },
            SignOff : {
                clientTitle : "Détails de signature par contact",
                inspectorTitle : "Détails d'approbation par l'inspecteur",
                labels : {
                    label1 : "NOM DU CONTACT",
                    label2 : "NOTES DE CONTACT",
                    label3 : "CONTACT SIGNATURE",
                    label4 : "NOM DE L'INSPECTEUR",
                    label5 : "NOTES DE L'INSPECTEUR",
                    label6 : "SIGNATURE DE L'INSPECTEUR"
                }
            }
        }
    },
    InspectionDetail : {
        tabs: {
            tab1: "Informations générales",
            tab2 : "Rapports"
        },
        labels : {
            label1 : "CLIENTE",
            label2 : "ADRESSE",
            label3 : "EMPLACEMENT DES SERVICES",
            label4 : "REVENDIQUÉE PAR",
            label5 : "CRÉÉ PAR",
            label6 : "Type d'inspection",
            label7 : "ÉCHÉANCE ON",
            label8 : "Remarques",
            label9 : "NON RÉCLAMÉ PAR",
            label10 : "Enregistré le"
        }
    },
    Mycalendar : {
        Search : {
            title : "Mon calendrier",
            field : {
                label : "Lieux de services",
                clear : "Lieux de services",
                placeholder : "Rechercher des emplacements de service"
            },
            eventTypes : {
                label : "Type d'événement",
                opt1 : "Toute",
                opt2 : "Contrôles",
                opt3 : "Work Orders",
                opt4 : "Periodics",
                opt5 : "Inspections non réclamées",
                opt6 : "Inspections réclamées"
            }
        },
        Types : {
            label1 :"Inspection réclamée",
            label2 : "Demande de service",
            label3 : "Périodique",
            label4 : "Inspection non réclamée"
        },
        AddEvent : {
            title : "Ajouter un évènement",
            editTitle : "Modifier l'événement",
            tabs : {
                tab1 : "Inspection",
                tab2 : "Demande de service",
                tab3 : "Périodique"
            }
        }
    },
    Notification : {
        title : "Notifications",
        MarkRead : "tout marquer comme lu",
        LoadButton : "Charger plus de notifications"
    },
    WorkOrder : {
        title : "Ordres de travail",
        addButton : "Ajouter un ordre de travail",
        searchPlaceholder : "Rechercher un client, un emplacement de service...",
        lastUpdated : "Dernière mise à jour par",
        lastUpdatedOptions : {
            opt1 : "Toute",
            opt2 : "Cliente",
            opt3 : "Sp"
        },
        WorkOrderOrign : "Origine de l'ordre de travail",
        WorkOrderOrignOptions : {
            opt1 : "Bons de travail SP",
            opt2 : "bons de travail des clients",
            opt3 : "Créé par inspection",
            opt4 : "Toute",
        },
        CreationDate : "Date de création",
        tabs : {
            tab1 : "Active",
            tab2 : "Complété"
        },
        table : {
            heading1 : "Sujette",
            heading2 : "Cliente",
            heading3 : "Origine de l'ordre de travail",
            heading4 : "DERNIÈRE MISE À JOUR PAR",
            heading5 : "DATE DE CRÉATION",
            heading6 : "Actions",
            heading7 : "Date d'achèvement",
        },
        EditInfo : {
            label1 : "Cliente",
            label2 : "Emplacement des services",
            label3 : "Sous-location",
            label4 : "Section"
        },
        AddWorkOrder : {
            title : "Ajouter un ordre de travail",
            editTitle : "Modifier l'ordre de travail",
            genralTitle : "Informations générales",
            sendTitle : "Envoyer les détails de l'ordre de travail à",
            tabs:{
                tab1 : "Employées",
                tab2 : "Contacts clients"
            },
            Errors : {
                e1 : "Veuillez sélectionner le client.",
                e2 : "Veuillez sélectionner l'emplacement du service.",
                e3 : "Veuillez sélectionner le sous-emplacement.",
                e4 : "Veuillez sélectionner la rubrique.",
                e5 : "Veuillez entrer la description.",
                e6 : "Veuillez sélectionner un modèle.",
                e7 : "Veuillez entrer une description valide.",
                e8 : "Veuillez entrer le sujet.",
                e9 : "Veuillez entrer un objet valide.",
                e10: "Veuillez entrer le sujet min 1 ou max 100 caractères.",
                e11: "Fichier trop volumineux, veuillez sélectionner un fichier de moins de 5 Mo.",
                e12: "Veuillez sélectionner un fichier valide.",
                e13 : "Max 5 fichiers autorisés."
            },
            fields : {
                Client : "Cliente*",
                Service : "Emplacement des services*",
                SubLocation : "Sous-emplacement*",
                Section : "Section*",
                Subject : "Matière*",
                Description :"La description*",
                Attachment : {
                    title : "Pièces jointes",
                    label1 : "Max. 5 photos peuvent être téléchargées",
                    label2 : "Joindre un fichier"
                },
                Template : "Sélectionnez un modèle d'autorisation"
            }
        },
        DeletePopup : {
            title : "Supprimer l'ordre de travail",
            text1 : "Êtes-vous sûr de vouloir supprimer le bon de travail ?"
        },
        MarkPopup : {
            title : "Modifier le statut de l'ordre de travail",
            text1 : "Êtes-vous sûr de vouloir marquer cet ordre de travail comme terminé?"
        },
        ReopenPopup : {
            text1 : "Voulez-vous vraiment marquer ce bon de travail comme rouvrir ?"
        }
    },
    WorkOrderDetail : {
        orderNumber : "Demande de service#:",
        subject : "Sujette:",
        labels : {
            label1 : "La description",
            label2 : "Cliente",
            label3 : "NOM DU LIEU DE SERVICE",
            label4 : "Date",
            label5 : "Sous-emplacement",
            label6 : "Section",
            label7 : "DEMANDE DE SERVICE#",
            label8 : "LA SOURCE",
            label9 : "CRÉÉ PAR",
            label10 : "ADRESSE",
            label11 : "Échéance le"
        },
        tabs : {
            tab1 : "Informations générales",
            tab2 : "Destinataires"
        },
        updateHistory : {
            title : "Last Updated History",
            table : {
                heading1 : "ACTION PAR",
                heading2 : "DATE/HEURE",
                heading3 : "DES DÉTAILS",
                heading4 : "Statut W.O.",
                heading5 : "Statut P.O."
            }
        },
        recipent : {
            label1 : "Des employés",
            label2 : "Contacts clients"
        },
        AttachmentBlock : {
            title : "Pièces jointes"
        },
        inspectionBlock : {
            title : "Informations sur l'inspection",
            labels : {
                label1 : "inspecté par",
                label2 : "Inspecté le",
                label3 : "But",
                label4 : "But %",
                label5 : "Évaluation"
            }
        }
    },
    Permission : {
        title : "Modèles d'autorisation",
        searchPlaceholder : "Modèle d'autorisation de recherche",
        addButton : "Ajouter un modèle d'autorisation",
        restoreLink : "Restaurer les autorisations de modèle par défaut",
        tabs : {
            tab1 : "Modèle d'autorisation d'employé",
            tab2 : "Modèle d'autorisation de contact"
        },
        table : {
            heading1 : "Type d'administrateur",
            heading2 : "La description",
            heading3 : "Actions"
        },
        DeletePopup: {
            title: "Supprimer l'autorisation",
            text2: "Êtes-vous sûr de vouloir supprimer l'autorisation."
        },
        AddPermission : {
            title : "Ajouter un modèle d'autorisation",
            editTitle : "Modifier le modèle d'autorisation",
            genralTitle : "Informations générales",
            typeLabel : "Taper",
            spEmployee : "Employé SP",
            clientContact : "Coordonnées clients",
            Errors: {
                e1 : "Veuillez entrer le nom de l'employé.",
                e2 : "Veuillez saisir un nom d'employé valide.",
                e3 : "Veuillez entrer le nom du contact min 1 ou max 50 caractères.",
                e4 : "Veuillez saisir une description valide."
            },
            fields : {
                Permission : "Nom du modèle d'autorisation*",
                Description : "La description",
                placeholder : {
                    Permission : "Par exemple. Inspecteurs GDI",
                    Description : "Entrez la description ici"
                }
            }
        },
        ModulePermissions : {
            title : "Autorisations de module",
            fields : {
                Name : "Nom du module",
                Permission : "Autorisation"
            }
        },
        labels : {
            label1 : "Nom du modèle d'autorisation",
            label2 : "La description"
        },
        EditPopup : {
            text : "Êtes-vous sûr de vouloir effectuer ces modifications. Notez que ces modifications affecteront également tous les employés/contacts existants associés à ce modèle"
        }
    },
    MyProfile : {
        title : "Mon profil",
        genralTitle : "réglages généraux",
        NotificationSettings: {
            label1 : "Notifications par email",
            text1 : "Recevoir une notification par e-mail",
            label2 : "Notifications dans l'application",
            text2 : "Recevoir une notification dans l'application",
            label3 : "Langue",
            label4 : "Authentification à deux facteurs",
            text3 : "Ajoutez une couche de sécurité supplémentaire",
            addDevice : "+ Ajouter un nouvel appareil"
        },
        PasswordSettings : {
            label : "Mot de passe",
            text : "Mettez à jour votre mot de passe",
            button : "Changer le mot de passe"
        },
        NotificationSettingsText : {
            label1 : "E-mail",
            text1 : "Recevoir des notifications par e-mail",
            label2 : "Dans l'APP",
            text2 : "Recevez des notifications dans l'application",
            
        },
        TwoFactorAuthModal : {
            title : "Authentification à deux facteurs",
            text : "Scannez le code QR ci-dessous à l'aide de votre application Authenticator sur laquelle vous souhaitez recevoir un code d'authentification à 6 chiffres.",
            fields : {
                label1 : "Code de vérification",
                label2 : "Nom de l'appareil"
            },
            placeholder : {
                label1 : "Par exemple. Samsung S11"
            },
            Options : {
                opt1 : "Numérisation impossible ?",
                opt1Link : "Recevoir le code par e-mail",
                opt2 : "Vous avez perdu votre appareil ?",
                opt2Link : "Ajouter un nouvel appareil"
            }
        },
        changePassword : {
            title : "Changez votre mot de passe",
            Errors : {
                e1 : "Veuillez entrer l'ancien mot de passe.",
                e2 : "Veuillez entrer le nouveau mot de passe.",
                e3 : "Le mot de passe doit comporter entre 6 et 15 caractères contenant uniquement des lettres alphabétiques, des chiffres ou des traits de soulignement.",
                e4: "Veuillez saisir à nouveau votre nouveau mot de passe.",
                e5 : "Réessayer! les deux mots de passe doivent correspondre."
            },
            fields : {
                label1 : "ancien mot de passe*",
                label2 : "nouveau mot de passe*",
                label3: "vérifier le nouveau mot de passe*"
            },
            placeholder : {
                label1 : "Entrez votre ancien mot de passe",
                label2 : "Entrez votre nouveau mot de passe",
                label3 : "Entrez à nouveau votre nouveau mot de passe"
            }
        }
    },
    Periodic : {
        AddPerodic : {
            title : "Add Periodic",
            genralTitle : "General Information"
        }
    },

    Periodic : {
        title : "Périodique",
        orderNumber : "Commande périodique#:",
        addButton : "Ajouter périodique",
        tabs : {
            tab1 : "En attente",
            tab2 : "Complété"
        },
        searchPlaceholder : "Rechercher un client, un emplacement de service...",
        lastUpdated : "Dernière mise à jour par",
        lastUpdatedOptions : {
            opt1 : "Toute",
            opt2 : "Cliente",
            opt3 : "Sp"
        },
        table : {
            heading1 : "Sujette",
            heading2 : "Cliente",
            heading3 : "Emplacement des services",
            heading4 : "DERNIÈRE MISE À JOUR PAR",
            heading5 : "Date d'échéance",
            heading6 : "Actions"
        },
        From : "Partir de la date",
        To : "À ce jour",
        DeletePopup: {
            title: 'Supprimer périodique',
            text1: 'Êtes-vous sûr de vouloir supprimer le périodique.'
        },
        MarkPopup : {
            title : "Modifier le statut périodique",
            text1 : "Voulez-vous vraiment marquer ce périodique comme terminé ?"
        },
        ReopenPopup : {
            text1 : "Voulez-vous vraiment marquer ce périodique comme rouvrir ?"
        },
        
        AddPerodic : {
            title : "Ajouter périodique",
            editTitle : "Modifier le périodique",
            genralTitle : "Informations générales",
            Errors: {
                e1 : "Veuillez sélectionner le client.",
                e2 : "Veuillez sélectionner l'emplacement du service.",
                e3 : "Veuillez sélectionner un modèle.",
                e4 : "Veuillez entrer une description valide.",
                e5 : "Veuillez entrer le sujet.",
                e6 : "Veuillez entrer un objet valide.",
                e7 : "Veuillez entrer le sujet min 1 ou max 100 caractères.",
                e8 : "Veuillez sélectionner la fréquence.",
                e9 : "Veuillez sélectionner la date.",
                e10 : "Veuillez sélectionner la semaine.",
                e11 : "Veuillez sélectionner le mois de début.",
                e12 : "Veuillez sélectionner l'année de départ.",
                e13 : "Veuillez sélectionner les dates du mois.",
                e14 : "Veuillez sélectionner des semaines.",
                e15 : "Fichier trop volumineux, veuillez sélectionner un fichier de moins de 5 Mo",
                e16 : "Max 5 fichiers autorisés.",
                e17 : "Veuillez sélectionner un fichier valide"
            },
            fields : {
                Client : "Cliente*",
                Service : "Emplacement des services*",
                Subject : "Sujette*",
                Description : "La description",
                Attachment : {
                    title : "Pièces jointes",
                    label1 : "Max. 5 photos peuvent être téléchargées",
                    label2 : "Joindre un fichier"
                },
                Template : "Sélectionnez un modèle d'autorisation"
            },
            placeholder : {
                Client : "Sélectionner un client",
                Service : "Par exemple. Place Scotia",
                Subject : "Entrer le sujet périodique",
                Description : "Entrez vos instructions de nettoyage ici"
            }
        },
        AttachmentBlock : {
            title : "Pièces jointes"
        }
    },
    CustomQuestions : {
        title : "Questions personnalisées",
        searchPlaceholder : "Recherche par nom de modèle",
        addButton : "Ajouter un modèle de questions personnalisées",
        table : {
            heading1 : "NOM DU MODÈLE",
            heading2 : "EMPLACEMENT DES SERVICES",
            heading3 : "Actions"
        },
        actions : {
            link1 : "Attribuer un emplacement de service"
        },
        DeletePopup: {
            title: 'Supprimer la question personnalisée',
            text2: 'Êtes-vous sûr de vouloir supprimer la question personnalisée'
        },
        DeleteFieldPopup: {
            title: 'Supprimer la question',
            text2: 'Êtes-vous sûr de vouloir supprimer la question'
        },
        DeleteOptionPopup: {
            title: "Supprimer l'option",
            text2: "Êtes-vous sûr de vouloir supprimer l'option"
        },
        ServicePopup : {
            title : "Ajouter un emplacement de service",
            label : "Emplacement des services*",
            placeholder : "Rechercher l'emplacement du service",
            Errors : {
                e1 : "Veuillez sélectionner les services."
            }
        }
    },
    AddCustomQuestion : {
        title : "Ajouter un modèle de question personnalisé",
        editTitle : "Modifier le modèle de question personnalisée",
        genralTitle : "Informations générales",
        addButton : "Ajouter une question",
        saveButton : "Enregistrer la question",
        editButton : "Mettre à jour la question",
        noAddedTitle : "Aucune question ajoutée",
        noAddedText : "Cliquez sur 'Ajouter une question' pour ajouter une question ici",
        Errors : {
            e1 : "Veuillez entrer la question.",
            e2 : "Veuillez entrer l'option.",
            e3 : "Veuillez entrer le nom du modèle de question.",
            e4 : "Veuillez entrer le modèle de question valide.",
            e5 : "Veuillez entrer le modèle de question max 50 caractères.",
            e6 : "Veuillez entrer une description valide.",
            e7 : "Fichier trop volumineux, veuillez sélectionner un fichier de moins de 5 Mo",
            e8 : "Max 5 fichiers autorisés.",
            e9 : "Veuillez sélectionner un fichier valide"
        },
        fields : {
            Name : "Nom du modèle de question personnalisé*",
            Description : "La description",
            questionType : "Type de question",
            questionType1 : "Réponse unique",
            questionType2 : "Réponses multiples",
            questionType3 : "Ouvrir le texte",
        },
        placeholder : {
            Name : "Par exemple. Question du sondage sur les bons de travail*",
            Description : "Entrez la description ici"
        },
        Attachment : {
            title : "Pièces jointes",
            label1 : "Max. 5 photos peuvent être téléchargées",
            label2 : "Joindre un fichier"
        },
        Questions : {
            title : "Des questions"
        },
        AddQuestion : {
            title : "Ajouter une question",
            editTitle: "Modifier la question",
            fields : {
                Type : "Type de question",
                TypeOption : {
                    opt1 : "Réponse unique",
                    opt2 : "Réponse multiple",
                    opt3 : "Texte ouvert"
                },
                Question : "Question*"
            },
            placeholder : {
                Question: "Décrivez votre question"
            },
            Options : {
                title : "Choix",
                placeholderText : "Entrez la valeur de l'option"
            },
            table : {
                heading1 : "Réponses",
                heading2 : "Actions"
            }
        }
    },
    CustomQuestionsDetail : {
        tabs : {
            tab1 : "Informations générales",
            tab2 : "Emplacements de service associés"
        },
        fields : {
            label1 : "Nom du modèle",
            label2 : "Description du modèle"
        },
        QuestioBlock : {
            title : "Des questions"
        },
        AttachmentBlock : {
            title : "Pièces jointes"
        },
        ServiceBlock : {
            searchPlaceholder : "Recherche par Service Location ou Cl..",
            addButton : "Ajouter un emplacement de service",
            table : {
                heading1 : "EMPLACEMENT DES SERVICES",
                heading2 : "CLIENTE",
                heading3 : "Actions",
            },
            DeletePopup: {
                title: "Supprimer l'emplacement du service",
                text: "Êtes-vous sûr de vouloir supprimer cet emplacement de service"
            },
        }
    },
    Messages: {
        title : 'Messages'
    },
    Reports : {
        title : "Rapports",
        tabs : {
            tab1 : "Rapports",
            tab2 : "Enregistrée"
        },
        fields : {
            link1 : "Résumé de l'inspection",
            link2 : "Note d'inspection moyenne",
            link3 : "Scores d'inspection moyens par mois par emplacement de service",
            link4 : "Détails des rapports d'inspection uniquement",
            link5 : "Rapport d'inspection de la qualité",
            link6 : "Notes d'inspection récentes par mois par emplacement de service"
        },
        DeletePopup: {
            title: 'Supprimer le rapport',
            text2: 'Voulez-vous vraiment supprimer le rapport'
        },
    },
    InspectionSummary : {
        title : "Rapport de synthèse d'inspection",
        reportTitle : "Report Criteria",
        Errors: {
            e1 : "Veuillez sélectionner l'inspection.",
            e2 : "Veuillez sélectionner l'emplacement du service."
        },
        fields : {
            label1 : "Emplacement des services*",
            label2 : "Inspection*"
        }
    },
    SaveReportPopup : {
        title : "Enregistrer le rapport sous",
        EmployeePermissions : "Autorisations des employés",
        SPDivisionPermissions : "Autorisations de la division SP",
        fields : {
            label1 : "Nom du rapport*",
            label2 : "La description",
            label3 : "Sélectionnez l'employé"
        },
        placeholder : {
            label1 : "Par exemple. Rapport mensuel",
            label2 : "Entrez la description",
            label3 : "Sélectionnez l'employé" 
        }
    },
    DiscarReportPopup : {
        titile : "Rejeter le rapport",
        text : "Êtes-vous sûr de vouloir supprimer tous les progrès que vous avez réalisés sur les rapports?"
    },
    SavedReports : {
        searchPlaceholder : "Rapport de recherche..",
        table : {
            heading1 : "Nom du rapport",
            heading2 : "TYPE DE RAPPORT",
            heading3 : "Créé par",
            heading4 : "ACTIONS"
        },
        typeFilter : {
            status : "Type de rapport",
            link1 : "Résumé de l'inspection",
            link2 : "Note d'inspection moyenne",
            link3 : "Scores d'inspection moyens par mois par emplacement de service",
            link4 : "Détails des rapports d'inspection uniquement",
            link5 : "Rapport d'inspection de la qualité",
            link6 : "Scores d'inspection récents par mois par emplacement de service"
        },
        deniedModal : {
            title : "Accès refusé",
            text : "Vous n'avez pas accès aux détails de l'emplacement du service associé à ce rapport. Veuillez contacter l'administrateur de l'entreprise."
        }
    },
    InspectionServiceLocation : {
        title: "Scores d'inspection moyens par mois par emplacement de service",
        reportTitle : "Critères de rapport",
        filterTitle : "Critères de filtrage",
        Errors : {
            e1 : "Veuillez sélectionner l'emplacement du service.",
            e2 : "Veuillez sélectionner la date de début.",
            e3 : "Veuillez n'entrer que des chiffres.",
            e4 : "Veuillez sélectionner la date de fin."
        },
        fields : {
            label1 : "Sélectionnez l'emplacement du service*",
            label2 : "Date de début*",
            label3 : "Date de fin*",
            label4 : "Le type de sortie",
            label5 : "PDF",
            label6 : "Exceller",
            label7 : "Pourcentage d'objectif"
        },
        placeholder : {
            label1 : "Sélectionner",
            label2 : "Sélectionner une date",
            label7 : "Par exemple 80%"
        }
    },
    InspectinScores : {
        title: "Notes d'inspection moyennes",
        reportTitle : "Critères de rapport",
        selectionTitle : "Les critères de sélection",
        getServiceTitle : "Obtenir un rapport par emplacement de service",
        getSublocationTitle : "Obtenir un rapport par sous-emplacement",
        getSectinTitle : "Obtenir un rapport par section",
        addSelectionButton : "Ajouter des critères de sélection",
        Errors : {
            e1 : "Veuillez sélectionner un employé.",
            e2 : "Veuillez sélectionner la date de début.",
            e3 : "Veuillez sélectionner la date de fin.",
            e4 : "Veuillez sélectionner le type de sortie.",
            e5 : "Veuillez sélectionner l'intervalle d'affichage.",
            e6 : "Vous devez sélectionner soit le(s) emplacement(s) de service, le(s) sous-emplacement(s) ou la(les) section(s) ainsi que les modèles de zone."
        },
        fields : {
            label1 : "Inclure les inspections",
            label2 : "Sélectionnez l'employé*",
            label3 : "Date de début*",
            label4 : "Date de fin*",
            label5 : "Le type de sortie*",
            label6 : "Intervalle d'affichage*",
            label7 : "Afficher la liste des emplacements de service*",
            label8 : "Obtenir un rapport par"
        },
        placeholder : {
            label1 : "Sélectionner",
            label2 : "Sélectionner une date"
        },
        inspectinOptions : {
            opt1 : "Afficher les données de toutes les inspections",
            opt2 : "Afficher les données des inspections effectuées par un employé spécifique",
        },
        outputOptions : {
            opt1 : "PDF",
            opt2 : "Exceller",
        },
        intervalOptions : {
            opt1 : "du quotidien",
            opt2 : "Mensuelle",
            opt3 : "Trimestrielle",
            opt4 : "Annuelle",
        },
        serviceOptions : {
            opt1 : "Oui",
            opt2 : "Non"
        },
        reportOptions: {
            opt1 : "Emplacement des services",
            opt2 : "Sous-emplacement",
            opt3 : "Section"
        }
    },
    InspectionReportDetail : {
        title : "Rapport d'inspection - Détails uniquement",
        Errors : {
            e1 : "Veuillez sélectionner un employé.",
            e2 : "Veuillez sélectionner l'inspection.",
            e3 : "Veuillez sélectionner la date de début.",
            e4 : "Veuillez sélectionner la date de fin.",
            e5  : "Vous devez sélectionner soit le(s) emplacement(s) de service, le(s) sous-emplacement(s) ou la(les) section(s) ainsi que les modèles de zone."
        },
        fields : {
            label1 : "Sélectionnez Inspection par",
            label2 : "Inspection*",
            label3 : "Inclure les inspections",
            label4 : "Sélectionnez l'employé*",
            label5 : "Date de début*",
            label6 : "Date de fin*",
            label7 : "Inclure les notes d'approbation",
            label8 : "Afficher la liste des emplacements de service",
            label9 : "Obtenir le rapport par"
        },
        InspectionByOption : {
            opt1 : "Plage de dates",
            opt2 : "Inspection"
        }
    },
    QualityInspectionReport : {
        title : "Rapport d'inspection de la qualité",
        Errors : {
            e1 : "Veuillez sélectionner un employé.",
            e2 : "Veuillez sélectionner l'inspection.",
            e3 : "Veuillez sélectionner la date de début.",
            e4 : "Veuillez sélectionner la date de fin.",
            e5 : "Veuillez sélectionner le type de sortie.",
            e6 : "Vous devez sélectionner soit le(s) emplacement(s) de service, le(s) sous-emplacement(s) ou la(les) section(s) ainsi que les modèles de zone."
        }
    },
    Sections : {
        searchPlaceholder : "Section de recherche",
        servicePlacholder: "Sélectionnez l'emplacement du service",
        sublocationPlaceholder: "Sélectionnez le sous-emplacement",
        clientPlaceholder : "Sélectionnez des clients",
        table : {
            heading1 : "Section",
            heading2 : "Sous-emplacement",
            heading3 : "EMPLACEMENT DES SERVICES",
            heading4 : "CLIENTE"
        }
    },
    RecentInspectionReport : {
        title : "Scores d'inspection récents par emplacement de service",
        Errors : {
            e1 : "Veuillez sélectionner un employé.",
            e2  : "Veuillez sélectionner la date de début.",
            e3 : "Veuillez sélectionner la date de fin.",
            e4  : "Vous devez sélectionner soit le(s) emplacement(s) de service, le(s) sous-emplacement(s) ou la(les) section(s) ainsi que les modèles de zone."
        },
        fields : {
            label1 : "Gérer les emplacements de service non inspectés",
            label2 : "Trier par",
            label3 : "Afficher les sous-emplacements et les sections",
            label4 : "Inclure les personnes responsables du lieu de service",
            label5 : "Afficher uniquement les inspections incluant une approbation du client"
        },
        UninspectedOpt : {
            opt1 : "Inclure les emplacements de service non inspectés",
            opt2 : "Masquer les emplacements de service non inspectés",
            opt3 : "Afficher uniquement les emplacements de service non inspectés"
        },
        SortOpt : {
            opt1 : "Emplacement du service",
            opt2 : "Date",
            opt3 : "Inspectrice"
        }
    },
    dashboardTypes : {
        manageDashboard : {
            title : "Gérer le tableau de bord",
            manageDashboardOptions : {
                opt1 : "Contrôles",
                opt2 : "Bons de travail",
                opt3 : "Périodique",
                opt4 : "Tableau de bord global"
            }
        },
        dashboardFilters : {
            filterOptions : {
                opt1 : "Ajouter un widget",
                opt2 : "Filtres",
                opt3 : "Référez-vous"
            },
            InspectionAddWidgetOptions : {
                title : "Widget",
                opt1 : "Note moyenne et nombre d'inspections au fil du temps",
                opt2 : "Meilleurs clients",
                opt3 : "Principaux emplacements de service",
                opt4 : "Emplacements de service inférieurs",
                opt5 : "Clients inférieurs"
            },
            WorkorderAddWidgetOptions : {
                opt1 : "Travail ouvert vs terminé",
                opt2 : "# de bons de travail par mois",
                opt3 : "# de bons de travail par origine",
                opt4 : "Tendance du temps d'exécution moyen",
            },
            PeriodicAddWidgetOptions : {
                opt1 : "Périodiques ouverts vs terminés",
                opt2 : "# de périodiques par mois",
                opt3 : "Meilleurs clients",    
            },
            ManageFilters : {
                title : "Gérer les filtres",
                searchPlaceholder : "Filtres de recherche",
                ManageFiltersOptions : {
                    opt1 : "Clientes",
                    opt2 : "Modèle de zone",
                    opt3 : "Inspectrices",
                    opt4 : "Emplacements de service",
                    opt5 : "Plage de dates spécifique",
                    opt6 : "Créé par",
                    opt5Text : "Aug 01, 2021 - Dec 31, 2021",
                    opt7 : "Terminé par"
                },
                DashboardFilter :{
                    title : "Clientes",
                    searchPlaceholder : "Rechercher des clients",
                    areaFilter : {
                        title : "Modèles de zone",
                        searchPlaceholder : "Modèles de zone de recherche",
                    },
                    serviceFilter : {
                        title : "Emplacements de service",
                        searchPlaceholder : "Service de recherche Emplacements",  
                    },
                    inspectorFilter : {
                        title : "Inspectrice",
                        searchPlaceholder : "Inspecteur de recherche",  
                    },
                    createdByFilter : {
                        title : "Créé par",
                        searchPlaceholder : "Recherche Créé par",  
                    },
                    compltedByFilter : {
                        title : "Terminé par",
                        searchPlaceholder : "Recherche terminée par",  
                    },
                    dateFilter : {
                        title : "Plage de dates spécifique"
                    },
                    GlobalDashboardFilter : {
                        commonFilters : {
                            title : "Filtres communs"
                        },
                        inspectionFilter: {
                            title : "Filtrer par inspection"
                        },
                        workorderFilter : {
                            title : "Filtrer par bon de travail"
                        },
                        periodicFilter: {
                            title : "Filtrer par Périodiques"
                        }
                    }
                } 
            }
        },
        inspectionDashboard : {
            title : "Tableau de bord des inspections",
            exportButton: "Exportation",
            From : "Partir de la date",
            To : "À ce jour",
            inspectionDashboardOverview : {
                title : "Aperçu des inspections",
                AverageInspectionScore : "ANote d'inspection moyenne",
                Inspections : "Contrôles",
                LocationsInspected : "Emplacements inspectés"
            }
        },
        WorkorderDashboard : {
            title : "Tableau de bord des bons de travail",
            WorkorderDashboardOverview: {
                title : "Présentation de l'ordre de travail",
                NewWorkorder : "Nouveaux bons de travail",
                AverageTime : "Temps moyen pour terminer ou déposer"
            }
        },
        periodicDashboard: {
            title : "Tableau de bord des périodiques",
            periodicDashboardOverview: {
                title : "Présentation des périodiques",
                OpenPeriodics : "Périodique ouvert",
                CompletedPeriodics : "Périodiques terminés"
            }
        },
        Widgets : {
            table : {
                heading1 : "Nom",
                heading2 : "Moyenne",
            },
            AverageScore: {
                title : "Note moyenne et nombre d'inspections au fil du temps",
                table : {
                    heading1 : "Année mois",
                    heading2 : "Inspection moyenne",
                    heading3 : "# d'inspections"
                }
            },
            TopServiceLocation : {
                title : "Principaux emplacements de service"
            },
            BottomSeriveLocations : {
                title : "Emplacements de service inférieurs"
            },
            TopClients : {
                title : "Meilleurs clients"
            },
            BottomClients : {
                title : "Clients inférieurs"
            },
            OpenCompleted : {
                title : "Travail ouvert vs terminé",
                table : {
                    heading1 : "Ouvrir",
                    heading2 : "Complété",
                }
           },
           OpenCompletedPeriodic : {
            title : "Périodiques ouverts vs terminés",
            table : {
                heading1 : "Ouvrir",
                heading2 : "Complété",
            }
           },
           PerMonth : {
            title : "# de bons de travail par mois",
            table : {
                heading1 : "Nom",
                heading2 : "Ouvrir",
                heading3 : "Complété",
            }
          },
          PerMonthPeriodic : {
            title : "# de périodiques par mois",
            table : {
                heading1 : "Nom",
                heading2 : "Ouvrir",
                heading3 : "Complété",
            }
        },
          ByOrigin : {
            title : "# de bons de travail par origine",
           },
             AverageCompetion : {
                title : "Tendance du temps d'exécution moyen (jours)",
                table : {
                    heading1 : "Nom",
                    heading2 : "Compter",
                }
            },
            WidgetDropdownOptions : {
                title : "Actions des dashlets",
                opt1 : "Afficher le sélecteur",
                opt2 : "Exporter en tant que",
                opt3  : "Impression",
                opt1Child1 : "Combiné",
                opt1Child2 : "Table",
                opt2Chidl1 : "PDF",
                opt2Chidl2 : "CSV"
          }
        }
    },
    ImportModal:{
        fileDragText : "Faites glisser et déposez le fichier Excel ici",
        orText : "ou alors",
        addButton : "Parcourir les fichiers",
        importButton : "Importer",
        downloadTemplate : "Télécharger le modèle Excel",
        supportLabel : "Type de fichier pris en charge:",
        maxLabel : "Max. Taille du fichier:",
        Client: {
            title : "Importer des clients"
        },
        Services:{
            title : "Services d'importation" 
        },
        Workorder : {
            title : "Importer des bons de travail"
        },
        Contact : {
            title : "Contacts d'importation"
        },
        ServiceLocations : {
            title : "Importer des emplacements de service"  
        },
        SubLocations : {
            title : "Importer des sous-emplacements et une section/zone"  
        },
        AreaTemplates : {
            title : "Importer des modèles de zone"  
        }
    },
    superAdminDashboard : {
        title : "Tableau de bord",
        overView : {
            title : "Aperçu",
            TotalCompanies: "Nombre total d'entreprises",
            TotalAdmins : "Nombre total d'administrateurs",
            TotalEmployees : "Total des employés",
            CompaniesOverview : "Présentation des entreprises",
            ServiceLocationOverview : "Aperçu des points de service",
            InspectionOverView: "Aperçu des inspections et des bons de travail"
        },
        ManageFilters : {
            title : "Gérer les filtres",
            searchPlaceholder : "Rechercher des entreprises",
            filterTitle : "Filtrer par Entreprise",
            ManageFiltersOptions : {
                opt1 : "Compagnie"
            }
        },
        AddWidgetOptions : {
            title : "Widget",
            opt1 : "Principaux clients par nombre d'inspections",
            opt2 : "Clients inférieurs par nombre d'inspections",
            opt3 : "Principaux clients par nombre de bons de travail",
            opt4 : "Clients inférieurs par nombre de bons de travail",
            opt5 : "Principaux clients par nombre de périodiques",
            opt6 : "Clients inférieurs par nombre de périodiques",
            opt7 : "# d'inspections par mois",
            opt8 : "# de bons de travail par mois",
            opt9 : "Principaux emplacements de service",
            opt10 : "Emplacements de service inférieurs"
        },
        Widgets : {
            TopInspectionClients: {
                title: "Principaux clients par nombre d'inspections",
                table : {
                    heading1 : "Nom",
                    heading2 : "Nombre d'inspections",
                },
            },
            BottomInspectionClients: {
                title: "Clients inférieurs par nombre d'inspections",
            },
            TopClientsWorkorder: {
                title: "Principaux clients par nombre de bons de travail",
                table : {
                    heading1 : "Nom",
                    heading2 : "Nombre de bons de travail",
                }
            },
            BottomClientsWorkorder: {
                title: "Clients inférieurs par nombre de bons de travail",
            },
            TopClientsPeriodic: {
                title: "Principaux clients par nombre de périodiques",
                table : {
                    heading1 : "Nom",
                    heading2 : "Compte périodique",
                }
            },
            BottomClientsPeriodic : {
                title: "Clients inférieurs par nombre de périodiques",
            },
            TopServiceLocation : {
                title : "Principaux emplacements de service",
                table : {
                    heading1 : "Nom",
                    heading2 : "Moyenne",
                }
            },
            BootomServiceLocation : {
                title : "Emplacements de service inférieurs"
            },
            InspectionPerMonth: {
                title : "# d'inspections par mois",
                table : {
                    heading1 : "Nom",
                    heading2 : "En attente",
                    heading3 : "Complété",
                }
            },
            WorkOrderPerMonth : {
                title : "# de bons de travail par mois",
                table : {
                    heading1 : "Nom",
                    heading2 : "Ouverte",
                    heading3 : "Complété",
                }
            }
        }
    },
    commonComponent: {
        clearFilter : "Effacer les filtres",
        adminTitleText : "Vous visualisez",
        ResetButton : "Réinitialiser les filtres",
        asText : "comme",
        Exit: "Quitter la vue",
        backText: "Retour",
        backStep : "Retour à l'étape précédente",
        contiuneStep : "Passez à l'étape suivante",
        genrateButton : "Générer un rapport",
        saveAsReportButton : "Enregistrer le rapport sous",
        saveButton: 'Sauvegarder',
        nextButton : "Suivante",
        addButton : "Ajouter",
        updateButton: "Mettre à jour",
        cancelButton: 'Annuler',
        applyButton : 'Appliquer',
        yesButton: 'Oui, continuez',
        yesMarkButton : "Oui, marquer comme terminé",
        yesReopenButton : "Oui, rouvrir",
        yesClaimButton: 'Oui, réclamation',
        yesUnClaimButton: 'Oui, Annuler la réclamation',
        yesDeleteButton: "Oui, supprimer",
        yesDeactivateButton : "Oui, désactiver",
        yesActivateButton : "Oui, activer",
        yesDiscardButton : "Oui, rejeter",
        backbutton: "Retourner",
        okButton : "D'accord",
        okButton2 : "Ok, compris",
        errorTitle : "Erreur",
        downDivisionText : "Vous avez choisi de supprimer tous les liens vers les divisions en aval. Es-tu sûr de vouloir faire ça ? (Vous devez sélectionner cette option si vous déplacez cette division dans ou en dessous du niveau de l'une de ces divisions descendantes)",
        archieveText1 : "La suppression déplacerait",
        archieveText2 : "à la section Archives.",
        textArea1 : "Suppression",
        textArea2 : "supprimera son association de",
        textArea3 : "Lieux de service",
        statusFilter: {
            status: 'Statut',
            all: "Toute",
            active: 'Active',
            Inactive: 'Inactive',
            Date : "Date créée"
        },
        DeleteSurvey : {
            text : "Ces champs sont actuellement utilisés dans les inspections actives, la suppression de cela supprimera également toutes les inspections actives"
        },
        DeleteSurveyRating : {
            text1 : "Ce modèle d'évaluation est actuellement utilisé dans certaines inspections enregistrées et en attente, toute modification se reflétera également dans toutes les inspections",
            text2 : "Voulez-vous vraiment modifier ce modèle d'évaluation ?"
        }
    }
};
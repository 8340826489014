import axios from "axios";
import { put, takeLatest, fork, call, delay } from 'redux-saga/effects';
import { WebConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getTemplateProviderRequested,
    getTemplateProviderSucess,
    getTemplateProviderDetail,
    getRatingListSuccess,
    getRatingTemplateDetailRequested,
    getRatingTemplateDetailSuccess,
    getRatingTemplateDropdownSuccess
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const RATING_TEMPLATE_POINT_URL = WebConfig.ApiUrls.configurations.ratingTemplates;


export function* addRatingTemplate(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${RATING_TEMPLATE_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }


}

export function* getTemplateProvider(payload) {

    yield put(getTemplateProviderRequested());
    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${RATING_TEMPLATE_POINT_URL}default_service_provider/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 302) {
        yield put(getTemplateProviderSucess(false));
    }

    if (response && response.data.status === 200) {
        yield put(getTemplateProviderSucess(true));
        yield put(getTemplateProviderDetail(response.data.response));
    }

    if (response && response.data && response.data.status !== 200 && response.data.status !== 302) {
        yield put(loadTodoError(response.data.message.detail));
    }





}


export function* getRatingList(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null } } = data;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(search ?
                `${API_POINT_URL}${RATING_TEMPLATE_POINT_URL}?page=${currentPage}&search=${search}` :
                status ? `${API_POINT_URL}${RATING_TEMPLATE_POINT_URL}?page=${currentPage}&is_active=${status}` :
                    `${API_POINT_URL}${RATING_TEMPLATE_POINT_URL}?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getRatingListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* getRatingTemplateDetail(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());
    yield put(getRatingTemplateDetailRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${RATING_TEMPLATE_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getRatingTemplateDetailSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }




}


export function* updateRatingStatus(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${RATING_TEMPLATE_POINT_URL}${data.payload.id}/`, { is_active: data.payload.is_active })
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)
        yield getRatingList({
            type: actionTypes.GetRatingList,
            payload: { currentPage: data.payload.currentPage }
        });

    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }




}



export function* editRatingTemplate(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${RATING_TEMPLATE_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* deleteTemplateRating(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${RATING_TEMPLATE_POINT_URL}${data.payload.templateId}/rating/${data.payload.ratingId}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)
        yield getRatingTemplateDetail({
            type: actionTypes.GetRatingTemplateDetail,
            payload: { id: data.payload.templateId }
        });
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}

export function* getRatingTemplateDropdown(payload) {

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${RATING_TEMPLATE_POINT_URL}items/?fields=name,id,default_for_sp`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getRatingTemplateDropdownSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }




}


export function* replaceRatingStatus(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${RATING_TEMPLATE_POINT_URL}${data.payload.id}/`,
                { is_active: data.payload.is_active, replace_with: data.payload.replace_with })
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)
        yield getRatingList({
            type: actionTypes.GetRatingList,
            payload: { currentPage: data.payload.currentPage }
        });

    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }




}




function* watchaddRatingTemplate() {
    yield takeLatest(actionTypes.AddRatingTemplate, addRatingTemplate)
}

function* watchgetTemplateProvider() {
    yield takeLatest(actionTypes.GetTemplateProvider, getTemplateProvider)
}

function* watchgetRatingList() {
    yield takeLatest(actionTypes.GetRatingList, getRatingList)
}

function* watchgetRatingTemplateDetail() {
    yield takeLatest(actionTypes.GetRatingTemplateDetail, getRatingTemplateDetail)
}

function* watchupdateRatingStaus() {
    yield takeLatest(actionTypes.UpdateRatingStatus, updateRatingStatus)
}

function* watcheditRatingTemplate() {
    yield takeLatest(actionTypes.EditRatingTemplate, editRatingTemplate)
}

function* watchdeleteTemplateRating() {
    yield takeLatest(actionTypes.DeleteTemplateRating, deleteTemplateRating)
}

function* watchgetRatingTemplateDropdown() {
    yield takeLatest(actionTypes.GetRatingTemplateDropdown, getRatingTemplateDropdown)
}

function* watchreplaceRatingStatus() {
    yield takeLatest(actionTypes.ReplaceRatingStatus, replaceRatingStatus)
}

export const ratingTemplateSaga = [
    fork(watchaddRatingTemplate),
    fork(watchgetTemplateProvider),
    fork(watchgetRatingList),
    fork(watchgetRatingTemplateDetail),
    fork(watchupdateRatingStaus),
    fork(watcheditRatingTemplate),
    fork(watchdeleteTemplateRating),
    fork(watchgetRatingTemplateDropdown),
    fork(watchreplaceRatingStatus)
]

import * as actionTypes from '../actionTypes';


export const getConfiguration = (data) => {
    return {
        type: actionTypes.GetConfiguration,
        payload: data
    }
}

export const getConfigurationRequested = (data) => {
    return {
        type: actionTypes.GetConfigurationRequested,
        payload: data
    }
}

export const getConfigurationSuccess = (data) => {
    return {
        type: actionTypes.GetConfigurationSuccess,
        payload: data
    }
}

export const addPosition = (data) => {
    return {
        type: actionTypes.AddPosition,
        payload: data
    }
}

export const getPosition = (data) => {
    return {
        type: actionTypes.GetPosition,
        payload: data
    }
}

export const getPositionSuccess = (data) => {
    return {
        type: actionTypes.GetPositionSuccess,
        payload: data
    }
}

export const getPostionDetail = (data) => {
    return {
        type: actionTypes.GetPostionDetail,
        payload: data
    }
}

export const getPostionDetailRequested = () => {
    return {
        type: actionTypes.GetPostionDetailRequested,
    }
}

export const getPostionDetailSuccess = (data) => {
    return {
        type: actionTypes.GetPostionDetailSuccess,
        payload: data
    }
}


export const deletePostion = (data) => {
    return {
        type: actionTypes.DeletePostion,
        payload: data
    }
}

export const editPostion = (data) => {
    return {
        type: actionTypes.EditPostion,
        payload: data
    }
}

export const getPostionDropdown = (data) => {
    return {
        type: actionTypes.GetPostionDropdown,
        payload: data
    }
}

export const getPostionDropdownSuccess = (data) => {
    return {
        type: actionTypes.GetPostionDropdownSuccess,
        payload: data
    }
}

export const getPositionEmploye = (data) => {
    return {
        type: actionTypes.GetPositionEmploye,
        payload: data
    }
}

export const getPositionEmployeSuccess = (data) => {
    return {
        type: actionTypes.GetPositionEmployeSuccess,
        payload: data
    }
}


export const getPositionContact = (data) => {
    return {
        type: actionTypes.GetPositionContact,
        payload: data
    }
}

export const getPositionContactSuccess = (data) => {
    return {
        type: actionTypes.GetPositionContactSuccess,
        payload: data
    }
}
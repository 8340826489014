import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig, } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getPermissionListSuccess,
    getPermissionModuleListRequested,
    getPermissionModuleListSuccess,
    getPermissionDetailRequested,
    getPermissionDetailSuccess,
    getPermissionDropdownListRequested,
    getPermissionDropdownListSuccess,
    getPermissionPrimaryDetailRequested,
    getPermissionPrimaryDetailSuccess,
    getPermissionSecondaryDetailRequested,
    getPermissionSecondaryDetailSuccess
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const PERMISSION_POINT_URL = WebConfig.ApiUrls.configurations.groups;
const PERMISSION_LIST_POINT_URL = WebConfig.ApiUrls.permissions;

export function* getPermissionList(payload) {
    const data = payload;
    const { payload: { search = null, type = null, currentPage = null } } = data;

    //  yield put(loadTodoRequest());


    const response = yield call(() => {
        return ClientInstance
            .get(search ?
                `${API_POINT_URL}${PERMISSION_POINT_URL}?page=${currentPage}&type=${type}&search=${search}` :
                `${API_POINT_URL}${PERMISSION_POINT_URL}?page=${currentPage}&type=${type}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {

        yield put(getPermissionListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}

export function* deleteGetPermission(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${PERMISSION_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));


        if (!data.payload.permissionDetailPage) {
            yield delay(1000)
            yield getPermissionList({
                type: actionTypes.GetPermissionList,
                payload: { currentPage: data.payload.currentPage, type: data.payload.type }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* getPermissionModuleList(payload) {

    //yield put(loadTodoRequest());
    yield put(getPermissionModuleListRequested());


    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${PERMISSION_LIST_POINT_URL}`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getPermissionModuleListSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }




}


export function* addPermissionTemplate(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${PERMISSION_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }


}

export function* upDatePermissionTemplate(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${PERMISSION_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }


}

export function* getPermissionDetail(payload) {
    const data = payload;

    // yield put(loadTodoRequest());
    yield put(getPermissionDetailRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${PERMISSION_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getPermissionDetailSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }




}



export function* getPermissionDropdownList(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    yield put(getPermissionDropdownListRequested());
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${PERMISSION_POINT_URL}?no_page&type=${data.payload.type}&fields=id,name`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getPermissionDropdownListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}



export function* getPermissionPrimaryDetail(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    yield put(getPermissionPrimaryDetailRequested());
    const response = yield call(() => {
        return axios
            .get(`${API_POINT_URL}${PERMISSION_POINT_URL}primary/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Company': `${data.payload.companyId}`
                },
            })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getPermissionPrimaryDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* getPermissionSecondaryDetail(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    yield put(getPermissionSecondaryDetailRequested());
    const response = yield call(() => {
        return axios
            .get(`${API_POINT_URL}${PERMISSION_POINT_URL}secondary/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Company': `${data.payload.companyId}`
                },
            })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getPermissionSecondaryDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}




function* watchgetPermissionList() {
    yield takeLatest(actionTypes.GetPermissionList, getPermissionList)
}

function* watchdeleteGetPermission() {
    yield takeLatest(actionTypes.DeleteGetPermission, deleteGetPermission)
}


function* watchgetPermissionModuleList() {
    yield takeLatest(actionTypes.GetPermissionModuleList, getPermissionModuleList)
}


function* watchaddPermissionTemplate() {
    yield takeLatest(actionTypes.AddPermissionTemplate, addPermissionTemplate)
}

function* watchupDatePermissionTemplate() {
    yield takeLatest(actionTypes.UpDatePermissionTemplate, upDatePermissionTemplate)
}

function* watchgetPermissionDetail() {
    yield takeLatest(actionTypes.GetPermissionDetail, getPermissionDetail)
}

function* watchgetPermissionDropdownList() {
    yield takeLatest(actionTypes.GetPermissionDropdownList, getPermissionDropdownList)
}


function* watchgetPermissionPrimaryDetail() {
    yield takeLatest(actionTypes.GetPermissionPrimaryDetail, getPermissionPrimaryDetail)
}

function* watchgetPermissionSecondaryDetail() {
    yield takeLatest(actionTypes.GetPermissionSecondaryDetail, getPermissionSecondaryDetail)
}


export const PermissionSaga = [
    fork(watchgetPermissionList),
    fork(watchdeleteGetPermission),
    fork(watchgetPermissionModuleList),
    fork(watchaddPermissionTemplate),
    fork(watchupDatePermissionTemplate),
    fork(watchgetPermissionDetail),
    fork(watchgetPermissionDropdownList),
    fork(watchgetPermissionPrimaryDetail),
    fork(watchgetPermissionSecondaryDetail)
]
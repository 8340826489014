export const LoaderHide = 'LOADER_HIDE';
export const LoaderShow = 'LOADER_SHOW';
export const LoadTodoRequested = 'LOAD_TO_REQUESTED';
export const LoadTodoSuccess = 'LOAD_TO_SUCCESS';
export const LoadTodoWarning = 'LOAD_TO_WARNING';
export const LoadTodoError = 'LOAD_TO_ERROR';
export const LogOut = 'USER_LOGGED_OUT';

export const Http500Error = 'HTTP_500_ERROR';
export const HttpOtherError= 'HTTP_OTHER_ERROR';

/*Auth-Actions*/
export const GetappAuth = 'GET_APP_AUTH';
export const GetappAuthSuccess = 'GET_APP_AUTH_SUCCESS'
export const Login = 'AUTH_LOGIN';
export const LoginDetail = 'AUTH_LOGIN_DETAIL';
export const GetProfile = 'GET_PROFILE';
export const GetProfileRequested = 'GET_PROFILE_REQUESTED';
export const GetProfileSuccess = 'GET_PROFILE_SUCCESS';
export const ForgotPassword = 'FORGOT_PASSWORD';
export const ResetPassword = 'RESET_PASSWORD';
export const ResetVerifyToken = 'RESET_PASSWORD_VERIFICATION';
export const ResetVerifyTokenFailed = 'RESET_PASSWORD_VERIFICATION_FAILED';
export const InPersonate = 'GET_INPERSONATE';
export const InPersonateRequested = 'GET_INPERSONATE_REQUESTED';
export const InPersonateSuccess = 'GET_INPERSONATE_SUCCESS';
export const InPersonateLogout = 'GET_INPERSONATE_LOGOUT';
export const azureLogin = 'AUTH_AZURE_LOGIN';

export const GetUserProfile = 'GET_USER_PROFILE';
export const GetUserProfileRequested = 'GET_USER_PROFILE_REQUESTED';
export const GetUserProfileSuccess = 'GET_USER_PROFILE_SUCCESS';

export const UpdateProfile = 'UPDATE_USER_PROFILE';
export const GetQrCode = 'GET_QR_CODE';
export const GetQrCodeRequested = 'GET_QR_CODE_REQUESTED';
export const GetQrCodeSuccess = 'GET_QR_CODE_SUCCESS';
export const GetDeviceList = 'GET_DEVICE_LIST';
export const GetDeviceListRequested = 'GET_DEVICE_LIST_REQUESTED';
export const GetDeviceListSuccess = 'GET_DEVICE_LIST_SUCCESS';
export const AddDevice = 'ADD_DEVICE';
export const AddDeviceSuccess = 'ADD_DEVICE_SUCESS';
export const AddDeviceRequested = 'ADD_DEVICE_REQUESTED';
export const AddDeviceFailure = 'ADD_DEVICE_FAILURE';
export const DeleteDevice = 'DELETE_DEVICE';
export const FallBackCode = 'FALL_BACK_CODE';

export const ChangePassword = 'CHANGE_PASSWORD';

/*Add-Company-Action*/
export const GetCountries = 'GET_COUNTRIES';
export const GetCountriesSuccess = 'GET_COUNTRIES_SUCESS';
export const GetRegion = 'GET_REGIONS';
export const GetRegionRequested = 'GET_REGIONS_REQUESTED';
export const GetRegionSuccess = 'GET_REGIONS_SUCESS';
export const GetCities = 'GET_CITIES';
export const GetCitiesRequested = 'GET_CITIES_REQUESTED';
export const GetCitiesSuccess = 'GET_CITIES_SUCESS';
export const CreateCompany = "ADD_COMPANY";
export const DeleteAdminPostion = 'DELETE_ADMIN_POSTION';

/*list-of-compaines*/
export const GetCompanyList = 'GET_COMPAINES_LIST';
export const GetCompanyListSuccess = 'GET_COMPAINES_LIST_SUCESS';
export const GetCompanyListRequested = 'GET_COMPAINES_LIST_REQUESTED';
export const UpdateStatus = 'UPDATE_COMPANY_STATUS';
export const EditCompany = 'EDIT_COMPANY';
export const GetCompanyAllList = 'GET_COMPAINES_ALL_LIST';
export const GetCompanyAllListSuccess = 'GET_COMPAINES_ALL_LIST_SUCESS';

/*Company-detail-Actions*/
export const GetCompanyDetail = 'GET_COMPANY_DETAIL';
export const GetCompanyDetailRequested = 'GET_COMPANY_DETAIL_REQUESTED';
export const GetCompanyDetailSuccess = 'GET_COMPANY_DETAIL_SUCCESS';

/*company-admin-page*/
export const GetPrimaryAdmin = 'GET_PRIMARY_ADMIN';
export const GetPrimaryAdminRequested = 'GET_PRIMARY_ADMIN_REQUESTED';
export const GetPrimaryAdminSucess = 'GET_PRIMARY_ADMIN_SUCCESS';
export const GetPrimaryAdminDetail = 'GET_PRIMARY_ADMIN_DETAIL';
export const AddCompanyAdmin = 'ADD_COMPANY_ADMIN';
export const EditCompanyAdmin = 'EDIT_COMPANY_ADMIN';
export const GetCompanyAdmin = 'GET_COMPANY_ADMIN';
export const GetCompanyAdminSuccess = 'GET_COMPANY_ADMIN_SUCCESS';
export const GetCompanyAdminRequested = 'GET_COMPANY_ADMIN_REQUESTED';
export const GetCompanyAdminDetail = 'GET_COMPANY_ADMIN_DETAIL';
export const GetCompanyAdminDetailRequested = 'GET_COMPANY_ADMIN_DETAIL_REQUESTED';
export const GetCompanyAdminDetailSuccess = 'GET_COMPANY_ADMIN_DETAIL_SUCCESS';
export const DeleteCompanyAdmin = 'DELETE_COMPANY_ADMIN';
export const GetRecentSearch = 'GET_RECENT_SEARCH';
export const GetRecentSearchClient = 'GET_RECENT_SEARCH_CLIENT';
export const GetRecentSearchRequested = 'GET_RECENT_SEARCH_REQUESTED';
export const GetRecentSearchSuccess = 'GET_RECENT_SEARCH_SUCCESS';
export const DeleteAdminCompany = "DELETE_ADMIN_COMPANY";

export const GetCompanyAdminArchive = "GET_COMPANY_ADMIN_ARCHIEVE";
export const GetCompanyAdminArchiveRequested = "GET_COMPANY_ADMIN_ARCHIEVE_REQUESTED";
export const GetCompanyAdminArchiveSuccess = "GET_COMPANY_ADMIN_ARCHIEVE_SUCCESS";
export const UpdateCompanyArchieveStatus = 'UPDATE_COMPANY_ARCHIEVE_STATUS'



/*clients-page*/
export const AddClient = "ADD_CLIENT";
export const GetClientList = "GET_CLIENT_LIST";
export const GetClientListRequested = "GET_CLIENT_LIST_REQUESTED";
export const GetClientListSuccess = "GET_CLIENT_LIST_SUCCESS";
export const UpdateClientStatus = 'UPDATE_CLIENT_STATUS';
export const DeleteClient = 'DELETE_CLIENT';
export const GetClientDetail = 'GET_CLIENT_DETAIL';
export const GetClientDetailRequested = 'GET_CLIENT_DETAIL_REQUESTED';
export const GetClientDetailSuccess = 'GET_CLIENT_DETAIL_SUCCESS';
export const EditClient = 'EDIT_CLIENT';
export const GetAllClientList = "GET_ALL_CLIENT_LIST";
export const GetAllClientListSuccess = "GET_ALL_CLIENT_LIST_SUCCESS";

export const GetClientArchiveList = "GET_CLIENT_LIST_ARCHIEVE";
export const GetClientArchiveListRequested = "GET_CLIENT_LIST_ARCHIEVE_REQUESTED";
export const GetClientArchiveListSuccess = "GET_CLIENT_LIST_ARCHIEVE_SUCCESS";
export const UpdateClientArchieveStatus = 'UPDATE_CLIENT_ARCHIEVE_STATUS'

export const ImportClients = 'IMPORT_CLIENTS'


/*service-page*/
export const AddServiceLocation = "ADD_SERVICE_LOCATION";
export const GetTimezone = 'GET_TIMEZONE';
export const GetTimezoneSuccess = 'GET_TIMEZONE_SUCCESS';
export const GetServiceLocation = 'GET_SERVICE_LOCATION';
export const GetServiceLocationRequested = 'GET_SERVICE_LOCATION_REQUESTED';
export const GetServiceLocationSuccess = 'GET_SERVICE_LOCATION_SUCCESS';
export const UpdateServiceStatus = 'UPDATE_SERVICE_STATUS';
export const DeleteService = 'DELETE_SERVICE';
export const GetServiceDetail = 'GET_SERVICE_DETAIL';
export const GetServiceDetailRequested = 'GET_SERVICE_DETAIL_REQUESTED';
export const GetServiceDetailSuccess = 'GET_SERVICE_DETAIL_SUCCESS';
export const EditService = 'EDIT_SERVICE';
export const GetAllServiceList = "GET_ALL_SERVICE_LIST";
export const GetAllServiceListSuccess = "GET_ALL_SERVICE_LIST_SUCCESS";
export const GetAllServiceListRequested = "GET_ALL_SERVICE_LIST_REQUESTED";

export const GetClientServiceLocation = 'GET_CLIENT_SERVICE_LOCATION';
export const UpdateClientServiceStatus = 'UPDATE_CLIENT_SERVICE_STATUS';
export const DeleteClientService = 'DELETE_CLIENT_SERVICE';

export const GetServiceContacts= 'GET_SERVICE_CONTACTS';
export const GetServiceContactsRequested = 'GET_SERVICE_CONTACTS_REQUESTED';
export const GetServiceContactsSuccess = 'GET_SERVICE_CONTACTS_SUCCESS';

export const GetServiceArchiveList = "GET_SERVICE_LIST_ARCHIEVE";
export const GetServiceArchiveListRequested = "GET_SERVICE_LIST_ARCHIEVE_REQUESTED";
export const GetServiceArchiveListSuccess = "GET_SERVICE_LIST_ARCHIEVE_SUCCESS";
export const UpdateServiceArchieveStatus = 'UPDATE_SERVICE_ARCHIEVE_STATUS'

export const GetAllServiceReportList = "GET_ALL_SERVICE_LIST_REPORT";
export const GetAllServiceListReportSuccess = "GET_ALL_SERVICE_LIST_REPORT_SUCCESS";
export const GetAllServiceListReportRequested = "GET_ALL_SERVICE_LIST_REPORT_REQUESTED";


export const GetAllServiceDropdown = "GET_ALL_SERVICE_DROP_DOWN";
export const GetAllServiceDropdownSuccess = "GET_ALL_SERVICE_DROP_DOWN_SUCCESS";
export const GetAllServiceDropdownRequested = "GET_ALL_SERVICE_DROP_DOWN_REQUESTED";

export const ImportServiceModalList = 'IMPORT_SERVICE_MODAL_LIST';



/*contact-page*/
export const AddContact = "ADD_CONTACT";
export const GetContactList = "GET_CONTACT_LIST";
export const GetContactListRequested = "GET_CONTACT_LIST_REQUESTED";
export const GetContactListSuccess = "GET_CONTACT_LIST_SUCCESS";
export const UpdateContactStatus = 'UPDATE_CONTACT_STATUS';
export const DeleteContact = 'DELETE_CONTACT';
export const GetContactDetail = 'GET_CONTACT_DETAIL';
export const GetContactRequested = 'GET_CONTACT_DETAIL_REQUESTED';
export const GetContactSuccess = 'GET_CONTACT_DETAIL_SUCCESS';
export const EditContact = 'EDIT_CONTACT';

export const GetClientContact = 'GET_CLIENT_CONTACT_LIST';
export const UpdateClientContactStatus = 'UPDATE_CLIENT_CONTACT_STATUS';
export const DeleteClientContact = 'DELETE_CLIENT_CONTACT';

export const GetContactArchiveList = "GET_CONTACT_LIST_ARCHIEVE";
export const GetContactArchiveListRequested = "GET_CONTACT_LIST_ARCHIEVE_REQUESTED";
export const GetContactArchiveListSuccess = "GET_CONTACT_LIST_ARCHIEVE_SUCCESS";
export const UpdateContactArchieveStatus = 'UPDATE_CONTACT_ARCHIEVE_STATUS'

export const ImportContactList = 'IMPORT_CONTACT_LIST'

/*employee-page*/
export const AddEmploye = "ADD_EMPLOYE";
export const GetEmployeList = "GET_EMPLOYE_LIST";
export const GetEmployeListRequested = "GET_EMPLOYE_LIST_REQUESTED";
export const GetEmployeListSuccess = "GET_EMPLOYE_LIST_SUCCESS";
export const UpdateEmployeStatus = 'UPDATE_EMPLOYE_STATUS';
export const DeleteEmploye = 'DELETE_EMPLOYE';
export const GetEmployeDetail = 'GET_EMPLOYE_DETAIL';
export const GetEmployeDetailRequested = 'GET_EMPLOYE_DETAIL_REQUESTED';
export const GetEmployeDetailSuccess = 'GET_EMPLOYE_DETAIL_SUCCESS';
export const EditEmploye = 'EDIT_EMPLOYE';
export const GetEmployeArchiveList = "GET_EMPLOYE_LIST_ARCHIEVE";
export const GetEmployeArchiveListRequested = "GET_EMPLOYE_LIST_ARCHIEVE_REQUESTED";
export const GetEmployeArchiveListSuccess = "GET_EMPLOYE_LIST_ARCHIEVE_SUCCESS";
export const UpdateEmployeArchieveStatus = 'UPDATE_EMPLOYE_ARCHIEVE_STATUS'
export const DeleteEmployePostion = 'DELETE_EMPLOYE_POSTION';
export const  GetEmployeDropdownList = 'GET_EMPLOYE_DROPDOWN';
export const  GetEmployeDropdownListRequested = 'GET_EMPLOYE_DROPDOWN_REQUESTED';
export const  GetEmployeDropdownListSuccess = 'GET_EMPLOYE_DROPDOWN_SUCCESS';
export const  GetAllInspectorList = 'GET_ALL_INSPECTOR_LIST';
export const  GetAllInspectorListRequested = 'GET_ALL_INSPECTOR_LIST_REQUESTED';
export const  GetAllInspectorListSuccess = 'GET_ALL_INSPECTOR_LIST_SUCCESS';

/*clientorgcharts*/

export const GetLevelPostion = "GET_LEVEL_POSTION";
export const GetLevelPostionSuccess = "GET_LEVEL_POSTION_SUCCESS";
export const AddLevel = "ADD_LEVEL";
export const GetDivisionLevelPostion = "GET_DIVISON_LEVEL_POSTION";
export const GetDivisionLevelPostionSuccess = "GET_DIVISON_LEVEL_POSTION_SUCCESS";
export const GetDivisionReportRequested = "GET_DIVISON_REPORT_REQUESTED";
export const GetDivisionReport = "GET_DIVISON_REPORT";
export const GetDivisionReportSuccess = "GET_DIVISON_REPORT_SUCCESS";
export const AddDivision = "ADD_DIVISON";
export const GetAllLevel = "GET_ALL_LEVEL";
export const GetAllLevelSuccess = "GET_ALL_LEVEL_SUCCESS";
export const GetAllDivision = "GET_ALL_DIVISION";
export const GetAllDivisionRequested = "GET_ALL_DIVISION_REQUESTED";
export const GetAllDivisionSuccess = "GET_ALL_DIVISION_SUCCESS";
export const GetDivisionSearch = "GET_DIVSION_SEARCH";
export const GetDivisionSearchRequested = "GET_DIVSION_SEARCH_REQUESTED";
export const GetDivisionSearchSuccess = "GET_DIVSION_SEARCH_SUCCESS";
export const GetLevelDetail = "GET_LEVEL_DETAIL";
export const GetLevelDetailRequested = "GET_LEVEL_DETAIL_REQUESTED";
export const GetLevelDetailSuccess = "GET_LEVEL_DETAIL_SUCCESS";
export const EditLevel = "EDIT_LEVEL";
export const DeleteLevel = "DELETE_LEVEL";
export const DeleteLevelRequested = "DELETE_LEVEL_REQUESTED";
export const DeleteLevelSuccess = "DELETE_LEVEL_SUCCESS";
export const DeleteLevelError = "DELETE_LEVEL_ERROR";
export const DeleteDivision = "DELETE_DIVISION";
export const GetLevel = "GET_LEVEL";
export const GetLevelRequested = "GET_LEVEL_REQUESTED";
export const GetLevelSuccess = "GET_LEVEL_SUCCESS";
export const GetDivison = "GET_DIVISON";
export const GetDivisonRequested = "GET_DIVISON_REQUESTED";
export const GetDivisonSuccess = "GET_DIVISON_SUCCESS";
export const UpdateDivisionSingle = "UPDATE_DIVISON_SINGLE";
export const UpdateDivision = "UPDATE_DIVISON";

export const GetClientLevel = "GET_CLIENT_LEVEL_DROPDOWN";
export const GetClientLevelSuccess = "GET_CLIENT_LEVEL_DROPDOWN_SUCCESS";
export const GetClientDivisionRequested = "GET_CLIENT_DIVSION_DROPDOWN_REQUESTED";
export const GetClientDivision = "GET_CLIENT_DIVSION_DROPDOWN";
export const GetClientDivisionSuccess = "GET_CLIENT_DIVSION_DROPDOWN_SUCCESS";

/*sporgcharts*/

export const GetSpLevelPostion = "GET_SP_LEVEL_POSTION";
export const GetSpLevelPostionSuccess = "GET_SP_LEVEL_POSTION_SUCCESS";
export const AddSpLevel = "ADD_SP_LEVEL";
export const GetSpDivisionLevelPostion = "GET_SP_DIVISON_LEVEL_POSTION";
export const GetSpDivisionLevelPostionSuccess = "GET_SP_DIVISON_LEVEL_POSTION_SUCCESS";
export const GetSpDivisionReportRequested = "GET_SP_DIVISON_REPORT_REQUESTED";
export const GetSpDivisionReport = "GET_SP_DIVISON_REPORT";
export const GetSpDivisionReportSuccess = "GET_SP_DIVISON_REPORT_SUCCESS";
export const AddSpDivision = "ADD_SP_DIVISON";
export const GetAllSpLevel = "GET_SP_ALL_LEVEL";
export const GetAllSpLevelRequested = "GET_SP_ALL_LEVEL_REQUESTED";
export const GetAllSpLevelSuccess = "GET_SP_ALL_LEVEL_SUCCESS";
export const GetAllSpDivision = "GET_SP_ALL_DIVISION";
export const GetAllSpDivisionRequested = "GET_ALL_SP)DIVISION_REQUESTED";
export const GetAllSpDivisionSuccess = "GET_ALL_SP_DIVISION_SUCCESS";
export const GetSpDivisionSearch = "GET_SP_DIVSION_SEARCH";
export const GetSpDivisionSearchRequested = "GET_SP_DIVSION_SEARCH_REQUESTED";
export const GetSpDivisionSearchSuccess = "GET_SP_DIVSION_SEARCH_SUCCESS";
export const GetSpLevelDetail = "GET_SP_LEVEL_DETAIL";
export const GetSpLevelDetailRequested = "GET_SP_LEVEL_DETAIL_REQUESTED";
export const GetSpLevelDetailSuccess = "GET_SP_LEVEL_DETAIL_SUCCESS";
export const EditSpLevel = "EDIT_SP_LEVEL";
export const DeleteSpLevel = "DELETE_SP_LEVEL";
export const DeleteSpLevelRequested = "DELETE_SP_LEVEL_REQUESTED";
export const DeleteSpLevelSuccess = "DELETE_SP_LEVEL_SUCCESS";
export const DeleteSpLevelError = "DELETE_SP_LEVEL_ERROR";
export const DeleteSpDivision = "DELETE_SP_DIVISION";
export const GetSpDivison = "GET_SP_DIVISON";
export const GetSpDivisonRequested = "GET_SP_DIVISON_REQUESTED";
export const GetSpDivisonSuccess = "GET_SP_DIVISON_SUCCESS";
export const UpdateSpDivisionSingle = "UPDATE_SP_DIVISON_SINGLE";
export const UpdateSpDivision = "UPDATE_SP_DIVISON";

export const GetSPLevellist = "GET_SP_LEVEL_DROPDOWN";
export const GetSPLevellistSuccess = "GET_SP_LEVEL_DROPDOWN_SUCCESS";
export const GetSPDivsionRequested = "GET_SP_DIVSION_DROPDOWN_REQUESTED";
export const GetSPDivsion = "GET_SP_DIVSION_DROPDOWN";
export const GetSPDivsionSuccess = "GET_SP_DIVSION_DROPDOWN_SUCCESS";

export const GetAllSpAdminDivision = "GET_SP_ADMIN_ALL_DIVISION";
export const GetAllSpAdminDivisionRequested = "GET_ALL_SP_ADMIN_DIVISION_REQUESTED";
export const GetAllSpAdminDivisionSuccess = "GET_ALL_SP_ADMIN_DIVISION_SUCCESS";

/*Configuration*/

export const GetConfiguration = "GET_CONFIGURATION";
export const GetConfigurationSuccess = "GET_CONFIGURATION_SUCCESS";
export const GetConfigurationRequested = "GET_CONFIGURATION_REQUESTED";

export const AddPosition = "ADD_POSITION";
export const GetPosition = "GET_POSTION";
export const GetPositionSuccess = "GET_POSTION_SUCCESS";

export const GetPostionDetail = 'GET_POSTION_DETAIL';
export const GetPostionDetailRequested = 'GET_POSTION_DETAIL_REQUESTED';
export const GetPostionDetailSuccess = 'GET_POSTION_DETAIL_SUCCESS';
export const EditPostion = "EDIT_POSTION";
export const DeletePostion = "DELETE_POSTION";

export const GetPostionDropdown = 'GET_POSTION_DROPDOWN';
export const GetPostionDropdownSuccess = 'GET_POSTION_DROPDOWN_SUCCESS'

export const GetPositionEmploye = "GET_POSITION_EMPLOYE";
export const GetPositionEmployeSuccess = "GET_POSITION_EMPLOYE_SUCCESS";


export const GetPositionContact = "GET_POSITION_CONTACT";
export const GetPositionContactSuccess = "GET_POSITION_CONTACT_SUCCESS";



/*Area-Configuration*/

export const AddService = "ADD_SERVICE";
export const GetServiceList = "GET_SERVICE_LIST";
export const GetServiceListSuccess = "GET_SERVICE_LIST_SUCCESS";
export const ImportServiceList = 'IMPORT_SERVICE_LIST'
export const GetAreaServiceDetail = 'GET_AREA_SERVICE_DETAIL';
export const GetAreaServiceDetailRequested = 'GET_AREA_SERVICE_DETAIL_REQUESTED';
export const GetAreaServiceDetailSuccess = 'GET_AREA_SERVICE_DETAIL_SUCCESS';
export const EditAreaService = "EDIT_AREA_SERVICE";
export const DeleteAreaService = "DELETE_AREA_POSTION";
export const GetAreaTemplateItems= "GET_AREA_TEMPLATE_ITEMS";
export const GetAreaTemplateItemsRequested = "GET_AREA_TEMPLATE_ITEMS_REQUESTED";
export const GetAreaTemplateItemsSuccess = "GET_AREA_TEMPLATE_ITEMS_SUCCESS";
export const GetAreaTemplateServices= "GET_AREA_TEMPLATE_SERVICES_ITEMS";
export const GetAreaTemplateServicesRequested = "GET_AREA_TEMPLATE_SERVICES_ITEMS_REQUESTED";
export const GetAreaTemplateServicesSuccess = "GET_AREA_TEMPLATE_SERVICES_ITEMS_SUCCESS";
export const GetCloneTemplate= "GET_CLONE_TEMPLATE_ITEMS";
export const GetCloneTemplateRequested = "GET_CLONE_TEMPLATE_REQUESTED";
export const GetCloneTemplateSuccess = "GET_CLONE_TEMPLATE_SUCCESS";
export const AddArea = "ADD_AREA";
export const AddAreaCheck = "ADD_AREA_CHECK";
export const AddAreaCheckLoad = "ADD_AREA_CHECK_LOAD";
export const AddAreaCheckRequested = "ADD_AREA_CHECK_REQUESTED";
export const AddAreaCheckSuccess = "ADD_AREA_CHECK_SUCCESS";
export const GetAreaList = "GET_AREA_LIST";
export const GetAreaListSuccess = "GET_AREA_LIST_SUCCESS";
export const GetAreaAllList = "GET_AREA_ALL_LIST";
export const GetAreaAllListRequested = "GET_AREA_ALL_LIST_REQUESTED";
export const GetAreaAllListSuccess = "GET_AREA_ALL_LIST_SUCCESS";
export const GetAreaDetail = 'GET_AREA_DETAIL';
export const GetAreaDetailRequested = 'GET_AREA_DETAIL_REQUESTED';
export const GetAreaDetailSuccess = 'GET_AREA_DETAIL_SUCCESS';
export const EditArea = "EDIT_AREA";
export const DeleteAreaTemplate = "DELETE_AREA_TEMPLATE";
export const DeleteArea = "DELETE_AREA";
export const RemoveAreaService = "REMOVE_AREA_SERVICE";
export const AddAreaDetail = "ADD_AREA_DETAIL";
export const EditAreaDetail = "EDIT_AREA_DETAIL";
export const GetServicesAreaAssociated = 'GET_SERVICES_AREA_ASSOICATED';
export const GetServicesAreaAssociatedRequested = 'GET_SERVICES_AREA_ASSOICATED_REQUESTED';
export const GetServicesAreaAssociatedSuccess = 'GET_SERVICES_AREA_ASSOICATED_SUCCESS';
export const RemoveAreaAssocaitedService = "REMOVE_AREA_ASSOICATED_SERVICE";
export const GetLocationAreaAssociated = 'GET_LOCATION_AREA_ASSOICATED';
export const GetLocationAreaAssociatedRequested = 'GET_LOCATION_AREA_ASSOICATED_REQUESTED';
export const GetLocationAreaAssociatedSuccess = 'GET_LOCATIOJN_AREA_ASSOICATED_SUCCESS';
export const ImportAreaList = 'IMPORT_AREA_LIST';




/*sublocation*/
export const AddSublocation = "ADD_SUBLOCATION";
export const EditSublocation = "EDIT_SUBLOCATION";
export const DeleteSublocation = "DELETE_SUBLOCATOIN";
export const DeleteSublocationSection = "DELETE_SUBLOCATOIN_SECTION";
export const GetSublocationList= "GET_SUBLOCATION_LIST";
export const GetSublocationListSuccess = "GET_SUBLOCATION_LIST_SUCCESS";
export const GetSublocationDetail = 'GET_SUBLOCATION_DETAIL';
export const GetSublocationDetailRequested = 'GET_SUBLOCATION_DETAIL_REQUESTED';
export const GetSublocationDetailSuccess = 'GET_SUBLOCATION_DETAIL_SUCCESS';
export const RemoveLocationAssocaited = "REMOVE_LOCTAION_ASSOICATED";
export const UpdateSublocationStatus = 'UPDATE_SUBLOCATION_STATUS';
export const GetAllSublocationList= "GET_ALL_SUBLOCATION_LIST";
export const GetAllSublocationListRequested = "GET_ALL_SUBLOCATION_LIST_REQUESTED";
export const GetAllSublocationListSuccess = "GET_ALL_SUBLOCATION_LIST_SUCCESS";
export const GetAllSectionList= "GET_ALL_SECTION_LIST";
export const GetAllSectionListRequested = "GET_ALL_SECTION_LIST_REQUESTED";
export const GetAllSectionListSuccess = "GET_ALL_SECTION_LIST_SUCCESS";

export const GetAllSublocationDropdownList= "GET_ALL_SUBLOCATION_LIST_DROPDOWN";
export const GetAllSublocationListDropdownRequested = "GET_ALL_SUBLOCATION_LIST_DROPDOWN_REQUESTED";
export const GetAllSublocationListDropdownSuccess = "GET_ALL_SUBLOCATION_LIST_DROPDOWN_SUCCESS";

export const ImportSublocationList = 'IMPORT_SUBLOCATION_LIST';


/*Rating Template*/

export const AddRatingTemplate= "ADD_RATING_TEMPLATE";
export const GetTemplateProviderRequested = 'RATING_TEMPLATE_PROVIER_REQUESTED';
export const GetTemplateProviderSucess = 'RATING_TEMPLATE_PROVIER_SUCCESS';
export const GetTemplateProvider = 'RATING_TEMPLATE_PROVIER';
export const GetTemplateProviderDetail = 'RATING_TEMPLATE_PROVIER_DETAIL';
export const GetRatingList= "GET_RATING_LIST";
export const GetRatingListSuccess = "GET_RATING_LIST_SUCCESS";
export const GetRatingTemplateDetail = 'GET_RATING_TEMPLATE_DETAIL';
export const GetRatingTemplateDetailRequested = 'GET_RATING_TEMPLATE_DETAIL_REQUESTED';
export const GetRatingTemplateDetailSuccess = 'GET_RATING_TEMPLATE_DETAIL_SUCCESS';
export const UpdateRatingStatus = 'UPDATE_RATING_TEMPLATE_STATUS';
export const EditRatingTemplate = "EDIT_RATING_TEMPLATE";
export const DeleteTemplateRating = "DELETE_TEMPLATE_RATING";
export const GetRatingTemplateDropdown= "GET_RATING_TEMPLATE_DROPDOWN";
export const GetRatingTemplateDropdownSuccess = "GET_RATING_TEMPLATE_DROPDOWN_SUCCESS";
export const ReplaceRatingStatus = 'REPLACE_RATING_TEMPLATE_STATUS';

/*Event*/
export const AddEvent= "ADD_EVENT";
export const EditEvent= "EDIT_EVENT";
export const GetInspectionList= "GET_INSPECTION_LIST";
export const GetInspectionListSuccess = "GET_INSPECTION_LIST_SUCCESS";
export const DeleteInspection = "DELETE_INSPECTION";
export const GetInspectionPoolList= "GET_INSPECTION_POOL_LIST";
export const GetInspectionPoolListRequested= "GET_INSPECTION_POOL_LIST_REQUESTED";
export const GetInspectionPoolListSuccess = "GET_INSPECTION_POOL_LIST_SUCCESS";
export const GetInspectionClaimedList= "GET_INSPECTION_CLAIMED_LIST";
export const GetInspectionClaimedListRequested= "GET_INSPECTION_CLAIMED_LIST_REQUESTED";
export const GetInspectionClaimedListSuccess = "GET_INSPECTION_CALIMED_LIST_SUCCESS";
export const GetInspectionDetail = 'GET_INSPECTION_DETAIL';
export const GetInspectionDetailRequested = 'GET_INSPECTION_DETAIL_REQUESTED';
export const GetInspectionDetailSuccess = 'GET_INSPECTION_DETAIL_SUCCESS';
export const GetEventList= "GET_EVENT_LIST";
export const GetEventListSuccess = "GET_EVENT_LIST_SUCCESS";
export const GetEventListRequested = "GET_EVENT_LIST_REQUESTED";
export const DeleteCalendar = "DELETE_CALENDAR";
export const CalendarCreateEvent= "CALENDAR_CREATE_EVENT";
export const CalendarCreateEventSuccess= "CALENDAR_CREATE_EVENT_SUCCESS";
export const ClaimInspection = "CLAIN_INSPECTION";
export const UnClaimInspection = "UNCLAIM_INSPECTION";
export const ChangeInspectionDate = "CHANGE_INSPECTION_DATE";
export const EditClaimEvent= "EDIT_CLAIM_EVENT";
export const GetWorkorderEventList= "GET_WORKORDER_EVENT_LIST";
export const GetWorkorderEventListSuccess = "GET_WORKORDER_EVENT_LIST_SUCCESS";
export const GetWorkorderListRequested = "GET_WORKORDER_EVENT_LIST_REQUESTED";
export const GetPeriodicEventList= "GET_PERIODIC_EVENT_LIST";
export const GetPeriodicEventListSuccess = "GET_PERIODIC_EVENT_LIST_SUCCESS";
export const GetPeriodicEventListRequested = "GET_PERIODIC_EVENT_LIST_REQUESTED";
export const  GetInspectionTemplates = 'GET_INSPECTION_TEMPLATE';
export const  GetInspectionTemplatesRequested = 'GET_INSPECTION_TEMPLATE_REQUESTED';
export const  GetInspectionTemplatesSuccess = 'GET_INSPECTION_TEMPLATE_SUCCESS';
export const  GetInspectionDropdownList = 'GET_INSPECTION_DROPDOWN';
export const  GetInspectionDropdownListRequested = 'GET_INSPECTION_DROPDOWN_REQUESTED';
export const  GetInspectionDropdownListSuccess = 'GET_INSPECTION_DROPDOWN_SUCCESS';

export const  GetInspectionSummary = 'GET_INSPECTION_SUMMARY';
export const  GetInspectionSummaryRequested = 'GET_INSPECTION_SUMMARY_REQUESTED';
export const  GetInspectionSummarySuccess = 'GET_INSPECTION_SUMMARY_SUCCESS';


/*notification*/

export const GetNotificationList= "GET_NOTIFICATION_LIST";
export const GetNotificationListRequested = "GET_NOTIFICATION_LIST_REQUESTED";
export const GetNotificationListSuccess = "GET_NOTIFICATION_LIST_SUCCESS";
export const GetNotificationAllList= "GET_NOTIFICATION_ALL_LIST";
export const GetNotificationAllListRequested= "GET_NOTIFICATION_ALL_LIST_REQUESTED";
export const GetNotificationAllListSuccess = "GET_NOTIFICATION_ALL_LIST_SUCCESS";
export const GetNotificationAllListSuccessReload = "GET_NOTIFICATION_ALL_LIST_RELOAD";
export const NotificationReadAll = "NOTIFICATION_READ_ALL";
export const GetNotificationRead= "GET_NOTIFICATION_READ";
export const GetNotificationDetail= "GET_NOTIFICATION_DETAIL";
export const GetNotificationDetailRequested = "GET_NOTIFICATION_DETAIL_REQUESTED";
export const GetNotificationDetailSuccess = "GET_NOTIFICATION_DETAIL_REQUESTED_SUCCESS";

/*work-order*/

export const GetWorkEmployeList= "GET_WORK_EMPLOYE_LIST";
export const GetWorkEmployeListSuccess = "GET_WORK_EMPLOYE_LIST_SUCCESS";
export const GetWorkEmployeRequested = "GET_WORK_EMPLOYE_LIST_REQUESTED";
export const GetWorkContactList= "GET_WORK_CONTACT_LIST";
export const GetWorkContactListSuccess = "GET_WORK_CONTACT_LIST_SUCCESS";
export const GetWorkContactListRequested = "GET_WORK_CONTACT_LIST_REQUESTED";
export const AddWorkOrder = "ADD_WORK_ORDER";
export const GetOrderList= "GET_ORDER_LIST";
export const GetOrderListSuccess = "GET_ORDER_LIST_SUCCESS";
export const GetOrderListSearch= "GET_ORDER_LIST_SEARCH";
export const GetOrderListSearchRequested= "GET_ORDER_LIST_SEARCH_REQUESTED";
export const GetOrderListSearchSuccess= "GET_ORDER_LIST_SEARCH_SUCCESS";
export const DeleteWorkOrder = "DELETE_WORK_ORDER";
export const MarkOrderComplete = "MARK_ORDER_COMPLETE";
export const GetSasToken= "GET_SAS_TOKEN";
export const GetSasTokenRequested= "GET_SAS_TOKEN_REQUESTED";
export const GetSasTokenSuccess= "GET_SAS_TOKEN_SUCCESS";
export const GetOrderDetail = 'GET_ORDER_DETAIL';
export const GetOrderDetailRequested = 'GET_ORDER_DETAIL_REQUESTED';
export const GetOrderDetailSuccess = 'GET_ORDER_DETAIL_SUCCESS';
export const UpDateWorkOrder = "UPDATE_WORK_ORDER";
export const GetLastUpdateHistory = 'GET_LAST_UPDATE_HISTORY';
export const GetLastUpdateHistorySuccess = 'GET_LAST_UPDATE_HISTORY_SUCCESS';

export const GetWorkMessageList= "GET_WORK_MESSAGE_LIST";
export const GetWorkMessageListRequested = "GET_WORK_MESSAGE_LIST_REQUESTED";
export const GetWorkMessageListSuccess = "GET_WORK_MESSAGE_LIST_SUCCESS";
export const AddWorkorderMessage = "ADD_WORK_ORDER_MESSAGE";
export const AddWorkorderMessageSuccess = "ADD_WORK_ORDER_MESSAGE_SUCCESS";
export const ImportWorkOrderList = 'IMPORT_WORKORDER_LIST'

/*permission*/

export const GetPermissionList= "GET_PERMISSION_LIST";
export const GetPermissionListSuccess = "GET_PERMISSION_LIST_SUCCESS";
export const GetPermissionListRequested = "GET_PERMISSION_LIST_REQUESTED";
export const DeleteGetPermission = "DELETE_PERMISSION_LIST";
export const GetPermissionModuleList= "GET_PERMISSION_MODULE_LIST";
export const GetPermissionModuleListSuccess = "GET_PERMISSION_MODULE_LIST_SUCCESS";
export const GetPermissionModuleListRequested = "GET_PERMISSION_MODULE_LIST_REQUESTED";
export const AddPermissionTemplate = "ADD_PERMISSION_TEMPLATE";
export const UpDatePermissionTemplate = "UPDATE_PERMISSION_TEMPLATE";
export const GetPermissionDetail = 'GET_PERMISSION_DETAIL';
export const GetPermissionDetailRequested = 'GET_PERMISSION_DETAIL_REQUESTED';
export const GetPermissionDetailSuccess = 'GET_PERMISSION_DETAIL_SUCCESS';
export const GetPermissionDropdownList= "GET_PERMISSION_DROPDOWN_LIST";
export const GetPermissionDropdownListSuccess = "GET_PERMISSION_DROPDOWN_LIST_SUCCESS";
export const GetPermissionDropdownListRequested = "GET_PERMISSION_DROPDOWN_LIST_REQUESTED";
export const GetPermissionPrimaryDetail = 'GET_PERMISSION_PRIMARY_DETAIL';
export const GetPermissionPrimaryDetailRequested = 'GET_PERMISSION_PRIMARY_DETAIL_REQUESTED';
export const GetPermissionPrimaryDetailSuccess = 'GET_PERMISSION_PRIMARY_DETAIL_SUCCESS';
export const GetPermissionSecondaryDetail = 'GET_PERMISSION_SECONDARY_DETAIL';
export const GetPermissionSecondaryDetailRequested = 'GET_PERMISSION_SECONDARY_DETAIL_REQUESTED';
export const GetPermissionSecondaryDetailSuccess = 'GET_PERMISSION_SECONDARY_DETAIL_SUCCESS';

/*permission*/

export const AddPeriodic = "ADD_PERIODIC";
export const UpDatePeriodic = "UPDATE_PERIODIC";
export const GetPeriodicDetail = 'GET_PERIODIC_DETAIL';
export const GetPeriodicDetailRequested = 'GET_PERIODIC_DETAIL_REQUESTED';
export const GetPeriodicDetailSuccess = 'GET_PERIODIC_DETAIL_SUCCESS';
export const DeletePeriodic = "DELETE_PERIODIC_LIST";
export const GetPeriodicList = "GET_PERIODIC_LIST";
export const GetPeriodicListSuccess = "GET_PERIODIC_LIST_SUCCESS";
export const GetPeriodicListRequested = "GET_PERIODIC_LIST_REQUESTED";
export const MarkPeriodicComplete = "MARK_PERIODIC_COMPLETE";
export const GetLastUpdatePeriodicHistory = 'GET_LAST_UPDATE_PERIODIC_HISTORY';
export const GetLastUpdatePeriodicHistorySuccess = 'GET_LAST_UPDATE_PERIODIC_HISTORY_SUCCESS';

export const GetPeriodicMessageList= "GET_PERIODIC_MESSAGE_LIST";
export const GetPeriodicMessageListRequested = "GET_PERIODIC_MESSAGE_LIST_REQUESTED";
export const GetPeriodicMessageListSuccess = "GET_PERIODIC_MESSAGE_LIST_SUCCESS";
export const AddPeriodicorderMessage = "ADD_PERIODIC_ORDER_MESSAGE";
export const AddPeriodicorderMessageSuccess = "ADD_PERIODIC_ORDER_MESSAGE_SUCCESS";

/*questions*/
export const AddCustomQuestion = "ADD_CUSTOM_QUESTION";
export const UpDateCustomQuestion = "UPDATE_CUSTOM_QUESTION";
export const GetCustomQuestion = 'GET_CUSTOM_QUESTION';
export const GetCustomQuestionRequested = 'GET_CUSTOM_QUESTION_REQUESTED';
export const GetCustomQuestionSuccess = 'GET_CUSTOM_QUESTION_SUCCESS';
export const  GetCustomQuestionDetail = 'GET_CUSTOM_QUESTION_DETAIL';
export const  GetCustomQuestionDetailRequested = 'GET_CUSTOM_QUESTION_DETAIL_REQUESTED';
export const  GetCustomQuestionDetailSuccess = 'GET_CUSTOM_QUESTION_DETAIL_SUCCESS';
export const DeleteCustomQuestion = "DELETE_CUSTOM_QUESTION";
export const DeleteCustomQuestionField = "DELETE_CUSTOM_QUESTION_FIELD";
export const DeleteCustomQuestionOption= "DELETE_CUSTOM_QUESTION_OPTION";
export const AddCustomServiceLocation= "ADD_CUSTOM_SERVICE_LOCATION";
export const GetCustomServiceLocations= "GET_CUSTOM_QUESTON_SERVICE_LOCATION";
export const GetCustomServiceLocationsRequested= "GET_CUSTOM_QUESTON_SERVICE_LOCATION_REQUESTED";
export const GetCustomServiceLocationsSuccess= "GET_CUSTOM_QUESTON_SERVICE_LOCATION_SUCCESS";
export const DeleteCustomServiceLocation = "DELETE_CUSTOM_QUESTION_SERVICE_LOCATION";

/*Report*/
export const AddReport = "ADD_REPORT";
export const GetSavedReport = 'GET_SAVED_REPORTS';
export const GetSavedReportSuccess = 'GET_SAVED_REPORTS_SUCCESS';
export const GetReportDetail = 'GET_REPORT_DETAIL';
export const GetReportDetailRequested = 'GET_REPORT_DETAIL_REQUESTED';
export const GetReportDetailSuccess = 'GET_REPORT_DETAIL_SUCCESS';
export const UpDateReoprt = "UPDATE_REPORT";
export const GenrateReport = "GENRATE_REPORT";
export const GenrateReportRequested = "GENRATE_REPORT_REQUESTED";
export const GenrateReportSuccess = "GENRATE_REPORT_SUCCESS";
export const DeleteSavedReport = "DELETE_SAVED_REPORT";


/*Dahsboard*/

export const GetDashboardOrderList = "GET_DASHBOARD_ORDER_LIST";
export const GetDashboardOrderListRequested = "GET_DASHBOARD_ORDER_LIST_REQUESTED";
export const GetDashboardOrderListSuccess = "GET_DASHBOARD_ORDER_LIST_SUCCESS";
export const AddDashboardOrderList = "ADD_DASHBOARD_ORDER_LIST";
export const  GetWorkOrderSummary = 'GET_WORK_ORDER_SUMMARY';
export const  GetWorkOrderSummaryRequested = 'GET_WORK_ORDER_SUMMARY_REQUESTED';
export const  GetWorkOrderSummarySuccess = 'GET_WORK_ORDER_SUMMARY_SUCCESS';
export const GetWidgetList = "GET_WIDGET_LIST";
export const GetAverageWidgetListRequested = "GET_AVERAGE_WIDGET_LIST_REQUESTED";
export const GetAverageWidgetListSuccess = "GET_AVERAGE_WIDGET_LIST_SUCESS";
export const GetTopClientWidgetListRequested = "GET_TOPCLIENT_WIDGET_LIST_REQUESTED";
export const GetTopClientWidgetListSuccess = "GET_TOPCLIENT_WIDGET_LIST_SUCESS";
export const GetBottomClientWidgetListRequested = "GET_BOTTOMCLIENT_WIDGET_LIST_REQUESTED";
export const GetBottomClientWidgetListSuccess = "GET_BOTTOMCLIENT_WIDGET_LIST_SUCESS";
export const GetTopServiceWidgetListRequested = "GET_TOPSERVICE_WIDGET_LIST_REQUESTED";
export const GetTopServiceWidgetListSuccess = "GET_TOPSERVICE_WIDGET_LIST_SUCESS";
export const GetBottomServiceWidgetListRequested = "GET_BOTTOMSERVICE_WIDGET_LIST_REQUESTED";
export const GetBottomServiceWidgetListSuccess = "GET_BOTTOMSERVICE_WIDGET_LIST_SUCESS";
export const GetWorkorderWidgetList = "GET_WORKORDER_WIDGET_LIST";
export const GetOpenWidgetListRequested = "GET_OPEN_WIDGET_LIST_REQUESTED";
export const GetOpenWidgetListSuccess = "GET_OPEN_WIDGET_LIST_SUCESS";
export const GetPerMonthWidgetListRequested = "GET_PERMONTH_WIDGET_LIST_REQUESTED";
export const GetPerMonthWidgetListSuccess = "GET_PERMONTH_WIDGET_LIST_SUCESS";
export const GetByOriginWidgetListRequested = "GET_ORIGIN_WIDGET_LIST_REQUESTED";
export const GetByOriginWidgetListSuccess = "GET_ORIGIN_WIDGET_LIST_SUCESS";
export const GetAvergaeCompetionWidgetListRequested = "GET_AVERAGECOMPETION_WIDGET_LIST_REQUESTED";
export const GetAvergaeCompetionWidgetListSuccess = "GET_AVERAGECOMPETION_WIDGET_LIST_SUCESS";
export const  GetPeriodicSummary = 'GET_PERIODIC_SUMMARY';
export const  GetPeriodicSummaryRequested = 'GET_PERIODIC_REQUESTED';
export const  GetPeriodicSummarySuccess = 'GET_PERIODIC_SUCCESS';
export const GetTopClientPeriodicWidgetListRequested = "GET_TOPCLIENTPERIODIC_WIDGET_LIST_REQUESTED";
export const GetTopClientPeriodicWidgetListSuccess = "GET_TOPCLIENTPERIODIC_WIDGET_LIST_SUCESS";
export const GetPerMonthPeriodicWidgetListRequested = "GET_PERMONTHPERIODIC_WIDGET_LIST_REQUESTED";
export const GetPerMonthPeriodicWidgetListSuccess = "GET_PERMONTHPERIODIC_WIDGET_LIST_SUCESS";
export const GetOpenPeriodicWidgetListRequested = "GET_OPENPERIODIC_WIDGET_LIST_REQUESTED";
export const GetOpenPeriodicWidgetListSuccess = "GET_OPENPERODIC_WIDGET_LIST_SUCESS";
export const GetPeriodicWidgetList = "GET_PERIODIC_WIDGET_LIST";
export const  SuperDashboardSummary = 'SUPER_DASHBOARD_SUMMARY';
export const  SuperDashboardSummaryRequested = 'SUPER_DASHBOARD_SUMMARY_REQUESTED';
export const  SuperDashboardSummarySuccess = 'SUPER_DASHBOARD_SUMMARY_SUCCESS';
export const GetSuperDashboardOrderList = "GET_SUPER_DASHBOARD_ORDER_LIST";
export const GetSuperDashboardOrderListRequested = "GET_SUPER_DASHBOARD_ORDER_LIST_REQUESTED";
export const GetSuperDashboardOrderListSuccess = "GET_SUPER_DASHBOARD_ORDER_LIST_SUCCESS";
export const AddSuperDashboardOrderList = "ADD_SUPER_DASHBOARD_ORDER_LIST";
export const GetSuperWidgetList = "GET_SUPER_WIDGET_LIST";
export const TopClientsInspectionRequested = "TOP_CLIENT_INSPECTIONS_REQUESTED";
export const TopClientsInspectionSuccess = "TOP_CLIENT_INSPECTIONS_SUCCESS";
export const BottomClientsInspectionRequested = "BOTTOM_CLIENT_INSPECTIONS_REQUESTED";
export const BottomClientsInspectionSuccess = "BOTTOM_CLIENT_INSPECTIONS_SUCCESS";
export const TopClientsWorkorderRequested = "TOP_CLIENT_WORKORDER_REQUESTED";
export const TopClientsWorkorderSuccess = "TOP_CLIENT_WORKORDER_SUCCESS";
export const BottomClientsWorkorderRequested = "BOTTOM_CLIENT_WORKORDER_REQUESTED";
export const BottomClientsWorkorderSuccess = "BOTTOM_CLIENT_WORKORDER_SUCCESS";
export const TopClientsPeriodicRequested = "TOP_CLIENT_PERIODIC_REQUESTED";
export const TopClientsPeriodicSuccess = "TOP_CLIENT_PERIODIC_SUCCESS";
export const BottomClientsPeriodicRequested = "BOTTOM_CLIENT_PERIODIC_REQUESTED";
export const BottomClientsPeriodicSuccess = "BOTTOM_CLIENT_PERIODIC_SUCCESS";
export const TopServiceLocationRequested = "TOP_SERVICE_LOCATION_REQUESTED";
export const TopServiceLocationSuccess = "TOP_SERVICE_LOCATION_SUCCESS";
export const BottomServiceLocationRequested = "BOTTOM_SERVICE_LOCATION_REQUESTED";
export const BottomServiceLocationSuccess = "BOTTOM_SERVICE_LOCATION_SUCCESS";
export const InspectionPerMonthRequested = "INSPECTION_PER_MONTH_REQUESTED";
export const InspectionPerMonthSuccess = "TINSPECTION_PER_MONTH_SUCCESS";
export const WorkOrderPerMonthRequested = "WORKORDER_PER_MONTH_REQUESTED";
export const WorkOrderPerMonthSuccess = "WORKORDER_PER_MONTH_SUCCESS";
export const GenrateMasterTemplate = "GENRATE_MASTER_TEMPLATE";


/*Import Files*/
export const ImportFilesRequested = "IMPORT_FILES_REQUESTED";
export const ImportFilesSuccess = "IMPORT_FILES_SUCCESS";
export const ImportFilesFailure = "IMPORT_FILES_FAILURE";









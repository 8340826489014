import * as actionTypes from '../actionTypes';

const initialState = {
    configurationDetail: null,
    positionList : null,
    positionDetail : null,
    positionDropdown :  null,
    positionEmployeList : null,
    positionContactList : null
};
const ConfigurationReducer = (state, { type , payload}) => {

    if (state === undefined) {
        state = initialState
    }
    
    let object;
    switch (type) {

        case actionTypes.GetConfigurationRequested:
            object = { ...state, configurationDetail: null }
            break

        case actionTypes.GetConfigurationSuccess:
            object = { ...state, configurationDetail: payload }
            break

        case actionTypes.GetPositionSuccess:
            object = { ...state, positionList: payload }
            break

        case actionTypes.GetPostionDetailRequested:
            object = { ...state, positionDetail: null }
            break

        case actionTypes.GetPostionDetailSuccess:
            object = { ...state, positionDetail: payload }
            break

        case actionTypes.GetPostionDropdownSuccess:
            object = { ...state, positionDropdown: payload }
            break

        case actionTypes.GetPositionEmployeSuccess:
            object = { ...state, positionEmployeList: payload }
            break

        case actionTypes.GetPositionContactSuccess:
            object = { ...state, positionContactList: payload }
            break
            
        case actionTypes.LogOut: 
        object = { state : undefined };
            break

        default:
            object = state;
    }
    return object
};
export default ConfigurationReducer;
import * as actionTypes from '../actionTypes';

const initialState = {
    isWarning: false,
    isSuccess: false,
    isError : false,
    errorMessage: null,
    errorData : null,
};
const ErrorReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    switch (type) {

        case actionTypes.LoadTodoRequested:
            object = { ...state, errorMessage: null , isSuccess: false, isWarning: false , isError : false }
            break

        case actionTypes.LoadTodoSuccess:
            object = { ...state, errorMessage: payload, isSuccess: true, isWarning: false , isError : false}
            break

        case actionTypes.LoadTodoWarning:
            object = { ...state, errorMessage: payload, isSuccess: false, isWarning: true , isError : false}
            break

        case actionTypes.LoadTodoError:
            object = { ...state, errorMessage: payload, isSuccess: false, isWarning: false , isError : true}
            break

        case actionTypes.Http500Error:
            object = { ...state, errorData: payload}
            break

        case actionTypes.HttpOtherError:
            object = { ...state, errorData: payload}
            break

        case actionTypes.LogOut: 
            object = { state : undefined };
            break
            
        default:
            object = state;
    }
    return object
};
export default ErrorReducer;
import * as actionTypes from '../actionTypes';

const initialState = {
    timezonelist: null,
    serviceList: null,
    serviceDetail: null,
    allServiceList :null,
    serviceContacts: null,
    archieveServiceList : null,
    reportAllServiceList : null,
    serviceDropdownList : null
};
const serviceReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    switch (type) {

        case actionTypes.GetTimezoneSuccess:
            object = { ...state, timezonelist: payload }
            break

        case actionTypes.GetServiceLocationRequested:
            object = { ...state, 
               // serviceList: null
             }
            break

        case actionTypes.GetServiceLocationSuccess:
            object = { ...state, serviceList: payload }
            break

        case actionTypes.GetServiceDetailRequested:
            object = { ...state, serviceDetail: null }
            break

        case actionTypes.GetServiceDetailSuccess:
            object = { ...state, serviceDetail: payload }
            break

        case actionTypes.GetAllServiceListSuccess:
            object = { ...state, allServiceList: payload }
            break

        case actionTypes.GetAllServiceListRequested:
            object = { ...state, allServiceList: null }
            break

        case actionTypes.GetServiceContactsRequested:
            object = { ...state, serviceContacts: null }
            break

        case actionTypes.GetServiceContactsSuccess:
            object = { ...state, serviceContacts: payload }
            break

        case actionTypes.GetServiceArchiveListRequested:
            object = { ...state, 
              //  archieveServiceList : null 
            }
            break   
            
        case actionTypes.GetServiceArchiveListSuccess:
            object = { ...state, archieveServiceList : payload }
            break

        case actionTypes.GetAllServiceListReportRequested:
            object = { ...state, reportAllServiceList : null }
            break
            
        case actionTypes.GetAllServiceListReportSuccess:
            object = { ...state, reportAllServiceList : payload }
            break
            
        case actionTypes.GetAllServiceDropdownRequested:
            object = { ...state, serviceDropdownList : null }
            break

        case actionTypes.GetAllServiceDropdownSuccess:
            object = { ...state, serviceDropdownList : payload }
            break

        case actionTypes.LogOut: 
            object = { state : undefined };
            break
        default:
            object = state;
    }
    return object
};
export default serviceReducer;
import * as actionTypes from '../actionTypes';


export const addEmploye = (data) => {
    return {
        type: actionTypes.AddEmploye,
        payload: data
    }
}


export const getEmployeList = (data) => {
    return {
        type: actionTypes.GetEmployeList,
        payload: data
    }
}

export const geEmployeListRequested = (data) => {
    return {
        type: actionTypes.GetEmployeListRequested,
        payload: data
    }
}


export const geEmployeListSuccess = (data) => {
    return {
        type: actionTypes.GetEmployeListSuccess,
        payload: data
    }
}



export const updateEmployeStatus = (data) => {
    return {
        type: actionTypes.UpdateEmployeStatus,
        payload: data
    }
}

export const deleteEmploye = (data) => {
    return {
        type: actionTypes.DeleteEmploye,
        payload: data
    }
}


export const getEmployeDetail = (data) => {
    return {
        type: actionTypes.GetEmployeDetail,
        payload: data
    }
}

export const getEmployeDetailRequested = () => {
    return {
        type: actionTypes.GetEmployeDetailRequested,
    }
}

export const getEmployeDetailSuccess = (data) => {
    return {
        type: actionTypes.GetEmployeDetailSuccess,
        payload: data
    }
}


export const editEmploye = (data) => {
    return {
        type: actionTypes.EditEmploye,
        payload: data
    }
}

export const getEmployeListArchieve = (data) => {
    return {
        type: actionTypes.GetEmployeArchiveList,
        payload: data
    }
}

export const getEmployeListArchieveRequested = (data) => {
    return {
        type: actionTypes.GetEmployeArchiveListRequested,
        payload: data
    }
}


export const getEmployeListArchieveSuccess = (data) => {
    return {
        type: actionTypes.GetEmployeArchiveListSuccess,
        payload: data
    }
}


export const updateEmployeArchieveStatus = (data) => {
    return {
        type: actionTypes.UpdateEmployeArchieveStatus,
        payload: data
    }
}


export const deleteEmployePostion = (data) => {
    return {
        type: actionTypes.DeleteEmployePostion,
        payload: data
    }
}

export const getEmployeDropdownList = (data) => {
    return {
        type: actionTypes.GetEmployeDropdownList,
        payload: data
    }
}

export const getEmployeDropdownListRequested = (data) => {
    return {
        type: actionTypes.GetEmployeDropdownListRequested,
        payload: data
    }
}

export const getEmployeDropdownListSuccess = (data) => {
    return {
        type: actionTypes.GetEmployeDropdownListSuccess,
        payload: data
    }
}


export const getAllInspectorList = (data) => {
    return {
        type: actionTypes.GetAllInspectorList,
        payload: data
    }
}

export const getAllInspectorListRequested = (data) => {
    return {
        type: actionTypes.GetAllInspectorListRequested,
        payload: data
    }
}

export const getAllInspectorListSuccess = (data) => {
    return {
        type: actionTypes.GetAllInspectorListSuccess,
        payload: data
    }
}

import React, { Component } from "react";
import AdminNav from "./components/AdminNav";
import { connect } from "react-redux";
import Helper from "utils/helpers";
import { UserGroups } from "config";
/*components*/
import DashboardLogo from "./components/DashboardLogo";
import DashboardNav from "./components/DashboardNav";


class DashboardMenu extends Component {

  render() {
    const { expandState, inperosnate, profile, userProfile, dashLayout } = this.props;
    // console.log("nav" , this.props);
    return (
      <div className="dashboard-sidebar" onMouseOver={this.props.handleSideMouseOver} onMouseOut={this.props.handleSideMouseOut}>
        <DashboardLogo expandState={expandState} dashLayout={dashLayout} />
        {!inperosnate && profile && profile.role === 2 && <DashboardNav expandState={expandState}  dashLayout={dashLayout} />}
        {inperosnate && profile && profile.role === 2 && <AdminNav expandState={expandState} dashLayout={dashLayout} userProfile={this.props.userProfile} />}
        {profile && (profile.role === 3 || profile.role === 4) && <AdminNav expandState={expandState} dashLayout={dashLayout} userProfile={this.props.userProfile} />}
      </div>
    );
  }
}



const mapStateToProps = ({
  auth: { profile, inperosnate, userProfile }
}) =>
  ({ profile, inperosnate, userProfile });

export default connect(mapStateToProps)(DashboardMenu);

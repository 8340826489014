import * as actionTypes from '../actionTypes';

const initialState = {
    inspectionList: null,
    inspectionDetail: null,
    eventList: null,
    workOrderEventList: null,
    periodicEventList: null,
    calendarEventDetail: null,
    inspectionPoolList: null,
    inspectionClaimedList: null,
    inspectionTemplateList: null,
    inspectionDropdownList: null,
    inspectionSummary: null
};
const EventReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;

    switch (type) {

        case actionTypes.GetInspectionListSuccess:
            object = { ...state, inspectionList: payload }
            break

        case actionTypes.GetInspectionDetailRequested:

            object = { ...state, inspectionDetail: null }
            break

        case actionTypes.GetInspectionDetailSuccess:
            object = { ...state, inspectionDetail: payload }
            break

        case actionTypes.GetEventListRequested:
            object = { ...state, eventList: null }
            break

        case actionTypes.GetEventListSuccess:
            object = { ...state, eventList: payload }
            break

        case actionTypes.GetWorkorderListRequested:
            object = { ...state, workOrderEventList: null }
            break

        case actionTypes.GetWorkorderEventListSuccess:
            object = { ...state, workOrderEventList: payload }
            break

        case actionTypes.GetPeriodicEventListRequested:
            object = { ...state, periodicEventList: null }
            break

        case actionTypes.GetPeriodicEventListSuccess:
            object = { ...state, periodicEventList: payload }
            break

        case actionTypes.CalendarCreateEventSuccess:
            object = { ...state, calendarEventDetail: payload }
            break

        case actionTypes.GetInspectionPoolListRequested:
            object = { ...state, inspectionPoolList: null }
            break

        case actionTypes.GetInspectionPoolListSuccess:
            object = { ...state, inspectionPoolList: payload }
            break

        case actionTypes.GetInspectionClaimedListRequested:
            object = { ...state, inspectionClaimedList: null }
            break

        case actionTypes.GetInspectionClaimedListSuccess:
            object = { ...state, inspectionClaimedList: payload }
            break


        case actionTypes.GetInspectionTemplatesRequested:
            object = { ...state, inspectionTemplateList: null }
            break

        case actionTypes.GetInspectionTemplatesSuccess:
            object = { ...state, inspectionTemplateList: payload }
            break

        case actionTypes.GetInspectionDropdownListRequested:
            object = { ...state, inspectionDropdownList: null }
            break

        case actionTypes.GetInspectionDropdownListSuccess:
            object = { ...state, inspectionDropdownList: payload }
            break

        case actionTypes.GetInspectionSummaryRequested:
            object = { ...state, inspectionSummary: null }
            break

        case actionTypes.GetInspectionSummarySuccess:
            object = { ...state, inspectionSummary: payload }
            break

        case actionTypes.LogOut:
            object = { state: undefined };
            break


        default:
            object = state;
    }
    return object
};
export default EventReducer;
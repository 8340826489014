import * as actionTypes from '../actionTypes';

export const addCustomQuestion = (data) => {
    return {
        type: actionTypes.AddCustomQuestion,
        payload: data
    }
}

export const updateCustomQuestion = (data) => {
    return {
        type: actionTypes.UpDateCustomQuestion,
        payload: data
    }
}

export const getCustomQuestion = (data) => {
    return {
        type: actionTypes.GetCustomQuestion,
        payload: data
    }
}
export const getCustomQuestionRequested = (data) => {
    return {
        type: actionTypes.GetCustomQuestionRequested,
        payload: data
    }
}
export const getCustomQuestionSuccess = (data) => {
    return {
        type: actionTypes.GetCustomQuestionSuccess,
        payload: data
    }
}

export const getCustomQuestionDetail = (data) => {
    return {
        type: actionTypes.GetCustomQuestionDetail,
        payload: data
    }
}
export const getCustomQuestionDetailRequested = (data) => {
    return {
        type: actionTypes.GetCustomQuestionDetailRequested,
        payload: data
    }
}
export const getCustomQuestionDetailSuccess = (data) => {
    return {
        type: actionTypes.GetCustomQuestionDetailSuccess,
        payload: data
    }
}
export const deleteCustomQuestion = (data) => {
    return {
        type: actionTypes.DeleteCustomQuestion,
        payload: data
    }
}
export const deleteCustomQuestionField = (data) => {
    return {
        type: actionTypes.DeleteCustomQuestionField,
        payload: data
    }
}
export const deleteCustomQuestionOption = (data) => {
    return {
        type: actionTypes.DeleteCustomQuestionOption,
        payload: data
    }
}

export const addCustomServiceLocation = (data) => {
    return {
        type: actionTypes.AddCustomServiceLocation,
        payload: data
    }
}

export const getCustomServiceLocations = (data) => {
    return {
        type: actionTypes.GetCustomServiceLocations,
        payload: data
    }
}

export const getCustomServiceLocationsRequested = (data) => {
    return {
        type: actionTypes.GetCustomServiceLocationsRequested,
        payload: data
    }
}

export const getCustomServiceLocationsSuccess = (data) => {
    return {
        type: actionTypes.GetCustomServiceLocationsSuccess,
        payload: data
    }
}

export const deleteCustomServiceLocation = (data) => {
    return {
        type: actionTypes.DeleteCustomServiceLocation,
        payload: data
    }
}




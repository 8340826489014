import * as actionTypes from '../actionTypes';

const initialState = {
    dashboardorderList: null,
    averageWidgetData: null,
    averageWidgetLoader: false,
    topClientWidgetData: null,
    topClientWidgetLoader: false,
    bottomClientWidgetData: null,
    bottomClientWidgetLoader: false,
    topServiceWidgetData: null,
    topServiceWidgetLoader: false,
    bottomServiceWidgetData: null,
    bottomServiceWidgetLoader: false,
    workorderSummary: null,
    openWidgetLoader: false,
    openWidgetData: null,
    perMonthWidgetLoader: false,
    perMonthWidgetData: null,
    byOriginWidgetLoader: false,
    byOriginWidgetData: null,
    averageCompetionWidgetLoader: false,
    averageCompetionWidgetData: null,
    topClientPeriodicWidgetData: null,
    topClientPeriodicWidgetLoader: false,
    openPeriodicWidgetLoader: false,
    openPeriodicWidgetData: null,
    perMonthPeriodicWidgetLoader: false,
    perMonthPeriodicWidgetData: null,
    periodicSummary: null,
    superdashboardSummary: null,
    superdashboardorderList: null,
    topClientInspectionWidgetData: null,
    topClientInspectionWidgetLoader: false,
    bottomClientInspectionWidgetData: null,
    bottomClientInspectionWidgetLoader: false,
    topClientWorkorderWidgetData: null,
    topClientWorkorderWidgetLoader: false,
    bottomClientWorkorderWidgetData: null,
    bottomClientWorkorderWidgetLoader: false,
    topClientPeriodicWidgetData: null,
    topClientPeriodicWidgetLoader: false,
    bottomClientPeriodicWidgetData: null,
    bottomClientPeriodicWidgetLoader: false,
    topServiceLocationWidgetData: null,
    topServiceLocationWidgetLoader: false,
    bottomServiceLocationWidgetData: null,
    bottomServiceLocationWidgetLoader: false,

    inspectionPerMonthWidgetData: null,
    inspectionPerMonthWidgetLoader: false,
    workorderPermonthWidgetData: null,
    workorderPermonthWidgettLoader: false,


};

const dashboardReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;

    switch (type) {

        case actionTypes.GetDashboardOrderListRequested:
            object = { ...state, dashboardorderList: null }
            break

        case actionTypes.GetDashboardOrderListSuccess:
            object = { ...state, dashboardorderList: payload }
            break


        case actionTypes.GetAverageWidgetListRequested:
            object = { ...state, averageWidgetLoader: true, averageWidgetData: null }
            break

        case actionTypes.GetAverageWidgetListSuccess:
            object = { ...state, averageWidgetLoader: false, averageWidgetData: payload }
            break


        case actionTypes.GetTopClientWidgetListRequested:
            object = { ...state, topClientWidgetLoader: true, topClientWidgetData: null }
            break

        case actionTypes.GetTopClientWidgetListSuccess:
            object = { ...state, topClientWidgetLoader: false, topClientWidgetData: payload }
            break


        case actionTypes.GetBottomClientWidgetListRequested:
            object = { ...state, bottomClientWidgetLoader: true, bottomClientWidgetData: null }
            break

        case actionTypes.GetBottomClientWidgetListSuccess:
            object = { ...state, bottomClientWidgetLoader: false, bottomClientWidgetData: payload }
            break


        case actionTypes.GetTopServiceWidgetListRequested:
            object = { ...state, topServiceWidgetLoader: true, topServiceWidgetData: null }
            break

        case actionTypes.GetTopServiceWidgetListSuccess:
            object = { ...state, topServiceWidgetLoader: false, topServiceWidgetData: payload }
            break


        case actionTypes.GetBottomServiceWidgetListRequested:
            object = { ...state, bottomServiceWidgetLoader: true, bottomServiceWidgetData: null }
            break

        case actionTypes.GetBottomServiceWidgetListSuccess:
            object = { ...state, bottomServiceWidgetLoader: false, bottomServiceWidgetData: payload }
            break

        case actionTypes.GetWorkOrderSummaryRequested:
            object = { ...state, workorderSummary: null }
            break

        case actionTypes.GetWorkOrderSummarySuccess:
            object = { ...state, workorderSummary: payload }
            break

        case actionTypes.GetOpenWidgetListRequested:
            object = { ...state, openWidgetLoader: true, openWidgetData: null }
            break

        case actionTypes.GetOpenWidgetListSuccess:
            object = { ...state, openWidgetLoader: false, openWidgetData: payload }
            break


        case actionTypes.GetPerMonthWidgetListRequested:
            object = { ...state, perMonthWidgetLoader: true, perMonthWidgetData: null }
            break

        case actionTypes.GetPerMonthWidgetListSuccess:
            object = { ...state, perMonthWidgetLoader: false, perMonthWidgetData: payload }
            break


        case actionTypes.GetByOriginWidgetListRequested:
            object = { ...state, byOriginWidgetLoader: true, byOriginWidgetData: null }
            break

        case actionTypes.GetByOriginWidgetListSuccess:
            object = { ...state, byOriginWidgetLoader: false, byOriginWidgetData: payload }
            break

        case actionTypes.GetAvergaeCompetionWidgetListRequested:
            object = { ...state, averageCompetionWidgetLoader: true, averageCompetionWidgetData: null }
            break

        case actionTypes.GetAvergaeCompetionWidgetListSuccess:
            object = { ...state, averageCompetionWidgetLoader: false, averageCompetionWidgetData: payload }
            break

        case actionTypes.LogOut:
            object = { state: undefined };
            break

        case actionTypes.GetPeriodicSummaryRequested:
            object = { ...state, periodicSummary: null }
            break

        case actionTypes.GetPeriodicSummarySuccess:
            object = { ...state, periodicSummary: payload }
            break

        case actionTypes.GetTopClientPeriodicWidgetListRequested:
            object = { ...state, topClientPeriodicWidgetLoader: true, topClientPeriodicWidgetData: null }
            break

        case actionTypes.GetTopClientPeriodicWidgetListSuccess:
            object = { ...state, topClientPeriodicWidgetLoader: false, topClientPeriodicWidgetData: payload }
            break

        case actionTypes.GetPerMonthPeriodicWidgetListRequested:
            object = { ...state, perMonthPeriodicWidgetLoader: true, perMonthPeriodicWidgetData: null }
            break

        case actionTypes.GetPerMonthPeriodicWidgetListSuccess:
            object = { ...state, perMonthPeriodicWidgetLoader: false, perMonthPeriodicWidgetData: payload }
            break

        case actionTypes.GetOpenPeriodicWidgetListRequested:
            object = { ...state, openPeriodicWidgetLoader: true, openPeriodicWidgetData: null }
            break

        case actionTypes.GetOpenPeriodicWidgetListSuccess:
            object = { ...state, openPeriodicWidgetLoader: false, openPeriodicWidgetData: payload }
            break

        case actionTypes.SuperDashboardSummaryRequested:
            object = { ...state, superdashboardSummary: null }
            break

        case actionTypes.SuperDashboardSummarySuccess:
            object = { ...state, superdashboardSummary: payload }
            break

        case actionTypes.GetSuperDashboardOrderListRequested:
            object = { ...state, superdashboardorderList: null }
            break

        case actionTypes.GetSuperDashboardOrderListSuccess:
            object = { ...state, superdashboardorderList: payload }
            break


        case actionTypes.TopClientsInspectionRequested:
            object = {
                ...state, topClientInspectionWidgetData: null,
                topClientInspectionWidgetLoader: true
            }
            break

        case actionTypes.TopClientsInspectionSuccess:
            object = {
                ...state, topClientInspectionWidgetData: payload,
                topClientInspectionWidgetLoader: false
            }
            break


        case actionTypes.BottomClientsInspectionRequested:
            object = {
                ...state, bottomClientInspectionWidgetData: null,
                bottomClientInspectionWidgetLoader: true
            }
            break

        case actionTypes.BottomClientsInspectionSuccess:
            object = {
                ...state, bottomClientInspectionWidgetData: payload,
                bottomClientInspectionWidgetLoader: false
            }
            break

        case actionTypes.TopClientsWorkorderRequested:
            object = {
                ...state, topClientWorkorderWidgetData: null,
                topClientWorkorderWidgetLoader: true
            }
            break

        case actionTypes.TopClientsWorkorderSuccess:
            object = {
                ...state, topClientWorkorderWidgetData: payload,
                topClientWorkorderWidgetLoader: false
            }
            break

        case actionTypes.BottomClientsWorkorderRequested:
            object = {
                ...state, bottomClientWorkorderWidgetData: null,
                bottomClientWorkorderWidgetLoader: true
            }
            break

        case actionTypes.BottomClientsWorkorderSuccess:
            object = {
                ...state, bottomClientWorkorderWidgetData: payload,
                bottomClientWorkorderWidgetLoader: false
            }
            break



        case actionTypes.TopClientsPeriodicRequested:
            object = {
                ...state, topClientPeriodicWidgetData: null,
                topClientPeriodicWidgetLoader: true
            }
            break

        case actionTypes.TopClientsPeriodicSuccess:
            object = {
                ...state, topClientPeriodicWidgetData: payload,
                topClientPeriodicWidgetLoader: false
            }
            break

        case actionTypes.BottomClientsPeriodicRequested:
            object = {
                ...state, bottomClientPeriodicWidgetData: null,
                bottomClientPeriodicWidgetLoader: true
            }
            break

        case actionTypes.BottomClientsPeriodicSuccess:
            object = {
                ...state, bottomClientPeriodicWidgetData: payload,
                bottomClientPeriodicWidgetLoader: false
            }
            break



        case actionTypes.TopServiceLocationRequested:
            object = {
                ...state, topServiceLocationWidgetData: null,
                topServiceLocationWidgetLoader: true
            }
            break

        case actionTypes.TopServiceLocationSuccess:
            object = {
                ...state, topServiceLocationWidgetData: payload,
                topServiceLocationWidgetLoader: false
            }
            break

        case actionTypes.BottomServiceLocationRequested:
            object = {
                ...state, bottomServiceLocationWidgetData: null,
                bottomServiceLocationWidgetLoader: true
            }
            break

        case actionTypes.BottomServiceLocationSuccess:
            object = {
                ...state, bottomServiceLocationWidgetData: payload,
                bottomServiceLocationWidgetLoader: false
            }
            break


        case actionTypes.InspectionPerMonthRequested:
            object = {
                ...state, inspectionPerMonthWidgetData: null,
                inspectionPerMonthWidgetLoader: true
            }
            break

        case actionTypes.InspectionPerMonthSuccess:
            object = {
                ...state, inspectionPerMonthWidgetData: payload,
                inspectionPerMonthWidgetLoader: false
            }
            break

        
        case actionTypes.WorkOrderPerMonthRequested:
            object = {
                ...state, workorderPermonthWidgetData: null,
                workorderPermonthWidgettLoader: true
            }
            break

        case actionTypes.WorkOrderPerMonthSuccess:
            object = {
                ...state, workorderPermonthWidgetData: payload,
                workorderPermonthWidgettLoader: false
            }
            break


        default:
            object = state;
    }

    return object

}

export default dashboardReducer;
import * as actionTypes from '../actionTypes';

export const addRatingTemplate = (data) => {
    return {
        type: actionTypes.AddRatingTemplate,
        payload: data
    }
}

export const getTemplateProvider = (data) => {
    return {
        type: actionTypes.GetTemplateProvider,
        payload: data
    }
}

export const getTemplateProviderDetail = (data) => {
    return {
        type: actionTypes.GetTemplateProviderDetail,
        payload: data
    }
}

export const getTemplateProviderRequested = () => {
    return {
        type: actionTypes.GetTemplateProviderRequested,
    }
}

export const getTemplateProviderSucess = (data) => {
    return {
        type: actionTypes.GetTemplateProviderSucess,
        payload: data
    }
}

export const getRatingList = (data) => {
    return {
        type: actionTypes.GetRatingList,
        payload: data
    }
}

export const getRatingListSuccess = (data) => {
    return {
        type: actionTypes.GetRatingListSuccess,
        payload: data
    }
}

export const getRatingTemplateDetail = (data) => {
    return {
        type: actionTypes.GetRatingTemplateDetail,
        payload: data
    }
}

export const getRatingTemplateDetailRequested = (data) => {
    return {
        type: actionTypes.GetRatingTemplateDetailRequested,
        payload: data
    }
}

export const getRatingTemplateDetailSuccess = (data) => {
    return {
        type: actionTypes.GetRatingTemplateDetailSuccess,
        payload: data
    }
}


export const updateRatingStatus = (data) => {
    return {
        type: actionTypes.UpdateRatingStatus,
        payload: data
    }
}

export const editRatingTemplate = (data) => {
    return {
        type: actionTypes.EditRatingTemplate,
        payload: data
    }
}

export const deleteTemplateRating = (data) => {
    return {
        type: actionTypes.DeleteTemplateRating,
        payload: data
    }
}

export const getRatingTemplateDropdown = (data) => {
    return {
        type: actionTypes.GetRatingTemplateDropdown,
        payload: data
    }
}
export const getRatingTemplateDropdownSuccess = (data) => {
    return {
        type: actionTypes.GetRatingTemplateDropdownSuccess,
        payload: data
    }
}

export const replaceRatingStatus = (data) => {
    return {
        type: actionTypes.ReplaceRatingStatus,
        payload: data
    }
}
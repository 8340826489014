
export const TRANSLATIONS_EN = {
    NAV: {
        dashboard: "Dashboard",
        companies: "Companies",
        companyAdmin: "Company Admins",
        Clients: "Clients",
        Service: "Service Locations",
        Contacts: "Contacts",
        Inspections: "Inspections",
        Work: "Work Order",
        Reports: "Reports",
        Periodics: "Periodics",
        Employees: "Employees",
        Admin: "Admin",
        Archives: "Archives",
        SpOrganization: "SP Organization",
        Configuration: "Configuration",
        Profile : "My Profile",
        Switch : "Switch Account",
        SignOut : "Sign Out",
        HiText : "Hi",
        SwitchModal : {
            text : "Are you sure you want to switch to"
        }
    },
    CompanyList: {
        title: 'Companies',
        addButton: 'Add Company',
        searchPlaceholder: 'Search Companies',
        statusFilter: {
            status: 'Status',
            all: "All",
            active: 'Active',
            Inactive: 'Inactive'
        },
        DeactivatePopup: {
            title: 'Deactivate Company',
            text1: 'Are you sure you want to deactivate the company',
        },
        ActivatePopup: {
            title: 'Activate Company',
            text1: 'Are you sure you want to activate the company',
        },
        table: {
            heading1: "COMPANY NAME",
            heading2: "STATUS",
            heading3: "ACTIONS"
        }
    },
    AddCompany: {
        title: 'Add Company',
        editTitle: 'Edit Company',
        genralTitle: 'GENERAL INFORMATION',
        addressTtitle: 'ADDRESS INFORMATION',
        Errors : {
            e1 : "Please enter the company name.",
            e2 : "Please enter the valid company name.",
            e3 : "Please enter the company name min 3 or max 30 charcters.",
            e4 : "Please enter the postal code.",
            e5 : "Please enter valid postal code.",
            e6 : "Please enter the address.",
            e7 : "Please enter the valid address.",
            e8 : "Please enter the address max 100 charcters.",
            e9: "Please enter the city.",
            e10 : "Please enter valid city name.",
            e11 : "Please enter the city max 100 charcters.",
            e12 : "Please select the country.",
            e13 : "Please select the state."
        },
        fields: {
            'CompanyName': 'Company Name*',
            'Address1': 'Address 1*',
            'Address2': 'Address 2',
            'City': 'City*',
            'Postal': 'Postal/Zip Code*',
            'Province': 'Province/State*',
            'Country': 'Country*'
        },
        placeholder: {
            'CompanyName': 'E.g. Steve Kent',
            'Address1': 'Enter Your Address',
            'Address2': 'Enter Your Address',
            'City': 'E.g. New York',
            'Postal': 'E.g. M1B M9W or 11017',
        }
    },

    companyDetail: {
        title: 'Company Details',
        labels: {
            'CompanyName': 'Company Name',
            'Address': 'Address',
        }
    },

    companyAdminDetail: {
        title: 'Company Admin Details',
        genralTitle: 'GENERAL INFORMATION',
        tooltip: "is Primary Company Admin for this company",
        labels: {
            'label1': 'ADMIN NAME',
            'label2': 'EMAIL',
            'label3': 'COMPANY',
            'label4': 'PHONE NUMBER',
            'label5': 'PREFERRED LANGUAGE',
            'label6': 'NOTES',
            'label7': 'ADDRESS',
            'label8': 'Admin Type'
        },
        tabs : {
            tab1 : "General Information",
            tab2 : "Assigned Companies & Permissions"
        }
    },

    AddCompanyAdmin: {
        title: 'Add Company Admin',
        companyTitle: "COMPANY",
        editTitle: 'Edit Company Admin',
        genralTitle: 'GENERAL INFORMATION',
        addressTitle: 'Address Information',
        addressSubTitle: "Is the company admin’s address same company address?(Optional)",
        AddCompanyButton: "Add Another Company",
        PrimayPopup: {
            title: 'Primary company Admin already exists!',
            text: 'This action will remove the primary admin access of',
            text3: 'for the company',
            text2: 'Would you still like to continue?'
        },
        Errors : {
            e1  : "Please enter the admin name.",
            e2 : "Please enter the valid admin name.",
            e3 : "Please enter the admin name min 3 or max 30 charcters.",
            e4 : "Please enter your email.",
            e5 : "Please enter your email in a valid format.",
            e6 : "Please enter a valid phone number.",
            e7 : "Please enter a valid notes.",
            e8 : "Please select the company.",
            e9 : "Please select the level.",
            e10 : "Please select the divsion.",
            e11 : "Please select the position.",
            e12 : "This company is already added.",
            e13 : "Please select the company.",
            e14 : "This divison is already added."
        },
        fields: {
            'AdminName': 'Company Admin Name*',
            'Email': 'Email Address*',
            'Company': 'Company*',
            'City': 'City*',
            'Phone': 'Phone No',
            "primaryadmin": "Make primary company admin",
            "primaryadminToolTip": "Primary company admin has the provision to create further company admins of the same company",
            "Language": "Preferred Language",
            "Notes": "Notes",
            "Yes": 'yes',
            "No": 'no',
            "optionalText": "Add New Address (Optional)"
        },
        placeholder: {
            'AdminName': 'E.g. Steve Kent',
            'Email': "E.g. gdi@email.com",
            'Phone': 'E.g. +1 333 444 5555',
            "Notes": "Enter notes here..",
            "Company": "Select Company"
        },
        DeletePopup: {
            title: 'Delete Position',
            text2: 'Are you sure you want to delete the postion.'
        },
        steps: {
            step1: "General Details",
            step2: "Assign Companies"
        },
    },

    CompanyAdmin: {
        title: 'Company Admins',
        addButton: 'Add Company Admin',
        searchPlaceholder: 'Search Company Admin',
        DeletePopup: {
            title: 'Delete Company Admin',
            text1: 'If you delete this company admin, they would no longer be able to login and manage the company',
            text2: 'Are you sure you want to delete admin '
        },
        DeletePopupCompany: {
            title: 'Delete Company',
            text1: 'Deleting this user will leave',
            text2: 'without a primary company admin. Please associate new primary admin before deleting.',
            text3: "Are you sure who want to delete / remove this company."
        },
        table: {
            heading1: "COMPANY ADMIN NAME",
            heading2: "COMPANY",
            heading3: "EMAIL ADDRESS",
            heading4: "COUNTRY",
            heading5: "PHONE NO.",
            heading6: "ACTIONS"
        },
        tooltip: {
            tooltip1: "This user is a primary company admin of a company"
        }
    },
    NotFoundComponent: {
        title: 'Page Not Found',
        text: 'Sorry, we couldn’t find the page you are looking for'
    },
    NotFound403Component: {
        title: 'Access Denied',
        text: 'You don’t have permission to access on this server.'
    },
    ServerErrorComponent: {
        title: 'Something is wrong',
        text: 'We are having some issues at the moment. Please try again later.'
    },
    clientList: {
        title: "Clients",
        searchPlaceholder: "Search Clients",
        addButton: 'Add Client',
        importButton: "Import",
        DeactivatePopup: {
            title: 'Deactivate Client',
            text1: 'Are you sure you want to deactivate the client',
        },
        ActivatePopup: {
            title: 'Activate Client',
            text1: 'Are you sure you want to activate the client',
        },
        DeletePopup: {
            title: 'Delete Client',
            text2: 'Are you sure you want to delete the client'
        },
        table: {
            heading1: "Client Name",
            heading2: "SP Level",
            heading3: "SP Division",
            heading4: "Status",
            heading5: "Actions"
        }
    },

    AddClient: {
        title: 'Add Client',
        editTitle: "Edit Client",
        genralTitle: 'GENERAL INFORMATION',
        addressTtitle: 'ADDRESS INFORMATION',
        tooltip: "This address will be added as a service location and included in the service locations list",
        Errors: {
            e1 : "Please enter the client name.",
            e2 : "Please enter the valid client name.",
            e3 : "Please enter the  client name  max 50 charcters.",
            e4 : "Please enter the postal code.",
            e5 : "Please enter valid postal code.",
            e6 : "Please enter the address.",
            e7 : "Please enter the valid address.",
            e8 : "Please enter the address max 100 charcters.",
            e9 : "Please enter the city.",
            e10 : "Please select sp level.",
            e11 : "Please select sp division.",
            e12 : "Please enter valid city name.",
            e13 : "Please enter the city max 100 charcters.",
            e14 : "Please select the country.",
            e15 : "Please select the state.",
            e16 : "Please enter a valid notes.",
            e17 : "Please enter a service location name.",
            e18 : "Please enter the valid service location name.",
            e19 : "Please enter the service location name max 100 charcters.",
            e20 : "Please select the time zone."
        },
        fields: {
            'clientName': 'Client Name*',
            'spName': "SP Level*",
            'divisonName': "SP Division*",
            'checbox1': "View Downline Contacts in Client Record",
            'checbox2': "View Upline Contacts in Service Location Records",
            'Address1': 'Address 1*',
            'Address2': 'Address 2',
            'City': 'City*',
            'Postal': 'Postal/Zip Code*',
            'Province': 'Province/State*',
            'Country': 'Country*',
            "Notes": "Notes",
            "Service": "Service Location Name*",
            "addressService": "This address is a service location",
            "timezone": "Time Zone*"
        },
        placeholder: {
            'clientName': 'E.g. Steve Kent',
            "Notes": "Enter notes here..",
            'spName': "Select SP Level",
            'divisonName': "Select SP Division",
            "service": "E.g. Scotia Place",
            'Address1': 'Enter Your Address',
            'Address2': 'Enter Your Address',
            'City': 'E.g. New York',
            'Postal': 'E.g. M1B M9W or 11017',
            "timezone": "Select Time Zone"
        }
    },

    clientDetail: {
        tabs: {
            tab1: "General Information",
            tab2: "Service Locations",
            tab3: "Contacts",
            tab4: "Organization Chart",
            tab5: "Reports"
        },

        labels: {
            label1: "Sp Level",
            label2: "SP Division",
            label3: "Status",
            label4: "VIEW DOWNLINE CONTACTS IN CLIENT RECORD",
            label5: "VIEW UPLINE CONTACTS IN SERVICE LOCATION RECORDS",
            label6: "Address",
            label7: "Notes"
        }
    },

    AddServiceLocation: {
        title: 'Add Service Location',
        editTitle: "Edit Service Location",
        genralTitle: 'GENERAL INFORMATION',
        addressTtitle: 'ADDRESS INFORMATION',
        Errors : {
            e1 : "Please enter the service location name.",
            e2 : "Please enter the valid service location name.",
            e3 : "Please enter the  service location name min 1 or max 50 charcters.",
            e4 : "Please enter the postal code.",
            e5 : "Please enter a valid postal code.",
            e6 :  "Please enter the address.",
            e7 : "Please enter the valid address.",
            e8 : "Please enter the address max 100 charcters.",
            e9 : "Please enter the city.",
            e10 : "Please enter valid city name.",
            e11 : "Please enter the city max 100 charcters.",
            e12 : "Please select the country.",
            e13 : "Please select the client.",
            e14 : "Please select the time zone.",
            e15 : "Please select the state.",
            e16 : "Please enter a valid notes.",
            e17 : "Please select sp level.",
            e18 : "Please select sp divison.",
            e19 : "Please select client level.",
            e20 : "Please select client divison."
        },
        fields: {
            'serviceName': 'Service Location Name*',
            'client': 'Client*',
            'ServiceArea': "Service Area*",
            'divisonName': "SP Division*",
            "Notes": "Notes",
            "timezone": "Time Zone*",
            "clientLevel": "Client Level*",
            "clientDivision": "Client Division*",
        },
        placeholder: {
            'serviceName': 'E.g. Scotia Place',
            'client': 'Select Client',
            "Notes": "Enter notes here..",
            'ServiceArea': "Select Area",
            'divisonName': "Select SP Division",
            "timezone": "Select Time Zone",
            "clientLevel": "Select Level",
            "clientDivision": "Select Division",
        }
    },
    serviceList: {
        title: 'Service Locations',
        importButton: "Import",
        addButton: 'Add Service Location',
        searchPlaceholder: 'Search Service Locations',
        DeactivatePopup: {
            title: 'Deactivate Service Location',
            text1: 'Are you sure you want to deactivate the service location',
        },
        ActivatePopup: {
            title: 'Activate Service Location',
            text1: 'Are you sure you want to activate the service location',
        },
        DeletePopup: {
            title: 'Delete Service Location',
            text2: 'Are you sure you want to delete the service location'
        },
        table: {
            heading1: "SERVICE LOCATION NAME",
            heading2: "CLIENT NAME",
            heading3: "Status",
            heading4: "SP Division",
            heading5: "Actions",
            heading6 : "SUB LOCATIONS",
            heading7 : "SECTIONS"
        },
        employePlacholder : "Select Employees",
        clientPlaceholder : "Select clients",
        divisionPlaceholder : "Select Divisons"
    },

    serviceDetail: {
        tabs: {
            tab1: "General Information",
            tab2: "Sub Locations",
            tab3: "Inspections",
            tab4: "Work Orders",
            tab5: "Periodics",
            tab6: "Schedule",
            tab7: "Reports"
        },

        labels: {
            label1: "Address",
            label2: "CLIENT",
            label3: "SP Level",
            label4: "Status",
            label5: "TIME ZONE",
            label6: "Notes",
            label7: "SP Division",
            label8: "Client Level",
            label9: "Client Division"
        },
        QuestioBlock : {
            title : "Custom Question Templates"
        }

    },

    AddContact: {
        title: 'Add Contact',
        editTitle: 'Edit Contact',
        genralTitle: 'GENERAL INFORMATION',
        Errors : {
            e1 : "Please enter the contact name.",
            e2 : "Please enter the valid contact name.",
            e3 : "Please enter the  contact name min 1 or max 50 charcters.",
            e4 : "Please enter your email.",
            e5 : "Please enter your email in a valid format.",
            e6 : "Please enter a valid phone number",
            e7 : "Please select the client.",
            e8 : "Please select the service location.",
            e9 : "Please enter a valid notes.",
            e10 : "Please select the level.",
            e11 : "Please select the divsion.",
            e12 : "Please select the position.",
            e13 : "Please select contact permission  template."
        },
        fields: {
            'contactName': 'Contact Name*',
            'email': 'Email Address*',
            'phone': 'Phone Number',
            'checkbox1': 'Enable Login Access',
            'checkbox2': 'Enable Notifications',
            'client': 'Client*',
            'service': 'Service Location*',
            'notes': 'Notes',
            Templates : "Contact Permission Template*"
        },
        placeholder: {
            'contactName': 'E.g. Adam Smith',
            'email': 'E.g. adamsmith@email.com',
            'phone': 'E.g. +1 333 444 5555',
            'client': 'Select Client',
            'service': 'Select Service Location',
            'notes': 'Enter notes here',
            Templates : "Select Contact Permission Template"
        },
        NotificationSettings : {
            label1 : "Email Notifications",
            text1 : "Receive an email notification",
            label2 : "In-App Notifications",
            text2 : "Receive In-App notification",
           
        }
    },

    contactList: {
        title: 'Contacts',
        importButton: "Import",
        addButton: 'Add Contact',
        searchPlaceholder: 'Search Contact',
        DeactivatePopup: {
            title: 'Deactivate Contact',
            text1: 'Are you sure you want to deactivate the contact',
        },
        ActivatePopup: {
            title: 'Activate Contact',
            text1: 'Are you sure you want to activate the contact',
        },
        DeletePopup: {
            title: 'Delete Contact',
            text2: 'Are you sure you want to delete the contact'
        },
        table: {
            heading1: "Contact Name",
            heading2: "Phone Number",
            heading3: "Client",
            heading4: "SERVICE LOCATION",
            heading5: "Status",
            heading6: "Actions",
            heading7: "Contact Email",
            heading8: "Position",
            heading9: "DIVISION"
        }
    },

    contactDetail: {
        tabs: {
            tab1: "General Information",
            tab2: "Permissions",
            tab3: "Notification Settings"
        },

        labels: {
            label1: "Client",
            label2: "SERVICE LOCATION NAME",
            label3: "Email Address",
            label4: "Phone",
            label5: "Status",
            label6: "Notes",
            label7: "Address"
        },
        NotificationSettings : {
            title : "Notification Settings",
            label1 : "Email",
            text1 : "Receive Email Notifications",
            label2 : "In-APP",
            text2 : "Receive In-App Notifications"
        }
    },

    Archives: {
        title: "Archives",
        tabs: {
            tab1: "Clients",
            tab2: "Service Locations",
            tab3: "Contacts",
            tab4: "Employees",
            tab5: "Company Admin"
        },
        contactTooltip1: "Service location :",
        contactTooltip2: "associated with this contact is deactivated. Please reactivate the service location first.",
        serviceTooltip1: "Client :",
        serviceTooltip2: "associated with this service location is deactivated. Please reactivate the client first.",
        templatePopup : {
            title : "Assign Permission Template",
            text1 : "Permission Template is no longer available.",
            fields : {
                label1 : "Choose Permission Template*"
            }
        }
  
    },

    AddEmploye: {
        title: 'Add Employee',
        editTitle: 'Edit Employee',
        genralTitle: 'GENERAL INFORMATION',
        Errors : {
            e1 : "Please enter the employee name.",
            e2 : "Please enter the valid employee name.",
            e3 :  "Please enter the  contact name min 1 or max 50 charcters.",
            e4 : "Please enter your email.",
            e5 : "Please enter your email in a valid format.",
            e6 : "Please enter a valid phone number.",
            e7 : "Please enter a valid notes.",
            e8 : "Please select the level.",
            e9 : "Please select the divsion.",
            e10 : "Please select the position.",
            e11 : "Please select employee permission  template.",
            e12 : "This divison is already added."
        },
        fields: {
            'EmployeeName': 'Employee Name*',
            'email': 'Email Address*',
            'phone': 'Phone Number',
            'secondaryAdmin': 'Is this employee Secondary Company Admin?',
            'permissions': 'Permissions',
            "Yes": 'Yes',
            "No": 'No',
            'checkbox1': 'Enable Login Access',
            'checkbox2': 'Enable Notifications',
            'checkbox3': 'Enable Inspections',
            'checkbox4': 'Enable Actions on Work Order',
            'language': 'Preferrred Language',
            'notes': 'Notes',
             Templates : "Employee Permission Template*"
        },
        placeholder: {
            'EmployeeName': 'E.g. Adam Smith',
            'email': 'E.g. adamsmith@email.com',
            'phone': 'E.g. +1 333 444 5555',
            'notes': 'Enter notes here',
            Templates : "Select Employee Permission Template"
        },
        postions: {
            title: "Position Assignment",
            subtitle: "Assigning position level details to your admin will reflect the hierarchical position in the org chart.",
            fields: {
                level: "Level*",
                division: "Division*",
                position: "Position*"
            },
            placeholder: {
                level: "Select Level",
                division: "Select Division",
                position: "Select Position"
            },
            alertPopup: {
                title: "Alert",
                text: "You have already assigned a position to this employee. Are you sure you want to add more positions?"
            }
        },
        steps: {
            step1: "General Details",
            step2: "Permission Template Details"
        },
    },

    commonPostion: {
        title: "Position Assignment",
        subtitle: "Assigning position level details to your admin will reflect the hierarchical position in the org chart.",
        fields: {
            level: "Level*",
            division: "Division*",
            position: "Position*"
        },
        placeholder: {
            level: "Select Level",
            division: "Select Division",
            position: "Select Position"
        },

        postions: {
            title: "Position Assignment",
            table: {
                heading1: "Level",
                heading2: "Division",
                heading3: "Position"
            }
        }
    },


    employeList: {
        title: 'Employees',
        importButton: "Import",
        addButton: 'Add Employee',
        searchPlaceholder: 'Search Employee',
        DeactivatePopup: {
            title: 'Deactivate Employee',
            text1: 'Are you sure you want to deactivate the employee',
        },
        ActivatePopup: {
            title: 'Activate Employee',
            text1: 'Are you sure you want to activate the employee',
        },
        DeletePopup: {
            title: 'Delete Employee',
            text2: 'Are you sure you want to delete the employee'
        },
        table: {
            heading1: "Employee Name",
            heading2: "Level",
            heading3: "SP DIVISION",
            heading4: "POSITION",
            heading5: "Status",
            heading6: "Actions",
            heading7: "Email Address",
            heading8: "Position"
        }
    },

    EmployeDetail: {
        tabs: {
            tab1: "General Information",
            tab2: "Permissions",
            tab3: "Notification Settings"
        },

        labels: {
            label1: "Email Address",
            label2: "Phone",
            label3: "ADDRESS",
            label4: "Employee Type",
            label5: "PREFERRED LANGUAGE",
            label6: "Status",
            label7: "Notes"
        },

    },


    clientOrg: {
        title: "Add Level",
        editTitle: "Edit Level",
        addLevelButton: "Add Level",
        addDivisionButton: "Add Division",
        ExpandAll: "Expand All",
        CollapseAll: "Collapse All",
        fields: {
            name: "Level Name*",
            position: "Position*",
            helpText: "Note: Position of this level cannot be changed as there are divisions associated with it"
        },
        placeholder: {
            name: "E.g. Wallmart",
            position: "Select Position"
        },
        AddLevel : {
            Errors : {
                e1 : "Please enter the  level name.",
                e2 : "Please enter the valid  level name.",
                e3 : "Please enter the  level name  max 50 charcters.",
                e4 : "Please select the postion"
            }  
        },
        addDvision: {
            title: "Add Division",
            editTitle: "Edit Division",
            Errors : {
                e1 : "Please enter the division name.",
                e2 : "Please enter the valid division name.",
                e3 : "Please enter the  division name  max 50 charcters.",
                e4 : "Please select the level.",
                e5 : "Please select the parent division.",
            },
            fields: {
                name: "Division Name*",
                level: "Level*",
                reports: "Reports To*"
            },
            placeholder: {
                name: "E.g. Wallmart",
                position: "Select Position",
                divison: "Select Division"
            }
        },

        levelsDetail: {
            title: "Divisions",
            searchPlaceholder: "Search Division",
            actions: {
                edit: "Edit Level",
                delete: "Delete Level",
                editDivison: "Edit Division",
                deleteDivison: "Delete Division"
            },
            table: {
                heading1: "Nom du contact",
                heading2: "Actions",
            },
            DeletePopup: {
                title: "Delete Level",
                text: "Are you sure you want to delete this level?"
            },
            DeleteDivison: {
                title: "Delete Division",
                text: "Deleting",
                text1: "will move all its positions, clients and service locations to parent division.",
                text2: "Are you sure you want to delete this division?"
            }
        },

        divisionDetail: {
            tabs: {
                client: "Client Contacts",
                service: "Service Locations"
            },
            actions: {
                editDivison: "Edit Division",
                deleteDivison: "Delete Division",
                editContact: "Edit Contact",
                deleteContact: "Delete Contact",
                activateContact: "Activate Contact",
                deactivateContact: "Deactivate Contact",
                editService: "Edit Service Location",
                deactivateService: "Deactivate Service Location",
                activateService: "Activate Service Location",
                deleteService: "Delete Service Location"
            },
        }
    },

    spOrg: {
        title: "Service Provider Organization",
        addLevelButton: "New Level",
        addDivisionButton: "New Division",
        ExpandAll: "Expand All",
        CollapseAll: "Collapse All",
        AddLevel: {
            leveltitle: "Add Level",
            leveleditTitle: "Edit Level",
            fields: {
                name: "Level Name*",
                position: "Position*",
                helpText: "Note: Position of this level cannot be changed as there are divisions associated with it"
            },
            placeholder: {
                name: "E.g. Wallmart",
                position: "Select Position"
            },
        },

        divisionDetail: {
            tabs: {
                employe: "Employees",
                client: "Clients",
                service: "Service Locations"
            },
            actions: {
                editEmployee: "Edit Employee",
                deleteEmployee: "Delete Employee",
                activateEmployee: "Activate Employee",
                deactivateEmployee: "Deactivate Employee",
                editService: "Edit Service",
                deactivateService: "Deactivate Service",
                activateService: "Activate Service",
                deleteService: "Delete Service",
                editClient: "Edit Client",
                deleteClient: "Delete Client",
                activateClient: "Activate Client",
                deactivateClient: "Deactivate Client"
            },
        }
    },

    configuration: {
        title: "Configuration",
        fields: {
            Positions: "Positions",
            Area: "Area Configuration",
            Permissions : "Permissions",
            Questions : "Custom Questions"
        },
        areaConfiguration: {
            title: "Area Configuration",
            fields: {
                Templates: "Area Templates",
                Services: "Services",
                Rating: "Rating Template",
                Sublocation: "Sublocation Service Assignment Templates",
                inspection: "Inspection Template Types",
                Mobile: "Mobile Survey Templates",
                Summary: "‘At A Glance’ Color Summary",
                Report: "Report Writer"

            }
        }
    },

    postions: {
        title: "Positions",
        addButton: "Add Position",
        searchPlaceholder: "Search Position",
        filter: {
            client: "Client",
            service: "Service Provider",
            status: "Login Type"
        },
        DeletePopup: {
            title: "Delete Position",
            text2: 'Are you sure you want to delete position '
        },
        addPostion: {
            title: "Add Position",
            edittitle: "Edit Position",
            genralTitle: "General Information",
            Errors : {
                e1 : "Please enter the position name.",
                e2 : "Please enter the valid position name.",
                e3 : "Please enter the  position name  max 50 charcters.",
                e4 : "Please select login type.",
                e5 : "Please enter a valid description."
            },
            fields: {
                name: "Position Name*",
                type: "Login Type*",
                notes: "Description",
                checkbox: "Directly responsible for the Service Location"
            },
            placeholder: {
                name: "E.g. Area Manager",
                type: "Select Login Type",
                notes: "Enter description here"
            }
        }
    },
    postionsList: {
        table: {
            heading1: "Position Name",
            heading2: "Login Type",
            heading3: "Actions"
        }
    },
    postionDetail: {
        tabs: {
            tab1: "General Information"
        },
        labels: {
            label1: "Login Type",
            label2: "Directly Responsible for the service location",
            label3: "Description"
        },
        positionEmployees: {
            title: "Employees"
        },
        positionContacts: {
            title: "Contacts"
        }
    },

    Services: {
        title: "Services",
        searchPlaceholder: "Search Services",
        addButton: "Add Service",
        AddServices: {
            title: "Add Service",
            edittitle: "Edit Service",
            genralTitle: "General Information",
            Errors : {
                e1 : "Please enter the service name.",
                e2 : "Please enter the valid service name.",
                e3 : "Please enter the  service name  max 50 charcters.",
                e4 : "Please enter weight.",
                e5 : "Please enter numeric only.",
                e6 : "Please enter a valid description."
            },
            fields: {
                Name: "Service Name*",
                Weight: "Service Weight*",
                Description: "Description"
            },
            placeholder: {
                Name: "E.g. Glass Partitions",
                Weight: "E.g. 10",
                Description: "Enter description here"
            }
        },
        table: {
            heading1: "Services",
            heading2: "SERVICE WEIGHT",
            heading3: "Actions"
        },
        DeletePopup: {
            title: "Delete Service",
            text2: 'Are you sure you want to delete service'
        },
        ServicesDetail: {
            title: "Service Details",
            labels: {
                label1: "Service",
                label2: "Service Weight",
                label3: "Description"
            },
            Areas: {
                title: "Associated Area Template",
                searchPlaceholder: "Search Area Template",
                table: {
                    heading1: "Template Name",
                    heading2: "Area",
                    heading3: "Actions"
                }
            }
        }
    },

    AreaTemplate: {
        title: "Area Templates",
        addButton: "Add Area Template",
        searchPlaceholder: "Search Area Template",
        table: {
            heading1: "Template Name",
            heading2: "Area",
            heading3: "Actions"
        },
        AddArea: {
            title: "Add Area Template",
            editTitle: "Edit Area Template",
            genralTitle: "General Information",
            toolTip: "Select any existing template to import all it’s services and areas for the new area template",
            addButton: "Add Area",
            steps: {
                step1: "Template Details",
                step2: "Area & Service Details"
            },
            Errors: {
                e1 : "Please enter the area name.",
                e2 : "Please enter the valid area name.",
                e3 : "Please enter the  area name  max 50 charcters.",
                e4 : "Please enter a valid description."
            },
            fields: {
                Name: "Template Name*",
                Clone: "Clone Template",
                Description: "Description"
            },
            placeholder: {
                Name: "E.g. Archives",
                Clone: "Select Template",
                Description: "Enter description here"
            },

            table: {
                heading1: "Services",
                heading2: "SERVICE WEIGHT",
                heading3: "Actions"
            },

            addPopup: {
                title: "Add Area",
                editTitle: "Edit Area",
                Errors : {
                    e1  : "Please enter the area name.",
                    e2  : "Please select the rating template.",
                    e3 : "Please enter the valid area name.",
                    e4 : "Please enter the  area name  max 50 charcters.",
                    e5 : "Please select services."
                },
                fields: {
                    Name: "Area Name*",
                    Services: "Services*",
                    Rating: "Rating Template*"
                },
                placeholder: {
                    Name: "E.g. Archives",
                    Services: "Select Services",
                    Rating: "Select Template"
                },
            },
            DeleteAreaPopup: {
                title: "Delete Area",
                text2: 'Are you sure you want to delete area'
            },
            DeleteServicePopup: {
                title: "Delete Service",
                text2: 'Are you sure you want to delete service'
            },
        },
        alertPopup: {
            title: "Alert",
            text1: "This is the last service assigned to",
            text2: "and thus can not be deleted."
        },
        DeleteAreaPopup: {
            title: "Delete Area Template",
            text1: "Deletion will remove this area template from the list.",
            text2: "Are you sure you want to delete this area template",
        }
    },

    AreaTemplateDetail: {
        tabs: {
            tab1: "General Information",
            tab2: "Associated Service Locations"
        },
        labels: {
            label1: "Description"
        },
        areas: {
            title: "Areas"
        }
    },

    Sublocation: {
        addButton: "Add Sub Location",
        searchPlaceholder : "Search Sublocation",
        serviePlaceholder : "Select service location",
        clientPlaceholder : "Select clients",
        addSubLocation: {
            title: "Add Sub Location",
            edittitle: "Edit Sub Location",
            genralTitle: "General Information",
            Errors : {
                e1 : "Please enter the name.",
                e2 : "Please enter the valid name.",
                e3 : "Please enter the  name  max 50 charcters."
            },
            fields: {
                Name: "Sub Location Name*",
                RadioLabel: "Does this Sub-location has a section?",
                Yes: "Yes",
                No: "No",
                Template: "Area Template",
                SectionName: "Section Name*"
            },
            placeholder: {
                Name: "E.g. Floor 1",
                Template: "Search Area Template",
                SectionName: "E.g. Corridor"
            }
        },
        table: {
            heading1: "Sub Location",
            heading2: "Section",
            heading3: "AREA TEMPLATE",
            heading4: "AREA",
            heading5: "Status",
            heading6: "Actions",
            heading7 : "SERVICE LOCATION",
            heading8 : "CLIENT"
        },
        DeactivatePopup: {
            title: 'Deactivate Sub Location',
            text1: 'Are you sure you want to deactivate the sub location',
        },
        ActivatePopup: {
            title: 'Activate Sublocation',
            text1: 'Are you sure you want to activate the sub location',
        },
        DeletelocationPopup: {
            title: "Remove Sub Location",
            text2: "Are you sure you want to remove this service from the sublocation"
        },
        DeleteSectionPopup: {
            title: "Delete Section",
            text2: "Are you sure you want to delete this section"
        }
    },

    RatingTemplate: {
        title: "Rating Template",
        searchPlaceholder: "Search Rating Template",
        addButton: "Add Rating Template",
        SPDefault: "SP Default",
        table: {
            heading1: "RATING NAME",
            heading2: "LABEL",
            heading3: "PERCENT",
            heading4: "DEFICIENT"
        },
        AddRatingTemplate: {
            title: "Add Rating Template",
            editTitle: "Edit Rating Template",
            genralTitle: "General Information",
            ratingTitle: "Ratings",
            ratingSubTitle: "Create and manage customized ratings for Inspection purposes.",
            Errors: {
                e1 : "Please enter the template name.",
                e2 : "Please enter the valid template name.",
                e3 : "Please enter the  template name  max 50 charcters.",
                e4 : "Please enter a valid description.",
                e5 : "Please enter the name.",
                e6 : "Please enter the label.",
                e7 : "Please enter alphanumeric only.",
                e8 : "Please enter the  label  name  max 5 charcters.",
                e9 : "Please enter the precentage.",
                e10 : "Please enter numeric only.",
                e11 : "Please enter 0 to 100 only.",
                e12 : "Please select the color."
            },
            fields: {
                Name: "Rating Template Name*",
                Description: "Description",
                Provider: "Default for Service Provider",
                placeholder: {
                    Name: "E.g. 1-5 Rating",
                    Description: "Enter description here"
                },
                Rating: {
                    label1: "Name",
                    label2: "Rating Label",
                    label3: "Percent",
                    label4: "Color",
                    label5: "Deficient",
                    placeholder: {
                        label1: "E.g. Spotless & Tidy",
                        label2: "E.g. 1",
                        label3: "E.g. 100"
                    }
                }
            },
            defaultFields: {
                label1: "Not Applicable"
            },
            PrimayPopup: {
                title: 'Default service provider already exists!',
                text: 'By selecting this you are replacing the existing',
                text2: 'rating template.',
                text3: 'Are you sure you want to proceed?'
            },
        },
        DeactivatePopup: {
            title: 'Deactivate Rating Template',
            text1: 'Are you sure you want to deactivate this rating template',
        },
        ActivatePopup: {
            title: 'Activate Rating Template',
            text1: 'Are you sure you want to activate this rating template',
        },
        DeletePopup: {
            title: 'Delete Rating',
            text2: 'Are you sure you want to delete the rating.'
        },
        ReplacementPopup: {
            title: "Deactivate Rating Template",
            subTitle: "is used by multiple area templates.",
            note: "To deactivate this rating template, select it’s replacement from below:",
            Errors : {
                e1 : "Please select the rating template."
            },
            fields: {
                ratingTemplate: "Rating Template*",
                placeholder: {
                    ratingTemplate: "Select Template"
                }
            },
            text: "Are you sure you want to deactivate",
            replaceButton: "Yes, Replace & Deactivate"
        }
    },

    Inspections: {
        AddInspections: {
            title: "Add Inspection",
            editTitle: "Edit Inspection",
            Errors : {
                e1 : "Please select the client.",
                e2 : "Please select the service location.",
                e3 : "Please select the inspection type.",
                e4 : "Please select the frequency.",
                e5 : "Please enter a valid description.",
                e6 : "Please select date.",
                e7 : "Please select week.",
                e8 : "Please select starting month.",
                e9 : "Please select starting year.",
                e10 : "Please select month dates.",
                e11 : "Please select weeks."
            },
            fields: {
                Client: "Client*",
                Service: "Service Location*",
                InspectionType: "Inspection Type*",
                InspectionDescription: "Inspection Description",
                StartTime: "Start Time",
                EndTime: "End Time",
                Frequency: "Frequency*",
                DueOn: "Due On*",
                Reminders: "Reminders",
                Event: "Days Before Event",
                Days: "Days",
                Hours: "Hours",
                Minutes: "Minutes",
                StartDate: "Start Date*",
                EndDate: "Ends",
                OnDays: "On Days",
                Repeat: "Repeat Every",
                EndDateOpt:
                {
                    label1: "On",
                    label2: "Never"
                }
                ,
                AllWeekdays: "All Weekdays",
                AllWeekends: "All Weekends",
                StartingMonth: "Starting Month",
                StartingYear: "Starting Year",
                OnDate: "On Date",
                SelectWeek: "Select Week",
                SelectDate: "Select Date",
                WeekDays: [
                    {
                        label1: "Sun",
                        label2: "Mon",
                        label3: "Tue",
                        label4: "Wed",
                        label5: "Thu",
                        label6: "Fri"
                    }
                ]
            },
            placeholder: {
                Client: "Select Client",
                Service: "Select Service Location",
                InspectionType: "Select Inspection",
                InspectionDescription: "Add description here",
                Frequency: "Select Frequency",
                DueOn: "Select Date",
                Days: "Select Days",
                Hours: "Select Hours",
                Minutes: "Select Minutes",
                Date: "Select Date",
                Repeat: "Select Week",
                Select: "Select",
                RepeatMonth: "Select Month",
                SelectWeek: "Select Week"
            }
        },
        title: "Inspections",
        addButton: "Add Inspection",
        importButton: "Import",
        searchPlaceholder: "Search Inspection",
        tabs: {
            tab1: "Pending",
            tab2: "Saved",
            tab3: "Completed",
            tab4 : "Saved",
            tab5 : "Unattended"
        },
        statusFilter: {
            status: 'Status',
            all: "All",
            Claimed: 'Claimed',
            Unclaimed: 'Unclaimed',
        },
        typeFilter: {
            status: 'Inspection Type',
            genral: "General Inspection",
            health: 'Health and Safety Inspection',
        },
        table: {
            heading1: "INSPECTION ID",
            heading2: "SERVICE LOCATION",
            heading3: "CLAIMED BY",
            heading4: "DUE ON",
            heading5: "CREATED BY",
            heading6: "INSPECTION TYPE",
            heading7: "Actions",
            heading8 : "Saved",
            heading9 : "Type"
        },
        DeletePopup: {
            title: 'Delete Inspection',
            text2: 'Are you sure you want to delete the inspection.'
        },
        DeleteInpection : {
            fields : {
                text1 : "Select which events of ",
                text2 : "you want to delete.",
                opt1 : "Delete this event only",
                opt2 : "Delete this event and all that follow"
            }
        },
        InspectionsPool : {
            tabs: {
                tab1: "Inspection Pool",
                tab2: "Claimed",
                tab3: "Saved",
                tab4: "Completed"
            },
        },
        ClaimPopup : {
            title : "Claim Inspection",
            text1 : "Claiming inspection",
            text2 : "will schedule the inspection on your calendar for",
            text3 : "Are you sure you want to claim this inspection?"
        },
        UnClaimPopup : {
            title : "Unclaim Inspection",
            text1 : "Unclaiming inspection",
            text2 : "will send it back to the inspection pool",
            text3 : "Are you sure you want to unclaim this inspection?"
        },
        ChangePopup : {
            title : "Change Inspection Date",
            DueOn: "Due On*",
        },
        Completed : {
            From : "From Date",
            To : "To Date",
            table: {
                heading1: "SERVICE LOCATION",
                heading2: "CLAIMED BY",
                heading3: "DUE ON",
                heading4: "COMPLETED ON",
                heading5: "CREATED BY",
                heading6: "INSPECTION TYPE"
            },
        },
        Unattended : {
            table: {
                heading1: "SERVICE LOCATION",
                heading2: "CLAIMED BY",
                heading3: "DUE ON",
                heading4: "Expired On",
                heading5: "CREATED BY",
                heading6: "INSPECTION TYPE",
                heading7: "Saved",
                heading8 : "Actions"
            },
        },
        UnattendedDetail : {
            labels: {
                label1 : "DUE ON",
                label2 : "Expired On",
                label3 : "Inspection Type",
                label4 : "CREATED BY",
                label5 : "CLAIMED BY",
                label6 : "CLIENT",
                label7 : "SERVICE LOCATION",
                label8 : "ADDRESS",
                label9 : "ADDITIONAL DETAILS",
            }
        },
        CompletedDetail : {
            tabs: {
                tab1: "Inspection Details",
                tab2: "Score Details",
                tab3: "Sign Off Details",
                tab4 : "Custom Questions Templates"
            },
            labels: {
                label1 : "DUE ON",
                label2 : "Completed On",
                label3 : "INSPECTION TOTALS",
                label4 : "INSPECTION SCORES",
                label5 : "Inspection Type",
                label6 : "CREATED BY",
                label7 : "INSPECTED BY",
                label8 : "CLIENT",
                label9 : "SERVICE LOCATION",
                label10 : "ADDRESS",
                label11 : "ADDITIONAL DETAILS",
                label12  : "INSPECTED AREAS"
            },
            Services : {
                table : {
                    heading1 : "Services",
                    heading2 : "Rated",
                    heading3 : "Rating",
                    heading4 : "Score",
                    heading5 : "Score%",
                    heading6 : "Inspection note",
                    heading7 : "Uploaded Images"
                }
            },
            SignOff : {
                clientTitle : "Sign Off Details by Contact",
                inspectorTitle : "Sign Off Details by Inspector",
                labels : {
                    label1 : "CONTACT NAME",
                    label2 : "CONTACT NOTES",
                    label3 : "CONTACT SIGNATURE",
                    label4 : "INSPECTOR NAME",
                    label5 : "INSPECTOR NOTES",
                    label6 : "INSPECTOR SIGNATURE"
                }
            }
        }
    },

    InspectionDetail : {
        tabs: {
            tab1: "General Information",
            tab2 : "Reports"
        },
        labels : {
            label1 : "CLIENT",
            label2 : "ADDRESS",
            label3 : "SERVICE LOCATION",
            label4 : "CLAIMED BY",
            label5 : "CREATED BY",
            label6 : "Inspection Type",
            label7 : "DUE ON",
            label8 : "Notes",
            label9 : "UNCLAIMED BY",
            label10 : "Saved On"
        }
    },
    
    Mycalendar : {
        Search : {
            title : "My Calendar",
            field : {
                label : "Services Locations",
                clear : "Services Locations",
                placeholder : "Search Service Locations"
            },
            eventTypes : {
                label : "Event Type",
                opt1 : "All",
                opt2 : "Inspections",
                opt3 : "Work Orders",
                opt4 : "Periodics",
                opt5 : "Unclaimed Inspections",
                opt6 : "Claimed Inspections"
            }
        },
        Types : {
            label1 :"Claimed Inspection",
            label2 : "Work Order",
            label3 : "Periodic",
            label4 : "Unclaimed Inspection"
        },
        AddEvent : {
            title : "Add Event",
            editTitle : "Edit Event",
            tabs : {
                tab1 : "Inspection",
                tab2 : "Work Order",
                tab3 : "Periodic"
            }
        }
    },

    Notification : {
        title : "Notifications",
        MarkRead : "Mark all as read",
        LoadButton : "Load More Notifications"
    },
    WorkOrder : {
        title : "Work Orders",
        addButton : "Add Work Order",
        searchPlaceholder : "Search Client, Service Location...",
        lastUpdated : "Last Updated By",
        lastUpdatedOptions : {
            opt1 : "All",
            opt2 : "Client",
            opt3 : "Sp"
        },
        WorkOrderOrign : "Work Order Origin",
        WorkOrderOrignOptions : {
            opt1 : "SP work orders",
            opt2 : "Client work orders",
            opt3 : "Created via inspection",
            opt4 : "All",
        },
        CreationDate : "Creation Date",
        tabs : {
            tab1 : "Active",
            tab2 : "Completed"
        },
        table : {
            heading1 : "Subject",
            heading2 : "Client",
            heading3 : "Work Order Origin",
            heading4 : "LAST UPDATED BY",
            heading5 : "CREATION DATE",
            heading6 : "Actions",
            heading7 : "Completion DATE",
        },
        EditInfo : {
            label1 : "Client",
            label2 : "Service Location",
            label3 : "Sub-loctaion",
            label4 : "Section"
        },
        AddWorkOrder : {
            title : "Add Work Order",
            editTitle : "Edit Work Order",
            genralTitle : "General Information",
            sendTitle : "Send Work Order Details To",
            tabs:{
                tab1 : "Employees",
                tab2 : "Client Contacts"
            },
            Errors : {
                e1 : "Please select the client.",
                e2 : "Please select the service location.",
                e3 : "Please select the sub location.",
                e4 : "Please select the section.",
                e5 : "Please enter the description.",
                e6 : "Please select template.",
                e7 : "Please enter a valid description.",
                e8 : "Please enter the subject.",
                e9 : "Please enter a valid subject.",
                e10: "Please enter the  subject min 1 or max 100 charcters.",
                e11: "File too Big, please select a file less than 5mb.",
                e12: "Please select valid file.",
                e13 : "Max 5 files allowed."
            },
            fields : {
                Client : "Client*",
                Service : "Service Location*",
                SubLocation : "Sub-Location*",
                Section : "Section*",
                Subject : "Subject*",
                Description :"Description*",
                Attachment : {
                    title : "Attachments",
                    label1 : "Max. 5 pictures can be uploaded",
                    label2 : "Attach a file"
                },
                Template : "Select Permission Template*"
            },
            placeholder : {
                Client : "Select Client",
                Service : "E.g. Scotia Place",
                SubLocation : "E.g. First Floor",
                Section : "E.g. Office Lobbies",
                Subject : "Enter Work Order Subject",
                Description : "Enter your cleaning instructions here"
            }
        },
        DeletePopup : {
            title : "Delete Work Order",
            text1 : "Are you sure you want to delete the work order?"
        },
        MarkPopup : {
            title : "Change Work Order Status",
            text1 : "Are you sure you want to mark this work order as Complete?"
        },
        ReopenPopup : {
            text1 : "Are you sure you want to mark this work order as Reopen?"
        }
    },
    WorkOrderDetail : {
        orderNumber : "Work Order#:",
        subject : "Subject:",
        labels : {
            label1 : "Description",
            label2 : "Client",
            label3 : "SERVICE LOCATION NAME",
            label4 : "Date",
            label5 : "Sub-location",
            label6 : "Section",
            label7 : "WORK ORDER#",
            label8 : "SOURCE",
            label9 : "CREATED BY",
            label10 : "ADDRESS",
            label11 : "Due On"
        },
        tabs : {
            tab1 : "General Information",
            tab2 : "Recipients"
        },
        updateHistory : {
            title : "Last Updated History",
            table : {
                heading1 : "ACTION BY",
                heading2 : "DATE/TIME",
                heading3 : "DETAILS",
                heading4 : "W.O Status",
                heading5 : "P.O Status"
            }
        },
        recipent : {
            label1 : "Employees",
            label2 : "Client Contacts"
        },
        AttachmentBlock : {
            title : "Attachments"
        },
        inspectionBlock : {
            title : "Inspection Information",
            labels : {
                label1 : "Inspected By",
                label2 : "Inspected On",
                label3 : "Score",
                label4 : "Score %",
                label5 : "Rating"
            }
        }
    },
    Permission : {
        title : "Permission Templates",
        searchPlaceholder : "Search Permission Template",
        addButton : "Add Permission Template",
        restoreLink : "Restore Default Template Permissions",
        
        tabs : {
            tab1 : "Employee Permission Template",
            tab2 : "Contact Permission Template"
        },
        table : {
            heading1 : "Admin Type",
            heading2 : "Description",
            heading3 : "Actions"
        },
        DeletePopup: {
            title: 'Delete Permission',
            text2: 'Are you sure you want to delete the permission.'
        },
        AddPermission : {
            title : "Add Permission Template",
            editTitle : "Edit Permission Template",
            genralTitle : "General Information",
            typeLabel : "Type",
            spEmployee : "SP Employee",
            clientContact : "Client Contact",
            Errors: {
                e1 : "Please enter the employee name.",
                e2 : "Please enter the valid employee name.",
                e3 : "Please enter the  contact name min 1 or max 50 charcters.",
                e4 : "Please enter the valid description."
            },
            fields : {
                Permission : "Permission Template Name*",
                Description : "Description",
                placeholder : {
                    Permission : "E.g. GDI Inspectors",
                    Description : "Enter description here"
                }
            }
        },
        ModulePermissions : {
            title : "Module Permissions",
            fields : {
                Name : "Module Name",
                Permission : "Permission"
            }
        },
        labels : {
            label1 : "Permission Template Name",
            label2 : "Description"
        },
        EditPopup : {
            text : "Are you sure you want to make these changes. Note these changes will also affect any existing employees/ contact associated with this template"
        }
    },

    MyProfile : {
        title : "My Profile",
        genralTitle : "General Settings",
        NotificationSettings : {
            label1 : "Email Notifications",
            text1 : "Receive an email notification",
            label2 : "In-App Notifications",
            text2 : "Receive In-App notification",
            label3 : "Language",
            label4 : "Two Factor Authentication",
            text3 : "Add an extra layer of security",
            addDevice : "+ Add New Device"
        },
        PasswordSettings : {
            label : "Password",
            text : "Update your password",
            button : "Change Password"
        },
        NotificationSettingsText : {
            label1 : "Email",
            text1 : "Receive Email Notifications",
            label2 : "In-APP",
            text2 : "Receive In-App Notifications" ,
          
        },
        TwoFactorAuthModal : {
            title : "Two Factor Authentication",
            text : "Scan the below QR Code using your Authenticator App on which you want to receive 6-digit authentication code.",
            fields : {
                label1 : "Verification Code",
                label2 : "Device Name"
            },
            placeholder : {
                label1 : "E.g. Samsung S11"
            },
            Options : {
                opt1 : "Unable to Scan?",
                opt1Link : "Receive Code via Email",
                opt2 : "Lost your device?",
                opt2Link : "Add New Device"
            }
        },
        changePassword : {
            title : "Change Your Password",
            Errors : {
                e1 : "Please enter the old password.",
                e2 : "Please enter the new password.",
                e3 : "Password should be between 6 and 15 characters long containing alphabets, numeric or underscore only.",
                e4: "Please enter the re enter your new password.",
                e5 : "Try again! both passwords should match."
            },
            fields : {
                label1 : "Old Password*",
                label2 : "New Password*",
                label3: "Verify New Password*"
            },
            placeholder : {
                label1 : "Enter your old password",
                label2 : "Enter your new password",
                label3 : "Re-enter your new password"
            }
        }
    },
    Periodic : {
        title : "Periodics",
        orderNumber : "Periodic Order#:",
        addButton : "Add Periodic",
        tabs : {
            tab1 : "Pending",
            tab2 : "Completed"
        },
        searchPlaceholder : "Search Client, Service Location...",
        lastUpdated : "Last Updated By",
        lastUpdatedOptions : {
            opt1 : "All",
            opt2 : "Client",
            opt3 : "Sp"
        },
        table : {
            heading1 : "Subject",
            heading2 : "Client",
            heading3 : "Service Location",
            heading4 : "LAST UPDATED BY",
            heading5 : "Due DATE",
            heading6 : "Actions"
        },
        From : "From Date",
        To : "To Date",

        DeletePopup: {
            title: 'Delete Periodic',
            text1: 'Are you sure you want to delete the periodic.'
        },

        MarkPopup : {
            title : "Change Periodic Status",
            text1 : "Are you sure you want to mark this periodic as Complete?"
        },
        ReopenPopup : {
            text1 : "Are you sure you want to mark this periodic as Reopen?"
        },
        AddPerodic : {
            title : "Add Periodic",
            editTitle : "Edit Periodic",
            genralTitle : "General Information",
            Errors: {
                e1 : "Please select the client.",
                e2 : "Please select the service location.",
                e3 : "Please select template.",
                e4 : "Please enter a valid description.",
                e5 : "Please enter the subject.",
                e6 : "Please enter a valid subject.",
                e7 : "Please enter the  subject min 1 or max 100 charcters.",
                e8 : "Please select the frequency.",
                e9 : "Please select date.",
                e10 : "Please select week.",
                e11 : "Please select starting month.",
                e12 : "Please select starting year.",
                e13 : "Please select month dates.",
                e14 : "Please select weeks.",
                e15 : "File too Big, please select a file less than 5mb",
                e16 : "Max 5 files allowed.",
                e17 : "Please select valid file"
            },
            fields : {
                Client : "Client*",
                Service : "Service Location*",
                Subject : "Subject*",
                Description : "Description",
                Attachment : {
                    title : "Attachments",
                    label1 : "Max. 5 pictures can be uploaded",
                    label2 : "Attach a file"
                },
                Template : "Select Permission Template*"
            },
            placeholder : {
                Client : "Select Client",
                Service : "E.g. Scotia Place",
                Subject : "Enter Periodic Subject",
                Description : "Enter your cleaning instructions here"
            }
        },
        AttachmentBlock : {
            title : "Attachments"
        }
    },

    CustomQuestions : {
        title : "Custom Questions",
        searchPlaceholder : "Search by Template Name",
        addButton : "Add Custom Questions Template",
        table : {
            heading1 : "TEMPLATE NAME",
            heading2 : "SERVICE LOCATION",
            heading3 : "Actions"
        },
        actions : {
            link1 : "Assign Service Location"
        },
        DeletePopup: {
            title: 'Delete Custom Question',
            text2: 'Are you sure you want to delete the custom question'
        },
        DeleteFieldPopup: {
            title: 'Delete Question',
            text2: 'Are you sure you want to delete the question'
        },
        DeleteOptionPopup: {
            title: 'Delete Option',
            text2: 'Are you sure you want to delete the option'
        },
        ServicePopup : {
            title : "Add Service Location",
            label : "Service Location*",
            placeholder : "Search Service Location",
            Errors : {
                e1 : "Please select services."
            }
        }
    },
    CustomQuestionsDetail : {
        tabs : {
            tab1 : "General Information",
            tab2 : "Associated Service Locations"
        },
        fields : {
            label1 : "Template Name",
            label2 : "Template Description"
        },
        QuestioBlock : {
            title : "Questions"
        },
        AttachmentBlock : {
            title : "Attachments"
        },
        ServiceBlock : {
            searchPlaceholder : "Search by Service Location or Cl..",
            addButton : "Add Service Location",
            table : {
                heading1 : "SERVICE LOCATION",
                heading2 : "CLIENT",
                heading3 : "Actions"
            },
            DeletePopup: {
                title: 'Delete Service Location',
                text: 'Are you sure you want to delete this service location'
            },
        }
    },

    AddCustomQuestion : {
        title : "Add Custom Question Template",
        editTitle : "Edit Custom Question Template",
        genralTitle : "General Information",
        addButton : "Add Question",
        saveButton : "Save Question",
        editButton : "Update Question",
        noAddedTitle : "No questions added",
        noAddedText : "Click on ‘Add Question’ to add a question here",
        Errors : {
            e1 : "Please enter the question.",
            e2 : "Please enter the option.",
            e3 : "Please enter the question template name.",
            e4 : "Please enter the valid question template.",
            e5 : "Please enter the  question template  max 50 charcters.",
            e6 : "Please enter a valid description.",
            e7 : "File too Big, please select a file less than 5mb",
            e8 : "Max 5 files allowed.",
            e9 : "Please select valid file"
        },
        fields : {
            Name : "Custom Question Template Name*",
            Description : "Description",
            questionType : "Question Type",
            questionType1 : "Single Answer",
            questionType2 : "Multipule Answers",
            questionType3 : "Open Text",
        },
        placeholder : {
            Name : "E.g. Work Order Survey Question*",
            Description : "Enter description here"
        },
        Attachment : {
            title : "Attachments",
            label1 : "Max. 5 pictures can be uploaded",
            label2 : "Attach a file"
        },
        Questions : {
            title : "Questions"
        },
        AddQuestion : {
            title : "Add Question",
            editTitle: "Edit Question",
            fields : {
                Type : "Question Type",
                TypeOption : {
                    opt1 : "Single Answer",
                    opt2 : "Multiple Answer",
                    opt3 : "Open Text"
                },
                Question : "Question*"
            },
            placeholder : {
                Question: "Describe your question"
            },
            Options : {
                title : "Options",
                placeholderText : "Enter option value"
            },
            table : {
                heading1 : "Answers",
                heading2 : "Actions"
            }
        }
    },

    Messages: {
        title : 'Messages'
    },
    Reports : {
      title : "Reports",
      tabs : {
          tab1 : "Reports",
          tab2 : "Saved"
      },
      fields : {
          link1 : "Inspection Summary",
          link2 : "Average Inspection Score",
          link3 : "Average Inspection Scores by Month by Service Location",
          link4 : "Inspection Reports Details Only",
          link5 : "Quality Inspection Report",
          link6 : "Recent Inspection Scores by Month by Service Location"
      },
      DeletePopup: {
        title: 'Delete Report',
        text2: 'Are you sure you want to delete the report'
      },
    },
    InspectionSummary : {
        title : "Inspection Summary Report",
        reportTitle : "Report Criteria",
        Errors: {
            e1 : "Please select inspection.",
            e2 : "Please select service location."
        },
        fields : {
            label1 : "Service Location*",
            label2 : "Inspection*"
        }
    },
    SaveReportPopup : {
        title : "Save Report as",
        EmployeePermissions : "Employee Permissions",
        SPDivisionPermissions : "SP Division Permissions",
        fields : {
            label1 : "Report Name*",
            label2 : "Description",
            label3 : "Select Employee"
        },
        placeholder : {
            label1 : "E.g. Monthly Report",
            label2 : "Enter Description",
            label3 : "Select Employee" 
        }
    },
    DiscarReportPopup : {
        title : "Discard Report",
        text : "Are you sure you want to discard all the progress you made on Reports?"
    },
    SavedReports : {
        searchPlaceholder : "Search Report..",
        table : {
            heading1 : "Report Name",
            heading2 : "REPORT TYPE",
            heading3 : "Created By",
            heading4 : "ACTIONS"
        },
        typeFilter : {
            status : "Report Type",
            link1 : "Inspection Summary",
            link2 : "Average Inspection Score",
            link3 : "Average Inspection Scores by Month by Service Location",
            link4 : "Inspection Reports Details Only",
            link5 : "Quality Inspection Report",
            link6 : "Recent Inspection Scores by Month by Service Location"
        },
        deniedModal : {
            title : "Access Denied",
            text : "You don’t have access to view details for the Service Location associated with this report. Please contact Company Admin."
        }
    },
    InspectionServiceLocation : {
        title: "Average Inspection Scores by Month by Service Location",
        reportTitle : "Report Criteria",
        filterTitle : "Filter Criteria",
        Errors : {
            e1 : "Please select service location.",
            e2 : "Please select start date.",
            e3 : "Please enter numeric only.",
            e4 : "Please select end date."
        },
        fields : {
            label1 : "Select Service Location*",
            label2 : "Start Date*",
            label3 : "End Date*",
            label4 : "Output Type",
            label5 : "PDF",
            label6 : "Excel",
            label7 : "Goal Percentage"
        },
        placeholder : {
            label1 : "Select",
            label2 : "Select Date",
            label7 : "E.g 80%"
        }
    },
    InspectinScores : {
        title: "Average Inspection Scores",
        reportTitle : "Report Criteria",
        selectionTitle : "Selection Criteria",
        getServiceTitle : "Get Report By Service Location",
        getSublocationTitle : "Get Report By Sub Location",
        getSectinTitle : "Get Report By Section",
        addSelectionButton : "Add Selection Criteria",
       
        Errors : {
            e1 : "Please select employee.",
            e2 : "Please select start date.",
            e3 : "Please select end date.",
            e4 : "Please select output type.",
            e5 : "Please select display interval.",
            e6 : "You have to select either service location(s), sub location(s) or section(s) along with area template(s)."
        },
        fields : {
            label1 : "Include Inspections",
            label2 : "Select Employee*",
            label3 : "Start Date*",
            label4 : "End Date*",
            label5 : "Output Type*",
            label6 : "Display Interval*",
            label7 : "Display Service Location List*",
            label8 : "Get Report by"
        },
        placeholder : {
            label1 : "Select",
            label2 : "Select Date"
        },
        inspectinOptions : {
            opt1 : "Show data from all Inspections",
            opt2 : "Show data from Inspections performed by specific Employee",
        },
        outputOptions : {
            opt1 : "PDF",
            opt2 : "Excel",
        },
        intervalOptions : {
            opt1 : "Daily",
            opt2 : "Monthly",
            opt3 : "Quarterly",
            opt4 : "Yearly",
        },
        serviceOptions : {
            opt1 : "Yes",
            opt2 : "No"
        },
        reportOptions: {
            opt1 : "Service Location",
            opt2 : "Sub Location",
            opt3 : "Section"
        }
    },
    InspectionReportDetail : {
        title : "Inspection Report - Details Only",
        Errors : {
            e1 : "Please select employee.",
            e2 : "Please select inspection.",
            e3 : "Please select start date.",
            e4 : "Please select end date.",
            e5  : "You have to select either service location(s), sub location(s) or section(s) along with area template(s)."
        },
        fields : {
            label1 : "Select Inspection by",
            label2 : "Inspection*",
            label3 : "Include Inspections*",
            label4 : "Select Employee*",
            label5 : "Start Date*",
            label6 : "End Date*",
            label7 : "Include Signoff Notes",
            label8 : "Display Service Location List",
            label9 : "Get Report by"
        },
        InspectionByOption : {
            opt1 : "Date Range",
            opt2 : "Inspection"
        },
    },
    QualityInspectionReport : {
        title : "Quality Inspection Report",
        Errors : {
            e1 : "Please select employee.",
            e2 : "Please select inspection.",
            e3 : "Please select start date.",
            e4 : "Please select end date.",
            e5 : "Please select output type.",
            e6 : "You have to select either service location(s), sub location(s) or section(s) along with area template(s)."
        }
    },
    Sections : {
        searchPlaceholder : "Search Section",
        servicePlacholder: "Select service location",
        sublocationPlaceholder: "Select sublocation",
        clientPlaceholder : "Select clients",
        table : {
            heading1 : "Section",
            heading2 : "Sub Location",
            heading3 : "SERVICE LOCATION",
            heading4 : "CLIENT"
        }
    },
    RecentInspectionReport : {
        title : "Recent Inspection Scores by Service Location",
        Errors : {
            e1 : "Please select employee.",
            e2  : "Please select start date.",
            e3 : "Please select end date.",
            e4  : "You have to select either service location(s), sub location(s) or section(s) along with area template(s)."
        },
        fields : {
            label1 : "Manage Uninspected Servcie Locations",
            label2 : "Sort By",
            label3 : "Show Sub Locations and Sections",
            label4 : "Include Individuals Responsible for the Service Location",
            label5 : "Only show inspections including a Client sign-off"
        },
        UninspectedOpt : {
            opt1 : "Include Uninspected Service Locations",
            opt2 : "Hide Uninspected Service Locations",
            opt3 : "Display Only Uninspected Service Locations"
        },
        SortOpt : {
            opt1 : "Service Location",
            opt2 : "Date",
            opt3 : "Inspector"
        }
    },
    dashboardTypes : {
        manageDashboard : {
            title : "Manage Dashboard",
            manageDashboardOptions : {
                opt1 : "Inspections",
                opt2 : "Work Orders",
                opt3 : "Periodics",
                opt4 : "Global Dashboard"
            }
        },
        dashboardFilters : {
            filterOptions : {
                opt1 : "Add Widget",
                opt2 : "Filters",
                opt3 : "Refersh"
            },
            InspectionAddWidgetOptions : {
                title : "Widgets",
                opt1 : "Average Score and # of Inspections Over Time",
                opt2 : "Top Clients",
                opt3 : "Top Service Locations",
                opt4 : "Bottom Service Locations",
                opt5 : "Bottom Clients"
            },
            WorkorderAddWidgetOptions : {
                opt1 : "Open vs Completed Work",
                opt2 : "# of Work Orders per Month",
                opt3 : "# of Work Orders by Origin",
                opt4 : "Average Completion Time Trend",
            },
            PeriodicAddWidgetOptions : {
                opt1 : "Open vs Completed Periodics",
                opt2 : "# of Periodics per Month",
                opt3 : "Top Clients Periodics",    
            },
            ManageFilters : {
                title : "Manage Filters",
                searchPlaceholder : "Search Filters",
                ManageFiltersOptions : {
                    opt1 : "Clients",
                    opt2 : "Area Template",
                    opt3 : "Inspectors",
                    opt4 : "Service Locations",
                    opt5 : "Specific Date Range",
                    opt6 : "Created by",
                    opt5Text : "Aug 01, 2021 - Dec 31, 2021",
                    opt7 : "Completed By"
                },
                DashboardFilter :{
                    title : "Clients",
                    searchPlaceholder : "Search Clients",
                    areaFilter : {
                        title : "Area Templates",
                        searchPlaceholder : "Search Area Templates",
                    },
                    serviceFilter : {
                        title : "Service Locations",
                        searchPlaceholder : "Search service Locations",  
                    },
                    inspectorFilter : {
                        title : "Inspector",
                        searchPlaceholder : "Search Inspector",  
                    },
                    createdByFilter : {
                        title : "Created by",
                        searchPlaceholder : "Search Created by",  
                    },
                    compltedByFilter : {
                        title : "Completed By",
                        searchPlaceholder : "Search Completed By",  
                    },
                    dateFilter : {
                        title : "Specific Date Range"
                    },
                    GlobalDashboardFilter : {
                        commonFilters : {
                            title : "Common Filters"
                        },
                        inspectionFilter: {
                            title : "Filter by Inspection"
                        },
                        workorderFilter : {
                            title : "Filter by Work Order"
                        },
                        periodicFilter: {
                            title : "Filter by Periodics"
                        }
                    }
                    
                } 
            }
        },
        inspectionDashboard : {
            title : "Inspections Dashboard",
            exportButton: "Export",
            From : "From Date",
            To : "To Date",
            inspectionDashboardOverview : {
                title : "Inspections Overview",
                AverageInspectionScore : "Average Inspection Score",
                Inspections : "Inspections",
                LocationsInspected : "Locations Inspected"
            }
        },
        WorkorderDashboard : {
            title : "Work Orders Dashboard",
            WorkorderDashboardOverview: {
                title : "Work Order Overview",
                NewWorkorder : "New Work Orders",
                AverageTime : "Average Time to Complete or File"
            }
        },
        periodicDashboard: {
            title : "Periodics Dashboard",
            periodicDashboardOverview: {
                title : "Periodics Overview",
                OpenPeriodics : "Open Periodics",
                CompletedPeriodics : "Completed Periodics"
            }
        },
        Widgets : {
            table : {
                heading1 : "Name",
                heading2 : "Average",
            },
            AverageScore: {
                title : "Average Score and # of Inspections over Time",
                table : {
                    heading1 : "Year/Month",
                    heading2 : "Average Inspection",
                    heading3 : "# of Inspections"
                }
            },
            TopServiceLocation : {
                title : "Top Service Locations"
            },
            BottomSeriveLocations : {
                title : "Bottom Service Locations"
            },
            TopClients : {
                title : "Top Clients",
                
            },
            BottomClients : {
                title : "Bottom Clients"
            },
            OpenCompleted : {
                    title : "Open vs Completed Work",
                    table : {
                        heading1 : "Open",
                        heading2 : "Completed",
                    }
            },
            OpenCompletedPeriodic : {
                title : "Open vs Completed Periodics",
                table : {
                    heading1 : "Open",
                    heading2 : "Completed",
                }
           },
            PerMonth : {
                title : "# of Work Orders per Month",
                table : {
                    heading1 : "Name",
                    heading2 : "Open",
                    heading3 : "Completed",
                }
            },
            PerMonthPeriodic : {
                title : "# of Periodics per Month",
                table : {
                    heading1 : "Name",
                    heading2 : "Open",
                    heading3 : "Completed",
                }
            },
            ByOrigin : {
                title : "# of Work Orders by Origin",
            },
            AverageCompetion : {
                title : "Average Completion Time Trend (Days)",
                table : {
                    heading1 : "Name",
                    heading2 : "Count",
                }
            },
            WidgetDropdownOptions : {
                title : "Dashlet Actions",
                opt1 : "View Selector",
                opt2 : "Export as",
                opt3  : "Print",
                opt1Child1 : "Combo",
                opt1Child2 : "Table",
                opt2Chidl1 : "PDF",
                opt2Chidl2 : "CSV"
          }
        }
    },
    ImportModal:{
        fileDragText : "Drag & drop excel file here",
        orText : "or",
        addButton : "Browse Files",
        importButton : "Import",
        downloadTemplate : "Download Excel Template",
        supportLabel : "Supported File Type:",
        maxLabel : "Max. File Size:",
        Client: {
            title : "Import Clients"
        },
        Services:{
            title : "Import Services" 
        },
        Workorder : {
            title : "Import Work Orders"
        },
        Contact : {
            title : "Import Contacts"
        },
        ServiceLocations : {
            title : "Import Service Locations"  
        },
        SubLocations : {
            title : "Import Sub Locations & Section/Area"  
        },
        AreaTemplates : {
            title : "Import Area Templates"  
        }
    },
    superAdminDashboard : {
        title : "Dashboard",
        overView : {
            title : "Overview",
            TotalCompanies: "Total Number of Companies",
            TotalAdmins : "Total Number of Admins",
            TotalEmployees : "Total Employees",
            CompaniesOverview : "Companies Overview",
            ServiceLocationOverview : "Service Locations Overview",
            InspectionOverView: "Inspections & Workorders Overview"
        },
        ManageFilters : {
            title : "Manage Filters",
            searchPlaceholder : "Search Companies",
            filterTitle : "Filter by Company",
            ManageFiltersOptions : {
                opt1 : "Company"
            },
        },
        
        Widgets : {
            TopInspectionClients: {
                title: "Top Clients by Number of Inspections",
                table : {
                    heading1 : "Name",
                    heading2 : "Inspection Count",
                }
            },
            BottomInspectionClients: {
                title: "Bottom Clients by Number of Inspections",
            },
            TopClientsWorkorder: {
                title: "Top Clients by Number of Workorders",
                table : {
                    heading1 : "Name",
                    heading2 : "Workorder Count",
                }
            },
            BottomClientsWorkorder: {
                title: "Bottom Clients by Number of Workorders",
            },
            TopClientsPeriodic: {
                title: "Top Clients by Number of Periodics",
                table : {
                    heading1 : "Name",
                    heading2 : "Periodic Count",
                }
            },
            BottomClientsPeriodic : {
                title: "Bottom Clients by Number of Periodics",
            },
            TopServiceLocation : {
                title : "Top Service Locations",
                table : {
                    heading1 : "Name",
                    heading2 : "Average",
                }
            },
            BootomServiceLocation : {
                title : "Bottom Service Locations"
            },
            InspectionPerMonth: {
                title : "# of Inspections per Month",
                table : {
                    heading1 : "Name",
                    heading2 : "Pending",
                    heading3 : "Completed",
                }
            },
            WorkOrderPerMonth : {
                title : "# of Work Orders per Month",
                table : {
                    heading1 : "Name",
                    heading2 : "Open",
                    heading3 : "Completed",
                }
            }
        },
        AddWidgetOptions : {
            title : "Widgets",
            opt1 : "Top Clients by Number of Inspections",
            opt2 : "Bottom Clients by Number of Inspections",
            opt3 : "Top Clients by Number of Workorders",
            opt4 : "Bottom Clients by Number of Workorders",
            opt5 : "Top Clients by Number of Periodics",
            opt6 : "Bottom Clients by Number of Periodics",
            opt7 : "# of Inspections per Month",
            opt8 : "# of Work Orders per Month",
            opt9 : "Top Service Locations",
            opt10 : "Bottom Service Locations"
        },
    },
    commonComponent: {
        clearFilter : "Clear Filters",
        adminTitleText : "You are viewing",
        ResetButton : "Reset Filters",
        asText : "as",
        Exit: "Exit View",
        backText: "Back",
        backStep : "Back to Previous Step",
        contiuneStep : "Continue to Next Step",
        genrateButton : "Generate Report",
        saveAsReportButton : "Save Report as",
        saveButton: 'Save',
        delteButton : "Delete",
        nextButton: "Next",
        addButton: "Add",
        updateButton: "Update",
        cancelButton: 'Cancel',
        applyButton : 'Apply',
        yesButton: 'Yes, Continue',
        yesMarkButton : "Yes, Mark as Complete",
        yesReopenButton : "Yes, Reopen",
        yesClaimButton: 'Yes, Claim',
        yesUnClaimButton: 'Yes, Unclaim',
        yesDeleteButton: 'Yes, Delete',
        yesDeactivateButton: "Yes, Deactivate",
        yesActivateButton: "Yes, Activate",
        yesDiscardButton : "Yes, Discard",
        backbutton: "Go Back",
        okButton: "Ok",
        okButton2: "Ok, Got it",
        errorTitle: "Error",
        downDivisionText: "You have chosen to remove all links to downline Divisions. Are you sure you want to do this ?  (You must select this option if you are moving this division into or below the level of one of those Downline divisions)",
        archieveText1: "Deleting would move",
        archieveText2: "to Archive section.",
        textArea1: "Deleting",
        textArea2: "will remove its association from",
        textArea3: "Service Location(s)",
        statusFilter: {
            status: 'Status',
            all: "All",
            active: 'Active',
            Inactive: 'Inactive',
            Date: "Date created"
        },
        DeleteSurvey : {
            text : "These fields are currently being used in active inspections, deleting this will also delete any active inspections"
        },
        DeleteSurveyRating : {
            text1 : "This rating template is currently being used in some saved and pending inspections, making any change will also reflect in all inspections",
            text2 : "Are you sure you want to edit this rating template"
        }

    }
};
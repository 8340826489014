import React, { Component } from "react";
import Helper from 'utils/helpers'
import { connect } from "react-redux";

/*actions*/
import { resetPassword , resettokenVerify } from "store/actions";

/*components*/
import AuthBanner from "../shared/AuthBanner";
import Input from "shared/components/Input";
import Footer from 'shared/components/Footer';
import isWrapperAuth from 'hoc';
import Toast from 'shared/components/Toast';

class ResetPassword extends Component {

  state = {
    errors: {},
    password: '',
    confirmPassword: '',
    isToast: false,
    notMatch: false
  }

  componentDidMount()
  {
    if (!Helper.isLoggedIn()) {
      const payload = 
      {
        token : this.props.match.params.token
      }
      this.props.resettokenVerify({...payload});
    }
   
    this.handleroute();
  }

  handleroute = () => {
    if (Helper.isLoggedIn()) {
      this.props.history.push('/admin');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage && !this.state.isToast) {
      this.setState({ isToast: true }, () => {
        window.scrollTo(0, 0);
        if (this.state.isToast) {
          setTimeout(() => {
            this.setState({ isToast: false });
          }, 4000);
        }
      });
    }
    if (prevProps.errorMessage !== this.props.errorMessage && this.props.isSuccess) {
      setTimeout(() => {
        this.props.history.push('/login')
      }, 3000);
    }

    if (prevProps.errorMessage !== this.props.errorMessage && !this.props.verifyToken) {
      setTimeout(() => {
        this.props.history.push('/login')
      }, 4000);
    }

  }

  isValid = () => {
    const {
      password, confirmPassword,
    } = this.state;

    const errors = {};

    let isValid = true;

    if (!password) {
      errors.password = 'Please enter new password';
      isValid = false;
    }

    if (password && !Helper.validatePassword(password)) {
      errors.password = 'Password should be between 6 and 15 characters long containing alphabets, numeric or underscore only';
      isValid = false;
    }

    if (!confirmPassword) {
      errors.confirmPassword = 'Please enter confirm new password.';
      isValid = false;
    }

    if (confirmPassword && !Helper.validatePassword(confirmPassword)) {
      errors.confirmPassword = 'Password should be between 6 and 15 characters long containing alphabets, numeric or underscore only.';
      isValid = false;
    }


    if (Helper.validatePassword(password) && Helper.validatePassword(confirmPassword) && !Helper.validateSamePassword(password, confirmPassword)) {
      errors.notMatch = 'Try again! both passwords should match.';
      isValid = false;
    }

    this.setState({ errors });

    return isValid;
  }


  InputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleReset = (event) => {
    event.preventDefault();
    const isValid = this.isValid();
    const {
      password,
    } = this.state; if (isValid) {

      const payload = {
        password,
        token: this.props.match.params.token
      }
      this.props.resetPassword({ ...payload });
    }
  }

  toastHide = () => {
    this.setState({ isToast: false });
  }


  render() {

    const { errors, isToast } = this.state;
    const { errorMessage, isSuccess, isWarning, isError } = this.props;


    return (
      <div className="auth-section">
        <div className="container-fluid">
          <div className="row no-gutters main-row">
            <div className="col-md-6 col-lg-7">
              <AuthBanner />
            </div>
            <div className="col-md-6 col-lg-5">
              {errorMessage && isToast &&
                <Toast
                  message={errorMessage}
                  Success={isSuccess}
                  Warn={isWarning}
                  Error={isError}
                  Close={this.toastHide}
                />}
              <div className="auth-form">
                <h1 className="title">Reset Password</h1>
                <h2>Enter new password for your account</h2>


                <div className="field-group">
                  {errors.notMatch && (
                    <div className="alert alert-danger">
                      <span>{errors.notMatch}</span>
                    </div>
                  )}
                  <form onSubmit={this.handleReset}>
                    <Input
                      label="New Password"
                      type="password"
                      placeholder="Enter your new password"
                      icon="lock-icon"
                      name="password"
                      handleChange={this.InputChange}
                      error={errors.password}
                    />

                    <Input
                      label="Confirm Password"
                      type="password"
                      placeholder="Re-enter your new password"
                      icon="lock-icon"
                      classes={["c-margin-sm"]}
                      name="confirmPassword"
                      handleChange={this.InputChange}
                      error={errors.confirmPassword}
                    />

                    <div className="button-block">
                      <button
                        type="submit"
                        title="Reset Password"
                        className="button button-height-large button-rounded button-block">Reset Password</button>
                    </div>
                  </form>
                </div>
                <Footer />
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  errors: { errorMessage, isSuccess, isWarning, isError },
  loading: { isLoading },
  auth : {verifyToken}
}) =>

  ({ isLoading, errorMessage, isSuccess, isWarning, isError , verifyToken });

export default connect(mapStateToProps, { resetPassword , resettokenVerify })(isWrapperAuth(ResetPassword));
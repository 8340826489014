import { put, takeLatest, fork, call, delay } from 'redux-saga/effects';
import { WebConfig, AppConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getContactRequested,
    getContactSuccess,
    getRecentSearchClient,
    getContactDetailRequested,
    getContactDetailSuccess,
    getServiceContact,
    getContactListArchieveRequested,
    getContactListArchieveSuccess,
    importFilesRequested,
    importFilesSuccess,
    importFilesFailure
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const CLIENTS_CONTACT_POINT_URL = WebConfig.ApiUrls.company.clientsContacts;


export function* addContact(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });


    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }





}

export function* getContact(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null, ordering = null, client_id = null,
        client_division = null, page_size = null } } = data;

    yield put(getContactRequested());
    // if (search) {
    //     yield delay(400);
    // }
    //yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(search && !client_division ?
                `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}?page=${currentPage}&search=${search}&fields=id,user,client_name,service_location_name,is_active` :
                status ? `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}?page=${currentPage}&is_active=${status}&fields=id,user,client_name,service_location_name,is_active` :
                    ordering ? `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}?page=${currentPage}&ordering=${ordering}&fields=id,user,client_name,service_location_name,is_active` :
                        client_division && !search ? `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}?page=${currentPage}&client_division=${client_division}&page_size=${page_size}&fields=id,user,client_name,service_location_name,is_active` :
                            client_division && search ? `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}?page=${currentPage}&client_division=${client_division}&page_size=${page_size}&search=${search}&fields=id,user,client_name,service_location_name,is_active`
                                : `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}?page=${currentPage}&fields=id,user,client_name,service_location_name,is_active`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getContactSuccess(response.data.response));
        if (search) {
            yield put(getRecentSearchClient({ page: 'client-contacts-list' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }





}


export function* getContactClient(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null, ordering = null, clientId = null } } = data;

    yield put(getContactRequested());
    // if (search) {
    //     yield delay(400);
    // }
    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(search ?
                `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}?client=${clientId}&page=${currentPage}&search=${search}&fields=id,user,client_name,service_location_name,is_active` :
                status ? `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}?client=${clientId}&page=${currentPage}&is_active=${status}&fields=id,user,client_name,service_location_name,is_active` :
                    ordering ? `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}?client=${clientId}&page=${currentPage}&ordering=${ordering}&fields=id,user,client_name,service_location_name,is_active`
                        : `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}?client=${clientId}&page=${currentPage}&fields=id,user,client_name,service_location_name,is_active`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getContactSuccess(response.data.response));

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }





}


export function* updateContactStatus(payload) {
    const data = payload;

    //yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}${data.payload.id}/`, { is_active: data.payload.is_active })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000);
        if (!data.payload.contactPage && !data.payload.serviceContact) {
            yield getContact({ type: actionTypes.GetContactList, payload: { currentPage: data.payload.currentPage } });
        }

        if (data.payload.clientorgContact) {
            yield getContact({
                type: actionTypes.GetContactList, payload: {
                    currentPage: data.payload.currentPage,
                    client_division: data.payload.client_division, page_size: data.payload.page_size
                }
            });
        }

        if (data.payload.serviceContact) {
            yield put(getServiceContact({ id: data.payload.serviceId, currentPage: 1 }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* updateContactClientStatus(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}${data.payload.id}/`, { is_active: data.payload.is_active })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000);
        if (!data.payload.contactPage) {
            yield getContactClient({
                type: actionTypes.GetClientContact,
                payload: { currentPage: data.payload.currentPage, clientId: data.payload.clientId }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* deleteContact(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });


    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)

        if (!data.payload.contactPage && !data.payload.serviceContact) {
            yield getContact({ type: actionTypes.GetContactList, payload: { currentPage: data.payload.currentPage } });
        }

        if (data.payload.serviceContact) {
            yield put(getServiceContact({ id: data.payload.serviceId, currentPage: 1 }));
        }

        if (data.payload.clientorgContact) {
            yield getContact({
                type: actionTypes.GetContactList, payload: {
                    currentPage: data.payload.currentPage,
                    client_division: data.payload.client_division, page_size: data.payload.page_size
                }
            });
        }

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* deleteContactClient(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });


    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)

        if (!data.payload.contactPage) {
            yield getContactClient({
                type: actionTypes.GetClientContact,
                payload: { currentPage: data.payload.currentPage, clientId: data.payload.clientId }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}



export function* getContactDetail(payload) {
    const data = payload;

    yield put(getContactDetailRequested());
    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getContactDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}


export function* editContact(payload) {
    const data = payload;
    const payloadData = { ...data.payload };
    delete payloadData["id"];


    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}${data.payload.id}/`, payloadData)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* getContactListArchieve(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null } } = data;

    yield put(getContactListArchieveRequested());
    // if (search) {
    //     yield delay(400);
    // }
    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            // .get(`${API_POINT_URL}companies/${data.payload.companyId}/clients/?page=${currentPage}`)
            .get(search ?
                `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}archived/?page=${currentPage}&search=${search}`
                : `${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}archived/?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getContactListArchieveSuccess(response.data.response));
        if (search) {
            yield put(getRecentSearchClient({ page: 'client-contacts-archived' }));
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }





}

export function* updateContactArchieveStatus(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}${data.payload.id}/undelete/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000);

        if (!data.payload.contactArchievePage) {
            yield getContactListArchieve({ type: actionTypes.GetContactArchiveList, payload: { currentPage: data.payload.currentPage } });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}

export function* importContactList(payload) {
    const data = payload;

    yield put(importFilesRequested());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}import_xls/`, data.payload.formData)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
       // console.log("response1" , response);
        yield put(importFilesSuccess(response.data.response.detail));
  
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
      //  console.log("response2" , response);
        yield put(importFilesFailure(response.data.message));
    }
    
}



function* watchaddContact() {
    yield takeLatest(actionTypes.AddContact, addContact)
}

function* watchgetContact() {
    yield takeLatest(actionTypes.GetContactList, getContact)
}

function* watchupdateContactStatus() {
    yield takeLatest(actionTypes.UpdateContactStatus, updateContactStatus)
}


function* watchdeleteContact() {
    yield takeLatest(actionTypes.DeleteContact, deleteContact)
}

function* watchgetContactDetail() {
    yield takeLatest(actionTypes.GetContactDetail, getContactDetail)
}

function* watcheditContact() {
    yield takeLatest(actionTypes.EditContact, editContact)
}

function* watchgetContactClient() {
    yield takeLatest(actionTypes.GetClientContact, getContactClient)
}

function* watchupdateContactClientStatus() {
    yield takeLatest(actionTypes.UpdateClientContactStatus, updateContactClientStatus)
}

function* watchdeleteContactClient() {
    yield takeLatest(actionTypes.DeleteClientContact, deleteContactClient)
}


function* watchgetContactListArchieve() {
    yield takeLatest(actionTypes.GetContactArchiveList, getContactListArchieve)
}

function* watchupdateContactArchieveStatus() {
    yield takeLatest(actionTypes.UpdateContactArchieveStatus, updateContactArchieveStatus)
}


function* watchimportContactList() {
    yield takeLatest(actionTypes.ImportContactList, importContactList)
}



export const contactSaga = [
    fork(watchaddContact),
    fork(watchgetContact),
    fork(watchupdateContactStatus),
    fork(watchdeleteContact),
    fork(watchgetContactDetail),
    fork(watcheditContact),
    fork(watchgetContactClient),
    fork(watchupdateContactClientStatus),
    fork(watchdeleteContactClient),
    fork(watchgetContactListArchieve),
    fork(watchupdateContactArchieveStatus),
    fork(watchimportContactList)
]
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helper from 'utils/helpers'
import { connect } from "react-redux";
import { msalInstance } from "../../../index";
import { loginRequest } from "config/authConfig";

/*actions*/
import { login, azureLogin } from "store/actions";
/*components*/
import AuthBanner from "../shared/AuthBanner";
import Input from "shared/components/Input";
import Footer from 'shared/components/Footer';
import isWrapperAuth from 'hoc';
import Toast from 'shared/components/Toast';;

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      email: '',
      password: '',
      isToast: false
    }
  }


  componentDidMount() {
    this.handleroute();
    if (sessionStorage && localStorage.getItem('LogAzure')) {
      msalInstance.handleRedirectPromise().then((response) => {
        if (response !== null) {
          // console.log("response", response);
          this.props.azureLogin({ azureToken: response.idToken })
        }
        return;
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage && !this.state.isToast) {
      this.setState({ isToast: true }, () => {
        window.scrollTo(0, 0);
        if (this.state.isToast) {
          setTimeout(() => {
            this.setState({ isToast: false });
          }, 4000);
        }
      });
    }
    this.handleroute();
  }

  handleroute = () => {
    if (Helper.isLoggedIn()) {
      this.props.history.push('/admin');
    }
    if (Helper.isTwoFactor()) {
      this.props.history.push('/token');
    }
  }

  azureLogin = async () => {
    localStorage.setItem('LogAzure' , true);
    try {
       await msalInstance.loginRedirect(
        {
          ...loginRequest,
          prompt: 'select_account'
        }
      );
      // if(loginResponse)
      // {

      //   const account = msalInstance.getActiveAccount();
      //   if (!account) {
      //       throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      //   }
      //   const response = await msalInstance.acquireTokenSilent({
      //       ...loginRequest,
      //       account: account
      //   });
      //   if(response && response.idToken)
      //   {
      //     this.props.azureLogin({azureToken : response.idToken })
      //   }
      // }
    }
    catch (err) {
      // this.setState({
      //   isAuthenticated: false,
      //   user: {},
      //   error: err
      // })
      console.log("err", err);
    }
  }


  isValid = () => {
    const {
      email,
      password,
    } = this.state;

    const errors = {};
    let isValid = true;

    if (!email) {
      errors.email = "Please enter your email.";
      isValid = false;
    }
    if (email && !Helper.validateEmail(email)) {
      errors.email = "Please enter your email in a valid format.";
      isValid = false;
    }

    if (!password) {
      errors.password = "Please enter your password.";
      isValid = false;
    }

    this.setState({ errors });
    return isValid;
  };


  InputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleLogin = (event) => {
    const isValid = this.isValid();
    event.preventDefault();
    const { email, password } = this.state;
    if (isValid) {
      const payload = {
        username: email,
        //  email,
        password
      }
      this.props.login({ ...payload });
    }
  }

  toastHide = () => {
    this.setState({ isToast: false });
  }


  render() {
    const { errors, isToast } = this.state;
    const { errorMessage, isSuccess, isWarning, isError } = this.props;
    // console.log("msalInstance", window.location.origin);

    return (
      <div className="auth-section">
        <div className="container-fluid">
          <div className="row no-gutters main-row">
            <div className="col-md-6 col-lg-7">
              <AuthBanner />
            </div>
            <div className="col-md-6 col-lg-5">
              {errorMessage && isToast &&
                <Toast
                  message={errorMessage}
                  Success={isSuccess}
                  Warn={isWarning}
                  Error={isError}
                  Close={this.toastHide}
                />}
              <div className="auth-form">
                <h1 className="title">Login to Your Account</h1>
                <div className="field-group">
                  <form onSubmit={this.handleLogin}>
                    <Input
                      label="Email Address"
                      type="email"
                      placeholder="Enter your email"
                      icon="email-icon"
                      name="email"
                      handleChange={this.InputChange}
                      error={errors.email}
                    />

                    <Input
                      label="Password"
                      type="password"
                      placeholder="Enter your password"
                      icon="lock-icon"
                      classes={["c-margin-sm"]}
                      name="password"
                      handleChange={this.InputChange}
                      error={errors.password}
                    />

                    <div className="forgot-link">
                      <Link to="/forgot-password" title="Forgot Password">Forgot Password?</Link>
                    </div>

                    <div className="button-block">
                      <button title="Log In" className="button button-height-large button-rounded button-block" type="submit">Log In</button>
                      <span className="sperator">Or</span>
                      <button title="Log In With Azure" type="button" onClick={this.azureLogin}
                        className="button button-border button-theme button-height-large button-rounded button-block">Log In With Azure</button>


                    </div>
                  </form>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  router,
  auth: { loginInfo },
  errors: { errorMessage, isSuccess, isWarning, isError },
  loading: { isLoading }
}) =>
  ({ router, isLoading, errorMessage, isSuccess, isWarning, isError, loginInfo });

export default connect(mapStateToProps, { login, azureLogin })(isWrapperAuth(Login));


import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="row no-gutters">
        <div className="col-md-6">
          <span className="cpy-right">&copy;{(new Date().getFullYear())}. All Rights Reserved.</span>
        </div>
        <div className="col-md-6 text-right">
          <Link to="/">Terms of Use</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import * as actionTypes from '../actionTypes';

const initialState = {
    workEmployeList: null,
    workContactList: null,
    workOrderList: null,
    workOrderLastUpdated: null,
    sasToken: null,
    orderDetail: null,
    LastUpdatedHistory: null,
    messageList : []
};

const WorkEmployeListReducer = (state, {
    type,
    payload
}) => {

    if (state === undefined) {
        state = initialState
    }

    let object;
    switch (type) {

        case actionTypes.GetWorkEmployeRequested:
            object = {
                ...state,
                workEmployeList: null
            }
            break

        case actionTypes.GetWorkEmployeListSuccess:
            object = {
                ...state,
                workEmployeList: payload
            }
            break


        case actionTypes.GetWorkContactListRequested:
            object = {
                ...state,
                workContactList: null
            }
            break


        case actionTypes.GetWorkContactListSuccess:
            object = {
                ...state,
                workContactList: payload
            }
            break

        case actionTypes.GetOrderListSuccess:
            object = {
                ...state,
                workOrderList: payload
            }
            break

        case actionTypes.GetOrderListSearchRequested:
            object = {
                ...state,
                workOrderLastUpdated: null
            }
            break

        case actionTypes.GetOrderListSearchSuccess:
            object = {
                ...state,
                workOrderLastUpdated: payload
            }
            break

        case actionTypes.GetSasTokenRequested:
            object = {
                ...state,
                sasToken: null
            }
            break

        case actionTypes.GetSasTokenSuccess:
            object = {
                ...state,
                sasToken: payload
            }
            break

        case actionTypes.GetOrderDetailRequested:
            object = {
                ...state,
                orderDetail: null
            }
            break

        case actionTypes.GetOrderDetailSuccess:
            object = {
                ...state,
                orderDetail: payload
            }
            break

        case actionTypes.GetLastUpdateHistorySuccess:
            object = {
                ...state,
                LastUpdatedHistory: payload
            }
            break

        case actionTypes.GetWorkMessageListRequested:
            object = {
                ...state,
                messageList: []
            }
            break
        case actionTypes.GetWorkMessageListSuccess:
            object = {
                ...state,
                messageList: payload
            }
            break
        case actionTypes.AddWorkorderMessageSuccess:
            object = {
                ...state,
                messageList: [...state.messageList , payload]
            }
            break
        
        case actionTypes.LogOut: 
            object = { state : undefined };
            break

        default:
            object = state;
    }
    return object
};
export default WorkEmployeListReducer;
import * as actionTypes from '../actionTypes';

export const getWorkEmployeList = (data) => {
    return {
        type: actionTypes.GetWorkEmployeList,
        payload: data
    }
}

export const getWorkEmployeListSuccess = (data) => {
    return {
        type: actionTypes.GetWorkEmployeListSuccess,
        payload: data
    }
}

export const getWorkEmployeRequested = (data) => {
    return {
        type: actionTypes.GetWorkEmployeRequested,
        payload: data
    }
}



export const getWorkContactList = (data) => {
    return {
        type: actionTypes.GetWorkContactList,
        payload: data
    }
}

export const getWorkContactListSuccess = (data) => {
    return {
        type: actionTypes.GetWorkContactListSuccess,
        payload: data
    }
}

export const getWorkContactListRequested = (data) => {
    return {
        type: actionTypes.GetWorkContactListRequested,
        payload: data
    }
}


export const addWorkOrder = (data) => {
    return {
        type: actionTypes.AddWorkOrder,
        payload: data
    }
}

export const getOrderList = (data) => {
    return {
        type: actionTypes.GetOrderList,
        payload: data
    }
}

export const getOrderListSuccess = (data) => {
    return {
        type: actionTypes.GetOrderListSuccess,
        payload: data
    }
}

export const getOrderListSearch = (data) => {
    return {
        type: actionTypes.GetOrderListSearch,
        payload: data
    }
}

export const getOrderListSearchRequested = (data) => {
    return {
        type: actionTypes.GetOrderListSearchRequested,
        payload: data
    }
}

export const getOrderListSearchSuccess = (data) => {
    return {
        type: actionTypes.GetOrderListSearchSuccess,
        payload: data
    }
}

export const deleteWorkOrder = (data) => {
    return {
        type: actionTypes.DeleteWorkOrder,
        payload: data
    }
}

export const markOrderComplete = (data) => {
    return {
        type: actionTypes.MarkOrderComplete,
        payload: data
    }
}


export const getSasToken = (data) => {
    return {
        type: actionTypes.GetSasToken,
        payload: data
    }
}

export const getSasTokenRequested = (data) => {
    return {
        type: actionTypes.GetSasTokenRequested,
        payload: data
    }
}

export const getSasTokenSuccess = (data) => {
    return {
        type: actionTypes.GetSasTokenSuccess,
        payload: data
    }
}



export const getOrderDetail = (data) => {
    return {
        type: actionTypes.GetOrderDetail,
        payload: data
    }
}

export const getOrderDetailRequested = (data) => {
    return {
        type: actionTypes.GetOrderDetailRequested,
        payload: data
    }
}

export const getOrderDetailSuccess = (data) => {
    return {
        type: actionTypes.GetOrderDetailSuccess,
        payload: data
    }
}


export const upDateWorkOrder = (data) => {
    return {
        type: actionTypes.UpDateWorkOrder,
        payload: data
    }
}


export const getLastUpdateHistory = (data) => {
    return {
        type: actionTypes.GetLastUpdateHistory,
        payload: data
    }
}


export const getLastUpdateHistorySuccess = (data) => {
    return {
        type: actionTypes.GetLastUpdateHistorySuccess,
        payload: data
    }
}

export const getWorkMessageList = (data) => {
    return {
        type: actionTypes.GetWorkMessageList,
        payload: data
    }
}

export const getWorkMessageListRequested = (data) => {
    return {
        type: actionTypes.GetWorkMessageListRequested,
        payload: data
    }
}

export const getWorkMessageListSuccess = (data) => {
    return {
        type: actionTypes.GetWorkMessageListSuccess,
        payload: data
    }
}

export const addWorkorderMessage = (data) => {
    return {
        type: actionTypes.AddWorkorderMessage,
        payload: data
    }
}
export const addWorkorderMessageSuccess = (data) => {
    return {
        type: actionTypes.AddWorkorderMessageSuccess,
        payload: data
    }
}

export const importWorkOrderList = (data) => {
    return {
        type: actionTypes.ImportWorkOrderList,
        payload: data
    }
}
import * as actionTypes from '../actionTypes';

export const getNotificationListHeader = (data) => {
    return {
        type: actionTypes.GetNotificationList,
        payload: data
    }
}

export const getNotificationListHeaderRequested = (data) => {
    return {
        type: actionTypes.GetNotificationListRequested,
        payload: data
    }
}


export const getNotificationListHeaderSuccess = (data) => {
    return {
        type: actionTypes.GetNotificationListSuccess,
        payload: data
    }
}

export const notificationReadAll = (data) => {
    return {
        type: actionTypes.NotificationReadAll,
        payload: data
    }
}

export const getNotificationAllListSuccess = (data) => {
    return {
        type: actionTypes.GetNotificationAllListSuccess,
        payload: data
    }
}

export const getNotificationAllListSuccessReload = (data) => {
    return {
        type: actionTypes.GetNotificationAllListSuccessReload,
        payload: data
    }
}


export const getNotificationAllListRequested = (data) => {
    return {
        type: actionTypes.GetNotificationAllListRequested,
        payload: data
    }
}


export const getNotificationRead = (data) => {
    return {
        type: actionTypes.GetNotificationRead,
        payload: data
    }
}


export const getNotificationDetail = (data) => {
    return {
        type: actionTypes.GetNotificationDetail,
        payload: data
    }
}

export const getNotificationDetailRequested = (data) => {
    return {
        type: actionTypes.GetNotificationDetailRequested,
        payload: data
    }
}

export const getNotificationDetailSuccess = (data) => {
    return {
        type: actionTypes.GetNotificationDetailSuccess,
        payload: data
    }
}
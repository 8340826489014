
import * as actionTypes from '../actionTypes';

export const loaderShow = (data) => {
    return {
        type: actionTypes.LoaderShow,
        payload: data
    }
}

export const loaderhide = (data) => {
    return {
        type: actionTypes.LoaderHide,
        payload: data
    }
}
import axios from "axios";
import { put, takeLatest, fork, call, delay } from 'redux-saga/effects';
import { WebConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
	loaderShow,
	loaderhide,
	loadTodoSuccess,
	loadTodoRequest,
	loadTodoError,
	getInspectionListSuccess,
	getInspectionDetailRequested,
	getInspectionDetailSuccess,
	getEventListSuccess,
	calendarCreateEventSuccess,
	getInspectionPoolListSuccess,
	getInspectionPoolListRequested,
	getInspectionPoolDetailRequested,
	getInspectionPoolDetailSuccess,
	getInspectionClaimedListRequested,
	getInspectionClaimedListSuccess,
	getWorkorderEventListSuccess,
	getPeriodicEventListSuccess,
	getInspectionTemplatesRequested,
	getInspectionTemplatesSuccess,
	getInspectionDropdownListRequested,
	getInspectionDropdownListSuccess,
	getInspectionSummaryRequested,
	getInspectionSummarySuccess
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const EVENTS_POINT_URL = WebConfig.ApiUrls.events;
const WORK_ORDER_POINT_URL = WebConfig.ApiUrls.eventsWorkOrder
const PERIODIC_POINT_URL = WebConfig.ApiUrls.eventsPeriodic;
const QUESTION_POINT_URL = WebConfig.ApiUrls.eventsQuestionnaires;

const EVENTS_SERVICE_POINT_URL = WebConfig.ApiUrls.ReportALLInspectionPoint;


export function* addEvent(payload) {
	const data = payload;

	//	yield put(loadTodoRequest());
	
	const response = yield call(() => {
		return ClientInstance
			.post(`${API_POINT_URL}${EVENTS_POINT_URL}`, data.payload)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}
	

}

export function* editEvent(payload) {
	const data = payload;

	//	yield put(loadTodoRequest());
	
	const response = yield call(() => {
		return ClientInstance
			.put(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/`, data.payload)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}
	

}



export function* getInspectionList(payload) {
	const data = payload;
	const { payload: { currentPage = null, search = null, status = null,
		claim_status = null, typestatus = null, start_date_before = null, start_date_after = null,
		tab = null, savedStatus = null, service_location = null } } = data;

	let claim_statusNames = null;

	let response = null;

	if (claim_status) {
		claim_statusNames = data.payload.claim_status.map((item) => `&claim_status=${[item]}`);
	}


	//yield put(loadTodoRequest());
	

	if (!typestatus && !service_location) {

		response = yield call(() => {
			return ClientInstance
				.get(search ?
					`${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&search=${search}` :
					status ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&inspection_type=${status}` :
						savedStatus ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&is_saved=${savedStatus}` :
							claim_status ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}${claim_statusNames.join("")}` :
								`${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}`)
				.then(response => {
					return response;
				})
		});
	}

	if (!typestatus && service_location) {
		response = yield call(() => {
			return ClientInstance
				.get(search ?
					`${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&search=${search}&service_location=${service_location}` :
					status ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&inspection_type=${status}&service_location=${service_location}` :
						savedStatus ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&is_saved=${savedStatus}&service_location=${service_location}` :
							claim_status ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&service_location=${service_location}${claim_statusNames.join("")}` :
								`${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&service_location=${service_location}`)
				.then(response => {
					return response;
				})
		});
	}

	if (typestatus && !service_location) {
		response = yield call(() => {
			return ClientInstance
				.get(search ?
					`${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&search=${search}&status=${typestatus}` :
					typestatus && !status && !start_date_after && !start_date_before && !savedStatus ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&status=${typestatus}` :
						status && !start_date_after && !start_date_before ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&inspection_type=${status}&status=${typestatus}` :
							savedStatus ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&is_saved=${savedStatus}` :
								start_date_before && start_date_after && !search ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&status=${typestatus}&completed_on_before=${start_date_before}&completed_on_after=${start_date_after}` :
									`${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&status=${typestatus}`)
				.then(response => {
					return response;
				})
		});
	}

	if (typestatus && service_location) {
		response = yield call(() => {
			return ClientInstance
				.get(search ?
					`${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&search=${search}&status=${typestatus}&service_location=${service_location}` :
					typestatus && !status && !start_date_after && !start_date_before && !savedStatus ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&status=${typestatus}&service_location=${service_location}` :
						status && !start_date_after && !start_date_before ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&inspection_type=${status}&status=${typestatus}&service_location=${service_location}` :
							savedStatus ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&is_saved=${savedStatus}&service_location=${service_location}` :
								start_date_before && start_date_after && !search ? `${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&status=${typestatus}&completed_on_before=${start_date_before}&completed_on_after=${start_date_after}&service_location=${service_location}` :
									`${API_POINT_URL}${EVENTS_POINT_URL}?tab=${tab}&page=${currentPage}&status=${typestatus}&service_location=${service_location}`)
				.then(response => {
					return response;
				})
		});
	}


	if (response && response.data.status === 200) {
		yield put(getInspectionListSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}
	


}


export function* deleteInspection(payload) {
	const data = payload;

	//console.log("payload" , data);

	//	yield put(loadTodoRequest());
	
	const response = yield call(() => {
		return ClientInstance
			.delete(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/`, data.payload.delete_type ? {
				data: {
					delete_type: data.payload.delete_type
				}
			} : null)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));
		

		if (!data.payload.inspectionDetailPage && !data.payload.calendarPage) {
			yield delay(1000)
			yield getInspectionList({
				type: actionTypes.GetInspectionList,
				payload: {
					currentPage: data.payload.currentPage, tab: data.payload.tab,
					typestatus: data.payload.typestatus ? data.payload.typestatus : null,
					tab : data.payload.tab
				}
			});
		}

		if (data.payload.calendarPage) {
			yield delay(1000);
			yield getEventList({
				type: actionTypes.GetEventList,
				payload: { form_date: data.payload.form_date, end_date: data.payload.end_date, service_location: data.payload.service_location }
			});

		}
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}
	


}

export function* deleteCalendar(payload) {
	const data = payload;

	//yield put(loadTodoRequest());
	
	const response = yield call(() => {
		return ClientInstance
			.delete(`${API_POINT_URL}${EVENTS_POINT_URL}calendar/`, {
				data: {
					parent: data.payload.parent,
					start_date: data.payload.start_date,
					delete_type: data.payload.delete_type
				}
			})
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));
		

		if (data.payload.calendarPage) {
			yield delay(1000);
			yield getEventList({
				type: actionTypes.GetEventList,
				payload: { form_date: data.payload.form_date, end_date: data.payload.end_date, service_location: data.payload.service_location }
			});
		}
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}
	


}


export function* getInspectionDetail(payload) {
	const data = payload;

	yield put(getInspectionDetailRequested());
	//yield put(loadTodoRequest());
	
	const response = yield call(() => {
		return ClientInstance
			.get(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/?fields=id,client_name,service_location_address,service_location_name,claim_status,original_start_date,start_date,end_date,status,claimed_by,claimed_on,created_by,inspection_type,saved_on,description,survey,completed_on,inspector_name,contact_name,contact_notes,contact_signature,inspector_notes,inspector_signature,is_saved,client,frequency,service_location,week_days,every,start_year,start_month,month_days,weeks,reminder,is_instant`)
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(getInspectionDetailSuccess(response.data.response));
	}

	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}

	


}


export function* getEventList(payload) {

	console.log("data", payload);

	const data = payload;
	const { payload: { service_location = null , claim_status = null } } = data;
	let serviceLocationNames = null;
	let claim_statusNames = null

	if (service_location) {
		serviceLocationNames = data.payload.service_location.map((item) => `&service_location=${[item]}`);
	}

	if (claim_status) {
		claim_statusNames = data.payload.claim_status.map((item) => `&claim_status=${[item]}`);
	}


	//	yield put(loadTodoRequest());
	

	const response = yield call(() => {
		return ClientInstance
			.get(service_location && claim_status ? `${API_POINT_URL}${EVENTS_POINT_URL}calendar/?from_date=${data.payload.form_date}&to_date=${data.payload.end_date}${serviceLocationNames.join("")}${claim_statusNames.join("")}` :
		    	service_location && !claim_status ? `${API_POINT_URL}${EVENTS_POINT_URL}calendar/?from_date=${data.payload.form_date}&to_date=${data.payload.end_date}${serviceLocationNames.join("")}` :
				claim_status ? `${API_POINT_URL}${EVENTS_POINT_URL}calendar/?from_date=${data.payload.form_date}&to_date=${data.payload.end_date}${claim_statusNames.join("")}`:
				`${API_POINT_URL}${EVENTS_POINT_URL}calendar/?from_date=${data.payload.form_date}&to_date=${data.payload.end_date}`)
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(getEventListSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}
	


}


export function* getWorkorderEventList(payload) {

	const data = payload;
	const { payload: { service_location = null } } = data;
	let serviceLocationNames = null;

	if (service_location) {
		serviceLocationNames = data.payload.service_location.map((item) => `&service_location=${[item]}`);
	}


	//yield put(loadTodoRequest());
	

	const response = yield call(() => {
		return ClientInstance
			.get(service_location ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}?no_page&fields=create_date,service_location,description,id&create_date_after=${data.payload.form_date}&create_date_before=${data.payload.end_date}${serviceLocationNames.join("")}` :
				`${API_POINT_URL}${WORK_ORDER_POINT_URL}?no_page&fields=create_date,service_location,description,id&create_date_after=${data.payload.form_date}&create_date_before=${data.payload.end_date}`)
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(getWorkorderEventListSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}
	


}


export function* getPeriodicEventList(payload) {

	const data = payload;
	const { payload: { service_location = null } } = data;
	let serviceLocationNames = null;

	if (service_location) {
		serviceLocationNames = data.payload.service_location.map((item) => `&service_location=${[item]}`);
	}


	//yield put(loadTodoRequest());
	

	const response = yield call(() => {
		return ClientInstance
			.get(service_location ? `${API_POINT_URL}${PERIODIC_POINT_URL}?no_page&start_date_after=${data.payload.form_date}&start_date_before=${data.payload.end_date}${serviceLocationNames.join("")}` :
				`${API_POINT_URL}${PERIODIC_POINT_URL}?no_page&start_date_after=${data.payload.form_date}&start_date_before=${data.payload.end_date}`)
			.then(response => {
				return response;
			})
	});

	if (response && response.data.status === 200) {
		yield put(getPeriodicEventListSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}
	


}


export function* calendarCreateEvent(payload) {
	const data = payload;

	//yield put(loadTodoRequest());
	
	const response = yield call(() => {
		return ClientInstance
			.put(`${API_POINT_URL}${EVENTS_POINT_URL}calendar/`, data.payload)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(calendarCreateEventSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}
	

}

export function* getInspectionPoolList(payload) {
	const data = payload;
	const { payload: { currentPage = null, search = null, status = null, claim_status = null, tab = null,
		service_location = null } } = data;
	let claim_statusNames = null;
	let response;

	if (claim_status) {
		claim_statusNames = data.payload.claim_status.map((item) => `&claim_status=${[item]}`);
	}


	//yield put(loadTodoRequest());
	//yield put(getInspectionPoolListRequested());
	

	if (!service_location) {
		response = yield call(() => {
			return ClientInstance
				.get(search ?
					`${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}&search=${search}${claim_statusNames.join("")}` :
					status ? `${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}&inspection_type=${status}${claim_statusNames.join("")}` :
						`${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}${claim_statusNames.join("")}`)
				.then(response => {
					return response;
				})
		});
	}

	if (service_location) {
		response = yield call(() => {
			return ClientInstance
				.get(search ?
					`${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}&search=${search}&service_location=${service_location}${claim_statusNames.join("")}` :
					status ? `${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}&inspection_type=${status}&service_location=${service_location}${claim_statusNames.join("")}` :
						`${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}&service_location=${service_location}${claim_statusNames.join("")}`)
				.then(response => {
					return response;
				})
		});
	}


	if (response && response.data.status === 200) {
		yield put(getInspectionPoolListSuccess(response.data.response));

	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}
	


}

export function* getInspectionClaimedList(payload) {
	const data = payload;
	const { payload: { currentPage = null, search = null, status = null, claim_status = null, tab = null,
		service_location = null } } = data;

	let claim_statusNames = null;
	let response;
	if (claim_status) {
		claim_statusNames = data.payload.claim_status.map((item) => `&claim_status=${[item]}`);
	}

	//yield put(loadTodoRequest());
//	yield put(getInspectionClaimedListRequested());
	

	if (!service_location) {
		response = yield call(() => {
			return ClientInstance
				.get(search ?
					`${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}&search=${search}${claim_statusNames.join("")}` :
					status ? `${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}&inspection_type=${status}${claim_statusNames.join("")}` :
						`${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}${claim_statusNames.join("")}`)
				.then(response => {
					return response;
				})
		});
	}

	if (service_location) {
		response = yield call(() => {
			return ClientInstance
				.get(search ?
					`${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}&search=${search}&service_location=${service_location}${claim_statusNames.join("")}` :
					status ? `${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}&inspection_type=${status}&service_location=${service_location}${claim_statusNames.join("")}` :
						`${API_POINT_URL}${EVENTS_POINT_URL}pool/?tab=${tab}&page=${currentPage}&service_location=${service_location}${claim_statusNames.join("")}`)
				.then(response => {
					return response;
				})
		});
	}


	if (response && response.data.status === 200) {
		yield put(getInspectionClaimedListSuccess(response.data.response));
	}
	if (response && response.data && response.data.status !== 200) {
		yield put(loadTodoError(response.data.message.detail));
	}
	


}



export function* claimInspection(payload) {
	const data = payload;

	//yield put(loadTodoRequest());
	
	const response = yield call(() => {
		return ClientInstance
			.get(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/claim/`, data.payload)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));
		

		if (!data.payload.inspectionDetailPage) {
			yield delay(1000);
			yield getInspectionPoolList({
				type: actionTypes.GetInspectionPoolList,
				payload: {
					currentPage: data.payload.currentPageNo,
					claim_status: data.payload.claim_status, tab: data.payload.tab
				}
			});
		}

	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}
	

}

export function* unClaimInspection(payload) {
	const data = payload;

	//	yield put(loadTodoRequest());
	
	const response = yield call(() => {
		return ClientInstance
			.get(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/unclaim/`, data.payload)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));
		

		if (!data.payload.inspectionDetailPage && !data.payload.calendarPage) {
			yield delay(1000);
			yield getInspectionClaimedList({
				type: actionTypes.GetInspectionClaimedList,
				payload: {
					currentPage: data.payload.currentPageNo,
					claim_status: data.payload.claim_status, tab: data.payload.tab
				}
			});
		}

		if (data.payload.calendarPage) {
			yield delay(1000);
			yield getEventList({
				type: actionTypes.GetEventList,
				payload: {
					form_date: data.payload.form_date,
					end_date: data.payload.end_date,
					service_location: data.payload.service_location
				}
			});
		}
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}
	

}


export function* changeInspectionDate(payload) {
	const data = payload;

	//yield put(loadTodoRequest());
	
	const response = yield call(() => {
		return ClientInstance
			.patch(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/claimed/`, {
				start_date: data.payload.start_date
			})
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));
		


		if (data.payload.currentPageNo) {
			yield delay(1000);
			yield getInspectionClaimedList({
				type: actionTypes.GetInspectionClaimedList,
				payload: {
					currentPage: data.payload.currentPageNo,
					claim_status: data.payload.claim_status, tab: data.payload.tab
				}
			});
		}

		if (data.payload.calendarPage) {
			yield delay(1000);
			yield getEventList({
				type: actionTypes.GetEventList,
				payload: {
					form_date: data.payload.form_date,
					end_date: data.payload.end_date,
					service_location: data.payload.service_location
				}
			});
		}
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}
	

}


export function* editClaimEvent(payload) {
	const data = payload;

	//yield put(loadTodoRequest());
	
	const response = yield call(() => {
		return ClientInstance
			.patch(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/`, data.payload)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(loadTodoSuccess(response.data.response.detail));
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}
	

}


export function* getInspectionTemplates(payload) {
	const data = payload;
	
	yield put(getInspectionTemplatesRequested());
	const response = yield call(() => {
		return ClientInstance
			.get(`${API_POINT_URL}${QUESTION_POINT_URL}?inspection=${data.payload.inspection}`)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(getInspectionTemplatesSuccess(response.data.response));
		
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}
	
}


export function* getInspectionDropdownList(payload) {
	const data = payload;
	const { payload: { service_location = null } } = data;
	
	yield put(getInspectionDropdownListRequested());
	const response = yield call(() => {
		return ClientInstance
			.get(service_location ? `${API_POINT_URL}${EVENTS_SERVICE_POINT_URL}?fields=id,completed_on&status=completed&service_location=${service_location}` :
			`${API_POINT_URL}${EVENTS_SERVICE_POINT_URL}?fields=id,completed_on&status=completed`)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(getInspectionDropdownListSuccess(response.data.response));
		
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}
}


export function* getInspectionSummary(payload) {
	const data = payload;
	//console.log("data" , data);
	let client_Names = null;
	let Template_Names = null;
	let serviceLocations_Names = null;
	let inspectors_Names = null;
	let completed_on_after = null;
	let completed_on_before = null;
	let completed_dates = null;


	if (data.payload && data.payload.clients) {
		client_Names = data.payload.clients.map((item) => `&client=${[item]}`);
	}

	if (data.payload && data.payload.areaTemplates) {
		Template_Names = data.payload.areaTemplates.map((item) => `&area_template=${[item]}`);
	}

	if (data.payload && data.payload.serviceLocations) {
		serviceLocations_Names = data.payload.serviceLocations.map((item) => `&service_location=${[item]}`);
	}

	if (data.payload && data.payload.inspectors) {
		inspectors_Names = data.payload.inspectors.map((item) => `&claimed_by=${[item]}`);
	}

	if (data.payload && data.payload.completed_on_before && data.payload.completed_on_after) {
		completed_on_after = data.payload.completed_on_after.map((item) => `&completed_on_after=${[item]}`);
		completed_on_before = data.payload.completed_on_before.map((item) => `completed_on_before=${[item]}`);
		completed_dates = [...completed_on_after , ...completed_on_before];
		//console.log("completed_dates" , completed_dates);
	}

	yield put(getInspectionSummaryRequested());
	const response = yield call(() => {
		return ClientInstance
			.get(client_Names || Template_Names || serviceLocations_Names || inspectors_Names || 
				completed_dates
				 ? `${API_POINT_URL}${EVENTS_POINT_URL}summary/?${client_Names ? client_Names.join("") : ''}${Template_Names ? Template_Names.join("") : ''}${serviceLocations_Names ? serviceLocations_Names.join("") : ''}${inspectors_Names ? inspectors_Names.join("") : ''}${completed_dates ? completed_dates.join("&") : ''}` :
			 `${API_POINT_URL}${EVENTS_POINT_URL}summary/`)
			.then(response => {
				return response;
			})
	});
	if (response && response.data.status === 200) {
		yield put(getInspectionSummarySuccess(response.data.response));
		
	}
	if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
		yield put(loadTodoError(response.data.message.detail));
	}
}



function* watchaddEvent() {
	yield takeLatest(actionTypes.AddEvent, addEvent)
}
function* watchgetInspectionList() {
	yield takeLatest(actionTypes.GetInspectionList, getInspectionList)
}
function* watchdeleteInspection() {
	yield takeLatest(actionTypes.DeleteInspection, deleteInspection)
}

function* watchgetInspectionDetail() {
	yield takeLatest(actionTypes.GetInspectionDetail, getInspectionDetail)
}

function* watcheditEvent() {
	yield takeLatest(actionTypes.EditEvent, editEvent)
}
function* watchgetEventList() {
	yield takeLatest(actionTypes.GetEventList, getEventList)
}

function* watchdeleteCalendar() {
	yield takeLatest(actionTypes.DeleteCalendar, deleteCalendar)
}
function* watchcalendarCreateEvent() {
	yield takeLatest(actionTypes.CalendarCreateEvent, calendarCreateEvent)
}
function* watchgetInspectionPoolList() {
	yield takeLatest(actionTypes.GetInspectionPoolList, getInspectionPoolList)
}

function* watchclaimInspection() {
	yield takeLatest(actionTypes.ClaimInspection, claimInspection)
}

function* watchunClaimInspection() {
	yield takeLatest(actionTypes.UnClaimInspection, unClaimInspection)
}

function* watchchangeInspectionDate() {
	yield takeLatest(actionTypes.ChangeInspectionDate, changeInspectionDate)
}

function* watcheditClaimEvent() {
	yield takeLatest(actionTypes.EditClaimEvent, editClaimEvent)
}
function* watchgetInspectionClaimedList() {
	yield takeLatest(actionTypes.GetInspectionClaimedList, getInspectionClaimedList)
}
function* watchgetWorkorderEventList() {
	yield takeLatest(actionTypes.GetWorkorderEventList, getWorkorderEventList)
}
function* watchgetPeriodicEventList() {
	yield takeLatest(actionTypes.GetPeriodicEventList, getPeriodicEventList)
}
function* watchgetInspectionTemplates() {
	yield takeLatest(actionTypes.GetInspectionTemplates, getInspectionTemplates)
}

function* watchgetInspectionDropdownList() {
	yield takeLatest(actionTypes.GetInspectionDropdownList, getInspectionDropdownList)
}


function* watchgetInspectionSummary() {
	yield takeLatest(actionTypes.GetInspectionSummary, getInspectionSummary)
}


export const eventSaga = [
	fork(watchaddEvent),
	fork(watchgetInspectionList),
	fork(watchdeleteInspection),
	fork(watchgetInspectionDetail),
	fork(watcheditEvent),
	fork(watchgetEventList),
	fork(watchdeleteCalendar),
	fork(watchcalendarCreateEvent),
	fork(watchgetInspectionPoolList),
	fork(watchclaimInspection),
	fork(watchunClaimInspection),
	fork(watchchangeInspectionDate),
	fork(watcheditClaimEvent),
	fork(watchgetInspectionClaimedList),
	fork(watchgetWorkorderEventList),
	fork(watchgetPeriodicEventList),
	fork(watchgetInspectionTemplates),
	fork(watchgetInspectionDropdownList),
	fork(watchgetInspectionSummary)
]

import axios from "axios";
import { put, takeLatest, fork, call, delay } from 'redux-saga/effects';
import { WebConfig, AppConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getServiceListSuccess,
    getAreaServiceDetailRequested,
    getAreaServiceDetailSuccess,
    getAreaTemplateItemsRequested,
    getAreaTemplateItemsSuccess,
    getAreaTemplateServicesRequested,
    getAreaTemplateServicesSuccess,
    getCloneTemplateRequested,
    getCloneTemplateSuccess,
    getAreaListSuccess,
    getAreaDetailRequested,
    getAreaDetailSuccess,
    getServicesAreaAssociatedRequested,
    getServicesAreaAssociatedSuccess,
    getLocationAreaAssociatedRequested,
    getLocationAreaAssociatedSuccess,
    addAreaCheckRequested,
    addAreaCheckSuccess,
    AddAreaCheckLoad,
    getAreaAllListSuccess,
    getAreaAllListRequested,
    importFilesRequested,
    importFilesSuccess,
    importFilesFailure
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const SERVICE_POINT_URL = WebConfig.ApiUrls.configurations.services;
const AREAS_POINT_URL = WebConfig.ApiUrls.configurations.areas;
const AREAS_TEMPLATE_POINT_URL = WebConfig.ApiUrls.configurations.areaTemplate;
const SUBLOCATION_POINT_URL = WebConfig.ApiUrls.company.subLocations;

export function* addAreaService(payload) {
    const data = payload;
    //yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${SERVICE_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* getServiceList(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null } } = data;
    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(search ?
                `${API_POINT_URL}${SERVICE_POINT_URL}?page=${currentPage}&search=${search}` :
                `${API_POINT_URL}${SERVICE_POINT_URL}?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getServiceListSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}


export function* editAreaService(payload) {
    const data = payload;
    //   yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${SERVICE_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}

export function* getAreaServiceDetail(payload) {
    const data = payload;
    //  yield put(loadTodoRequest());
    yield put(getAreaServiceDetailRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${SERVICE_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getAreaServiceDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* deleteAreaService(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${SERVICE_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)

        if (!data.payload.serviceAreaDetailPage) {
            yield getServiceList({ type: actionTypes.GetServiceList, payload: { currentPage: data.payload.currentPage } });
        }

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}


export function* getAreaTemplateItems(payload) {
    const data = payload;
    //  yield put(loadTodoRequest());
    yield put(getAreaTemplateItemsRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}items/?fields=name,id`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getAreaTemplateItemsSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* getAreaTemplateServices(payload) {
    const data = payload;
    //yield put(loadTodoRequest());
    yield put(getAreaTemplateServicesRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${SERVICE_POINT_URL}items/?fields=id,name,weight`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getAreaTemplateServicesSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}



export function* getCloneTemplate(payload) {
    const data = payload;
    // yield put(loadTodoRequest());
    yield put(getCloneTemplateRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getCloneTemplateSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* addArea(payload) {
    const data = payload;
    //   yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* getAreaList(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null } } = data;
    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(search ?
                `${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}?page=${currentPage}&search=${search}` :
                `${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}?page=${currentPage}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getAreaListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}

export function* getAreaAllList(payload) {
    //  console.log("payload" , payload);
    const data = payload;
    const { payload: { search = null, service_location = null, isGroup = null, isNoParm = null } } = data;
    let serviceNames = [];
    let subNames = [];
    let sectionNames = [];
    let response;
    if (data.payload && data.payload.service_locations) {
        serviceNames = data.payload.service_locations.map((item) => `&service_location=${[item]}`);
    }
    if (data.payload && data.payload.sub_locations) {
        subNames = data.payload.sub_locations.map((item) => `&sub_location=${[item]}`);
    }
    if (data.payload && data.payload.sections) {
        sectionNames = data.payload.sections.map((item) => `&section=${[item]}`);
    }

    yield put(getAreaAllListRequested());

    if (!isNoParm) {
        response = yield call(() => {
            return ClientInstance
                .post(search && !isGroup ?
                    `${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}list_post/?no_page&fields=name,id&search=${search}`:
                    isGroup && !search ? (`${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}list_post/?no_page&fields=name,id`) :
                        isGroup && search ? `${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}list_post/?no_page&fields=name,id&search=${search}` :
                            `${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}list_post/?no_page&fields=name,id` , 
                            search && !isGroup ? {service_location : data.payload.service_location} :
                            isGroup && !search  ? {
                                service_location : data.payload.service_locations,
                                sub_location : data.payload.sub_locations,
                                section : data.payload.sections
                            } : 
                            isGroup && search ? {
                                service_location : data.payload.service_locations,
                                sub_location : data.payload.sub_locations,
                                section : data.payload.sections
                            } :
                            {service_location : [data.payload.service_location]})
                .then(response => {
                    return response;
                })
        });
    }

    if (isNoParm) {
        response = yield call(() => {
            return ClientInstance
                .get(`${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}?no_page&fields=name,id`)
                .then(response => {
                    return response;
                })
        });
    }

    if (response && response.data.status === 200) {
        yield put(getAreaAllListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}

export function* getAreaDetail(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());
    yield put(getAreaDetailRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getAreaDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* editArea(payload) {
    const data = payload;
    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }


}


export function* deleteAreaTemplate(payload) {
    const data = payload;

    //   yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)

        if (!data.payload.areaDeailPage) {
            yield getAreaList({ type: actionTypes.GetAreaList, payload: { currentPage: data.payload.currentPage } });
        }

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* deleteArea(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${AREAS_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)
        yield getAreaDetail({ type: actionTypes.GetAreaDetail, payload: { id: data.payload.templateId } });
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}

export function* removeAreaService(payload) {
    const data = payload;
    //   yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${AREAS_POINT_URL}${data.payload.areaId}/service/${data.payload.serviceId}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)
        yield getAreaDetail({ type: actionTypes.GetAreaDetail, payload: { id: data.payload.templateId } });

    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}



export function* editAreaDetail(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .put(`${API_POINT_URL}${AREAS_POINT_URL}${data.payload.areaId}/`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        yield delay(1000);
        yield getAreaDetail({ type: actionTypes.GetAreaDetail, payload: { id: data.payload.area_template } });
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }





}


export function* addAreaDetail(payload) {
    const data = payload;


    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${AREAS_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        yield delay(1000)
        yield getAreaDetail({ type: actionTypes.GetAreaDetail, payload: { id: data.payload.area_template } });
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }





}


export function* getServicesAreaAssociated(payload) {
    const data = payload;
    const { payload: { search = null } } = data;

    //   yield put(loadTodoRequest());
    yield put(getServicesAreaAssociatedRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(search ?
                `${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}?service=${data.payload.id}&page=${data.payload.currentPage}&search=${search}` :
                `${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}?service=${data.payload.id}&page=${data.payload.currentPage}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getServicesAreaAssociatedSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* removeAreaAssocaitedService(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${SERVICE_POINT_URL}${data.payload.serviceId}/template/${data.payload.templateId}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)
        yield getServicesAreaAssociated({
            type: actionTypes.GetServicesAreaAssociated, payload: {
                id: data.payload.serviceId,
                currentPage: 1
            }
        });
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* getLocationAreaAssociated(payload) {
    const data = payload;
    const { payload: { search = null } } = data;

    // yield put(loadTodoRequest());
    yield put(getLocationAreaAssociatedRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(search ?
                `${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}service-locations/?template=${data.payload.id}&search=${search}` :
                `${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}service-locations/?template=${data.payload.id}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getLocationAreaAssociatedSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* removeLocationAssocaited(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${SUBLOCATION_POINT_URL}${data.payload.sublocationId}/area-template/${data.payload.areaTemplateId}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        yield delay(1000)
        yield getLocationAreaAssociated({ type: actionTypes.GetLocationAreaAssociated, payload: { id: data.payload.areaTemplateId } });
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* addAreaCheck(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());
    // yield put(AddAreaCheckLoad());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}validate_name/`, data.payload)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(addAreaCheckRequested());
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
        yield put(addAreaCheckSuccess());

    }

}


export function* importServiceList(payload) {
    const data = payload;
    yield put(importFilesRequested());
    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${SERVICE_POINT_URL}import_xls/`, data.payload.formData)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(importFilesSuccess(response.data.response.detail));

    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(importFilesFailure(response.data.message));
    }
}


export function* importAreaList(payload) {
    const data = payload;
    yield put(importFilesRequested());
    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${AREAS_TEMPLATE_POINT_URL}import_xls/`, data.payload.formData)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(importFilesSuccess(response.data.response.detail));

    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(importFilesFailure(response.data.message));
    }
}



function* watchaddAreaService() {
    yield takeLatest(actionTypes.AddService, addAreaService)
}

function* watchgetServiceList() {
    yield takeLatest(actionTypes.GetServiceList, getServiceList)
}

function* watcheditAreaService() {
    yield takeLatest(actionTypes.EditAreaService, editAreaService)
}

function* watchdeleteAreaService() {
    yield takeLatest(actionTypes.DeleteAreaService, deleteAreaService)
}

function* watchgetAreaServiceDetail() {
    yield takeLatest(actionTypes.GetAreaServiceDetail, getAreaServiceDetail)
}

function* watchgetAreaTemplateItems() {
    yield takeLatest(actionTypes.GetAreaTemplateItems, getAreaTemplateItems)
}


function* watchgetAreaTemplateServices() {
    yield takeLatest(actionTypes.GetAreaTemplateServices, getAreaTemplateServices)
}


function* watchgetCloneTemplate() {
    yield takeLatest(actionTypes.GetCloneTemplate, getCloneTemplate)
}

function* watchaddArea() {
    yield takeLatest(actionTypes.AddArea, addArea)
}

function* watchgetAreaList() {
    yield takeLatest(actionTypes.GetAreaList, getAreaList)
}

function* watchgetAreaDetail() {
    yield takeLatest(actionTypes.GetAreaDetail, getAreaDetail)
}

function* watcheditArea() {
    yield takeLatest(actionTypes.EditArea, editArea)
}

function* watchdeleteAreaTemplate() {
    yield takeLatest(actionTypes.DeleteAreaTemplate, deleteAreaTemplate)
}

function* watchdeleteArea() {
    yield takeLatest(actionTypes.DeleteArea, deleteArea)
}

function* watchremoveAreaService() {
    yield takeLatest(actionTypes.RemoveAreaService, removeAreaService)
}

function* watcheditAreaDetail() {
    yield takeLatest(actionTypes.EditAreaDetail, editAreaDetail)
}

function* watchaddAreaDetail() {
    yield takeLatest(actionTypes.AddAreaDetail, addAreaDetail)
}

function* watchgetServicesAreaAssociated() {
    yield takeLatest(actionTypes.GetServicesAreaAssociated, getServicesAreaAssociated)
}

function* watchremoveAreaAssocaitedService() {
    yield takeLatest(actionTypes.RemoveAreaAssocaitedService, removeAreaAssocaitedService)
}

function* watchgetLocationAreaAssociated() {
    yield takeLatest(actionTypes.GetLocationAreaAssociated, getLocationAreaAssociated)
}

function* watchremoveLocationAssocaited() {
    yield takeLatest(actionTypes.RemoveLocationAssocaited, removeLocationAssocaited)
}


function* watchaddAreaCheck() {
    yield takeLatest(actionTypes.AddAreaCheck, addAreaCheck)
}

function* watchgetAreaAllList() {
    yield takeLatest(actionTypes.GetAreaAllList, getAreaAllList)
}


function* watchimportServiceList() {
    yield takeLatest(actionTypes.ImportServiceList, importServiceList)
}

function* watchimportAreaList() {
    yield takeLatest(actionTypes.ImportAreaList, importAreaList)
}


export const areaServiceSaga = [
    fork(watchaddAreaService),
    fork(watchgetServiceList),
    fork(watcheditAreaService),
    fork(watchdeleteAreaService),
    fork(watchgetAreaServiceDetail),
    fork(watchgetAreaTemplateItems),
    fork(watchgetAreaTemplateServices),
    fork(watchgetCloneTemplate),
    fork(watchaddArea),
    fork(watchgetAreaList),
    fork(watchgetAreaDetail),
    fork(watcheditArea),
    fork(watchdeleteAreaTemplate),
    fork(watchdeleteArea),
    fork(watchremoveAreaService),
    fork(watcheditAreaDetail),
    fork(watchaddAreaDetail),
    fork(watchgetServicesAreaAssociated),
    fork(watchremoveAreaAssocaitedService),
    fork(watchgetLocationAreaAssociated),
    fork(watchremoveLocationAssocaited),
    fork(watchaddAreaCheck),
    fork(watchgetAreaAllList),
    fork(watchimportServiceList),
    fork(watchimportAreaList)
]

import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig, AppConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getRecentSearchRequested,
    getRecentSearchSuccess

} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const SEARCH_POINT_URL = WebConfig.ApiUrls.search;

export function* getRecentSearch(payload) {

    const data = payload;
    yield put(getRecentSearchRequested());
    //  yield put(loadTodoRequest());
    
    const response = yield call(() => {

        return axios
            .get(`${API_POINT_URL}${SEARCH_POINT_URL}?page=${data.payload.page}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getRecentSearchSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
   


}


export function* getRecentSearchClient(payload) {

    const data = payload;
    yield put(getRecentSearchRequested());
    // yield put(loadTodoRequest());
    
    const response = yield call(() => {

        return ClientInstance
            .get(`${API_POINT_URL}${SEARCH_POINT_URL}?page=${data.payload.page}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getRecentSearchSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
   


}



function* watchgetRecentSearch() {
    yield takeLatest(actionTypes.GetRecentSearch, getRecentSearch)
}

function* watchgetRecentSearchClient() {
    yield takeLatest(actionTypes.GetRecentSearchClient, getRecentSearchClient)
}

export const SearchSaga = [
    fork(watchgetRecentSearch),
    fork(watchgetRecentSearchClient)
]
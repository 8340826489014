import * as actionTypes from '../actionTypes';

const initialState = {
    importFileSuccess: false,
    importFileFail: false,
    importFailMessage: null,
    importSuccessMessage : null
};
const ImportReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;

    switch (type) {

        case actionTypes.ImportFilesRequested:
            object = { ...state, importFileFail: false, importFailMessage: null,  importSuccessMessage : null, importFileSuccess: false }
            break

        case actionTypes.ImportFilesSuccess:
            object = { ...state, importFileFail: false, importFailMessage: null, importFileSuccess: true , importSuccessMessage :  payload }
            break

        case actionTypes.ImportFilesFailure:
            object = { ...state, importFileFail: true, importFailMessage: payload, importFileSuccess: false , importSuccessMessage : null}
            break

        case actionTypes.LogOut:
            object = { state: undefined };
            break


        default:
            object = state;
    }
    return object
};
export default ImportReducer;
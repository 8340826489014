import * as actionTypes from '../actionTypes';


export const getTimezone = () => {
    return {
        type: actionTypes.GetTimezone,
    }
}

export const getTimezoneSuccess = (data) => {
    return {
        type: actionTypes.GetTimezoneSuccess,
        payload: data
    }
}

export const addService = (data) => {
    return {
        type: actionTypes.AddServiceLocation,
        payload: data
    }
}

export const getServiceLocation = (data) => {
    return {
        type: actionTypes.GetServiceLocation,
        payload: data
    }
}

export const getServiceclientLocation = (data) => {
    return {
        type: actionTypes.GetClientServiceLocation,
        payload: data
    }
}

export const getServiceLocationRequested = () => {
    return {
        type: actionTypes.GetServiceLocationRequested,
    }
}

export const getServiceLocationSuccess = (data) => {
    return {
        type: actionTypes.GetServiceLocationSuccess,
        payload: data
    }
}

export const updateServiceStatus = (data) => {
    return {
        type: actionTypes.UpdateServiceStatus,
        payload: data
    }
}

export const updateClientServiceStatus = (data) => {
    return {
        type: actionTypes.UpdateClientServiceStatus,
        payload: data
    }
}

export const deleteService = (data) => {
    return {
        type: actionTypes.DeleteService,
        payload: data
    }
}

export const deleteClientService = (data) => {
    return {
        type: actionTypes.DeleteClientService,
        payload: data
    }
}


export const getServiceDetail = (data) => {
    return {
        type: actionTypes.GetServiceDetail,
        payload: data
    }
}

export const getServiceDetailRequested = () => {
    return {
        type: actionTypes.GetServiceDetailRequested,
    }
}

export const getServiceDetailSuccess = (data) => {
    return {
        type: actionTypes.GetServiceDetailSuccess,
        payload: data
    }
}

export const editService = (data) => {
    return {
        type: actionTypes.EditService,
        payload: data
    }
}


export const getServiceAll = (data) => {
    return {
        type: actionTypes.GetAllServiceList,
        payload: data
    }
}

export const getServiceAllSuccess = (data) => { 
    return {
        type: actionTypes.GetAllServiceListSuccess,
        payload: data
    }
}

export const getAllServiceListRequested = (data) => { 
    return {
        type: actionTypes.GetAllServiceListRequested,
        payload: data
    }
}



export const getServiceContact = (data) => {
    return {
        type: actionTypes.GetServiceContacts,
        payload: data
    }
}

export const getServiceContactRequested = () => {
    return {
        type: actionTypes.GetServiceContactsRequested,
    }
}

export const getServiceContactSuccess = (data) => {
    return {
        type: actionTypes.GetServiceContactsSuccess,
        payload: data
    }
}



export const getServiceListArchieve = (data) => {
    return {
        type: actionTypes.GetServiceArchiveList,
        payload: data
    }
}

export const getServiceListArchieveRequested = (data) => {
    return {
        type: actionTypes.GetServiceArchiveListRequested,
        payload: data
    }
}


export const getServiceListArchieveSuccess = (data) => {
    return {
        type: actionTypes.GetServiceArchiveListSuccess,
        payload: data
    }
}

export const updateServiceArchieveStatus = (data) => {
    return {
        type: actionTypes.UpdateServiceArchieveStatus,
        payload: data
    }
}


export const getAllServiceReportList = (data) => {
    return {
        type: actionTypes.GetAllServiceReportList,
        payload: data
    }
}

export const getAllServiceListReportSuccess = (data) => {
    return {
        type: actionTypes.GetAllServiceListReportSuccess,
        payload: data
    }
}

export const getAllServiceListReportRequested = (data) => {
    return {
        type: actionTypes.GetAllServiceListReportRequested,
        payload: data
    }
}


export const getAllServiceDropdown = (data) => {
    return {
        type: actionTypes.GetAllServiceDropdown,
        payload: data
    }
}

export const getAllServiceDropdownSuccess = (data) => {
    return {
        type: actionTypes.GetAllServiceDropdownSuccess,
        payload: data
    }
}

export const getAllServiceDropdownRequested = (data) => {
    return {
        type: actionTypes.GetAllServiceDropdownRequested,
        payload: data
    }
}

export const importServiceModalList = (data) => {
    return {
        type: actionTypes.ImportServiceModalList,
        payload: data
    }
}


import * as actionTypes from '../actionTypes';

export const addContact = (data) => {
    return {
        type: actionTypes.AddContact,
        payload: data
    }
}


export const getContact = (data) => {
    return {
        type: actionTypes.GetContactList,
        payload: data
    }
}

export const getContactClient = (data) => {
    return {
        type: actionTypes.GetClientContact,
        payload: data
    }
}

export const getContactRequested = () => {
    return {
        type: actionTypes.GetContactListRequested,
    }
}

export const getContactSuccess = (data) => {
    return {
        type: actionTypes.GetContactListSuccess,
        payload: data
    }
}

export const updateContactStatus = (data) => {
    return {
        type: actionTypes.UpdateContactStatus,
        payload: data
    }
}

export const updateContactClientStatus = (data) => {
    return {
        type: actionTypes.UpdateClientContactStatus,
        payload: data
    }
}


export const deleteContact = (data) => {
    return {
        type: actionTypes.DeleteContact,
        payload: data
    }
}

export const deleteContactClient = (data) => {
    return {
        type: actionTypes.DeleteClientContact,
        payload: data
    }
}




export const getContactDetail = (data) => {
    return {
        type: actionTypes.GetContactDetail,
        payload: data
    }
}

export const getContactDetailRequested = () => {
    return {
        type: actionTypes.GetContactRequested,
    }
}

export const getContactDetailSuccess = (data) => {
    return {
        type: actionTypes.GetContactSuccess,
        payload: data
    }
}


export const editContact = (data) => {
    return {
        type: actionTypes.EditContact,
        payload: data
    }
}


export const getContactListArchieve = (data) => {
    return {
        type: actionTypes.GetContactArchiveList,
        payload: data
    }
}

export const getContactListArchieveRequested = (data) => {
    return {
        type: actionTypes.GetContactArchiveListRequested,
        payload: data
    }
}


export const getContactListArchieveSuccess = (data) => {
    return {
        type: actionTypes.GetContactArchiveListSuccess,
        payload: data
    }
}

export const updateContactArchieveStatus = (data) => {
    return {
        type: actionTypes.UpdateContactArchieveStatus,
        payload: data
    }
}

export const importContactList = (data) => {
    return {
        type: actionTypes.ImportContactList,
        payload: data
    }
}

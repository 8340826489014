import { LogLevel } from "@azure/msal-browser";
import { WebConfig } from 'config';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: `${WebConfig.serviceUrls.ClientIdUrl}`,
       // clientId: "6b27f926-4290-4082-8d50-eab9652e41d5",
       // authority: "https://login.microsoftonline.com/df7b24ff-b999-468c-bec1-d4e3068b23d3",
        authority: `${WebConfig.serviceUrls.AuthorityUrl}`,
       // redirectUri: 'http://localhost:3000/login',
        redirectUri : `${WebConfig.serviceUrls.AzureUrl}`,
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl : false

    },
    cache: {
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
        cacheLocation: 'sessionStorage',
        //storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {	
                    return;	
                }
                switch (level) {	
                    case LogLevel.Error:	
                        console.error(message);	
                        return;	
                    case LogLevel.Info:	
                        console.info(message);	
                        return;	
                    case LogLevel.Verbose:	
                        console.debug(message);	
                        return;	
                    case LogLevel.Warning:	
                        console.warn(message);	
                        return;	
                    default:
                        return;
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    // scopes: ['user.read','email', 'profile', 'openid' , "Mail.Read"]
    scopes: ['user.read', 'openid']
};


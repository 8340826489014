import * as actionTypes from '../actionTypes';

const initialState = {
    recentSearch : []
};
const SearchReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    switch (type) {

        case actionTypes.GetRecentSearchRequested:
            object = { ...state, recentSearch: null }
            break

        case actionTypes.GetRecentSearchSuccess:
            object = { ...state, recentSearch: payload }
            break
            
        case actionTypes.LogOut: 
            object = { state : undefined };
            break
    
        default:
            object = state;
    }
    return object
};
export default SearchReducer;
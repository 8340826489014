import React from "react";
import { useTranslation } from "react-i18next";

const Notfound403 = (props) => {
  const { t } = useTranslation();
  return (
    <div className="notfound-block">
      <img
        src={require("assets/images/403.svg").default}
        className="img-fluid"
      />
      <h3> {t("NotFound403Component.title")}</h3>
      <p> {t("NotFound403Component.text")}</p>
      <button
        className="button button-rounded button-height-meidum"
        onClick={() => props.history.push('/')}
        title="Back to Home"
      >
        Back to Home
      </button>
    </div>
  );
};

export default Notfound403;

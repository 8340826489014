import * as actionTypes from '../actionTypes';

export const addReport = (data) => {
    return {
        type: actionTypes.AddReport,
        payload: data
    }
}


export const getSavedReport = (data) => {
    return {
        type: actionTypes.GetSavedReport,
        payload: data
    }
}

export const getSavedReportSuccess = (data) => {
    return {
        type: actionTypes.GetSavedReportSuccess,
        payload: data
    }
}


export const getReportDetail = (data) => {
    return {
        type: actionTypes.GetReportDetail,
        payload: data
    }
}

export const getReportDetailRequested = (data) => {
    return {
        type: actionTypes.GetReportDetailRequested,
        payload: data
    }
}

export const getReportDetailSuccess = (data) => {
    return {
        type: actionTypes.GetReportDetailSuccess,
        payload: data
    }
}

export const upDateReoprt = (data) => {
    return {
        type: actionTypes.UpDateReoprt,
        payload: data
    }
}

export const genrateReport = (data) => {
    return {
        type: actionTypes.GenrateReport,
        payload: data
    }
}

export const genrateReportRequested = (data) => {
    return {
        type: actionTypes.GenrateReportRequested,
        payload: data
    }
}

export const genrateReportSuccess = (data) => {
    return {
        type: actionTypes.GenrateReportSuccess,
        payload: data
    }
}

export const deleteSavedReport = (data) => {
    return {
        type: actionTypes.DeleteSavedReport,
        payload: data
    }
}


import * as actionTypes from '../actionTypes';

const initialState = {
    permissonList: null,
    permissionModuleList: null,
    permissionDetail: null,
    permissionDropdownList : null,
    permissionPrimaryDetail :null,
    permissionSecondaryDetail : null
};

const PermissonReducer = (state, {
    type,
    payload
}) => {

    if (state === undefined) {
        state = initialState
    }

    let object;
    switch (type) {

        case actionTypes.GetPermissionListRequested:
            object = {
                ...state,
                permissonList: null
            }
            break

        case actionTypes.GetPermissionListSuccess:
            object = {
                ...state,
                permissonList: payload
            }
            break

        case actionTypes.GetPermissionModuleListRequested:
            object = {
                ...state,
                permissionModuleList: null
            }
            break

        case actionTypes.GetPermissionModuleListSuccess:
            object = {
                ...state,
                permissionModuleList: payload
            }
            break

        case actionTypes.GetPermissionDetailRequested:
            object = {
                ...state,
                permissionDetail: null
            }
            break

        case actionTypes.GetPermissionDetailSuccess:
            object = {
                ...state,
                permissionDetail: payload
            }
            break


        case actionTypes.GetPermissionDropdownListRequested:
            object = {
                ...state,
                permissionDropdownList: null
            }
            break

        case actionTypes.GetPermissionDropdownListSuccess:
            object = {
                ...state,
                permissionDropdownList: payload
            }
            break


        case actionTypes.GetPermissionPrimaryDetailRequested:
            object = {
                ...state,
                permissionPrimaryDetail: null
            }
            break

        case actionTypes.GetPermissionPrimaryDetailSuccess:
            object = {
                ...state,
                permissionPrimaryDetail: payload
            }
            break

        case actionTypes.GetPermissionSecondaryDetailRequested:
            object = {
                ...state,
                permissionSecondaryDetail: null
            }
            break

        case actionTypes.GetPermissionSecondaryDetailSuccess:
            object = {
                ...state,
                permissionSecondaryDetail: payload
            }
            break

        case actionTypes.LogOut: 
            object = { state : undefined };
                break
            
        default:
            object = state;
    }
    return object
};
export default PermissonReducer;
import React from "react";

const DashboardLogo = (props) => {
    return (
        <div className="dashboard-logo">
            {props.expandState || props.dashLayout ?
                <img src={require('assets/images/dashboard-gdi-logo.svg').default} className="img-fluid" />
                : <img src={require('assets/images/logo.svg').default}  className="img-fluid" />}

        </div>
    )
}

export default DashboardLogo;

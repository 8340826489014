import * as actionTypes from '../actionTypes';


export const logout = (data) => {
    return {
        type: actionTypes.LogOut,
        payload: data
    }
}

export const loginApp = (data) => {
    return {
        type: actionTypes.GetappAuth,
        payload: data
    }
}

export const loginAppSuccess = (data) => {
    return {
        type: actionTypes.GetappAuthSuccess,
        payload: data
    }
}

export const login = (data) => {
    return {
        type: actionTypes.Login,
        payload: data
    }
}

export const azureLogin = (data) => {
    return {
        type: actionTypes.azureLogin,
        payload: data
    }
}



export const loginDetail = (data) => {
    return {
        type: actionTypes.LoginDetail,
        payload: data
    }
}

export const forgotPassword = (data) => {
    return {
        type: actionTypes.ForgotPassword,
        payload: data
    }
}

export const resetPassword = (data) => {
    return {
        type: actionTypes.ResetPassword,
        payload: data
    }
}

export const resettokenVerify = (data) => {
    return {
        type: actionTypes.ResetVerifyToken,
        payload: data
    }
}

export const resettokenVerifyfailed = (data) => {
    return {
        type: actionTypes.ResetVerifyTokenFailed,
        payload: data
    }
}

export const getProfile = (data) => {
    return {
        type: actionTypes.GetProfile,
        payload: data
    }
}

export const getProfileRequested = () => {
    return {
        type: actionTypes.GetProfileRequested,
    }
}


export const getProfilesuccess = (data) => {
    return {
        type: actionTypes.GetProfileSuccess,
        payload: data
    }
}


export const getInpersonate = (data) => {
    return {
        type: actionTypes.InPersonate,
        payload : data
    }
}
export const getInpersonateRequested = () => {
    return {
        type: actionTypes.InPersonateRequested,
    }
}

export const getInpersonateSuccess = (data) => {
    return {
        type: actionTypes.InPersonateSuccess,
        payload : data
    }
}


export const getInpersonatelogout = () => {
    return {
        type: actionTypes.InPersonateLogout,
    }
}



export const getUserProfile = (data) => {
    return {
        type: actionTypes.GetUserProfile,
        payload: data
    }
}

export const getUserProfileRequested = () => {
    return {
        type: actionTypes.GetUserProfileRequested,
    }
}

export const getUserProfileSuccess = (data) => {
    return {
        type: actionTypes.GetUserProfileSuccess,
        payload: data
    }
}



export const updateProfile = (data) => {
    return {
        type: actionTypes.UpdateProfile,
        payload: data
    }
}


export const getQrCode = (data) => {
    return {
        type: actionTypes.GetQrCode,
        payload: data
    }
}

export const getQrCodeRequested = (data) => {
    return {
        type: actionTypes.GetQrCodeRequested,
        payload: data
    }
}

export const getQrCodeSuccess = (data) => {
    return {
        type: actionTypes.GetQrCodeSuccess,
        payload: data
    }
}

export const getDeviceList = (data) => {
    return {
        type: actionTypes.GetDeviceList,
        payload: data
    }
}

export const getDeviceListRequested = (data) => {
    return {
        type: actionTypes.GetDeviceListRequested,
        payload: data
    }
}

export const getDeviceListSuccess = (data) => {
    return {
        type: actionTypes.GetDeviceListSuccess,
        payload: data
    }
}

export const addDevice = (data) => {
    return {
        type: actionTypes.AddDevice,
        payload: data
    }
}

export const addDeviceSuccess = () => {
    return {
        type: actionTypes.AddDeviceSuccess,
    }
}

export const addDeviceRequested = () => {
    return {
        type: actionTypes.AddDeviceRequested,
    }
}

export const addDeviceFailure = (data) => {
    return {
        type: actionTypes.AddDeviceFailure,
        payload: data
    }
}


export const deleteDevice = (data) => {
    return {
        type: actionTypes.DeleteDevice,
        payload: data
    }
}


export const fallBackCode = (data) => {
    return {
        type: actionTypes.FallBackCode,
        payload: data
    }
}

export const changePassword = (data) => {
    return {
        type: actionTypes.ChangePassword,
        payload: data
    }
}




import * as actionTypes from '../actionTypes';

const initialState = {
 contactList : null,
 contactDetail : null,
 archieveContactList : null
};

const contactReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    switch (type) {

        case actionTypes.GetContactListRequested:
            object = { ...state, 
               // contactList: null
             }
            break

        case actionTypes.GetContactListSuccess:
            object = { ...state, contactList: payload }
            break


        case actionTypes.GetContactRequested:
            object = { ...state, contactDetail: null }
            break

        case actionTypes.GetContactSuccess:
            object = { ...state, contactDetail: payload }
            break

        case actionTypes.GetContactArchiveListRequested:
            object = { ...state, 
                //archieveContactList : null
             }
            break   
            
        case actionTypes.GetContactArchiveListSuccess:
            object = { ...state, archieveContactList : payload }
            break
            
        case actionTypes.LogOut: 
           object = { state : undefined };
            break
        default:
            object = state;
    }
    return object
};
export default contactReducer;
import * as actionTypes from '../actionTypes';

const initialState = {
    subLocationList: null,
    subLocationDetail : null,
    allSublocationList : null ,
    allSectionsList : null,
    allSublocationDropdownList : null
};
const subLocationReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    
    switch (type) {

        case actionTypes.GetSublocationListSuccess:
            object = { ...state, subLocationList: payload }
            break

        case actionTypes.GetSublocationDetailRequested:
            object = { ...state, subLocationDetail: null }
            break
        
        case actionTypes.GetSublocationDetailSuccess:
            object = { ...state, subLocationDetail: payload }
            break


        
        case actionTypes.GetAllSublocationListRequested:
            object = { ...state, allSublocationList: null }
            break
        
        case actionTypes.GetAllSublocationListSuccess:
            object = { ...state, allSublocationList: payload }
            break

        
        case actionTypes.GetAllSectionListRequested:
            object = { ...state, allSectionsList: null }
            break
        
        case actionTypes.GetAllSectionListSuccess:
            object = { ...state, allSectionsList: payload }
            break

        
        case actionTypes.GetAllSublocationListDropdownRequested:
            object = { ...state, allSublocationDropdownList: null }
            break

        case actionTypes.GetAllSublocationListDropdownSuccess:
            object = { ...state, allSublocationDropdownList: payload }
            break

            
        case actionTypes.LogOut: 
             object = { state : undefined };
            break
        default:
            object = state;
    }
    return object
};
export default subLocationReducer;
import * as actionTypes from '../actionTypes';

const initialState = {
    defaultProvider: null,
    ratingProviderDetail: null,
    ratingList: null,
    ratingTemplateDetail : null,
    ratingDropdwnList : null
};
const ratingTemplateReducer = (state, { type, payload }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;

    switch (type) {

        case actionTypes.GetTemplateProviderSucess:
            object = { ...state, defaultProvider: payload }
            break

        case actionTypes.GetTemplateProviderRequested:
            object = { ...state, defaultProvider: null }
            break

        case actionTypes.GetTemplateProviderDetail:
            object = { ...state, ratingProviderDetail: payload }
            break

        case actionTypes.GetRatingListSuccess:
            object = { ...state, ratingList: payload }
            break

        case actionTypes.GetRatingTemplateDetailRequested:
            object = { ...state, ratingTemplateDetail: null }
            break

        case actionTypes.GetRatingTemplateDetailSuccess:
            object = { ...state, ratingTemplateDetail: payload }
            break

        case actionTypes.GetRatingTemplateDropdownSuccess:
            object = { ...state, ratingDropdwnList: payload }
            break 

        case actionTypes.LogOut: 
            object = { state : undefined };
            break

        default:
            object = state;
    }
    return object
};
export default ratingTemplateReducer;
import React, { Component, Fragment } from "react";
import DashboardRoutes from "./routes";
import { connect } from "react-redux";
import Helper from "utils/helpers";
import { withTranslation } from "react-i18next";
/*actions*/
import {
  getProfile, getInpersonate, getInpersonatelogout, getUserProfile, getUserProfileRequested,
  getNotificationListHeader, getNotificationAllList, getNotificationListHeaderRequested
} from "store/actions";
/*components*/
import DashboardHeader from "shared/components/DashboardHeader";
import DashboardMenu from "shared/components/DashboardMenu";
import i18n from "translations";
import { WebConfig } from 'config';
import { ToastContainer, toast } from 'react-toastify';
import sanitizeHtml from 'sanitize-html';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loader from 'shared/components/Loader'

let socket = null;

class DashboardLayout extends Component {

  state = {
    expandState: true,
    socketConnection: true,
    dashLayout: false
  };

  componentDidMount() {
    const isLoggedIn = Helper.isLoggedIn();
    if (isLoggedIn) {
      this.props.getProfile();
      window.addEventListener("resize", this.updateDimensions);
      window.addEventListener("load", this.updateDimensions);
    }
    if (!isLoggedIn && window.location.href) {
      // console.log("window.location" , window.location);
      localStorage.setItem('RedirectUrl', window.location.pathname);
      this.props.history.push(`/login?redirect=${window.location.pathname}`);
    }
    if (!localStorage.getItem("language")) {
      i18n.changeLanguage("en");
    }
    if (socket) {
      socket.close();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.inperosnateLogout !== this.props.inperosnateLogout && this.props.inperosnateLogout) {
      this.props.history.push('../admin');
    }

    if (this.props.profile && prevProps.profile !== this.props.profile) {
      this.props.i18n.changeLanguage(this.props.profile.preferred_language);
    }
    if (this.props.profile && prevProps.profile !== this.props.profile && !this.props.userProfile &&
      this.props.profile.role !== 2) {
      this.props.getUserProfile();
    }
    if (localStorage.getItem("inperosnate") && localStorage.getItem("inperosnateId") &&
      this.props.profile && prevProps.profile !== this.props.profile && this.props.profile.role === 2 && !this.props.inperosnateDetail
    ) {
      const payload = {
        id: localStorage.getItem("inperosnateId")
      }
      this.props.getInpersonate({ ...payload });
    }

    if (this.props.profile && this.state.socketConnection) {

      if (localStorage.getItem("inperosnateId")) {
        socket = new WebSocket(`${WebConfig.serviceUrls.SocketApi}ws/notifications?company=${localStorage.getItem("inperosnateId")}&token=${localStorage.getItem("impersonate_access_token")}`);
      //  console.log("socket" , socket);
        this.setState({ socketConnection: false })
      }

      if (socket) {
        socket.onopen = (e) => {
          console.log("[open] Connection established");
        };


        socket.onmessage = (event) => {
          let data = JSON.parse(event.data)
          // console.log(event.data);
          toast(<div className="media">
            <div className="margin-right-2x">
              <img
                src={require("assets/images/notification.svg").default}
                className="img-fluid" alt="img"
              />
            </div>
            <div className="media-body">
              <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.text) }}></div>
            </div>
          </div>, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });

          setTimeout(() => {
            this.props.getNotificationListHeader({ page_size: 5 });
          }, 1000);

          if (this.props.location.pathname === "/admin/notification" && this.props.notificationAllList) {
            this.props.getNotificationAllList({ currentPage: 1, page_size: this.props.notificationAllList.results.length, reload: true });
          }
        }
        socket.onerror = (error) => {
          console.log("error", error);
        //  console.log(`[error] ${error.message}`);
        };

      }
    }

  }

  componentWillUnmount() {
    if (socket) {
      console.log("closed");
      socket.close()
    }
  }

  updateDimensions = () => {
    if (window.innerWidth < 1200) {
      this.setState({ expandState: false });
    } else {
      this.setState({ expandState: true });
    }
  };

  handleSideDraw = () => {
    this.setState((prevState) => ({ expandState: !prevState.expandState, dashLayout: false }));
  };


  handleExit = () => {
    this.props.getUserProfileRequested();
    this.props.getNotificationListHeaderRequested();
    localStorage.removeItem("inperosnate")
    localStorage.removeItem("inperosnateId")
    localStorage.removeItem("impersonate_access_token");
    this.props.getInpersonatelogout();
    this.props.history.push('/');
  }

  handleSideMouseOver = () => {
    if (!this.state.expandState && !this.state.dashLayout) {
      this.setState({ dashLayout: true });
    }
  }

  handleSideMouseOut = () => {
    if (!this.state.expandState && this.state.dashLayout) {
      this.setState({ dashLayout: false });
    }

  }

  render() {
    const { expandState } = this.state;
    const { profile, inperosnate, inperosnateDetail, userProfile, isLoading } = this.props;
    let allowedPermissions = Helper.getUserPermissions();
    let ComapnyName = null;

    if (profile && profile.user_company_users.length > 0) {
      ComapnyName = profile.user_company_users.filter
        ((item) => item.company === +localStorage.getItem("inperosnateId")).map((dataItem) => dataItem.company_name);
    }

    // console.log("allowedPermissions123" , allowedPermissions);
    // console.log("container-props" , this.props);


    return (
      <div
        className={`dashboard-section ${expandState ? "open-menu" : "hide-menu"
          }`}
      >
        {!profile && isLoading ? <Loader /> : null}
        <DashboardMenu expandState={expandState} dashLayout={this.state.dashLayout}
          handleSideMouseOver={this.handleSideMouseOver}
          handleSideMouseOut={this.handleSideMouseOut} />
        <div className={`dashboard-container ${this.state.dashLayout && 'no-padding'}`}>
          <DashboardHeader
            handleSideDraw={this.handleSideDraw}
            profile={profile}
          />
          <div className={`dashboard-layout ${this.props.location.pathname === '/admin/dashboard' || this.props.location.pathname === '/admin/super-dashboard' ? 'dahboard-page-layout' : ''}`}>
            {inperosnate && inperosnateDetail && (
              <div className="inperson-view">
                <span className="text"> {this.props.t("commonComponent.adminTitleText")} {" "}
                  {inperosnateDetail.company.name} {this.props.t("commonComponent.asText")}   {inperosnateDetail.name}</span>
                <span className="exit-btn" onClick={this.handleExit}>{this.props.t("commonComponent.Exit")}</span>
              </div>
            )}

            {profile && profile.role === 3 && (
              <div className="inperson-view inperson-view-admin">
                <span className="text"> {this.props.t("commonComponent.adminTitleText")} {" "}
                  {ComapnyName && ComapnyName.length > 0 && ComapnyName[0]}
                </span>
              </div>
            )}

            {profile &&
              <DashboardRoutes {...this.props}
                allowedPermissions={allowedPermissions} profile={profile} inperosnate={inperosnate}
                expand={this.state.expandState}
              />
            }

          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  router,
  auth: { profile, inperosnate, inperosnateDetail, inperosnateLogout, userProfile },
  errors: { errorMessage, isSuccess, isWarning, isError },
  loading: { isLoading },
  notification: { notificationAllList }
}) => ({
  isLoading, errorMessage, isSuccess, isWarning, isError, profile,
  inperosnate, inperosnateDetail, inperosnateLogout, router, notificationAllList, userProfile
});

export default connect(mapStateToProps, {
  getProfile, getInpersonate, getInpersonatelogout, getUserProfileRequested, getNotificationListHeaderRequested,
  getNotificationListHeader, getNotificationAllList, getUserProfile
})((withTranslation()(DashboardLayout)));

import { components } from 'react-select';

class Helper {

  static getCompanyId = () => {
    if(localStorage.getItem("inperosnateId"))
    {
      return +localStorage.getItem("inperosnateId")
    }
    return null
  }

  static getUserPermissions = () =>
  {
    if(localStorage.getItem("inperosnateId") && localStorage.getItem("user_company_users"))
    {
      const retrievedObject = localStorage.getItem('user_company_users');
      const userProfile = JSON.parse(retrievedObject)
      return userProfile.filter((item) => item.company === +localStorage.getItem("inperosnateId"))
            .map((dataitem) => dataitem.permissions).flatMap((flat) => flat).map((final) => final.codename)
    }
    return null
  }

  static isLoggedIn = () => {
    return localStorage.getItem("private_token") ? true : false;
  };

  static isTwoFactor = () => {
    return localStorage.getItem("two_factor") ? true : false;
  };

  static validateSamePassword = (newPassword, confrimPassword) => {
    if (newPassword === confrimPassword) {
      return true;
    }

    return false;
  }

  static validateSpace = (value) => {
    if (value.trim() == "") {
      return true;
    }
    return false
  }

  static validateNumber = (number) => {
    // && number.length >= 10 && number.length <= 10
    if (number.length >= 10 && number.length <= 10) {
      // ^\+?[\(\)\d-]+$
      return true;
    }
    return false;
  }

  static validatePassword = (passwordValue) => {
    if (/^\w+$/.test(passwordValue) && passwordValue.length >= 6 && passwordValue.length <= 15) {
      return true;
    }

    return false;
  }

  static validateAlphabet = (value) => {
    if (!/^[0-9a-zA-Z!@#\$%\^\&*\)\(+=._-]+$/g.test(value)) {
      return true;
    }

    return false;
  }

  static validateNumeric = (value) => {

    if (!/^\d+$/.test(value)) {
      return true;
    }

    return false;
  }


  static validatePostcode = (code) => {
    if (/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/i.test(code)) {
      return true;
    }

    return false;
  }

  static validateUSAPostcode = (code) => {
    if (/^[0-9]{5}(?:-[0-9]{4,5})?$/.test(code)) {
      return true;
    }

    return false;
  }


  static validateEmail = (email) => {
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      return true;
    }

    return false;
  };

  static formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
}


export function JSON_to_URLEncoded(element, key, list) {
  var list = list || [];
  if (typeof (element) == 'object') {
    for (var idx in element)
      JSON_to_URLEncoded(element[idx], key ? key + '[' + idx + ']' : idx, list);
  } else {
    list.push(key + '=' + encodeURIComponent(element));
  }
  return list.join('&');
}


export const Option = props => (
  <div>
    <components.Option {...props}>
      <div className="ch-checkbox">
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
        <span> {props.label}</span>
      </div>
    </components.Option>
  </div>
);

export const ValueContainer = ({ children, hasValue, getValue, ...props }) => {
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  const CHIPS_LIMIT = 4;
  const [chips, otherChildren] = children;
  const overflowCounter = getValue().length;
  const displayChips = chips.slice(0, CHIPS_LIMIT);

  if (overflowCounter > CHIPS_LIMIT) {
    return (
      <components.ValueContainer {...props}>
        {displayChips}
        {otherChildren}
        {overflowCounter > CHIPS_LIMIT ? `+ ${overflowCounter - CHIPS_LIMIT}` : <span style={{ width: '10px' }}></span>}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      {displayChips}
      {otherChildren}
    </components.ValueContainer>
  );
};




export default Helper;

import * as actionTypes from '../actionTypes';

const initialState = {
    customQuestionList: null,
    customQuestionDetail: null,
    customServiceLocationList: null
};

const QuestionReducer = (state, {
    type,
    payload
}) => {
    if (state === undefined) {
        state = initialState
    }
    let object;
    switch (type) {
        case actionTypes.GetCustomQuestionRequested:
            object = {
                ...state,
                customQuestionList: null
            }
            break
        case actionTypes.GetCustomQuestionSuccess:
            object = {
                ...state,
                customQuestionList: payload
            }
            break
        case actionTypes.GetCustomQuestionDetailRequested:
            object = {
                ...state,
                customQuestionDetail: null
            }
            break
        case actionTypes.GetCustomQuestionDetailSuccess:
            object = {
                ...state,
                customQuestionDetail: payload
            }
            break

        case actionTypes.GetCustomServiceLocationsRequested:
            object = {
                ...state,
                customServiceLocationList: null
            }
            break
        case actionTypes.GetCustomServiceLocationsSuccess:
            object = {
                ...state,
                customServiceLocationList: payload
            }
            break
        case actionTypes.LogOut: 
            object = { state : undefined };
                break

        default:
            object = state;
    }
    return object
};
export default QuestionReducer;
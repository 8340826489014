import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { UserRole } from 'config';
import Helper from "utils/helpers";
/*components*/
const Companies = React.lazy(() => import('../SuperAdmin/Companies'));
const PrivateRoutes = React.lazy(() => import('containers/privateRoutes'));
const CompaniesAdmin = React.lazy(() => import('../SuperAdmin/CompanyAdmin'));
const AddCompany = React.lazy(() => import('../SuperAdmin/AddCompany'));
const AddCompanyAdmin = React.lazy(() => import('../SuperAdmin/AddCompany-admin'));
const CompanyDetail = React.lazy(() => import('../SuperAdmin/CompanyDetail'));
const CompanyAdminDetail = React.lazy(() => import('../SuperAdmin/CompanyAdminDetail'));
const Clients = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Clients'));
const Contacts = React.lazy(() => import('../CompanyAdmin/Contacts'));
const AddClient = React.lazy(() => import('../CompanyAdmin/AddClient'));
const ClientDetail = React.lazy(() => import('../CompanyAdmin/ClientDetail'));
const AddServiceLocation = React.lazy(() => import('../CompanyAdmin/AddServiceLocation'));
const ServiceLocations = React.lazy(() => import('../CompanyAdmin/ServiceLocations'));
const ServiceDetail = React.lazy(() => import('../CompanyAdmin/ServiceLocationDetail'));
const AddContact = React.lazy(() => import('../CompanyAdmin/AddContact'));
const ContactDetail = React.lazy(() => import('../CompanyAdmin/ContactDetail'));
const Archives = React.lazy(() => import('../Shared/Archive'));
const AddEmploye = React.lazy(() => import('../CompanyAdmin/AddEmploye'));
const Employes = React.lazy(() => import('../CompanyAdmin/Employes'));
const EmployeDetail = React.lazy(() => import('../CompanyAdmin/EmployeDetail'));
const SpOrgChart = React.lazy(() => import('../CompanyAdmin/SpOrgnizationChart/SpOrg'));
const Configuration = React.lazy(() => import('../CompanyAdmin/Configuration'));
const Postions = React.lazy(() => import('../CompanyAdmin/Configuration/Postions'));
const AddPostion = React.lazy(() => import('../CompanyAdmin/Configuration/Postions/AddPostion'));
const PostionDetail = React.lazy(() => import('../CompanyAdmin/Configuration/PostionDetail'));
const AreaConfiguration = React.lazy(() => import('../CompanyAdmin/Configuration/AreaConfiguration'));
const Services = React.lazy(() => import('../CompanyAdmin/Configuration/AreaConfiguration/Services'));
const AddServices = React.lazy(() => import('../CompanyAdmin/Configuration/AreaConfiguration/Services/Add-Service'));
const AreaServiceDetail = React.lazy(() => import('../CompanyAdmin/Configuration/AreaConfiguration/Services/Services-Detail'));
const AddAreaTemplate = React.lazy(() => import('../CompanyAdmin/Configuration/AreaConfiguration/AreaTemplates/AddAreaTemplate'));
const AreaTemplates = React.lazy(() => import('../CompanyAdmin/Configuration/AreaConfiguration/AreaTemplates'));
const AreaTemplateDetail = React.lazy(() => import('../CompanyAdmin/Configuration/AreaConfiguration/AreaTemplates/AreaTemplateDetail'));
const AddSubLocation = React.lazy(() => import('../CompanyAdmin/SubLocation/AddSubLocation'));
const SubLocationDetail = React.lazy(() => import('../CompanyAdmin/SubLocation/SubLocationDetail'));
const AddRatingTemplate = React.lazy(() => import('../CompanyAdmin/Configuration/RatingTemplates/AddRatingTemplate'));
const RatingTemplates = React.lazy(() => import('../CompanyAdmin/Configuration/RatingTemplates'));
const AddInspection = React.lazy(() => import('../CompanyAdmin/Inspections/AddInspection'));
const Inspections = React.lazy(() => import('../CompanyAdmin/Inspections'));
const InspectionDetail = React.lazy(() => import('../CompanyAdmin/Inspections/Inspection-Detail'));
const CalendarView = React.lazy(() => import('../CompanyAdmin/Calendar'));
const calendarAddEvent = React.lazy(() => import('../CompanyAdmin/Calendar/calendar-add-event'));
const Notification = React.lazy(() => import('../CompanyAdmin/Notification'));
const AddWorkOrder = React.lazy(() => import('../CompanyAdmin/WorkOrders/AddWorkOrder'));
const WorkOrder = React.lazy(() => import('../CompanyAdmin/WorkOrders'));
const WorkOrderDetail = React.lazy(() => import('../CompanyAdmin/WorkOrders/WorkOrderDetail'));
const Permissions = React.lazy(() => import('../CompanyAdmin/Permissions'));
const AddPermissionTemplate = React.lazy(() => import('../CompanyAdmin/Permissions/Add-Permisson-Template'));
const PermissionDetail = React.lazy(() => import('../CompanyAdmin/Permissions/Permission-detail'));
const MyProfile = React.lazy(() => import('pages/Auth/Profile'));
const AddPeriodic = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Peridoics/AddPeriodic'));
const Periodics = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Peridoics'));
const PeriodicDetail = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Peridoics/PeriodicDetail'));
const AddCustomQuestion = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Configuration/Questions/Add-Custom-Question'));
const CustomQuestions = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Configuration/Questions'));
const QuestionDetail = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Configuration/Questions/Question-Detail'));
const Reports = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Reports'));
const InspectionSummary = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Reports/Inpection-summary'));
const InspectionMonthServiceLocation = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Reports/Inspection-Score-Month'));
const InspectionScores = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Reports/Inspection-Scores'));
const InspectionDetailsOnly = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Reports/Inspection-Detail-Only'));
const QualityInspectionReport = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Reports/Quality-Inspection-Report'));
const RecentInspectionScore = React.lazy(() => import('pages/Dashboard/CompanyAdmin/Reports/Recent-Inspection-Score'));
const DashboardTypes =  React.lazy(() => import('pages/Dashboard/CompanyAdmin/DashboardTypes'));
const SuperDashboard = React.lazy(() => import('pages/Dashboard/SuperAdmin/Dashboard'));

const DashboardRoutes = (props) => {

    const { allowedPermissions } = props;
    const isLoggedIn = Helper.isLoggedIn();

    const [redirectUrl, setredirectUrl] = useState(false);

    useEffect(() => {
        if(props.profile && allowedPermissions && (props.profile.role === 3 || props.profile.role === 4) 
        && localStorage.getItem('RedirectUrl') && !redirectUrl)
        {
            props.history.push(localStorage.getItem('RedirectUrl'));
            localStorage.removeItem('RedirectUrl');
            setredirectUrl(true);
        }

    });



    // console.log("Dashboard-props" , props);
    // console.log("redirectUrl" , redirectUrl);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <PrivateRoutes component={Companies} path={`${props.match.path}/companies`} roles={[UserRole.SuperAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={SuperDashboard} path={`${props.match.path}/super-dashboard`} roles={[UserRole.SuperAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={CompaniesAdmin} path={`${props.match.path}/companies-admin`} roles={[UserRole.SuperAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddCompany} key="add-company" path={`${props.match.path}/add-company`} roles={[UserRole.SuperAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddCompany} key="edit-company" path={`${props.match.path}/edit-company/:id`} roles={[UserRole.SuperAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddCompanyAdmin} key="add-companyadmin" path={`${props.match.path}/add-company-admin`} roles={[UserRole.SuperAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddCompanyAdmin} key="edit-companyadmin" path={`${props.match.path}/edit-company-admin/:id`} roles={[UserRole.SuperAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={CompanyDetail} path={`${props.match.path}/company-detail/:id`} roles={[UserRole.SuperAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={CompanyAdminDetail} path={`${props.match.path}/company-admin-detail/:id`} roles={[UserRole.SuperAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Clients} path={`${props.match.path}/client`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddClient} key="add-client" path={`${props.match.path}/add-client`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddClient} key="edit-client" path={`${props.match.path}/edit-client/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={ClientDetail} path={`${props.match.path}/client-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddServiceLocation} key="add-service" path={`${props.match.path}/add-service`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddServiceLocation} key="add-service-client" path={`${props.match.path}/add-service-client`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddServiceLocation} key="edit-service" path={`${props.match.path}/edit-service/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={ServiceLocations} path={`${props.match.path}/service-locations`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={ServiceDetail} path={`${props.match.path}/service-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={ServiceDetail} path={`${props.match.path}/service-client-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddContact} key="add-client-contact" path={`${props.match.path}/add-client-contact`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddContact} key="add-contact" path={`${props.match.path}/add-contact`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddContact} key="edit-contact" path={`${props.match.path}/edit-contact/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddContact} key="service-add-contact" path={`${props.match.path}/service-add-contact`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Contacts} path={`${props.match.path}/contacts`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={ContactDetail} key="contact-detail" path={`${props.match.path}/contact-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={ContactDetail} key="client-contact-detail" path={`${props.match.path}/client-contact-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Archives} path={`${props.match.path}/archives`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} isGurad={true} />
                <PrivateRoutes component={AddEmploye} key="add-empolye" path={`${props.match.path}/add-employe`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddEmploye} key="edit-empolye" path={`${props.match.path}/edit-empolye/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Employes} path={`${props.match.path}/employes`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={EmployeDetail} path={`${props.match.path}/employes-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={SpOrgChart} path={`${props.match.path}/sp-orgnization`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Configuration} path={`${props.match.path}/configuration`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Postions} path={`${props.match.path}/postions`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddPostion} path={`${props.match.path}/add-postion`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddPostion} path={`${props.match.path}/edit-postion/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={PostionDetail} path={`${props.match.path}/postion-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AreaConfiguration} path={`${props.match.path}/area-configuration`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Services} path={`${props.match.path}/services`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddServices} path={`${props.match.path}/add-services`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddServices} path={`${props.match.path}/edit-services/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AreaServiceDetail} path={`${props.match.path}/services-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddAreaTemplate} path={`${props.match.path}/add-area-template`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddAreaTemplate} path={`${props.match.path}/edit-area-template/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AreaTemplates} path={`${props.match.path}/area-templates`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddSubLocation} path={`${props.match.path}/add-sublocation/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddSubLocation} path={`${props.match.path}/edit-sublocation/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AreaTemplateDetail} path={`${props.match.path}/area-template-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={SubLocationDetail} path={`${props.match.path}/sublocation-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddRatingTemplate} path={`${props.match.path}/add-rating-template`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={RatingTemplates} path={`${props.match.path}/rating-templates`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddRatingTemplate} path={`${props.match.path}/edit-rating-template/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddInspection} key="add-insepection" path={`${props.match.path}/add-inspection`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddInspection} key="edit-client" path={`${props.match.path}/edit-inspection/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Inspections} path={`${props.match.path}/inspections`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={InspectionDetail} path={`${props.match.path}/inspection-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={CalendarView} path={`${props.match.path}/my-calendar`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={calendarAddEvent} path={`${props.match.path}/add-event`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={calendarAddEvent} path={`${props.match.path}/edit-event/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Notification} path={`${props.match.path}/notification`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddWorkOrder} path={`${props.match.path}/add-work-order`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={WorkOrder} path={`${props.match.path}/work-orders`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddWorkOrder} path={`${props.match.path}/edit-work-order/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={WorkOrderDetail} path={`${props.match.path}/work-order-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Permissions} path={`${props.match.path}/permissions`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="add-permission" component={AddPermissionTemplate} path={`${props.match.path}/add-permission`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="edit-permission" component={AddPermissionTemplate} path={`${props.match.path}/edit-permission/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={PermissionDetail} path={`${props.match.path}/permission-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={MyProfile} path={`${props.match.path}/my-profile`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} isGurad={true} />
                <PrivateRoutes component={AddPeriodic} path={`${props.match.path}/add-periodic`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Periodics} path={`${props.match.path}/periodics`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={AddPeriodic} path={`${props.match.path}/edit-periodic/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={PeriodicDetail} path={`${props.match.path}/periodic-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="add-question" component={AddCustomQuestion} path={`${props.match.path}/add-custom-questions`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="edit-question" component={AddCustomQuestion} path={`${props.match.path}/edit-custom-question/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={CustomQuestions} path={`${props.match.path}/custom-questions`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={QuestionDetail} path={`${props.match.path}/custom-questions-detail/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes component={Reports} path={`${props.match.path}/reports`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="add-inspecion-summary" component={InspectionSummary} path={`${props.match.path}/inspection-summary`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="edit-inspection-summary" component={InspectionSummary} path={`${props.match.path}/edit-inspection-summary/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="add-inspecion-summary-location"  component={InspectionMonthServiceLocation} path={`${props.match.path}/inspection-servicelocation`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="edit-inspecion-summary-location"  component={InspectionMonthServiceLocation} path={`${props.match.path}/edit-inspection-servicelocation/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="add-inspecion-scores" component={InspectionScores} path={`${props.match.path}/inspection-scores`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="edit-inspecion-scores" component={InspectionScores} path={`${props.match.path}/edit-inspection-scores/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="add-inspecion-detail-report" component={InspectionDetailsOnly} path={`${props.match.path}/inspection-detail-only`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="edit-inspecion-detail-report" component={InspectionDetailsOnly} path={`${props.match.path}/edit-inspection-detail-only/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="add-quality-inspection-report" component={QualityInspectionReport} path={`${props.match.path}/quality-inspection-report`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="edit-quality-inspection-report" component={QualityInspectionReport} path={`${props.match.path}/edit-quality-inspection-report/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="add-recent-inspection-report" component={RecentInspectionScore} path={`${props.match.path}/recent-inspection-report`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes key="edit-recent-inspection-report" component={RecentInspectionScore} path={`${props.match.path}/edit-recent-inspection-report/:id`} roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]} auth={isLoggedIn} />
                <PrivateRoutes  component={DashboardTypes} 
                 path={`${props.match.path}/dashboard`} 
                 roles={[UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.ContactAdmin]}
                 auth={isLoggedIn}
                 expand={props.expand} />
                      
                {props.profile && props.profile.role === 2  && <Redirect exact from="/admin" to={`${props.match.path}/super-dashboard`} />}
                {props.profile && (props.profile.role === 3 || props.profile.role === 4) && allowedPermissions && !redirectUrl &&
                    <Switch>
                        {allowedPermissions && allowedPermissions.includes('view_dashboard') ? <Redirect exact from="/admin" to='/admin/dashboard' /> : <Redirect from='/admin/*' to='/404' />}
                        {allowedPermissions && allowedPermissions.includes('view_client') ? <Redirect exact from="/admin" to='/admin/client' /> : <Redirect from='/admin/*' to='/404' />}
                        {allowedPermissions && allowedPermissions.includes('view_service_location') ? <Redirect exact from="/admin" to='/admin/service-locations' /> : <Redirect from='/admin/*' to='/404' />}
                        {allowedPermissions && allowedPermissions.includes('view_contact') ? <Redirect exact from="/admin" to='/admin/contacts' /> : <Redirect from='/admin/*' to='/404' />}
                        {allowedPermissions && allowedPermissions.includes('view_inspection') ? <Redirect exact from="/admin" to='/admin/inspections' /> : <Redirect from='/admin/*' to='/404' />}
                        {allowedPermissions && allowedPermissions.includes('view_work_order') ? <Redirect exact from="/admin" to='/admin/work-orders' /> : <Redirect from='/admin/*' to='/404' />}
                        {allowedPermissions && allowedPermissions.includes('view_periodic') ? <Redirect exact from="/admin" to='/admin/periodics' /> : <Redirect from='/admin/*' to='/404' />}
                        {allowedPermissions && allowedPermissions.includes('view_employee') ? <Redirect exact from="/admin" to='/admin/employes' /> : <Redirect from='/admin/*' to='/404' />}
                        {allowedPermissions && allowedPermissions.includes('view_archive') ? <Redirect exact from="/admin" to='/admin/archives' /> : <Redirect from='/admin/*' to='/404' />}
                        {allowedPermissions && allowedPermissions.includes('view_admin') ? <Redirect exact from="/admin" to='/admin/sp-orgnization' /> : <Redirect from='/admin/*' to='/404' />}
                        {allowedPermissions && allowedPermissions.includes('view_report') ? <Redirect exact from="/admin" to='/admin/reports' /> : <Redirect from='/admin/*' to='/404' />}
                    </Switch>
                }
                <Redirect from='/admin/*' to='/404' />
            </Switch></Suspense>
    )
}

export default DashboardRoutes;
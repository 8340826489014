import React from "react";

const Input = (props) => {
  return (
    <div className={`c-field ${props.classes ? props.classes.join(' ') : ''}`}>
      <label>{props.label}</label>
      <div className={`input-control ${props.icon ? 'has-ico' : ''}`}>
        <input
          type={props.type}
          placeholder={props.placeholder} 
          className={`c-form-control ${props.error ? 'error-input' : ''}`}
          name={props.name}  
          onInput={props.handleChange} 
          value={props.Value}
          maxLength={props.MaxLength}
        />
        {props.icon && (
          <span className="ico">
            <img src={require(`assets/images/${props.icon}.svg`).default} />
          </span>
        )}
      </div>
      {props.error && <span className="error-msg">{props.error}</span>}
    </div>
  );
};

export default Input;

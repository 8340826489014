import * as actionTypes from '../actionTypes';

const initialState = {
    isLoading: false,
};
const LoadingReducer = (state, { type }) => {

    if (state === undefined) {
        state = initialState
    }
    let object;
    switch (type) {

        case actionTypes.LoaderShow:
            object = { ...state, isLoading: true }
            break

        case actionTypes.LoaderHide:
            object = { ...state, isLoading: false };
            break

        case actionTypes.LogOut: 
           object = { state : undefined };
            break
            
        default:
            object = state;
    }
    return object
};
export default LoadingReducer;
import React from "react";

const HeaderSearch = () => {
  return (
    <div className="search-box">
      <span className="icon">
        <img src={require("assets/images/search.svg").default} />
      </span>
      <input type="text" placeholder="Search" />
    </div>
  );
};

export default HeaderSearch;

import * as actionTypes from '../actionTypes';

const initialState = {
    levelPostionList: null,
    divisionlevelList : null,
    divisionReportList : null,
    allLevelList : null,
    allDumpList : null,
    searchDivsionList : null,
    levelDetail : null,
    deleteLevelError: false,
    deleteLevelErrorMessage : null,
    levelDetailinfo : null,
    divisionDetail : null,
    clientleveldropdownList : null,
    clientDivisiondropdownList : null
    
};
const LevelDivisonReducer = (state, { type , payload}) => {

    if (state === undefined) {
        state = initialState
    }
    
    let object;
    switch (type) {

        case actionTypes.GetLevelPostionSuccess:
            object = { ...state, levelPostionList: payload }
            break


        case actionTypes.GetDivisionLevelPostionSuccess:
            object = { ...state, divisionlevelList: payload }
            break

        case actionTypes.GetDivisionReportRequested:
            object = { ...state, divisionReportList: null }
            break

        case actionTypes.GetDivisionReportSuccess:
            object = { ...state, divisionReportList: payload }
            break

        case actionTypes.GetAllLevelSuccess:
            object = { ...state, allLevelList: payload }
            break

        case actionTypes.GetAllDivisionRequested:
            object = { ...state, allDumpList: null }
            break

        case actionTypes.GetAllDivisionSuccess:
            object = { ...state, allDumpList: payload }
            break

        case actionTypes.GetDivisionSearchRequested:
            object = { ...state, searchDivsionList: null }
            break

        case actionTypes.GetDivisionSearchSuccess:
            object = { ...state, searchDivsionList: payload }
            break

        case actionTypes.GetLevelDetailRequested:
            object = { ...state, levelDetail: null }
            break

        case actionTypes.GetLevelDetailSuccess:
            object = { ...state, levelDetail: payload }
            break

        case actionTypes.DeleteLevelError:
            object = { ...state, deleteLevelError: true , deleteLevelErrorMessage : payload}
            break

        case actionTypes.DeleteLevelSuccess:
            object = { ...state, deleteLevelError: false , deleteLevelErrorMessage : null }
            break

        case actionTypes.DeleteLevelRequested:
            object = { ...state, deleteLevelError: false , deleteLevelErrorMessage : null }
            break

        case actionTypes.GetLevelRequested:
            object = { ...state, levelDetailinfo: null }
            break

        case actionTypes.GetLevelSuccess:
            object = { ...state, levelDetailinfo: payload }
            break

        case actionTypes.GetDivisonRequested:
            object = { ...state, divisionDetail: null }
            break

        case actionTypes.GetDivisonSuccess:
            object = { ...state, divisionDetail: payload }
            break

        case actionTypes.GetClientLevelSuccess:
            object = { ...state, clientleveldropdownList: payload }
            break

        case actionTypes.GetClientDivisionRequested:
            object = { ...state, clientDivisiondropdownList: null }
            break

        case actionTypes.GetClientDivisionSuccess:
            object = { ...state, clientDivisiondropdownList: payload }
            break
            
        case actionTypes.LogOut: 
        object = { state : undefined };
            break
       

        default:
            object = state;
    }
    return object
};
export default LevelDivisonReducer;
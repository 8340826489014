import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig, } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getWorkEmployeListSuccess,
    getWorkEmployeRequested,
    getWorkContactListSuccess,
    getOrderListSuccess,
    getOrderListSearchSuccess,
    getOrderListSearchRequested,
    getSasTokenRequested,
    getSasTokenSuccess,
    getOrderDetailRequested,
    getOrderDetailSuccess,
    getLastUpdateHistorySuccess,
    getWorkMessageListRequested,
    getWorkMessageListSuccess,
    addWorkorderMessageSuccess,
    importFilesRequested,
    importFilesSuccess,
    importFilesFailure
} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const EMPLOYEES_POINT_URL = WebConfig.ApiUrls.company.employees;
const CLIENTS_CONTACT_POINT_URL = WebConfig.ApiUrls.company.clientsContacts;
const WORK_ORDER_POINT_URL = WebConfig.ApiUrls.eventsWorkOrder
const COMPANIES_POINT_URL = WebConfig.ApiUrls.company.companies;

export function* getWorkEmployeList(payload) {
    const data = payload;
    const groupNames = data.payload.groups.map((item) => `&group=${[item]}`);

    // yield put(loadTodoRequest());

    yield put(getWorkEmployeRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${EMPLOYEES_POINT_URL}?fields=name&service_provider_division=${data.payload.service_provider_division}&no_page${groupNames.join("")}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {

        yield put(getWorkEmployeListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}

export function* getWorkContactList(payload) {
    const data = payload;

    //yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${CLIENTS_CONTACT_POINT_URL}?no_page&service_location=${data.payload.id}`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getWorkContactListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}

export function* addWorkOrder(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${WORK_ORDER_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }


}


export function* getOrderList(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null, origin = null,
        create_date = null, service_location = null, updated_by = null } } = data;

    // yield put(loadTodoRequest());

    let response;

    if (service_location) {
        response = yield call(() => {
            return ClientInstance
                .get(search && !create_date ?
                    `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&search=${search}&service_location=${service_location}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                    origin && !create_date ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&origin=${origin}&service_location=${service_location}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                        create_date && !search && !origin ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&create_date=${create_date}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                            create_date && search ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&create_date=${create_date}&search=${search}&service_location=${service_location}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                                create_date && origin ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&create_date=${create_date}&origin=${origin}&service_location=${service_location}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                                    updated_by ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&updated_by=${updated_by}&service_location=${service_location}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                                        `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&service_location=${service_location}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images`
                )
                .then(response => {
                    return response;
                })
        });
    }

    if (!service_location) {
        response = yield call(() => {
            return ClientInstance
                .get(search && !create_date ?
                    `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&search=${search}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                    origin && !create_date ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&origin=${origin}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                        create_date && !search && !origin ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&create_date=${create_date}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                            create_date && search ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&create_date=${create_date}&search=${search}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                                create_date && origin ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&create_date=${create_date}&origin=${origin}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                                    updated_by ? `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&updated_by=${updated_by}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images` :
                                        `${API_POINT_URL}${WORK_ORDER_POINT_URL}?page=${currentPage}&status=${status}&fields=id,subject,client_name,service_location,origin,updated_by,create_date,status,completed_at,images`
                )
                .then(response => {
                    return response;
                })
        });
    }
    if (response && response.data.status === 200) {

        yield put(getOrderListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}

export function* getOrderListSearch(payload) {
    const data = payload;
    const { payload: { type = null, search = null } } = data;

    //yield put(loadTodoRequest());
    yield put(getOrderListSearchRequested());
    yield delay(400);
    const response = yield call(() => {
        return ClientInstance
            .get(type ? `${API_POINT_URL}${COMPANIES_POINT_URL}users/?type=${type}&search=${search}`
                : `${API_POINT_URL}${COMPANIES_POINT_URL}users/?search=${search}`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {

        yield put(getOrderListSearchSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
    //   


}


export function* deleteWorkOrder(payload) {
    const data = payload;

    //yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${WORK_ORDER_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));


        if (!data.payload.workorderDetailPage) {
            yield delay(1000)
            yield getOrderList({
                type: actionTypes.GetOrderList,
                payload: { currentPage: data.payload.currentPage, status: data.payload.status }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* markOrderComplete(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${WORK_ORDER_POINT_URL}${data.payload.id}/status/`, {
                status: data.payload.orderStatus
            })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        if (!data.payload.workorderDetailPage) {
            yield delay(1000)
            yield getOrderList({
                type: actionTypes.GetOrderList,
                payload: { currentPage: data.payload.currentPage, status: data.payload.status }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* getSasToken(payload) {


    // yield put(loadTodoRequest());
    yield put(getSasTokenRequested());
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}master/azure/sas/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {

        yield put(getSasTokenSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* getOrderDetail(payload) {
    const data = payload;

    // yield put(loadTodoRequest());
    yield put(getOrderDetailRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${WORK_ORDER_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getOrderDetailSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }




}

export function* upDateWorkOrder(payload) {
    const data = payload;

    //yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${WORK_ORDER_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }


}

export function* getLastUpdateHistory(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());
    yield put(getSasTokenRequested());
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${WORK_ORDER_POINT_URL}${data.payload.id}/history/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {

        yield put(getLastUpdateHistorySuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}



export function* getWorkMessageList(payload) {
    const data = payload;
    yield put(getWorkMessageListRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${WORK_ORDER_POINT_URL}${data.payload.id}/messages/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getWorkMessageListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* addWorkorderMessage(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${WORK_ORDER_POINT_URL}${data.payload.id}/messages/`, {
                text: data.payload.text
            })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(addWorkorderMessageSuccess(response.data.response));

    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }
}

export function* importWorkOrderList(payload) {
    const data = payload;

    yield put(importFilesRequested());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${WORK_ORDER_POINT_URL}import_xls/`, data.payload.formData)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
       // console.log("response1" , response);
        yield put(importFilesSuccess(response.data.response.detail));
  
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
      //  console.log("response2" , response);
        yield put(importFilesFailure(response.data.message));
    }
    
}


function* watchgetWorkEmployeList() {
    yield takeLatest(actionTypes.GetWorkEmployeList, getWorkEmployeList)
}

function* watchgetWorkContactList() {
    yield takeLatest(actionTypes.GetWorkContactList, getWorkContactList)
}

function* watchaddWorkOrder() {
    yield takeLatest(actionTypes.AddWorkOrder, addWorkOrder)
}

function* watchgetOrderList() {
    yield takeLatest(actionTypes.GetOrderList, getOrderList)
}

function* watchgetOrderListSearch() {
    yield takeLatest(actionTypes.GetOrderListSearch, getOrderListSearch)
}

function* watchdeleteWorkOrder() {
    yield takeLatest(actionTypes.DeleteWorkOrder, deleteWorkOrder)
}

function* watchmarkOrderComplete() {
    yield takeLatest(actionTypes.MarkOrderComplete, markOrderComplete)
}

function* watchgetSasToken() {
    yield takeLatest(actionTypes.GetSasToken, getSasToken)
}

function* watchgetOrderDetail() {
    yield takeLatest(actionTypes.GetOrderDetail, getOrderDetail)
}

function* watchupDateWorkOrder() {
    yield takeLatest(actionTypes.UpDateWorkOrder, upDateWorkOrder)
}

function* watchgetLastUpdateHistory() {
    yield takeLatest(actionTypes.GetLastUpdateHistory, getLastUpdateHistory)
}

function* watchgetWorkMessageList() {
    yield takeLatest(actionTypes.GetWorkMessageList, getWorkMessageList)
}

function* watchaddWorkorderMessage() {
    yield takeLatest(actionTypes.AddWorkorderMessage, addWorkorderMessage)
}


function* watchimportWorkOrderList() {
    yield takeLatest(actionTypes.ImportWorkOrderList, importWorkOrderList)
}





export const WorkOrderSaga = [
    fork(watchgetWorkEmployeList),
    fork(watchgetWorkContactList),
    fork(watchaddWorkOrder),
    fork(watchgetOrderList),
    fork(watchgetOrderListSearch),
    fork(watchdeleteWorkOrder),
    fork(watchmarkOrderComplete),
    fork(watchgetSasToken),
    fork(watchgetOrderDetail),
    fork(watchupDateWorkOrder),
    fork(watchgetLastUpdateHistory),
    fork(watchgetWorkMessageList),
    fork(watchaddWorkorderMessage),
    fork(watchimportWorkOrderList)
]
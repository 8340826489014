import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getPeriodicListRequested,
    getPeriodicListSuccess,
    getPeriodicDetailRequested,
    getPeriodicDetailSuccess,
    getLastUpdatePeriodicHistorySuccess,
    getPeriodicMessageListRequested,
    getPeriodicMessageListSuccess,
    addPeriodicorderMessageSuccess

} from "store/actions";

import { ClientInstance } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const EVENTS_POINT_URL = WebConfig.ApiUrls.eventsPeriodic;


export function* addPeriodic(payload) {
    const data = payload;

    // yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${EVENTS_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }


}


export function* getPeriodicList(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null,
        service_location = null, start_date_after = null,
        start_date_before = null, updated_by = null } } = data;

    yield put(getPeriodicListRequested());
    // yield put(loadTodoRequest());

    let response;

    if (service_location) {

        if(status === 'open')
        {
            response = yield call(() => {
                return ClientInstance
                    .get(search && !start_date_before && !start_date_after ?
                        `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&search=${search}&service_location=${service_location}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                        start_date_before && start_date_after && !search ? `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&start_date_before=${start_date_before}&start_date_after=${start_date_after}&service_location=${service_location}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                            updated_by ? `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&updated_by=${updated_by}&service_location=${service_location}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                                `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&service_location=${service_location}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at`
                    )
                    .then(response => {
                        return response;
                    })
            });
        }

        if(status === 'complete')
        {
            response = yield call(() => {
                return ClientInstance
                    .get(search && !start_date_before && !start_date_after ?
                        `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&search=${search}&service_location=${service_location}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                        start_date_before && start_date_after && !search ? `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&completed_at_before=${start_date_before}&completed_at_after=${start_date_after}&service_location=${service_location}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                            updated_by ? `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&updated_by=${updated_by}&service_location=${service_location}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                                `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&service_location=${service_location}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at`
                    )
                    .then(response => {
                        return response;
                    })
            });
        }
       
    }

    if (!service_location) {

        if(status === 'open')
        {
            response = yield call(() => {
                return ClientInstance
                    .get(search && !start_date_before && !start_date_after ?
                        `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&search=${search}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                        start_date_before && start_date_after && !search ? `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&start_date_before=${start_date_before}&start_date_after=${start_date_after}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                            updated_by ? `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&updated_by=${updated_by}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                                `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at`
                    )
                    .then(response => {
                        return response;
                    })
            });
        }

        if(status === 'complete')
        {
            response = yield call(() => {
                return ClientInstance
                    .get(search && !start_date_before && !start_date_after ?
                        `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&search=${search}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                        start_date_before && start_date_after && !search ? `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&completed_at_before=${start_date_before}&completed_at_after=${start_date_after}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                            updated_by ? `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&updated_by=${updated_by}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at` :
                                `${API_POINT_URL}${EVENTS_POINT_URL}?page=${currentPage}&status=${status}&fields=id,subject,client_name,service_location,updated_by,start_date,status,completed_at`
                    )
                    .then(response => {
                        return response;
                    })
            });
        }
    }
    if (response && response.data.status === 200) {
        yield put(getPeriodicListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
}

export function* getPeriodicDetail(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());
    yield put(getPeriodicDetailRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getPeriodicDetailSuccess(response.data.response));
    }

    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }




}

export function* upDatePeriodic(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }


}


export function* deletePeriodic(payload) {
    const data = payload;

    //  yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .delete(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));


        if (!data.payload.periodicDetailPage) {
            yield delay(1000)
            yield getPeriodicList({
                type: actionTypes.GetPeriodicList,
                payload: { currentPage: data.payload.currentPage, status: data.payload.status }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}

export function* markPeriodicComplete(payload) {
    const data = payload;

    //yield put(loadTodoRequest());

    const response = yield call(() => {
        return ClientInstance
            .patch(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/status/`, {
                status: data.payload.orderStatus
            })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));

        if (!data.payload.periodicDetailPage) {
            yield delay(1000)
            yield getPeriodicList({
                type: actionTypes.GetPeriodicList,
                payload: { currentPage: data.payload.currentPage, status: data.payload.status }
            });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }



}


export function* getLastUpdatePeriodicHistory(payload) {
    const data = payload;

    //yield put(loadTodoRequest());
    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/history/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {

        yield put(getLastUpdatePeriodicHistorySuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }


}

export function* getPeriodicMessageList(payload) {

    const data = payload;
    yield put(getPeriodicMessageListRequested());

    const response = yield call(() => {
        return ClientInstance
            .get(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/messages/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getPeriodicMessageListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* addWorkorderMessage(payload) {
    const data = payload;

    const response = yield call(() => {
        return ClientInstance
            .post(`${API_POINT_URL}${EVENTS_POINT_URL}${data.payload.id}/messages/`, {
                text: data.payload.text
            })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(addPeriodicorderMessageSuccess(response.data.response));

    }
    if (response && response.data && response.data.status !== 200 && response.data.status !== 401) {
        yield put(loadTodoError(response.data.message.detail));
    }


}



function* watchaddPeriodic() {
    yield takeLatest(actionTypes.AddPeriodic, addPeriodic)
}


function* watchgetPeriodicList() {
    yield takeLatest(actionTypes.GetPeriodicList, getPeriodicList)
}


function* watchgetPeriodicDetail() {
    yield takeLatest(actionTypes.GetPeriodicDetail, getPeriodicDetail)
}


function* watchupDatePeriodic() {
    yield takeLatest(actionTypes.UpDatePeriodic, upDatePeriodic)
}

function* watchdeletePeriodic() {
    yield takeLatest(actionTypes.DeletePeriodic, deletePeriodic)
}

function* watchmarkPeriodicComplete() {
    yield takeLatest(actionTypes.MarkPeriodicComplete, markPeriodicComplete)
}

function* watchgetLastUpdatePeriodicHistory() {
    yield takeLatest(actionTypes.GetLastUpdatePeriodicHistory, getLastUpdatePeriodicHistory)
}

function* watchgetPeriodicMessageList() {
    yield takeLatest(actionTypes.GetPeriodicMessageList, getPeriodicMessageList)
}

function* watchaddWorkorderMessage() {
    yield takeLatest(actionTypes.AddPeriodicorderMessage, addWorkorderMessage)
}


export const PeriodicSaga = [
    fork(watchaddPeriodic),
    fork(watchgetPeriodicList),
    fork(watchgetPeriodicDetail),
    fork(watchupDatePeriodic),
    fork(watchdeletePeriodic),
    fork(watchmarkPeriodicComplete),
    fork(watchgetLastUpdatePeriodicHistory),
    fork(watchgetPeriodicMessageList),
    fork(watchaddWorkorderMessage)
]
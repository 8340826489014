import * as actionTypes from '../actionTypes';

export const addPeriodic = (data) => {
    return {
        type: actionTypes.AddPeriodic,
        payload: data
    }
}


export const getPeriodicList = (data) => {
    return {
        type: actionTypes.GetPeriodicList,
        payload: data
    }
}


export const getPeriodicListRequested = (data) => {
    return {
        type: actionTypes.GetPeriodicListRequested,
        payload: data
    }
}


export const getPeriodicListSuccess = (data) => {
    return {
        type: actionTypes.GetPeriodicListSuccess,
        payload: data
    }
}


export const getPeriodicDetail = (data) => {
    return {
        type: actionTypes.GetPeriodicDetail,
        payload: data
    }
}


export const getPeriodicDetailRequested = (data) => {
    return {
        type: actionTypes.GetPeriodicDetailRequested,
        payload: data
    }
}


export const getPeriodicDetailSuccess = (data) => {
    return {
        type: actionTypes.GetPeriodicDetailSuccess,
        payload: data
    }
}

export const upDatePeriodic = (data) => {
    return {
        type: actionTypes.UpDatePeriodic,
        payload: data
    }
}

export const deletePeriodic = (data) => {
    return {
        type: actionTypes.DeletePeriodic,
        payload: data
    }
}

export const markPeriodicComplete = (data) => {
    return {
        type: actionTypes.MarkPeriodicComplete,
        payload: data
    }
}

export const getLastUpdatePeriodicHistory = (data) => {
    return {
        type: actionTypes.GetLastUpdatePeriodicHistory,
        payload: data
    }
}
export const getLastUpdatePeriodicHistorySuccess = (data) => {
    return {
        type: actionTypes.GetLastUpdatePeriodicHistorySuccess,
        payload: data
    }
}


export const getPeriodicMessageList = (data) => {
    return {
        type: actionTypes.GetPeriodicMessageList,
        payload: data
    }
}


export const getPeriodicMessageListRequested = (data) => {
    return {
        type: actionTypes.GetPeriodicMessageListRequested,
        payload: data
    }
}


export const getPeriodicMessageListSuccess = (data) => {
    return {
        type: actionTypes.GetPeriodicMessageListSuccess,
        payload: data
    }
}


export const addPeriodicorderMessage = (data) => {
    return {
        type: actionTypes.AddPeriodicorderMessage,
        payload: data
    }
}


export const addPeriodicorderMessageSuccess = (data) => {
    return {
        type: actionTypes.AddPeriodicorderMessageSuccess,
        payload: data
    }
}

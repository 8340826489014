import * as actionTypes from '../actionTypes';

export const addEvent = (data) => {
    return {
        type: actionTypes.AddEvent,
        payload: data
    }
}

export const editEvent = (data) => {
    return {
        type: actionTypes.EditEvent,
        payload: data
    }
}

export const getInspectionList = (data) => {
    return {
        type: actionTypes.GetInspectionList,
        payload: data
    }
}

export const getInspectionListSuccess = (data) => {
    return {
        type: actionTypes.GetInspectionListSuccess,
        payload: data
    }
}

export const deleteInspection = (data) => {
    return {
        type: actionTypes.DeleteInspection,
        payload: data
    }
}


export const getInspectionDetail = (data) => {
    return {
        type: actionTypes.GetInspectionDetail,
        payload: data
    }
}


export const getInspectionDetailRequested = (data) => {
    return {
        type: actionTypes.GetInspectionDetailRequested,
        payload: data
    }
}

export const getInspectionDetailSuccess = (data) => {
    return {
        type: actionTypes.GetInspectionDetailSuccess,
        payload: data
    }
}

export const getEventList = (data) => {
    return {
        type: actionTypes.GetEventList,
        payload: data
    }
}

export const getEventListSuccess = (data) => {
    return {
        type: actionTypes.GetEventListSuccess,
        payload: data
    }
}


export const getEventListRequested = (data) => {
    return {
        type: actionTypes.GetEventListRequested,
        payload: data
    }
}


export const getWorkorderEventList = (data) => {
    return {
        type: actionTypes.GetWorkorderEventList,
        payload: data
    }
}

export const getWorkorderEventListSuccess = (data) => {
    return {
        type: actionTypes.GetWorkorderEventListSuccess,
        payload: data
    }
}


export const getWorkorderListRequested = (data) => {
    return {
        type: actionTypes.GetWorkorderListRequested,
        payload: data
    }
}


export const getPeriodicEventList = (data) => {
    return {
        type: actionTypes.GetPeriodicEventList,
        payload: data
    }
}

export const getPeriodicEventListSuccess = (data) => {
    return {
        type: actionTypes.GetPeriodicEventListSuccess,
        payload: data
    }
}

export const getPeriodicEventListRequested = (data) => {
    return {
        type: actionTypes.GetPeriodicEventListRequested,
        payload: data
    }
}


export const deleteCalendar = (data) => {
    return {
        type: actionTypes.DeleteCalendar,
        payload: data
    }
}

export const calendarCreateEvent = (data) => {
    return {
        type: actionTypes.CalendarCreateEvent,
        payload: data
    }
}

export const calendarCreateEventSuccess = (data) => {
    return {
        type: actionTypes.CalendarCreateEventSuccess,
        payload: data
    }
}


export const getInspectionPoolList = (data) => {
    return {
        type: actionTypes.GetInspectionPoolList,
        payload: data
    }
}

export const getInspectionPoolListRequested = (data) => {
    return {
        type: actionTypes.GetInspectionPoolListRequested,
        payload: data
    }
}

export const getInspectionPoolListSuccess = (data) => {
    return {
        type: actionTypes.GetInspectionPoolListSuccess,
        payload: data
    }
}

export const getInspectionClaimedList = (data) => {
    return {
        type: actionTypes.GetInspectionClaimedList,
        payload: data
    }
}

export const getInspectionClaimedListRequested = (data) => {
    return {
        type: actionTypes.GetInspectionClaimedListRequested,
        payload: data
    }
}

export const getInspectionClaimedListSuccess = (data) => {
    return {
        type: actionTypes.GetInspectionClaimedListSuccess,
        payload: data
    }
}

export const claimInspection = (data) => {
    return {
        type: actionTypes.ClaimInspection,
        payload: data
    }
}

export const unClaimInspection = (data) => {
    return {
        type: actionTypes.UnClaimInspection,
        payload: data
    }
}

export const changeInspectionDate = (data) => {
    return {
        type: actionTypes.ChangeInspectionDate,
        payload: data
    }
}


export const editClaimEvent = (data) => {
    return {
        type: actionTypes.EditClaimEvent,
        payload: data
    }
}


export const getNotificationAllList = (data) => {
    return {
        type: actionTypes.GetNotificationAllList,
        payload: data
    }
}

export const getNotificationAllListSuccess = (data) => {
    return {
        type: actionTypes.GetNotificationAllListSuccess,
        payload: data
    }
}



export const getInspectionTemplates = (data) => {
    return {
        type: actionTypes.GetInspectionTemplates,
        payload: data
    }
}

export const getInspectionTemplatesRequested = (data) => {
    return {
        type: actionTypes.GetInspectionTemplatesRequested,
        payload: data
    }
}

export const getInspectionTemplatesSuccess = (data) => {
    return {
        type: actionTypes.GetInspectionTemplatesSuccess,
        payload: data
    }
}


export const getInspectionDropdownList = (data) => {
    return {
        type: actionTypes.GetInspectionDropdownList,
        payload: data
    }
}

export const getInspectionDropdownListRequested = (data) => {
    return {
        type: actionTypes.GetInspectionDropdownListRequested,
        payload: data
    }
}

export const getInspectionDropdownListSuccess = (data) => {
    return {
        type: actionTypes.GetInspectionDropdownListSuccess,
        payload: data
    }
}


export const getInspectionSummary = (data) => {
    return {
        type: actionTypes.GetInspectionSummary,
        payload: data
    }
}

export const getInspectionSummaryRequested = (data) => {
    return {
        type: actionTypes.GetInspectionSummaryRequested,
        payload: data
    }
}

export const getInspectionSummarySuccess = (data) => {
    return {
        type: actionTypes.GetInspectionSummarySuccess,
        payload: data
    }
}



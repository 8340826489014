import axios from "axios";
import { put, takeLatest, fork, call, throttle, debounce, delay } from 'redux-saga/effects';
import { WebConfig, AppConfig } from 'config';
import * as actionTypes from '../actionTypes';
import {
    loaderShow,
    loaderhide,
    loadTodoSuccess,
    loadTodoRequest,
    loadTodoError,
    getCountriesSucess,
    getRegionRequested,
    getRegionSuccess,
    getCitiesRequested,
    getCitesSuccess,
    getcompanyListSuccess,
    getcompanyListRequested,
    getCompanyDetailRequested,
    getCompanyDetailSuccess,
    getcompanyallSuccess

} from "store/actions";

import { masterInstacne } from 'utils/axios-instance';

const API_POINT_URL = WebConfig.serviceUrls.RestApi;
const COMPANIES_POINT_URL = WebConfig.ApiUrls.company.companies;

export function* getCountries() {
    try {
        yield put(loadTodoRequest());
        
        const response = yield call(() => {
            return masterInstacne
                .get(`${API_POINT_URL}master/countries/`)
                .then(response => {
                    return response;
                })
        });

        if (response.data.status === 200) {
            yield put(getCountriesSucess(response.data.response));
        }
        if (response.data && response.data.status !== 200 && response.data.status !== 401) {
            yield put(loadTodoError(response.data.message.detail));
        }

    } catch (error) {
        if (error.response && error.response.status === 404) {
            yield put(loadTodoError(error.response.statusText));
        }
        else {
            yield put(loadTodoError("Network Error"));
        }
       
    }
}


export function* getRegion(payload) {
    const data = payload;
    try {
        yield put(getCitiesRequested());
        yield put(loadTodoRequest());
        // 
        const response = yield call(() => {
            return masterInstacne
                .get(`${API_POINT_URL}master/regions/${data.payload.id}/`)
                .then(response => {
                    return response;
                })
        });

        if (response.data.status === 200) {
            yield put(getRegionSuccess(response.data.response));
        }
        if (response.data && response.data.status !== 200 && response.data.status !== 401) {
            yield put(loadTodoError(response.data.message.detail));
        }

        //


    } catch (error) {

        if (error.response && error.response.status === 404) {
            yield put(loadTodoError(error.response.statusText));
        }
        else {
            yield put(loadTodoError("Network Error"));
        }
        // 
    }
}


export function* getCites(payload) {
    const data = payload;
    try {
        yield put(getCitiesRequested());
        yield put(loadTodoRequest());
        
        const response = yield call(() => {
            return masterInstacne
                .get(`${API_POINT_URL}master/cities/${data.payload.id}/`)
                .then(response => {
                    return response;
                })
        });

        if (response.data.status === 200) {
            yield put(getCitesSuccess(response.data.response));
        }
        if (response.data && response.data.status !== 200 && response.data.status !== 401) {
            yield put(loadTodoError(response.data.message.detail));
        }

       


    } catch (error) {
        if (error.response && error.response.status === 404) {
            yield put(loadTodoError(error.response.statusText));
        }
        else {
            yield put(loadTodoError("Network Error"));
        }
       
    }
}

export function* addCompany(payload) {
    const data = payload;
    const response = yield call(() => {
        return axios
            .post(`${API_POINT_URL}${COMPANIES_POINT_URL}`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
  
}


export function* getcompanyList(payload) {
    const data = payload;
    const { payload: { currentPage = null, search = null, status = null } } = data;
   // yield put(getcompanyListRequested());
   
    const response = yield call(() => {
        return axios
            .get(search ?
                `${API_POINT_URL}${COMPANIES_POINT_URL}?page=${currentPage}&search=${search}&fields=id,name,is_active` :
                status ? `${API_POINT_URL}${COMPANIES_POINT_URL}?page=${currentPage}&is_active=${status}&fields=id,name,is_active`
                    : `${API_POINT_URL}${COMPANIES_POINT_URL}?page=${currentPage}&fields=id,name,is_active`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getcompanyListSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
   
}


export function* updateStatus(payload) {
    const data = payload;
   
    const response = yield call(() => {
        return axios
            .patch(`${API_POINT_URL}${COMPANIES_POINT_URL}${data.payload.id}/`, { is_active: data.payload.is_active })
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
        yield delay(1000);
        if (!data.payload.companyPage) {
            yield getcompanyList({ type: actionTypes.GetCompanyList, payload: { currentPage: data.payload.currentPage } });
        }
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
 
}


export function* getCompanyDetail(payload) {
    const data = payload;
    yield put(getCompanyDetailRequested());
    
    const response = yield call(() => {
        return axios
            .get(`${API_POINT_URL}${COMPANIES_POINT_URL}${data.payload.id}/`)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(getCompanyDetailSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}

export function* editCompany(payload) {
    const data = payload;
    const response = yield call(() => {
        return axios
            .put(`${API_POINT_URL}${COMPANIES_POINT_URL}${data.payload.id}/`, data.payload)
            .then(response => {
                return response;
            })
    });
    if (response && response.data.status === 200) {
        yield put(loadTodoSuccess(response.data.response.detail));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }

}


export function* getcompanyall() {
    const response = yield call(() => {
        return axios
            .get(`${API_POINT_URL}${COMPANIES_POINT_URL}?no_page&fields=id,name,is_active`)
            .then(response => {
                return response;
            })
    });

    if (response && response.data.status === 200) {
        yield put(getcompanyallSuccess(response.data.response));
    }
    if (response && response.data && response.data.status !== 200) {
        yield put(loadTodoError(response.data.message.detail));
    }
}




function* watchgetCountries() {
    yield takeLatest(actionTypes.GetCountries, getCountries)
}

function* watchgetRegion() {
    yield takeLatest(actionTypes.GetRegion, getRegion)
}

function* watchgetCites() {
    yield takeLatest(actionTypes.GetCities, getCites)
}

function* watchaddCompany() {
    yield takeLatest(actionTypes.CreateCompany, addCompany)
}

function* watchgetcompanyList() {
    yield takeLatest(actionTypes.GetCompanyList, getcompanyList)
}

function* watchupdateStatus() {
    yield takeLatest(actionTypes.UpdateStatus, updateStatus)
}

function* watchgetCompanyDetail() {
    yield takeLatest(actionTypes.GetCompanyDetail, getCompanyDetail)
}

function* watcheditCompany() {
    yield takeLatest(actionTypes.EditCompany, editCompany)
}

function* watchgetcompanyall() {
    yield takeLatest(actionTypes.GetCompanyAllList, getcompanyall)
}


export const CompanySaga = [
    fork(watchgetCountries),
    fork(watchgetRegion),
    fork(watchgetCites),
    fork(watchaddCompany),
    fork(watchgetcompanyList),
    fork(watchupdateStatus),
    fork(watchgetCompanyDetail),
    fork(watcheditCompany),
    fork(watchgetcompanyall)
]
import React from "react";
import { useTranslation } from "react-i18next";
const ServerError = (props) => {
    const { t } = useTranslation();
    return (
        <div className="server-error-block">
            <div className="notfound-block notfound-block-server">
                <img
                    src={require("assets/images/500.svg").default}
                    className="img-fluid"
                />
                <h3> {t("ServerErrorComponent.title")}</h3>
                <p> {t("ServerErrorComponent.text")}</p>

                <button
                    className="button button-rounded button-height-meidum"
                    onClick={props.location.state ? () => props.history.push(`${props.location.state.PrevLocation.pathname}`) :
                        props.history.push('/')}
                    title="Refresh page"
                >
                    Refresh page </button>
            </div>
            {props.location.state && props.location.state.Errordata && !props.location.state.Errordata.error &&
                <div dangerouslySetInnerHTML={{ __html: props.location.state.Errordata }}></div>
            }
            {props.location.state && props.location.state.Errordata && props.location.state.Errordata.error &&
                <h1 className="text-center">{props.location.state.Errordata.error}</h1>
            }
        </div>

    );
};

export default ServerError;

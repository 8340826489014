import * as actionTypes from '../actionTypes';


export const importFilesRequested = (data) => {
    return {
        type: actionTypes.ImportFilesRequested,
        payload: data
    }
}

export const importFilesSuccess = (data) => {
    return {
        type: actionTypes.ImportFilesSuccess,
        payload: data
    }
}

export const importFilesFailure = (data) => {
    return {
        type: actionTypes.ImportFilesFailure,
        payload: data
    }
}
import * as actionTypes from '../actionTypes';


export const getSpLevelPostion = (data) => {
    return {
        type: actionTypes.GetSpLevelPostion,
        payload: data
    }
}

export const getSpLevelPostionSuccess = (data) => {
    return {
        type: actionTypes.GetSpLevelPostionSuccess,
        payload: data
    }
}

export const addSpLevel = (data) => {
    return {
        type: actionTypes.AddSpLevel,
        payload: data
    }
}

export const getSpDivisionLevel = (data) => {
    return {
        type: actionTypes.GetSpDivisionLevelPostion,
        payload: data
    }
}

export const getSpDivisionLevelSuccess = (data) => {
    return {
        type: actionTypes.GetSpDivisionLevelPostionSuccess,
        payload: data
    }
}

export const getSpDivisionReportRequested = (data) => {
    return {
        type: actionTypes.GetSpDivisionReportRequested,
        payload: data
    }
}


export const getSpDivisionReport = (data) => {
    return {
        type: actionTypes.GetSpDivisionReport,
        payload: data
    }
}

export const getSpDivisionReportSuccess = (data) => {
    return {
        type: actionTypes.GetSpDivisionReportSuccess,
        payload: data
    }
}

export const addSpDivision = (data) => {
    return {
        type: actionTypes.AddSpDivision,
        payload: data
    }
}

export const getAllSpLevel = (data) => {
    return {
        type: actionTypes.GetAllSpLevel,
        payload: data
    }
}

export const getAllSpLevelRequested = (data) => {
    return {
        type: actionTypes.GetAllSpLevelRequested,
        payload: data
    }
}


export const getAllSpLevelSuccess = (data) => {
    return {
        type: actionTypes.GetAllSpLevelSuccess,
        payload: data
    }
}

export const getAllSpDivision = (data) => {
    return {
        type: actionTypes.GetAllSpDivision,
        payload: data
    }
}


export const getAllSpDivisionRequested = (data) => {
    return {
        type: actionTypes.GetAllSpDivisionRequested,
        payload: data
    }
}


export const getAllSpDivisionSuccess = (data) => {
    return {
        type: actionTypes.GetAllSpDivisionSuccess,
        payload: data
    }
}


export const getSpDivisionSearch = (data) => {
    return {
        type: actionTypes.GetSpDivisionSearch,
        payload: data
    }
}


export const getSpDivisionSearchRequested = (data) => {
    return {
        type: actionTypes.GetSpDivisionSearchRequested,
        payload: data
    }
}


export const getSpDivisionSearchSuccess = (data) => {
    return {
        type: actionTypes.GetSpDivisionSearchSuccess,
        payload: data
    }
}




export const getSpLevelDetailRequested = (data) => {
    return {
        type: actionTypes.GetSpLevelDetailRequested,
        payload: data
    }
}


export const getSpLevelDetail = (data) => {
    return {
        type: actionTypes.GetSpLevelDetail,
        payload: data
    }
}


export const getSpLevelDetailSuccess = (data) => {
    return {
        type: actionTypes.GetSpLevelDetailSuccess,
        payload: data
    }
}


export const editSpLevel = (data) => {
    return {
        type: actionTypes.EditSpLevel,
        payload: data
    }
}

export const deleteSpLevelRequested = (data) => {
    return {
        type: actionTypes.DeleteSpLevelRequested,
        payload: data
    }
}


export const deleteSpLevelSuccess = (data) => {
    return {
        type: actionTypes.DeleteSpLevelSuccess,
        payload: data
    }
}


export const deleteSpLevel = (data) => {
    return {
        type: actionTypes.DeleteSpLevel,
        payload: data
    }
}


export const deleteSpLevelError = (data) => {
    return {
        type: actionTypes.DeleteSpLevelError,
        payload: data
    }
}



export const deleteSpDivision = (data) => {
    return {
        type: actionTypes.DeleteSpDivision,
        payload: data
    }
}



export const getSpDivison = (data) => {
    return {
        type: actionTypes.GetSpDivison,
        payload: data
    }
}


export const getSpDivisonRequested = (data) => {
    return {
        type: actionTypes.GetSpDivisonRequested,
        payload: data
    }
}


export const getSpDivisonSuccess = (data) => {
    return {
        type: actionTypes.GetSpDivisonSuccess,
        payload: data
    }
}

export const updateSpDivisionSingle = (data) => {
    return {
        type: actionTypes.UpdateSpDivisionSingle,
        payload: data
    }
}

export const updateSpDivision = (data) => {
    return {
        type: actionTypes.UpdateSpDivision,
        payload: data
    }
}





export const getSPLevellist = (data) => {
    return {
        type: actionTypes.GetSPLevellist,
        payload: data
    }
}

export const getSPLevellistSuccess = (data) => {
    return {
        type: actionTypes.GetSPLevellistSuccess,
        payload: data
    }
}

export const getSPDivsionRequested = (data) => {
    return {
        type: actionTypes.GetSPDivsionRequested,
        payload: data
    }
}


export const getSPDivsion = (data) => {
    return {
        type: actionTypes.GetSPDivsion,
        payload: data
    }
}

export const getSPDivsionSuccess = (data) => {
    return {
        type: actionTypes.GetSPDivsionSuccess,
        payload: data
    }
}


export const getAllSpAdminDivision = (data) => {
    return {
        type: actionTypes.GetAllSpAdminDivision,
        payload: data
    }
}


export const getAllSpAdminDivisionRequested = (data) => {
    return {
        type: actionTypes.GetAllSpAdminDivisionRequested,
        payload: data
    }
}


export const getAllSpAdminDivisionSuccess = (data) => {
    return {
        type: actionTypes.GetAllSpAdminDivisionSuccess,
        payload: data
    }
}
import * as actionTypes from '../actionTypes';

export const loadTodoRequest = (data) => {
    return {
        type: actionTypes.LoadTodoRequested,
        payload: data
    }
}


export const loadTodoSuccess = (data) => {
    return {
        type: actionTypes.LoadTodoSuccess,
        payload: data
    }
}

export const loadTodoWarning = (data) => {
    return {
        type: actionTypes.LoadTodoWarning,
        payload: data
    }
}

export const loadTodoError = (data) => {
  
    return {
        type: actionTypes.LoadTodoError,
        payload: data
    }
}

export const http500Error = (data) => {
    return {
        type: actionTypes.Http500Error,
        payload: data
    }
}

export const httpOtherError = (data) => {
    return {
        type: actionTypes.HttpOtherError,
        payload: data
    }
}
import { all } from "redux-saga/effects";
import { AuthSaga } from './saga-auth';
import { ClientSaga } from "./saga-clients";
import { CompanySaga } from "./saga-company";
import { CompanyAdminSaga } from "./saga-company-admin";
import { contactSaga } from "./saga-contact";
import { EmployeSaga } from "./saga-employe";
import { LevelDivisonSaga } from "./saga-clientorg";
import { SearchSaga } from "./saga-search";
import { serviceSaga } from "./saga-service";
import { SpOrgSaga } from "./saga-sporg";
import { ConfigurationSaga } from "./saga-configuration";
import { areaServiceSaga } from "./saga-area-service";
import { sublocationSaga } from "./saga-sublocation";
import { ratingTemplateSaga } from "./saga-rating-template";
import { eventSaga } from "./saga-event";
import { notificationSaga } from "./saga-notification";
import { WorkOrderSaga } from "./saga-work-order";
import { PermissionSaga } from "./saga-permission";
import { PeriodicSaga } from "./saga-periodic";
import { QuestionSaga } from "./saga-question";
import { Reportsaga } from "./saga-report";
import { DashboardSaga } from "./saga-dashboard";

export default function* rootSaga() {
    yield all([...AuthSaga , ...CompanySaga , ...CompanyAdminSaga , ...SearchSaga ,
         ...ClientSaga , ...serviceSaga , ...contactSaga , ...EmployeSaga , ...LevelDivisonSaga , ...SpOrgSaga 
         , ...ConfigurationSaga , ...areaServiceSaga , ...sublocationSaga , ...ratingTemplateSaga , ...eventSaga , ...notificationSaga
        ,...WorkOrderSaga , ...PermissionSaga , ...PeriodicSaga , ...QuestionSaga , ...Reportsaga , ...DashboardSaga]);
}

import React, { Component } from 'react';
import Routes from 'containers/routes';
import { connect } from "react-redux";


class App extends Component {
 
  render() {
    // console.log("app-props", this.props);
    return (
      <div className="main-container">
        <Routes />
      </div>
    );
  }
}


const mapStateToProps = ({router}) => ({router});

export default connect(mapStateToProps, null)(App);


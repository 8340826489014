import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  getInpersonatelogout, getProfileRequested, getNotificationListHeader, getNotificationListHeaderRequested,
  getNotificationAllList, getNotificationDetail, getNotificationRead, logout, genrateMasterTemplate
} from "store/actions";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next"
import sanitizeHtml from 'sanitize-html';
import { Modal, ModalBody } from "reactstrap";
/*components*/
import HeaderSearch from "./components/HeaderSearch";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, UncontrolledDropdown
} from "reactstrap";
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';
import Helper from "utils/helpers";

class DashboardHeader extends Component {
  state = {
    dropdownOpen: false,
    NoticationdropdownOpen: false,
    switchPopup: false,
    switchPopupData: null,
    allowedPermissions: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (localStorage.getItem("inperosnate")) {
      return {
        allowedPermissions: Helper.getUserPermissions()
      }
    }
    return { allowedPermissions: null }
  }

  componentDidMount() {
    moment.locale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: '1s',
        ss: '%ss',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1M',
        MM: '%dM',
        y: '1Y',
        yy: '%dY'
      }
    })
  }

  notifcationtoogle = () => {
    this.setState((prevState) => ({
      NoticationdropdownOpen: !prevState.NoticationdropdownOpen,
    }));
  };

  toogle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  logout = () => {

    this.props.logout();
    if (localStorage.getItem("impersonate_access_token")) {
      this.props.getInpersonatelogout();
      localStorage.clear();
    }
    localStorage.clear();
    sessionStorage.clear();
    // window.location.reload();
    this.props.history.push('/');
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile && this.props.profile.in_app_notifications && !this.props.notificationList &&
      localStorage.getItem("inperosnate") && this.props.userProfile && prevProps.userProfile !== this.props.userProfile) {
      this.props.getNotificationListHeader({ page_size: 5 });
    }



    if (prevProps.notificationDetail !== this.props.notificationDetail &&
      this.props.profile && this.props.notificationDetail && this.props.notificationDetail.object_id) {

      if (this.props.notificationDetail.content_type === "inspection") {
        this.props.history.push(`/admin/inspection-detail/${this.props.notificationDetail.object_id}`,
          { inspectionDetailPage: true, inspectionDetailNotifcation: true })
      }

      if (this.props.notificationDetail.content_type === "workorder") {
        this.props.history.push(`/admin/work-order-detail/${this.props.notificationDetail.object_id}`,
          { workorderDetailPage: true });
      }

      if (this.props.notificationDetail.content_type === "periodic") {
        this.props.history.push(`/admin/periodic-detail/${this.props.notificationDetail.object_id}`,
          { periodicDetailPage: true });
      }


    }
  }

  handlreRead = (item) => {
    if (!item.read) {
      this.props.getNotificationRead({
        id: item.id,
        read: true,
      });
    }
    if (item.object_id) {
      this.props.getNotificationDetail({ id: item.id });
    }
  }

  NotifyDropdown = () => {
    const { notificationList } = this.props;
    return (
      <div className="notification-dropdown">
        <Dropdown isOpen={this.state.NoticationdropdownOpen} toggle={this.notifcationtoogle}>
          <DropdownToggle>
            <span className="notify-ico">
              <img
                src={require("assets/images/notification-bell.svg").default}
                className="img-fluid" alt="img"
              />
              {notificationList.unread > 0 && <span title={notificationList.unread}>{notificationList.unread}</span>}

            </span>
          </DropdownToggle>
          <DropdownMenu right>
            {notificationList.total > 0 && <div className="total-items">{notificationList.total} Notifications</div>}

            {notificationList.results.map((item) => {
              return (
                <DropdownItem key={item.id} className={item.read ? 'read' : ' '}>
                  <div className="media" onClick={() => this.handlreRead(item)}>
                    <img
                      src={require("assets/images/notification.svg").default}
                      className="img-fluid margin-right-1x" alt="img"
                    />
                    <div className="media-body">
                      <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.text) }}></div>
                    </div>
                  </div>
                  <span className="time">{moment(item.received_on).fromNow()}</span>
                </DropdownItem>
              )
            })}
            {notificationList.results.length === 0 &&

              <div className="empty-notification">
                <img
                  src={require("assets/images/empty-notification.svg").default}
                  className="img-fluid" alt="img"
                />
                <h4>No Notifications Right Now!</h4>
                <p>You’re up-to-date!</p>
              </div>

            }

            {notificationList.results.length > 0 &&
              <div className="dropdown-footer">
                <span onClick={this.handleNotifcationPush}> Show All Notifications</span>
              </div>
            }
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }

  closeModal = () => {
    this.setState({ switchPopup: false, switchPopupData: null })
  }
  openModal = (data) => {
    this.setState({ switchPopup: true, switchPopupData: data })
  }

  switchModal = () => {
    return (
      <Modal isOpen={this.state.switchPopup} className="modal-dialog-centered  custom-modal">
        <ModalBody>
          <span
            className="close-btn" onClick={this.closeModal}>
            <img src={require("assets/images/close.svg").default} />
          </span>
          <div className="modal-content-block">
            <p>{this.props.t("NAV.SwitchModal.text")} <strong>{this.state.switchPopupData && this.state.switchPopupData.company_name}</strong></p>

            <div className="button-block">
              <button
                className="button button-rounded margin-right-2x  
             button-width-medium"
                title={this.props.t("commonComponent.yesButton")}
                onClick={this.handleSwitch}
              >
                {this.props.t("commonComponent.yesButton")}
              </button>
              <button
                className="button button-rounded  button-min-100  button-border button-dark"
                title={this.props.t("commonComponent.cancelButton")}
                onClick={this.closeModal}
              >
                {this.props.t("commonComponent.cancelButton")}
              </button>
            </div>


          </div>

        </ModalBody>
      </Modal>
    )
  }

  handleSwitch = () => {
    localStorage.setItem("inperosnateId", this.state.switchPopupData.company);
    this.closeModal();
    setTimeout(() => {
      this.props.history.push('/admin');
    }, 500);

  }

  handleNotifcationPush = () => {
    this.props.history.push('/admin/notification');
    this.setState({ NoticationdropdownOpen: false });
  }

  masterTemplateHandler = () => {
    this.props.genrateMasterTemplate();
  }



  render() {
    const { handleSideDraw, profile, notificationList, userProfile } = this.props;

    let companiesList = null;

    if (userProfile && userProfile.user_company_users.length > 1) {
      companiesList = userProfile.user_company_users.filter((item) => item.company !== JSON.parse(+localStorage.getItem('inperosnateId')));
    }
    // console.log("header-props", this.props);
    //  console.log("header-state" , this.state);

    return (
      <header className="dashboard-header">
        <div className="burger-menu" onClick={handleSideDraw}>
          <img
            src={require("assets/images/burger-menu.svg").default}
            className="img-fluid" alt="img"
          />
        </div>
        <HeaderSearch />

        <div className="user-dtl">
          {profile && (localStorage.getItem("inperosnate") || profile.role !== 2) &&
            <span className="master-template-ico" id={`master-template`}
            onClick={this.masterTemplateHandler}> <img
                src={require("assets/images/download.svg").default}
                className="img-fluid" alt="img"
              />

              <UncontrolledTooltip placement="bottom" target={`master-template`}
                container={`master-template`} innerClassName="c-tooltip">
                Download Master Template
            </UncontrolledTooltip>
            </span>
          }

          {profile && localStorage.getItem("inperosnate") &&
            (this.state.allowedPermissions && this.state.allowedPermissions.includes('view_work_order')) ||
            (this.state.allowedPermissions && this.state.allowedPermissions.includes('view_inspection')) ||
            (this.state.allowedPermissions && this.state.allowedPermissions.includes('view_periodic')) ?
            <div className="calender-ico">
              <Link to="/admin/my-calendar">
                <img
                  src={require("assets/images/calendar-header.svg").default}
                  className="img-fluid" alt="img"
                />
              </Link>
            </div>
            : null}

          {profile && this.props.profile.in_app_notifications && notificationList && localStorage.getItem("inperosnate") &&
            <Fragment>
              {this.NotifyDropdown()}
            </Fragment>
          }

          <div className="user-dropdown">
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toogle}>
              <DropdownToggle>
                {profile && profile.image !== '' ?
                  <div className="user-img">
                    <img src={profile.image} alt="img" />
                  </div>
                  :
                  <div className="user-name">
                    <span>{profile && profile.name.charAt(0)}</span>
                  </div>}

                <img src={require("assets/images/arrow-down.svg").default} alt="img" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem header>{this.props.t("NAV.HiText")} {profile && profile.name} !</DropdownItem>
                <Link to="/admin/my-profile"><DropdownItem>{this.props.t("NAV.Profile")}</DropdownItem></Link>
                {companiesList && profile && profile.role !== 2 &&
                  <div className="switch-account">
                    <label>{this.props.t("NAV.Switch")}</label>
                    {companiesList.map((item, index) =>
                      <DropdownItem key={index}
                        onClick={() => this.openModal(item)}>{item.company_name}</DropdownItem>)}
                  </div>
                }

                <DropdownItem onClick={this.logout}>{this.props.t("NAV.SignOut")}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        {this.switchModal()}
      </header>
    );
  }
}

const mapStateToProps = ({
  auth: { userProfile, profile, inperosnate, inperosnateDetail },
  notification: { notificationList, notificationAllList, notificationDetail }
}) =>
  ({ profile, inperosnate, inperosnateDetail, notificationList, notificationAllList, notificationDetail, userProfile });
export default connect(mapStateToProps, {
  getInpersonatelogout, getProfileRequested, logout,
  getNotificationListHeader, getNotificationAllList, getNotificationDetail, getNotificationRead,
  getNotificationListHeaderRequested, genrateMasterTemplate
})(withTranslation()(withRouter(DashboardHeader)));



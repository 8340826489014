import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import LoadingReducer from './loadReducer';
import ErrorReducer from "./errorReducer.js";
import authReducer from "./authReducer";
import companyReducer from "./companyReducer";
import companyAdminReducer from "./companyAdminReducer";
import SearchReducer from "./searchReducer";
import clientReducer from "./clientReducer";
import serviceReducer from "./serviceReducer";
import contactReducer from "./contactReducer";
import EmployeReducer from "./employeReducer";
import LevelDivisonReducer from "./clientorg";
import SpOrgReducer from "./sporg";
import ConfigurationReducer from "./configurationReducer";
import areaServiceReducer from "./AreaServiceReducer";
import subLocationReducer from "./subLocationReducer";
import ratingTemplateReducer from "./RatingTemplateReducer";
import EventReducer from "./EventReducer";
import NotificationReducer from "./NotificationReducer";
import WorkEmployeListReducer from "./WorkOrderReducer";
import PermissonReducer from "./PermissionReducer";
import PeriodicReducer from "./PeriodicReducer";
import QuestionReducer from "./QuestionReducer";
import ReportReducer from "./ReportReducer";
import dashboardReducer from "./DashboardReducer";
import ImportReducer from "./ImportFilesReducer";

const reducer = history =>
    combineReducers({
        router: connectRouter(history),
        loading: LoadingReducer,
        errors: ErrorReducer,
        auth : authReducer,
        company: companyReducer,
        companyAdmin : companyAdminReducer,
        RecentSearch : SearchReducer,
        clients : clientReducer,
        service : serviceReducer,
        contact : contactReducer,
        employe : EmployeReducer,
        LevelDivison : LevelDivisonReducer,
        SpOrg : SpOrgReducer,
        configuration:ConfigurationReducer,
        areaService : areaServiceReducer,
        subLocation : subLocationReducer,
        ratingTemplate : ratingTemplateReducer,
        events : EventReducer,
        notification : NotificationReducer,
        workOrder : WorkEmployeListReducer,
        permission : PermissonReducer,
        periodic  : PeriodicReducer,
        customQuestion : QuestionReducer,
        reports : ReportReducer,
        dashboard : dashboardReducer,
        importFiles: ImportReducer
    });

const rootReducer = (state, action) => {
    return reducer(state, action);
};
      
export default rootReducer;

import * as actionTypes from '../actionTypes';

export const getRecentSearch = (data) => {
    return {
        type: actionTypes.GetRecentSearch,
        payload: data
    }
}

export const getRecentSearchClient = (data) => {
    return {
        type: actionTypes.GetRecentSearchClient,
        payload: data
    }
}

export const getRecentSearchRequested = () => {
    return {
        type: actionTypes.GetRecentSearchRequested,
    }
}

export const getRecentSearchSuccess = (data) => {
    return {
        type: actionTypes.GetRecentSearchSuccess,
        payload: data
    }
}